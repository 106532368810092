import {
  buildTimeFromHM,
  clickEventType,
  convertTime12to24,
  followLink,
  initializeZTimeP,
  parseTime
} from '../helpers';
import { timeToFloat } from './shared';

$(document).ready(function () {
  $(function () {
    if ($('#end-of-day-wizard').length > 0) {
      $('.wizard-progress-bar').each(function () {
        var value = $(this).data('currentValue') * 100 / $(this).data('totalValues');
        $(this).progressbar({ value: value });
      });
      $('body').addClass('no-overflow');
    }
  });

  $(document).on('click', '#end-of-day-wizard-icon', function (e) {
    e.stopPropagation();
    $('body').removeClass('no-overflow');
    var date = $(this).data('date');
    followLink('/end_of_day_wizards/initial', { date: date })
  });

  $(document).on(clickEventType, '#end-of-day-wizard .timepicker-area', function (e) {
    var timepickerArea = $(this);
    var parsedTime = parseTime($('#end-of-day-wizard .working-time-value').val());
    var durationOption = timepickerArea.data('durationOption')
    var options = {
      duration: durationOption,
      hour: parsedTime.hour,
      min: parsedTime.min,
      ampm: parsedTime.ampm,
      clickEvents: {
        onSave: function (h, m, ampm) {
          var time = buildTimeFromHM(h, m);
          var timeValue = time;
          var time24h = time;
          if (durationOption) {
            timeValue = timeToFloat(time);
          } else {
            time24h = convertTime12to24(time + ' ' + ampm);
            timeValue = time24h;
          }
          timepickerArea.find('.working-time > span').text(time24h);
          $('#end-of-day-wizard .working-time-value').val(timeValue).trigger('change');
        }
      }
    };
    initializeZTimeP(options);
    $('.ztimep-open').trigger(clickEventType);
  });

  $(document).on('click', '#end-of-day-wizard .wizard-close', function () {
    var date = $('#end-of-day-wizard #date').val();
    followLink('/planned_employee_days', { date_for_scroll: date, date: date });
  });

  function checkWizardAvailability(successCallback) {
    var date = $('#end-of-day-wizard-icon').data('date');
    $.ajax({
      url: '/end_of_day_wizards/is_available',
      data: { 'date': date },
      type: 'GET',
      success: function (jsonData) {
        successCallback(jsonData.wizard_available);
      }
    });
  }

  $(document).on('click', '#end-of-day-wizard .scrollable-container .employee', function () {
    $(this).toggleClass('selected');
    var taskIds = $('#end-of-day-wizard .selected.employee').map(function () {
      return $(this).data('taskId');
    }).toArray();
    $('#end-of-day-wizard #task_ids').val(taskIds);
  });

  $(document).on('click', '#end-of-day-wizard .next-step-button', function () {
    $('#end-of-day-wizard form').submit();
  });

  $(document).on('change', '#end-of-day-wizard .working-time-value', function () {
    var isSet = false;
    var workingTimeInput = $(this);
    if (workingTimeInput.val().length > 0) {
      $('#end-of-day-wizard .main-area').removeClass('base-red').addClass('base-green');
      var isSet = true;
    } else {
      $('#end-of-day-wizard .main-area').removeClass('base-green').addClass('base-red');
    }
    if (isSet !== workingTimeInput.data('isSet')) {
      $('#end-of-day-wizard .bottom-bar .paired-element').toggleClass('hidden');
      $('#end-of-day-wizard .working-time .paired-element').toggleClass('hidden');
      $('#end-of-day-wizard .action-comment .paired-element').toggleClass('hidden');
    }
    workingTimeInput.data('isSet', isSet);
  });

  function findClosestWeekDay() {
    if (($('.employee_day_mobile').length === 0) || $('#end-of-day-wizard-icon').length === 0) { return; }
    var iconTop = $('#end-of-day-wizard-icon').offset().top;
    var weekDays = $('.week_day_mobile');
    var closestWeekDay = null;
    weekDays.each(function () {
      if ($(this).offset().top < iconTop) {
        closestWeekDay = $(this);
      }
    })
    return closestWeekDay;
  }

  $('#planner-mobile').scroll(function () {
    if (($('.employee_day_mobile').length === 0) || ($('#end-of-day-wizard-icon').length === 0)) { return; }
    var weekDay = findClosestWeekDay();
    var date = weekDay.data('date');
    var employeeDayMobile = weekDay.closest('.employee_day_mobile');
    var oldDate = $('#end-of-day-wizard-icon').data('date');
    $('#end-of-day-wizard-icon').data('date', date);
    if (oldDate !== date) {
      $('#end-of-day-wizard-icon').trigger('wizardDateChanged');
    }
  });

  $(document).on('wizardDateChanged', '#end-of-day-wizard-icon', function () {
    var icon = $('#end-of-day-wizard-icon');
    var successCallback = function (wizardAvailable) {
      wizardAvailable ? icon.removeClass('invisible') : icon.addClass('invisible');
    };
    checkWizardAvailability(successCallback);
  });

  $(document).on('click', '#end-of-day-wizard .ignore-button', function () {
    $('#end-of-day-wizard #ignore_adding').val(true);
    $('#end-of-day-wizard form').submit();
  });
});
