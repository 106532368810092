import {
  clickEventType, confirmationDialog,
  initializeZTimeP,
  parse12hTime,
} from './helpers';

$(document).on(clickEventType, '.activity', function (event) {
  var id = $(event.target).parent().data('id');
  $.ajax({
    url: '/actual_plant_activities/' + id + '/edit.js',
    type: 'GET'
  });
});

$(document).on('click', '.delete_plant_delivery', function (event) {
  event.preventDefault();
  var deliveryId = $(event.target).data('delivery-id');
  var approveCallback = function () {
    $.ajax({
      url: '/plant_deliveries/' + deliveryId,
      type: 'DELETE'
    });
  };
  var denyCallback = function () { return; }
  confirmationDialog('This will delete this delivery forever. Are you sure?',
    approveCallback, denyCallback, 'Delete delivery');
});

$(document).on('click', '.time-picker-input', function () {
  var timeInput = $(this);
  var parsedTime = parse12hTime(timeInput.val());
  var options = {
    hour: parsedTime.hour,
    min: parsedTime.min,
    ampm: parsedTime.ampm,
    duration: false,
    clickEvents: {
      onSave: function (h, m, ampm) {
        if (m == null) {
          this.min = 0;
          m = '00';
        }
        timeInput.val(h + ':' + m + ' ' + ampm);
      }
    }
  };
  initializeZTimeP(options);
  $('.ztimep-open').trigger(clickEventType);
});

$(document).on('click', '.date-picker-input', function (event) {
  var input = $(event.target);
  $('#change_date_form #datepicker').
    datepicker({ dateFormat: 'yy-mm-dd' }).
    datepicker('option', 'onSelect', function (dateText) {
      input.val(dateText);
      $(this).parent().dialog('close');
    }).datepicker('setDate', input.val());

  $('#change_date_form').dialog({
    modal: true, title: 'Date', zIndex: 10000,
    width: 'auto', resizable: false, autoOpen: true,
    close: function () {
      $(this).dialog('destroy');
    }
  });
});
