import {
  clickEventType,
  adjustOverlayAreaSizesForLoading,
  unfocusOrEnterPress,
  messageOnEmptyValue,
  handleResourceNameInput,
  convertInputToSpan
} from './helpers';
import { showFlashMessage } from './flash';

$(document).ready(function () {
  $(document).on(clickEventType, '#plan_advanced_link', function () {
    $('#plan_advanced').toggle();
    $('#plan_advanced_break_element').toggle();
    $('#plan_advanced_separator').toggle();
    adjustOverlayAreaSizesForLoading('#cmas_blocking_area');
  });

  $(document).on(clickEventType, '#subproject_edit_link', function () {
    var subprojectId = $('#subproject_dropdown').val();
    var projectId = $('#subcontractor_plan_project_id').data('id');
    window.location = '/projects/' + projectId + '/subprojects/' + subprojectId + '/edit';
  });

  var elements = ['subproject_name', 'subproject_description', 'subproject_start_date'];
  elements.forEach(function (el) {
    unfocusOrEnterPress('.' + el + '_input', updateOrSaveSubproject);
  });

  function updateOrSaveSubproject(context) {
    var val = $(context).val();
    if (($(context).attr('class') === 'subproject_description_input') && ( val === '')) {
      $(context).prev().html('click to enter description');
      $(context).prev().show();
      $(context).remove();
      return false;
    }
    if (messageOnEmptyValue(val, 'Value can\'t be empty')) { return false; }
    var attrName = $(context).attr('class').replace('subproject_', '').replace('_input', '');
    attrName = attrName.replace('hasDatepicker', '').replace(/\s/g, '');
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    var values = { };
    values['subproject[' + attrName + ']'] = val;
    if (subprojectId) {
      updateSubproject(projectId, subprojectId, values, context);
    } else {
      val = $('.subproject_name_input').val();
      if (messageOnEmptyValue(val, 'Subproject name can\'t be empty')) { return false; }
      $.ajax({
        url: '/projects/' + projectId + '/subprojects.js',
        type: 'POST',
        data: values,
        success: function () {
          showFlashMessage('Subproject has been updated', 'success');
          subprojectId = $('#subproject_id').data('id');
          window.location = '/projects/' + projectId + '/subprojects/' + subprojectId + '/edit';
        }
      });
    }
  }

  $(document).on(clickEventType, '.subproject_start_date_span', function () {
    $(document).off('focusout', '.subproject_start_date_input');
  });

  $(document).on('change', '.subproject_start_date_input', function () {
    unfocusOrEnterPress('.subproject_start_date_input', updateOrSaveSubproject);
    $(this).trigger('focusout');
  });

  $(document).on(clickEventType, '.act_name_input', function () {
    var projectId = $('#project_id').data('id');
    $(this).autocomplete({
      source: '/projects/' + projectId + '/proj_contr_acts.json'
    });
  });

  $(document).on('focusin', '.subcontract_name_input', function () {
    var projectId = $('#project_id').data('id');
    $(this).autocomplete({
      source: '/projects/' + projectId + '/subcontracts.json'
    });
  });

  $(document).on(clickEventType, '.location_name_span, .location_type_name_span, .subproject_description_span, .subproject_name_span, .subproject_start_date_span, .act_name_span, .subcontract_name_span', function () {
    convertInputToSpan(this);
  });

  function updateSubproject(projectId, subprojectId, values, context) {
    $.ajax({
      url: '/projects/' + projectId + '/subprojects/' + subprojectId,
      type: 'PUT',
      data: values,
      success: function () {
        $(context).prev().html($(context).val());
        $(context).prev().show();
        $(context).remove();
        showFlashMessage('Subproject has been updated', 'success');
      }
    });
  }

  $(function () {
    var elem = '#contr_main_acts .act_name_input';
    unfocusOrEnterPress(elem, updatePCAName);
  });

  function updatePCAName(context) {
    var val = $(context).val();
    if (messageOnEmptyValue(val, 'Value can\'t be empty')) { return false; }
    var cmaId = $(context).parent().data('cmaId');
    var projectId = $('#project_id').data('id');
    var values = { };
    values['contr_main_act[act_name]'] = val;
    values.project_id = projectId;
    $.ajax({
      url: '/contr_main_acts/' + cmaId + '/change',
      type: 'POST',
      data: values,
      success: function (data) {
        var res = data.status;
        if (res === 'ok') {
          $(context).prev().html(val);
          $(context).prev().show();
          $(context).remove();
          redrawListOfCMAs();
          showFlashMessage('Successfully changed.', 'success');
        } else {
          $(context).focus();
          showFlashMessage(res);
        }
      }
    });
  }

  $(function () {
    var elem = '#contr_main_acts .subcontract_name_input';
    unfocusOrEnterPress(elem, updateSubcontractName);
  });

  function updateSubcontractName(context) {
    var val = $(context).val();
    if (messageOnEmptyValue(val, 'Value can\'t be empty')) { return false; }
    var cmaId = $(context).parent().data('cmaId');
    var projectId = $('#project_id').data('id');
    var values = { };
    values['contr_main_act[subcontract_name]'] = val;
    values.project_id = projectId;
    $.ajax({
      url: '/contr_main_acts/' + cmaId + '/change',
      type: 'POST',
      data: values,
      success: function (data) {
        var res = data.status;
        if (res === 'ok') {
          $(context).prev().html($(context).val());
          $(context).prev().show();
          $(context).remove();
          redrawListOfCMAs();
          showFlashMessage('Successfully changed.', 'success');
        } else {
          $(context).focus();
          showFlashMessage(res);
        }
      }
    });
  }

  $(function () {
    var elem = '#unallocated_sp_acts .act_name_input';
    unfocusOrEnterPress(elem, updateSPAName);
  });

  function updateSPAName(context) {
    var val = $(context).val();
    if (messageOnEmptyValue(val, 'Value can\'t be empty')) { return false; }
    var spaId = $(context).parent().data('spaId');
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    var values = { };
    values.spa_name = val;
    values.spa_id = spaId;
    $.ajax({
      url: '/projects/' + projectId + '/subprojects/' + subprojectId + '/manage_spas',
      type: 'POST',
      data: values,
      success: function (data) { successOnUpdateSPAName(data, context); }
    });
  }

  function successOnUpdateSPAName(data, context) {
    var res = data.status;
    if (res === 'ok') {
      $(context).prev().html($(context).val());
      $(context).prev().show();
      $(context).remove();
      showFlashMessage('Successfully changed.', 'success');
    } else {
      $(context).focus();
      showFlashMessage(res);
    }
  }

  function redrawListOfCMAs() {
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    $.ajax({
      url: '/contr_main_acts/reorder',
      type: 'POST',
      dataType: 'html',
      data: { project_id: projectId, subproject_id: subprojectId },
      success: function (data) {
        $('#contr_main_acts').html(data);
        return false;
      }
    });
  }

  $(document).on(clickEventType, '#remove_cma_link', function (e) {
    e.preventDefault();
    var cmaId = $(this).parent().data('cmaId');
    $.ajax({
      url: '/contr_main_acts/' + cmaId,
      type: 'DELETE',
      dataType: 'json',
      success: function (data) {
        var res = data.status;
        if (res !== 'ok') {
          showFlashMessage(res);
          return false;
        }
        redrawListOfCMAs();
        listUnallocatedSPAs();
        showFlashMessage('Successfully removed.', 'success');
      }
    });
  });

  $(function () {
    var elem = '#unallocated_sp_acts .subcontract_name_input';
    unfocusOrEnterPress(elem, createCMA);
  });

  function createCMA(context) {
    var val = $(context).val();
    if (messageOnEmptyValue(val, 'Value can\'t be empty')) { return false; }
    var spaId = $(context).parent().data('spaId');
    var projectId = $('#project_id').data('id');
    var values = { };
    values['contr_main_act[subcontract_name]'] = val;
    values['contr_main_act[subproj_act_id]'] = spaId;
    values.project_id = projectId;
    insertCMA(context, values, successCreateCMA);
  }

  function insertCMA(context, values, callback) {
    $.ajax({
      url: '/contr_main_acts/insert',
      type: 'POST',
      data: values,
      dataType: 'json',
      success: function (data) {
        callback(data, context);
        return false;
      }
    });
  }

  function successCreateCMA(data, context) {
    var res = data.status;
    if (res !== 'ok') {
      showFlashMessage(res);
      return false;
    }
    redrawListOfCMAs();
    listUnallocatedSPAs();
    $(context).parent().remove();
    showFlashMessage('Successfully created.', 'success');
  }

  function listUnallocatedSPAs() {
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    $.ajax({
      url: '/projects/' + projectId + '/subprojects/' + subprojectId + '/unallocated_spas',
      type: 'GET',
      success: function (data) {
        $('#unallocated_sp_acts').html(data);
      }
    });
    return false;
  }

  $(document).on('keydown', '#new_plan_act .subcontract_name_input', function (e) {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code === 9) {
      var clonedElem = $(this).parent().clone();
      clonedElem.children().each(function () { $(this).val('');});
      $(this).parent().after(clonedElem);
      var subcontractName = $(this).val();
      var actName = $(this).parent().children('.act_name_input').val();
      if (messageOnEmptyValue(actName, 'Activity can\'t be empty')) {
        clonedElem.children('input').first().focus();
        return false;
      }
    }
  });

  $(document).on('focusout', '#new_plan_act .subcontract_name_input', function () {
    var val = $(this).val();
    var actNameElem = $(this).parent().children('.act_name_input');
    var actName = actNameElem.val();
    if (messageOnEmptyValue(actName, 'Activity can\'t be empty')) { return false; }
    if (val === '') {
      handleActNameInput(actNameElem);
      return true;
    }
    var values = prepareValuesForCMACreation(actName, val);
    insertCMA(this, values, successCreateCMAFromNames);
  });

  handleResourceNameInput('#new_plan_act .act_name_input', handleActNameInput);

  function handleActNameInput(context) {
    var actName = $(context).val();
    var subcontractName = $(context).parent().children('.subcontract_name_input').val();
    if ((actName.length > 0) && (subcontractName.length > 0)) {
      var values = prepareValuesForCMACreation(actName, subcontractName);
      insertCMA(context, values, successCreateCMAFromNames);
      return true;
    } else if (actName.length > 0) {
      var projectId = $('#project_id').data('id');
      var subprojectId = $('#subproject_id').data('id');
      var values = { };
      values.spa_name = actName;
      $.ajax({
        url: '/projects/' + projectId + '/subprojects/' + subprojectId + '/manage_spas',
        type: 'POST',
        data: values,
        success: function (data) {
          successCreateCMAFromNames(data, context);
          return false;
        }
      });
    }
  }

  $(document).on('keydown', '#new_plan_act .act_name_input', function (e) {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code === 9) {
      $(document).off('focusout', '#new_plan_act .act_name_input');
      $('#new_plan_act .subcontract_name_input').focus();
      return false;
    }
  });

  function prepareValuesForCMACreation(actName, subcontractName) {
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    var values = { };
    if (subcontractName !== '') {
      values['contr_main_act[subcontract_name]'] = subcontractName;
    }
    values['contr_main_act[act_name]'] = actName;
    values.project_id = projectId;
    values.subproject_id = subprojectId;
    return values;
  }

  function successCreateCMAFromNames(data, context) {
    var res = data.status;
    if (res !== 'ok') {
      showFlashMessage(res);
      return false;
    }
    var parentElem = $(context).parent();
    var clonedElem = parentElem.clone();
    var sectionElem = $('#new_plan_act');
    redrawListOfCMAs();
    listUnallocatedSPAs();
    parentElem.remove();
    clonedElem.children('input').each(function () { $(this).val('');});
    if (sectionElem.children().select('section').size() === 0) {
      sectionElem.append(clonedElem);
      clonedElem.children('input').first().focus();
    }
    showFlashMessage('Successfully created.', 'success');
  }

  $(document).bind('DOMSubtreeModified', function () {
    var elName = '.subproject_start_date_input';
    if ($(elName).length) {
      $(elName).datepicker({dateFormat: 'dd/mm/yy'});
    }

    if ($('#subproject_id').data('id') === '') {
      if ($('#subproject_activities').is(':visible')) {
        $('#subproject_activities').hide();
        $('#subproject_locations').hide();
      }
    } else if (!$('#subproject_activities').is(':visible')) {
      $('#subproject_activities').show();
      $('#subproject_locations').show();
    }

    if ($('#contr_main_acts').html() === '') {
      if ($('#subproject_activities').is(':visible')) {
        $('#subproject_change_order').hide();
      }
    } else if (!$('#subproject_change_order').is(':visible')) {
      $('#subproject_change_order').show();
    }
    if ($('#unallocated_sp_acts').html() === '') {
      if ($('#unallocated_header').is(':visible')) {
        $('#unallocated_header').hide();
      }
    } else if (!$('#unallocated_header').is(':visible')) {
      $('#unallocated_header').show();
    }
  });

  $(document).on(clickEventType, '#subproject_add_new_cma, #subproject_add_new_location_type, #subproject_add_new_location', function (e) {
    e.preventDefault();
  });

  $(document).on(clickEventType, '#remove_spa_link', function (e) {
    e.preventDefault();
    var spaId = $(this).parent().data('spaId');
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    $.ajax({
      url: '/projects/' + projectId + '/subprojects/' + subprojectId + '/remove_spa',
      type: 'POST',
      data: {spa_id: spaId},
      dataType: 'json',
      success: function (data) {
        var res = data.status;
        if (res !== 'ok') {
          showFlashMessage(res);
          return false;
        }
        listUnallocatedSPAs();
        showFlashMessage('Successfully removed.', 'success');
        return false;
      }
    });
  });
});
