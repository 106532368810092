import {
  clickEventType,
  getProjectIdFromSubcontractorPlanPage,
  showSpinner,
  massEditModeEnabled,
  stringRepresentationOfArrayOfParams
} from './helpers';
import { showFlashMessage } from './flash';

$(document).ready(function () {
  $(document).on(clickEventType, '#cma-row .cma', function () {
    if (massEditModeEnabled()) { return; }
    var cmaId = $(this).data('cmaId');
    var projectId = getProjectIdFromSubcontractorPlanPage();
    var subprojectId = $('#subproject_dropdown').val();
    var weekStart = $('#week_start_date').val();
    showSpinner();
    $.ajax({
      url: '/contr_main_acts/' + cmaId + '/edit.js',
      data: {
        'project_id': projectId,
        'week_start': weekStart,
        'subproject_id': subprojectId
      },
      type: 'GET',
      success: function () {
        $('.spinner').hide();
        $('#plan-contr-act-form').dialog({
          modal: true, title: 'Edit activity',
          autoOpen: true, resizable: false,
          open: function () {
            $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
          },
          close: function () {
            $(this).dialog('destroy');
          }
        });
      },
      error: function () {
        $('.spinner').hide();
      }
    });
  });

  $(document).on(clickEventType, '#new-plan-contr-act', function () {
    var projectId = getProjectIdFromSubcontractorPlanPage();
    var subprojectId = $('#subproject_dropdown').val();
    showSpinner();
    $.ajax({
      url: '/contr_main_acts/new.js',
      data: {'project_id': projectId, 'subproject_id': subprojectId },
      type: 'GET',
      success: function () {
        $('.spinner').hide();
      },
      error: function () {
        $('.spinner').hide();
      }
    });

    $('#plan-contr-act-form').dialog({
      modal: true, title: 'New activity', autoOpen: true,
      width: 'auto', resize: 'none',
      open: function () {
        $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
      },
      close: function () {
        $(this).dialog('destroy');
      }
    });
  });

  $(document).on(clickEventType, '.contr_main_act_day', function () {
    if (massEditModeEnabled()) { return; }
    var date = getDateFromCMATableHeader(this);
    $('#adjustment_date').val(date);
    $('#working-adjustment-popup').data('newRecord', true).dialog({
      modal: true, title: 'Working day adjustment', autoOpen: true,
      resize: 'none',
      open: function () {
        $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
      },
      close: function () {
        $('#activities').hide();
        $(this).find('#adjustment_form')[0].reset();
        $(this).dialog('destroy');
      }
    });
  });

  $(document).on('change', '#adjustment_type_dropdown', function () {
    var form = $(this).parents('#adjustment_form');
    form.find('#activities').hide();
    form.find('#subprojects').hide();
    switch (this.value) {
    case 'ContrMainAct':
      form.find('#activities').show();
      break;
    case 'Subproject':
      form.find('#subprojects').show();
      if (form.parents('#working-adjustment-popup').data('newRecord')) { selectCurrentSubprojectInWDAPopup(); }
      break;
    }
  });

  function selectCurrentSubprojectInWDAPopup() {
    var currentSubprojectId = $('#subproject_dropdown').val();
    $('#working-adjustment-popup #subprojects .subproject').each(function () {
      if ($(this).data('id') === currentSubprojectId) {
        $(this).find('input[type=checkbox]').prop('checked', true);
      } else {
        $(this).find('input[type=checkbox]').prop('checked', false);
      }
    });
  }

  function getDateFromCMATableHeader(context) {
    var weekStartDate = $('#week_start_date').val();
    var date = $.datepicker.parseDate('dd/mm/yy', weekStartDate);
    var year = (new Date(date)).getFullYear();
    var shortDate = $(context).text().replace(/^\w{3}/, '');
    var fullDate = new Date(shortDate + ' ' + year);
    return $.datepicker.formatDate('dd/mm/yy', fullDate);
  }

  $(document).on(clickEventType, '#plan_view_pdf_report', function () {
    var projectId = getProjectIdFromSubcontractorPlanPage();
    var subprojectId = $('#subproject_dropdown').val();
    var weekStartDate = $('#week_start_date').val();
    var daysCount = $('.contr_main_act_day').length;
    var calId = $('#plan_cal_dropdown').val();
    var locationIds = $('#plan_locations_dropdown').val();
    var contractorIds = $('#plan_contractors_dropdown').val();
    var activityIds = $('#plan_activities_dropdown').val();
    if (!subprojectId) {
      showFlashMessage('Subproject can\'t be empty');
      return;
    }
    if (!weekStartDate) {
      showFlashMessage('Week start date can\'t be empty');
      return;
    }
    var params = paramsForPlanViewPdfReport(projectId, subprojectId, weekStartDate, daysCount, calId, locationIds, contractorIds, activityIds);
    window.location.href = '/contr_main_acts/pdf_report.pdf?' + params;
  });

  function paramsForPlanViewPdfReport(projectId, subprojectId, weekStartDate, daysCount, calId,
    locationIds, contractorIds, activityIds) {
    var params = 'project_id=' + projectId + '&subproject_id=' + subprojectId +
      '&week_start=' + weekStartDate + '&days_count=' + daysCount;
    if (calId) { params += '&contr_act_loc_id=' + calId; }
    if (locationIds) { params += '&' + stringRepresentationOfArrayOfParams(locationIds, 'location_ids'); }
    if (contractorIds) { params += '&' + stringRepresentationOfArrayOfParams(contractorIds, 'contractor_ids'); }
    if (activityIds) { params += '&' + stringRepresentationOfArrayOfParams(activityIds, 'activity_ids'); }
    return params;
  }
});
