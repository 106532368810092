import {
  clickEventType,
  openConfirmationPopup,
  openPopup,
  showUploadedPhotos
} from '../helpers';
import {
  checkHovering,
  timeToFloat,
  updateClockIconColor,
  stylePopup,
  setMobilePopupWidth,
  getDateFromEmployeeDay,
  getProjectId,
  getEmployeeId,
  getSupervisorId,
  openHover,
  validStringForTime,
  isTaskBasedView,
  showManagePlannedEmployeesPopup
} from './shared';
import { showFlashMessage } from '../flash';

$(document).ready(function () {
  $(document).on('click', '#employee_planner_view .task_hover', function () {
    var date = getDateFromEmployeeDay($(this));
    var supervisorId = getSupervisorId($(this));
    var projectId = getProjectId($(this));
    var employeeId = getEmployeeId($(this));
    var data = { employee_id: employeeId, project_id: projectId, supervisor_id: supervisorId, date: date };
    $.ajax({
      url: '/planned_employee_days/tasks',
      type: 'GET',
      data: data,
      success: function (data) {
        var selector = '#tasks_popup';
        var openCallback = function () {
          $(selector).data('date', date);
          $(selector).data('supervisorId', supervisorId);
          $(selector).data('projectId', projectId);
          $(selector).data('employeeId', employeeId);
          stylePopup(selector);
        };
        var closeCallback = function () {
          $(selector).dialog('destroy');
          drawPlanner(false);
        };
        openPopup(selector, data, openCallback, closeCallback);
      }
    });
    return false;
  });

  $(document).on('click', '#tasks_popup #new_task', function () {
    $('#tasks_popup').closest('.ui-dialog').hide();
    var context = contextWithDataForNewTaskPopup($(this));
    var date = context.data('date');
    var employeeId = context.data('employeeId');
    var projectId = context.data('projectId');
    var supervisorId = context.data('supervisorId');
    $.ajax({
      url: '/planned_employee_days/employee_competencies',
      type: 'GET',
      data: {
        date: date,
        employee_id: employeeId,
        project_id: projectId,
        supervisor_id: supervisorId
      },
      success: function (data) {
        var selector = '#new_task_popup';
        var openCallback = function () {
          $(selector).data('date', date);
          $(selector).data('employeeId', employeeId);
          $(selector).data('projectId', projectId);
          $(selector).data('supervisorId', supervisorId);
          stylePopup(selector);
          $(selector).data('date', date);
          $(selector).data('employeeId', employeeId);
          $(selector).data('projectId', projectId);
          $(selector).data('supervisorId', supervisorId);
          $(selector).dialog('option', 'autoSize', true);
        };
        var closeCallback = function () {
          $(selector).dialog('destroy');
          $('#tasks_popup').closest('.ui-dialog').show();
          drawPlanner(false);
        };
        openPopup(selector, data, openCallback, closeCallback);
      }
    });
    return false;
  });

  function contextWithDataForNewTaskPopup(element) {
    var result = null;
    if ($('#planner-mobile').length) {
      result = element.closest('.employee_mobile');
    } else if ($('#end-of-day-wizard .summary-step').length) {
      result = $('#summary-step-data');
    } else {
      result = element.closest('#tasks_popup');
    }
    return result;
  }

  $(document).on('click', '#tasks_planner_view .add_task, .task_day_mobile .add_task', function () {
    open_add_task_popup(this);
  });

  function open_add_task_popup(context) {
    var supervisorId = $(context).data('supervisorId');
    var projectId = $(context).data('projectId');
    var date = $(context).data('date');
    var data = { supervisor_id: supervisorId };
    var selector = '#new_task_popup';
    var openCallback = function () {
      $(selector).data('date', date);
      $(selector).data('supervisorId', supervisorId);
      $(selector).data('projectId', projectId);
      stylePopup(selector);
      $(selector).dialog('option', 'width', setMobilePopupWidth());
      $(selector).dialog('option', 'autoSize', true);
    };
    var closeCallback = function () {
      $(selector).dialog('destroy');
    };
    var openNewTaskPopupCallback = function (content) {
      openPopup(selector, content, openCallback, closeCallback);
    };
    showCompetencies(data, openNewTaskPopupCallback);
    return false;
  }

  $(document).on('click', '.competency', function(){
    // HANDLE DATES WITH FUTURE TASK VAR !
    var competencyId = $(this).data('companyCompetencyId');
    var otherTasksMode = $('#new_task_popup').data('otherTasksMode');
    var competencyHasChildren = $(this).data('hasChildren');
    var employeeId = $('#tasks_popup').data('employeeId');
    var date = $('#tasks_popup').data('date');
    var today = new Date();
    if ($(this).parents('#new_task_popup').length === 0) {
      var dateObj = new Date(date.substring(6, 10), date.substring(3, 5) - 1, date.substring(0, 2));
      var futureDate = (dateObj > today);
    }
    var data = {};
    if (competencyHasChildren && !otherTasksMode) {
      date = date || $.datepicker.formatDate('dd/mm/yy', today);
      data = {
        competency_id: competencyId,
        employee_id: employeeId,
        date: date,
        only_competencies_section: true
      };
      showCompetencies(data);
    } else {
      $("body").css({ overflow: 'inherit' });
      date = $('#new_task_popup').data('date');
      var supervisorId = $('#new_task_popup').data('supervisorId');
      $('#new_task_popup').dialog('destroy');
      if (isTaskBasedView()) {
        var data = {
          supervisor_id: supervisorId,
          competency_id: competencyId
        };
        if ($('.task_day_mobile').first().is(':visible')) {
          data.date = date;
        }
        showFinalStepForCreatingPlannedTask(data);
      } else {
        createTask(date, $(this));
      }
    }
    return false;
  });

  function getConfirmationAboutAddingEmployees(data, taskIds) {
    var content = '<p>Would you like to add employees now?</p>';
    var popupSelector = '#confirmation_popup';
    var options = [
      {
        text: 'Yes',
        click: function() {
          addEmployeesToPlannedTask(data, taskIds);
          $(popupSelector).dialog('destroy');
        }
      },
      {
        text: 'No',
        click: function() {
          $(popupSelector).dialog('destroy');
        }
      }
    ];
    var openCallback = function() { stylePopup(popupSelector); };
    openConfirmationPopup(popupSelector, content, openCallback, null, options);
  }

  function addEmployeesToPlannedTask(dataAttributes, taskIds) {
    var data = {
      task_id: taskIds[0],
      task_class: 'PlannedTask',
      date: dataAttributes['planned_from'],
      project_id: dataAttributes['project_id'],
      supervisor_id: dataAttributes['supervisor_id'],
      competency_id: dataAttributes['competency_id']
    };
    var datesRangeSection =
      "<div> \
        <h3>Dates range:</h3> \
        <span>" + dataAttributes['planned_from'] + ' - ' + dataAttributes['planned_to'] + "</span> \
      </div>"
    var preparePopup = function() {
      $('#required_employees_section').hide();
      $('#required_employees_section').after(datesRangeSection);
      $('#manage_planned_employees_popup').data('taskIds', taskIds);
      $('#manage_planned_employees_popup').data('plannedFrom', dataAttributes['planned_from']);
      $('#manage_planned_employees_popup').data('plannedTo', dataAttributes['planned_to']);
      $('#manage_planned_employees_popup').data('onlyPlannedEmployees', true)
    }
    showManagePlannedEmployeesPopup(data, preparePopup);
  }

  function showFinalStepForCreatingPlannedTask(data) {
    $.ajax({
      url: 'planned_employee_days/planned_task_final_step',
      type: 'GET',
      data: data,
      success: function (data) {
        var selector = '#planned_task_final_step_popup';
        var openCallback = function () {
          stylePopup(selector);
          var minDate = new Date();
          minDate.setDate(minDate.getDate() + 1);
          $('.datepicker').datepicker({ minDate: minDate, dateFormat: 'dd/mm/yy' });
        };
        var closeCallback = function () {
          $(selector).dialog('destroy');
          drawPlanner(false);
        };
        openPopup(selector, data, openCallback, closeCallback);
      }
    });
    return false;
  }

  var ajaxRequest = null;
  function showCompetencies(data, callback) {
    if (ajaxRequest !== null) { ajaxRequest.abort(); }
    ajaxRequest = $.ajax({
      url: '/planned_employee_days/company_competencies',
      type: 'GET',
      data: data,
      success: function (content) {
        $('#new_task_popup #competencies_section').html(content);
        if (callback) { callback(content); }
      }
    });
  }

  function createTask(date, competency, description) {
    var contextWithData = $('#new_task_popup');
    var employeeId = contextWithData.data('employeeId');
    var projectId = contextWithData.data('projectId');
    var supervisorId = contextWithData.data('supervisorId');
    if (competency) {
      var competencyId = competency.data('companyCompetencyId');
    }
    var taskParams = {
      supervisor_id: supervisorId,
      competency_id: competencyId,
      description: description
    };
    $.ajax({
      url: '/planned_employee_days/create_task',
      type: 'POST',
      data: {
        date: date,
        employee_id: employeeId,
        project_id: projectId,
        supervisor_id: supervisorId,
        task: taskParams
      },
      success: function (data) {
        if ($('#planner-mobile').length) {
          var summaryStepData = $('#end-of-day-wizard #summary-step-data');
          var context = summaryStepData.length ? summaryStepData : $('#new_task_popup');
          showEmployeeDayTasks(date, context, true);
        } else if ($('#end-of-day-wizard .summary-step').length) {
          $('#end-of-day-wizard .summary-step .employee_tasks').html(data);
        } else {
          $('#tasks_popup').html(data);
          $('#tasks_popup').closest('.ui-dialog').show();
        }
      }
    });
  }

  function showEmployeeDayTasks(date, context, keepOpen) {
    var employeeId = getEmployeeIdMobile(context);
    var employeeMobile = $('.employee_mobile[data-date="' + date + '"]');
    var element = employeeMobile.find('#toggle_employee_details_' + employeeId);
    if ($('#end-of-day-wizard .employee-details-content').length) {
      element = $('#end-of-day-wizard .employee-details-content');
    }
    if (element.is(':visible') && !keepOpen) {
      element.hide();
    } else {
      var supervisorId = getSupervisorIdMobile(context);
      var projectId = getProjectIdMobile(context);
      var data = { employee_id: employeeId, project_id: projectId, supervisor_id: supervisorId, date: date };
      $.ajax({
        url: '/planned_employee_days/tasks',
        type: 'GET',
        data: data,
        success: function (data) {
          element.html(data);
          var employeeDetails = element.children('.employee_details');
          employeeDetails.data('employeeId', employeeId);
          var tasksPopup = employeeDetails.children('#tasks_popup');
          tasksPopup.data('date', date);
          tasksPopup.data('employeeId', employeeId);
          tasksPopup.data('supervisorId', supervisorId);
          tasksPopup.data('projectId', projectId);
          element.show();
          if ($('#planner-mobile').length) { updateClockIconColor(employeeDetails); }
          var employeeMobile = $('.employee_mobile[data-date="' + date + '"][data-employee-id="' + employeeId + '"]');
          updateCounter('employees', employeeMobile);
        }
      });
    }
  }

  function getProjectIdMobile(context) {
    return context.data('projectId');
  }

  function getSupervisorIdMobile(context) {
    return context.data('supervisorId');
  }

  function getEmployeeIdMobile(context) {
    return context.data('employeeId');
  }

  $(document).on('click', '#other-activities', function () {
    var employeeId = $('#tasks_popup').data('employeeId');
    var date = $('#tasks_popup').data('date');
    $.ajax({
      url: '/planned_employee_days/company_competencies',
      type: 'GET',
      data: {
        date: date,
        employee_id: employeeId
      },
      success: function (data) {
        $('#new_task_popup').html(data);
        $('#new_task_popup').data('otherTasksMode', true);
        $('#other-activities, #search_for_activity, #add_new_task').hide();
      }
    });
    return false;
  });

  $(document).on('click', '#new_task_popup .custom-activity', function () {
    var description = $(this).text();
    createCustomTask(description);
  });

  $(document).on('click', '#save_new_custom_task', function() {
    var description = $('#search_for_activity').val();
    createCustomTask(description);
  });

  function createCustomTask(description) {
    var date = $('#new_task_popup').data('date');
    $('#new_task_popup').dialog('destroy');
    $("body").css({ overflow: 'inherit' });
    if (isTaskBasedView()) {
      var supervisorId = $('#new_task_popup').data('supervisorId');
      var data = {
        description: description,
        supervisor_id: supervisorId,
        date: date
      };
      showFinalStepForCreatingPlannedTask(data);
    } else {
      createTask(date, undefined, description);
    }
  }

  $(document).on('keyup', '#search_for_activity', function () {
    var date = $('#new_task_popup').data('date');
    var employeeId = $('#new_task_popup').data('employeeId');
    var supervisorId = $('#new_task_popup').data('supervisorId');
    var activityName = $(this).val();
    var competencyId = $('#new_task_popup #parent_competency').data('companyCompetencyId');
    var data = {
      date: date,
      employee_id: employeeId,
      competency_id: competencyId,
      activity_name: activityName,
      supervisor_id: supervisorId,
      only_competencies_section: true
    };
    var callback = function () {
      var searchField = $('#search_for_activity');
      if (searchField.val().length) {
        $('#new_task_popup #parent_competency, #new_task_popup .planned_tasks').hide();
      } else {
        $('#new_task_popup .planned_tasks').show();
      }
    };
    showCompetencies(data, callback);
  });

  $(document).on('mouseover', '#employee_planner_view .task_hover', function (event) {
    if (checkHovering(event, this)) {
      var date = getDateFromEmployeeDay($(this));
      var supervisorId = getSupervisorId($(this));
      var projectId = getProjectId($(this));
      var employeeId = getEmployeeId($(this));
      var data = { employee_id: employeeId, project_id: projectId, supervisor_id: supervisorId, date: date };
      var context = this;
      $.ajax({
        url: '/planned_employee_days/tasks',
        type: 'GET',
        data: data,
        success: function (data) {
          openHover(data, context, hideElementsForTasksHoverPopup);
        }
      });
    }
  });

  function hideElementsForTasksHoverPopup() {
    $('.employee_day_task .delete_task').hide();
    $('#hover_popup #new_task').hide();
  }

  $(document).on('click', '.save_task_button', function () {
    var element = $(this).closest('.container_with_task_tracked_time').find('.task_tracked_time_input');
    var value = element.val();
    var notificationSelector = '#new_task_popup #errors';
    if (!validStringForTime(value)) {
      showFlashMessage('Invalid time', 'danger', notificationSelector);
      var untrackedTimeIcon = $(element).next();
      $(element).remove();
      if (taskTrackedTimeSpan.html() === '') {
        untrackedTimeIcon.show();
      } else {
        taskTrackedTimeSpan.show();
      }
      return;
    }
    var duration = timeToFloat(value);
    var taskId = $('#new_task_popup').data('taskId');
    var projectId = $('#new_task_popup').data('projectId');
    var supervisorId = $('#tasks_popup').data('supervisorId');
    var employeeId = $('#new_task_popup').data('employeeId');
    var date = $('#new_task_popup').data('date');
    var selectorForAssignedEmployee = '.employee_mobile[data-employee-id="' + employeeId + '"][data-date="' + date + '"]';
    var employeeDetails = $(selectorForAssignedEmployee).find('.employee_details');
    var activityPeriodParams = { activity_type: 'working_time', duration: duration };

    $.ajax({
      url: '/employee_activity_periods',
      type: 'PUT',
      data: {
        date: date,
        project_id: projectId,
        supervisor_id: supervisorId,
        task_id: taskId,
        employee_activity_period: activityPeriodParams
      },
      success: function (data) {
        if (data.status === 'ok') {
          $('#new_task_popup').dialog('destroy');
          var summaryStepData = $('#end-of-day-wizard #summary-step-data');
          var context = summaryStepData.length ? summaryStepData : $('#tasks_popup');
          showEmployeeDayTasks(date, context, true);
          showFlashMessage('Updated', 'success', notificationSelector);
        } else {
          showFlashMessage(data.status, 'success', notificationSelector);
        }
      }
    });
  });

  $(document).on('click', '.colored_employee_task_mobile', function () {
    var task = $(this);
    var taskId = task.data('id');
    var taskClass = task.data('taskClass');
    var taskDescription = task.data('taskDescription');
    var contextWithData = contextWithDataForNewTaskPopup($(this));
    var employeeId = contextWithData.data('employeeId');
    var date = contextWithData.data('date');
    var supervisorId = contextWithData.data('supervisorId');
    var projectId = contextWithData.data('projectId');
    var context = this;
    var selector = '#new_task_popup';
    var openCallback = function () {
      $.ajax({
        url: '/planned_employee_days/show_task_mobile',
        type: 'GET',
        data: {task_id: taskId, task_class: taskClass},
        success: function (data) {
          $(selector).html(data);
          $(selector).data('employeeId', employeeId);
          $(selector).data('taskId', taskId);
          $(selector).data('date', date);
          $(selector).data('supervisorId', supervisorId);
          $(selector).data('projectId', projectId);
          var commentInputBox = $(selector).find('#comment_input_box');
          var uploaderElem = commentInputBox.siblings('.photoupload_comment');
          uploaderElem.show();
          initializeUpload(commentInputBox, context);
          showUploadedPhotos();
        }
      });
      stylePopup(selector);
      $(selector).dialog('option', 'width', setMobilePopupWidth());
    };
    var closeCallback = function () {
      $(selector).dialog('destroy');
      showEmployeeDayTasks(date, contextWithData, true);
    };
    openPopup(selector, '', openCallback, closeCallback);
    return false;
  });

  $(document).on('click', '.employees_for_day_mobile.actual_status, .employees_for_day_mobile.future_status', function () {
    var date = $(this).closest('.employee_mobile').data('date');
    showEmployeeDayTasks(date, $(this).parents('.employee_mobile'), false);
  });

  function initializeUpload(input, context) {
    var parentElem = {};
    var taskId = '';
    if ($(context).parents('.employee_day_task').length) {
      parentElem = input.parents('.employee_day_task');
      taskId = parentElem.data('id');
    } else {
      parentElem = input.parents('.assigned_to_task_employee');
      taskId = parentElem.data('taskId');
    }

    if ($(document).find('#planner-mobile').length) {
      var mobileCounter = true;
      parentElem = input.parents('.container_with_task_tracked_time');
      taskId = parentElem.data('taskId');
    }
    parentElem.find('.photoupload_comment #fileupload').fileupload({
      dataType: 'html',
      replaceFileInput: false,
      fileInput: $('input:file'),
      add: function (e, data) {
        var taskClass = parentElem.data('taskClass');
        data.context = set_button_for_upload().addClass('nav-button')
          .appendTo(parentElem)
          .click(function () {
            data.url = '/planned_employee_days/leave_task_comment?task_id=' + taskId + '&task_class=' + taskClass;
            data.context = $('#submit_button').text('Uploading...').replaceAll($(this));
            data.submit();
          });
      },
      done: function (e, data) {
        parentElem.find('.task_comments').html(data.result);
        taskCount = input.siblings('.task_comments').find('.panel').size();
        if (mobileCounter) {
          $(context).find('.comments_count').html(taskCount);
        } else {
          parentElem.find('.comments_count').html(taskCount);
        }
        showUploadedPhotos();
        input.val('');
        input.attr('placeholder', '');
        reset_inputs(data);
      },
      fail: function (e, data) {
        flash_upload_error('Something went wrong. Try again later.');
        reset_inputs(data);
      }
    }).on('fileuploadsubmit', function (e, data) {
      var input = $('#comment_input_box');
      data.formData = {message: input.val()};

      return validate_photo(data);
    });
  }

  $(document).on('click', '.employee_day_task .fa-comment', function () {
    var task = $(this).closest('.employee_day_task');
    var taskId = task.data('id');
    toggleCommentsSection(task, taskId, this);
  });

  $(document).on('click', '.assigned_to_task_employee .fa-comment', function () {
    var employee = $(this).closest('.assigned_to_task_employee');
    var taskId = employee.data('taskId');
    toggleCommentsSection(employee, taskId, this);
  });

  function toggleCommentsSection(parentElement, taskId, commentIcon) {
    var taskComments = parentElement.find('.task_comments');
    var taskClass = parentElement.data('taskClass');
    var data = { task_id: taskId, task_class: taskClass };
    var commentInputBox = parentElement.find('#comment_input_box');
    showComments(taskComments, commentInputBox, data, commentIcon);
  }

  function showComments(taskComments, commentInputBox, data, context) {
    var uploaderElem = commentInputBox.siblings('.photoupload_comment');
    if (taskComments.is(':visible')) {
      taskComments.hide();
      commentInputBox.hide();
      uploaderElem.hide();
    } else {
      $.ajax({
        url: '/planned_employee_days/task_comments',
        type: 'GET',
        data: data,
        success: function (data) {
          taskComments.html(data);
          commentInputBox.show();
          uploaderElem.show();
          taskComments.show();
          initializeUpload(commentInputBox, context);
          showUploadedPhotos();
        }
      });
    }
  }

  $(document).on('click', '.employee_day_task .delete_task', function () {
    var task = $(this).closest('.employee_day_task');
    var id = task.data('id');
    var taskClass = task.data('taskClass');
    $.ajax({
      url: '/planned_employee_days/destroy_task',
      type: 'DELETE',
      data: { task_id: id, task_class: taskClass },
      success: function (data) {
        if (data.status === 'ok') {
          task.remove();
          drawPlanner(false);
        } else {
          showFlashMessage(data.status, 'danger', '#tasks_popup');
        }
      }
    });
  });

  $(document).on('click', '#planned_task_final_step_popup #save_planned_task', function() {
    createPlannedTask();
  });

  function createPlannedTask() {
    var requestParams = getDataForSavingPlannedTask();
    $.ajax({
      url: 'planned_employee_days/create_planned_task',
      type: 'POST',
      data: requestParams,
      success: function(data){
        if (data.status === 'ok'){
          $('#planned_task_final_step_popup').dialog('destroy');
          $("body").css({ overflow: 'inherit' });
          drawPlanner(false);
          getConfirmationAboutAddingEmployees(requestParams.task, data.task_ids);
        } else {
          showFlashMessage(data.status, 'danger', '#planned_task_final_step_popup #errors');
        }
      }
    });
  }

  function getDataForSavingPlannedTask() {
    var plannedFrom = $('#planned_task_final_step_popup #planned_from').val();
    var plannedTo = $('#planned_task_final_step_popup #planned_to').val();
    var requiredLabour = $('#planned_task_final_step_popup #required_labour').val();
    var supervisorId = $('#new_task_popup').data('supervisorId');
    var projectId = $('#new_task_popup').data('projectId');
    var data = {
      task: {
        planned_from: plannedFrom,
        planned_to: plannedTo,
        supervisor_id: supervisorId,
        required_labour: requiredLabour,
        project_id: projectId
      }
    };
    if ($('#planned_task_final_step_popup #competency_id').length) {
      var competencyId = $('#planned_task_final_step_popup #competency_id').val();
      data.task.competency_id = parseInt(competencyId, 10);
    }
    if ($('#planned_task_final_step_popup #description').length) {
      data.task.description = $('#planned_task_final_step_popup #description').val();
    }
    return data;
  }

  $(document).on('keypress', '.employee_day_task #comment_input_box, .assigned_to_task_employee #comment_input_box, .task_comments_mobile #comment_input_box', function (e) {
    if (e.which === 13) {
      var commentInputBox = $(this);
      var container = $(this).closest('.container_with_task_tracked_time');
      var keyForTaskId = container.hasClass('employee_day_task') ? 'id' : 'taskId';
      var taskId = container.data(keyForTaskId);
      var taskClass = container.data('taskClass');
      var url = '/planned_employee_days/leave_task_comment';
      var message = $(this).val();
      $.ajax({
        url: url,
        type: 'POST',
        data: { message: message, task_id: taskId, task_class: taskClass },
        success: function (data) {
          commentInputBox.val('');
          var taskComments = container.find('.task_comments');
          taskComments.html(data);
          var commentsCount = taskComments.find('div.panel').length;
          container.find('.comments_count').text(commentsCount);
        }
      });
    }
  });


  $(document).on('click', '.delete_task_mobile', function () {
    var task = $(this).closest('.delete_task_mobile');
    var id = task.data('taskId');
    var taskClass = task.data('taskClass');
    $.ajax({
      url: '/planned_employee_days/destroy_task',
      type: 'DELETE',
      data: { task_id: id, task_class: taskClass },
      success: function (data) {
        if (data.status === 'ok') {
          if ($('#end-of-day-wizard').length) {
            $('#new_task_popup').dialog('destroy');
            var date = $('#summary-step-data').data('date');
            showEmployeeDayTasks(date, $('#summary-step-data'), true);
          } else {
            var taskId = $('#new_task_popup').data('taskId');
            var employeeId = $('#new_task_popup').data('employeeId');
            $('#new_task_popup').dialog('destroy');
            $('body').css({ overflow: 'inherit' });
            $('#tasks_popup').parents('#toggle_employee_details_' + employeeId).find("[data-id='" + taskId + "']").remove();
            drawPlanner(false);
            showFlashMessage('Task deleted', 'success', '#new_task_popup');
          }
        } else {
          showFlashMessage(data.status, 'danger', '#new_task_popup');
        }
      }
    });
    return false;
  });

  $(document).on('click', '.convert_planned_to_actual', function (event) {
    event.stopPropagation();
    var plannedTaskId = $(this).closest('.colored_employee_task_mobile').data('id');
    var edAttr = $(this).closest('.employee_mobile');
    var date = edAttr.data('date');
    var employeeId = edAttr.data('employee-id');
    var projectId = edAttr.data('project-id');
    var supervisorId = edAttr.data('supervisor-id');
    var data = {
      date: date,
      employee_id: employeeId,
      project_id: projectId,
      supervisor_id: supervisorId,
      planned_task_id: plannedTaskId
    };
    $.ajax({
      url: 'planned_employee_days/create_task',
      type: 'POST',
      data: data,
      success: function (data) {
        var mobileDetails = edAttr.find('#toggle_employee_details_' + employeeId);
        mobileDetails.find('.employee_tasks').html(data);
        updateCounter('employees', edAttr);
        updateClockIconColor(mobileDetails);
      }
    });
    return false;
  });

  function updateCounter(type, attr) {
    if (attr.length === 0) { return; }
    var date = attr.data('date');
    var employee_id = attr.data('employee-id');
    var project_id = attr.data('project-id');
    var supervisor_id = attr.data('supervisor-id');
    var dateObj = new Date(date.substring(6, 10), date.substring(3, 5) - 1, date.substring(0, 2));
    var today = new Date();
    var futureDate = (dateObj > today);
    var employeeSection = $("[data-date='" + date + "'][data-supervisor-id='" + supervisor_id + "'][data-employee-id='" + employee_id + "'][data-project-id='" + project_id + "']");
    var tasks = employeeSection.find('.employee_tasks').children();
    var display = 'block';
    var totalCount = 0;
    var readyCount = 0;
    tasks.each( function (i) {
      if ($(this).data('task-class') === 'Task') {readyCount++;}
      totalCount++;
    } );
    var state = '';
    var blockText = '';
    if (futureDate) {
      blockText = totalCount;
      state = 'future';
    } else {
      blockText = readyCount + '/' + totalCount;
      var completed = readyCount === totalCount;
      state = completed ? 'completed' : 'incompleted';
    }
    var classes =  state + '_task ' + type + '_counter task_hover';
    var counter = employeeSection.find('.employees_counter');
    counter.html(blockText);
    counter.removeClass().addClass(classes);
  }

  $(document).on(clickEventType, '#new_task_popup .nav_competency', function () {
    var date = $('#tasks_popup').data('date');
    var employeeId = $('#tasks_popup').data('employeeId');
    var competencyId = $(this).data('id');
    var data = {
      date: date,
      employee_id: employeeId,
      competency_id: competencyId,
      only_competencies_section: true
    };
    showCompetencies(data);
  });
});
