import { getEmployeePlannerDate } from './draw_employee_planner';
import { changeDateByDays } from '../helpers';
import { checkHovering, removeHoverPopup } from './shared';

$(document).ready(function () {
  $(function () { drawPlanner(); });

  $(document).on('click', '.week-navbar #next_week_icon', function () {
    $('.week-navbar #next_week').focus();
  });

  $(document).on('click', '.week-navbar #prev_week_icon', function () {
    $('.week-navbar #prev_week').focus();
  });

  $(document).on('mouseout', '.employee_hover, .task_hover', function (event) {
    if (checkHovering(event, this)) {
      removeHoverPopup();
    }
  });

  $(document).on('click', '#change-week-button', function () {
    drawPlanner(false);
  });

  $(document).on('click', '.week-navbar .prev-week', function () {
    navigateToAnotherWeek(-7);
  });

  $(document).on('click', '.week-navbar .next-week', function () {
    navigateToAnotherWeek(7);
  });

  function navigateToAnotherWeek (daysDiff) {
    var date = getEmployeePlannerDate();
    var newDate = changeDateByDays(date, daysDiff);
    var formattedDate = $.datepicker.formatDate('dd/mm/yy', newDate);
    $('#start_day').val(formattedDate);
    drawPlanner(false);
  }

  $('#start_day').datepicker({
    firstDay: 1,
    dateFormat: 'dd/mm/yy',
    beforeShowDay: function (date) {
      return [date.getDay() === 1];
    }
  });

  $(document).on('click', '.unallocated', function () {
    if ($(this).hasClass('selected-employee')) {
      $(this).removeClass('selected-employee');
    } else {
      $(this).addClass('selected-employee');
    }
    toggleAddToProjectButton();
  });

  function toggleAddToProjectButton () {
    if ($('.selected-employee').length > 0) {
      if ($('#add-button-div').length === 0) {
        var el = $('#unallocated_section');
        var buttonHtml = addToProjectButtonTemplate();
        el.after(buttonHtml);
      }
    } else {
      $('#add-button-div').remove();
    }
  }

  function addToProjectButtonTemplate () {
    return '<div id="add-button-div" data-span="7"><button class="nav-button" id="add-to-project-button">+Add to project</button></div>';
  }

  function getSelectedEmployees () {
    return $('.selected-employee').map(function (_, el) { return $(el).data('employeeId'); }).toArray();
  }

  $(document).on('click', '.tasks_employees .slider', function (e) {
    e.stopPropagation();
    var plannerMode = $('.tasks_employees .slider');
    if (plannerMode.hasClass('employees_mode')) {
      switchClasses(plannerMode, 'tasks_mode', 'employees_mode');
    } else {
      switchClasses(plannerMode, 'employees_mode', 'tasks_mode');
    }
    drawPlanner(false);
  });

  function switchClasses (el, classTo, classFrom) {
    el.addClass(classTo);
    el.removeClass(classFrom);
  }
})
