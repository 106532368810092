import { clickEventType } from './helpers';

$(document).ready(function () {
  $(document).on('keyup', '.numbersOnly', function () {
    this.value = this.value.replace(/[^0-9\.]/g, '');
  });

  $('#operator_id').on('change', function () {
    var currOperatorId = $(this).val();
    var regexp = /operator\/(\d+)/;
    $('.plant_deliveries_link').each(function () {
      var form = $(this).parent();
      var prevOperatorId = form.attr('action').match(regexp)[1];
      var newLink = form.attr('action').replace(prevOperatorId, currOperatorId);
      form.attr('action', newLink);
    });
  });

  $(document).on(clickEventType, '#login_as_operator', function () {
    var plantId = $(this).data('plantId');

    $('[id=submit_operator]').each(function () {
      var operatorId = $(this).children('#operator_id').val();
      var url = '/plants/' + plantId + '/operator/' + operatorId + '/day_activities';
      $(this).attr('action', url);
      $(this).attr('method', 'post');
    });
    $('#operators_dialog').dialog({
      modal: true, title: 'Login as operator', zIndex: 10000, autoOpen: true,
      width: 'auto', resizable: false,
      close: function () {
        $(this).hide();
      }
    });
  });
});
