import { clickEventType } from './helpers';

$(document).ready(function () {
  $(document).on(clickEventType, '#new_report_subscription', function () {
    var userId = $(this).data('userId')
    $.ajax({
      data: {user_id: userId},
      url: '/report_subscriptions/new.js',
      type: 'GET'
    });
  });
});
