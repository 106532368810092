import { clickEventType } from './helpers';

$(document).ready(function(){
  $(document).on(clickEventType, '#user_activity_rule_edit_dialog', function(){
    var ruleId = $(this).data('ruleId');
    $.ajax({
      url: '/user_activity_rules/'+ruleId+ '/edit.js',
      type: 'GET',
      success: function(){
        $('#user-activity-rule-form').dialog({
          modal: true, resizable: false,
          title: 'Edit Rule', autoOpen: true,
          close: function() {
            $('#user-activity-rule-form').html('');
            $(this).dialog('destroy');
          }
        })
      }
    })
  })
})
