import {
  clickEventType,
  emailIsValid,
  followLink,
  phoneIsValid
} from './helpers';
import { showFlashMessage } from './flash';

$(document).ready(function () {
  $(document).on(clickEventType, '.subscribe_people_icon', function () {
    var id = $(this).closest('li').data('cmaId');
    var subcontractName = $(this).siblings('.subcontract_name_span').text();
    $.ajax({
      url: '/contr_main_acts/' + id + '/cma_subscription_invites',
      type: 'GET',
      dataType: 'html',
      success: function (data) {
        $('#cma_subscription_invites_popup').html(data);
        $('#cma_subscription_invites_popup a').attr('tabindex', -1);
        $('#cma_subscription_invites_popup').data('cmaId', id);
        $('#cma_subscription_invites_popup').data('subcontractName', subcontractName);
        $('#cma_subscription_invites_popup').dialog({
          modal: true, title: 'Subscribe people to activity',
          autoOpen: true, resizable: false, width: 'auto',
          close: function () {
            $(this).dialog('destroy');
          }
        });
        $('#cma_subscription_invites_popup').closest('.ui-dialog').attr('tabindex', -1).focus();
      }
    });
  });

  $(document).on(clickEventType, '#show_invitation_section', function () {
    $('#invitation_section').show();
  });

  $(document).on(clickEventType, '#invitation_section #email', function () {
    var id = $('#cma_subscription_invites_popup').data('cmaId');
    $('#cma_subscription_invites_popup #email').autocomplete({
      source: '/contr_main_acts/' + id + '/available_company_users.json',
      minLength: 0
    });
  });

  $(document).on(clickEventType, '#invite_user_to_cma', function () {
    var id = $('#cma_subscription_invites_popup').data('cmaId');
    var emailOrPhoneNumber = $('#cma_subscription_invites_popup #email_or_phone_number').val();
    if (!emailOrPhoneNumberIsValid(emailOrPhoneNumber)) {
      showFlashMessage('Email or phone number is invalid', 'danger', '#cma_subscription_invites_popup #errors');
      return;
    }
    $.ajax({
      url: '/contr_main_acts/' + id + '/invite_user',
      type: 'POST',
      dataType: 'json',
      data: { email_or_phone_number: emailOrPhoneNumber },
      success: function (data) {
        $('#cma_subscription_invites_popup').html(data.content);
      },
      error: function () {
        $('#cma_subscription_invites_popup #new_user_details').show();
      }
    });
  });

  $(document).on(clickEventType, '#new_user_details #send_invite', function () {
    var id = $('#cma_subscription_invites_popup').data('cmaId');
    var emailOrPhoneNumber = $('#cma_subscription_invites_popup #email_or_phone_number').val();
    var subcontractName = $('#cma_subscription_invites_popup').data('subcontractName').trim();
    var firstName = $('#new_user_details #first_name').val();
    var lastName = $('#new_user_details #last_name').val();
    $.ajax({
      url: '/contr_main_acts/' + id + '/invite_user',
      type: 'POST',
      data: { email_or_phone_number: emailOrPhoneNumber, company_name: subcontractName, name: firstName, surname: lastName },
      success: function (data) {
        $('#cma_subscription_invites_popup').html(data.content);
        $('#invitation_section').hide();
      },
      error: function (data) {
        var message = data.responseJSON.error_message;
        showFlashMessage(message, 'danger', '#cma_subscription_invites_popup #errors');
      }
    });
  });

  $(document).on(clickEventType, '#invites tr', function () {
    var id = $(this).data('id');
    var link = '/invites/' + id + '/edit';
    followLink(link);
  });

  $(document).on(clickEventType, '.remove_cma_invite', function () {
    var id = $(this).closest('.cma_subscription_invite').data('id');
    if (confirm('Are you sure?')) {
      $.ajax({
        url: '/cma_subscription_invites/' + id,
        type: 'DELETE',
        success: function (data) {
          $('#cma_subscription_invites_popup').html(data);
        }
      });
    }
  });

  $(document).on(clickEventType, '.accept_cma_invite', function (e) {
    e.preventDefault();
    var cmaId = $(this).data('cmaId');
    $.ajax({
      url: '/contr_main_acts/' + cmaId + '/user_cma_subscriptions',
      type: 'POST',
      success: function () {
        reloadSubcontractorInterface();
      }
    });
  });

  $(document).on(clickEventType, '.decline_cma_invite', function (e) {
    e.preventDefault();
    var id = $(this).data('cmaInviteId');
    $.ajax({
      url: '/cma_subscription_invites/' + id,
      type: 'PUT',
      data: { status: 'rejected' },
      success: function () {
        reloadSubcontractorInterface();
      }
    });
  });

  function reloadSubcontractorInterface() {
    var projectId = $('#subcontract_interface_acts').data('projectId');
    $.ajax({
      url: '/projects/' + projectId + '/subcontr_acts.js',
      type: 'GET',
      dataType: 'html',
      success: function (data) {
        $('#subcontract_interface_acts').html(data);
        $('.chosen-select').chosen({ width: 'auto' });
      }
    });
  }

  $(document).on(clickEventType, '.send_invite_again', function () {
    var id = $(this).closest('.cma_subscription_invite').data('id');
    $.ajax({
      url: '/cma_subscription_invites/' + id + '/send_again',
      type: 'POST',
      dataType: 'html',
      success: function (data) {
        $('#cma_subscription_invites_popup').html(data);
      }
    });
  });

  function emailOrPhoneNumberIsValid(emailOrPhoneNumber) {
    var validEmail = emailIsValid(emailOrPhoneNumber);
    var validPhone = phoneIsValid(emailOrPhoneNumber);
    return validEmail || validPhone;
  }
});
