import { format } from 'date-fns';
import {
  clickEventType,
  getProjectIdFromSubcontractorPlanPage,
  adjustOverlayAreaSizesForLoading,
  showSpinner,
  lagLeadTextLink,
  unfocusOrEnterPress,
  followLink,
  getProjectIdFromUrl,
} from './helpers';
import { showFlashMessage } from './flash';

$(document).ready(() => {
  function setLinksForCompanyProjectsPopup(resourceName, popupName) {
    if (!popupName) {
      popupName = '#company-projects';
    }
    $(`#sidebar ${popupName} .project_name`).each(function () {
      const id = $(this).data('id');
      const path = `/projects/${id}/${resourceName}`;
      $(this).attr('href', path);
    });
  }

  function showCompanyProjectsPopup(popupName) {
    const projects = $(`#sidebar ${popupName} .project_name`);
    if (projects.length === 1) {
      window.location.href = $(projects[0]).attr('href');
    } else {
      $(`#sidebar ${popupName}`).dialog({
        modal: true,
        title: 'Choose project',
        width: 250,
        resizable: false,
        autoOpen: true,
        close() {
          $(this).dialog('destroy');
        },
      });
    }
  }

  $(document).on(clickEventType, '#plant-link', () => {
    const selectedDate = sessionStorage.getItem('selectedDate');
    const date = !selectedDate ? new Date() : new Date(selectedDate);
    const days = localStorage.getItem('daysToShow') || 1;
    const getLinkCallback = function (projectId) {
      return `/projects/${projectId}/plan?date=${date}&days=${days}?`;
    };
    const setLinksForPopupCallback = function () {
      setLinksForCompanyProjectsPopup('plan', '#plants-projects');
    };
    openProjectRelatedResource(getLinkCallback, setLinksForPopupCallback, '#plants-projects');
  });

  $(document).on(clickEventType, '#tasks-link', () => {
    const getLinkCallback = function (projectId) {
      return `/projects/${projectId}`;
    };
    const setLinksForPopupCallback = function () {
      setLinksForCompanyProjectsPopup('');
    };
    openProjectRelatedResource(getLinkCallback, setLinksForPopupCallback);
  });

  function setLinksWithProjectId(path) {
    $('#sidebar #company-projects .project_name').each(function () {
      const id = $(this).data('id');
      const pathWithProjectId = `${path}?project_id=${id}`;
      $(this).attr('href', pathWithProjectId);
    });
  }

  $(document).on(clickEventType, '#plans-link', () => {
    const selectedDate = sessionStorage.getItem('selectedDate');
    const days = localStorage.getItem('daysToShow') || 1;
    const date = !selectedDate ? new Date() : new Date(selectedDate);
    const getLinkCallback = function (projectId) {
      return `/projects/plan?project_id=${projectId}&date=${date}&days=${days}`;
    };
    const setLinksForPopupCallback = function () {
      setLinksWithProjectId(`/projects/plan?date=${date}&days=${days}`);
    };
    openProjectRelatedResource(getLinkCallback, setLinksForPopupCallback);
  });

  $(document).on(clickEventType, '#plan_builder_link', () => {
    const projectId = getProjectIdFromSubcontractorPlanPage();
    const subprojectId = $('#subproject_dropdown').val();
    const path = `/projects/plan_builder?project_id=${projectId}&subproject_id=${subprojectId}`;
    window.location.href = path;
  });

  $(document).on('change', '.subcontr_act_checkbox', function () {
    const calId = $(this).data('id');
    let calStatus = 0;
    if ($(this).is(':checked')) {
      calStatus = 1;
    }
    $.ajax({
      url: `/contr_act_locs/${calId}/update_status`,
      type: 'POST',
      data: { status: calStatus },
      success() {
        updateSubcontractorInterface();
      },
    });
  });

  $(document).on(clickEventType, '#next_week', function () {
    const startDate = $(this).data('startDate');
    const endDate = $(this).data('nextDate');
    updateSubcontractorInterface(startDate, endDate);
  });

  $(document).on(clickEventType, '#previous_week', function () {
    const startDate = $(this).data('prevDate');
    const endDate = $(this).data('endDate');
    updateSubcontractorInterface(startDate, endDate);
  });

  function updateSubcontractorInterface(startDate, endDate) {
    const projectId = $('#subcontract_interface_acts').data('projectId');
    const data = { start_date: startDate, end_date: endDate };
    $.ajax({
      url: `/projects/${projectId}/subcontr_acts.js`,
      type: 'GET',
      data,
      dataType: 'html',
      success(data) {
        $('#subcontract_interface_acts').html(data);
      },
    });
  }

  function updateLocationTypes(projectId, subprojectId) {
    $.ajax({
      url: `/projects/${projectId}/plan_builder_location_types`,
      type: 'GET',
      data: { subproject_id: subprojectId },
      success() {
        updateLocationOrder();
        updateExistingActsCount();
      },
    });
  }

  $(document).on('change', '#plan_builder #project_dropdown', () => {
    const projectId = $('#project_dropdown').val();

    $.ajax({
      url: `/projects/${projectId}/subprojects.js`,
      dataType: 'html',
      success(data) {
        $('#subprojects_filter').html(data);
        $('#subproject_dropdown').change();
        const subprojectId = $('#subproject_dropdown').val();
        updateLocationTypes(projectId, subprojectId);
        updateLocationOrder();
      },
    });
  });

  $(document).on('change', '#plan_builder #subproject_dropdown', () => {
    const projectId = $('#project_dropdown').val();
    const subprojectId = $('#subproject_dropdown').val();
    $.ajax({
      url: `/projects/${projectId}/plan_builder_activities`,
      type: 'GET',
      data: { subproject_id: subprojectId },
      success() {
        $('#location_order').html('');
        updateLocationTypes(projectId, subprojectId);
        updateLocationOrder();
      },
    });
  });

  function setSelectedLocations() {
    const locationIds = getLocationsFromPage();
    const locations = getLocationObjsFromPage();
    let locationSelectedHtml = '';
    locationIds.forEach((id) => {
      const locObj = { id, name: locations[id] };
      $(`#sortable_location_all li[data-order=${id}]`).remove();
      locationSelectedHtml += templateForLocationOrderList(locObj);
    });
    $('#locations-form #sortable_location_selected').html(locationSelectedHtml);
  }

  $(document).on(clickEventType, '#manage-locations', (e) => {
    e.preventDefault();
    const projectId = $('#project_dropdown').val();
    const subprojectId = $('#subproject_dropdown').val();
    setSelectedLocations();
    $('#locations-form').dialog({
      modal: true,
      title: 'Choose locations',
      autoOpen: 'true',
      width: 'auto',
      height: 'auto',
      resizable: false,
      open() {
        $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
        $('#sortable_location_all').sortable({
          connectWith: '#sortable_location_selected',
          update() { applyAllLocationsFilters(); },
        }).disableSelection();
        $('#sortable_location_selected').sortable({
          connectWith: '#sortable_location_all',
          update() { splitLocsByConcurentLocsCount(); },
        }).disableSelection();
        splitLocsByConcurentLocsCount();
        $('#filter_parent_location').autocomplete({
          source: `/projects/${projectId}/subprojects/${subprojectId}/parent_location_full_names`,
        });
        $('#filter_parent_location').on('autocompleteselect', (event, ui) => {
          $('#filter_parent_location').val(ui.item.value);
          applyParentLocationFilter();
        });
      },
      close() {
        $('#sortable_location_all').append($('#sortable_location_selected').html());
        $('#sortable_location_selected').html('');
        $('#sortable_location_all hr').remove();
        $('#filter_location_order').val('');
        showLocationAll();
        $(this).dialog('destroy');
      },
    });
  });

  function getExistingActsWithLocs() {
    const locationIds = getLocationsFromPage();
    const activityIds = getExistedActIds();
    const result = [];
    activityIds.forEach((actId) => {
      locationIds.forEach((locId) => {
        result.push([locId, actId]);
      });
    });
    return result;
  }

  function updateExistingActsCount() {
    if ($('#plan_builder').length === 0) { return; }
    const projectId = $('#project_dropdown').val();
    const existingActsWithLocs = getExistingActsWithLocs();
    const jsonActsWithLocs = JSON.stringify(existingActsWithLocs);
    const subprojectId = $('#subproject_dropdown').val();
    $.ajax({
      url: '/projects/plan_builder/existing_acts_count',
      type: 'POST',
      data: {
        id: projectId,
        subproject_id: subprojectId,
        existing_acts_with_locs: jsonActsWithLocs,
      },
      success() {
        const existingActsCount = $('#plan_builder #existing_acts_count').html();
        if (existingActsCount == '0') {
          $('#plan_builder #override_activities_block').hide();
        } else {
          $('#plan_builder #override_activities_block').show();
        }
      },
    });
  }

  updateExistingActsCount();

  $(document).on(clickEventType, '#locations-form [type=submit]', (e) => {
    e.preventDefault();
    updateLocationOrder();
    updateExistingActsCount();
    $('#filter_location_order').val('');
    showLocationAll();
    $('#locations-form').dialog('destroy');
  });

  $(document).on(clickEventType, '#plan-project-link', (e) => {
    e.preventDefault();
    const id = $(e.currentTarget).data('projectId');
    const selectedDate = sessionStorage.getItem('selectedDate');
    const date = !selectedDate ? new Date() : new Date(selectedDate);
    const days = localStorage.getItem('daysToShow') || 1;
    const link = `/projects/${id}/plan`;
    followLink(link, { date: format(date, 'dd/MM/yyyy'), days, set_default_project_id: id });
  });

  $(document).on('change', '#location_types_dropdown', () => {
    const projectId = $('#project_id').data('id');
    const locationTypeId = $('#location_types_dropdown').val();
    $.ajax({
      url: `/projects/${projectId}/plan_builder_locations`,
      type: 'GET',
      data: { location_type_id: locationTypeId },
      success() {
        updateLocationOrder();
        updateExistingActsCount();
      },
    });
  });

  $(document).on(clickEventType, '.destroy-plan-builder-location', function () {
    $(this).parent().parent().remove();
  });

  $(document).on(clickEventType, '#new-activity', (e) => {
    const projectId = $('#project_dropdown').val();
    const subprojectId = $('#subproject_dropdown').val();
    e.preventDefault();
    $.ajax({
      url: `/projects/${projectId}/activity_form`,
      type: 'GET',
      data: {
        id: projectId,
        subproject_id: subprojectId,
      },
      success() {
        $('#destroy-plan-builder-activity').hide();
        $('#activity-form').data('id', '').dialog({
          modal: true,
          title: 'Choose activities',
          autoOpen: true,
          resize: false,
          width: 'auto',
          autoSize: true,
          height: 'auto',
          open() {
            $('#activities_pb').show();
            $('#duration_pb').hide();
            $('#activity-form #pb_acts').attr('tabindex', -1).chosen({ width: 'auto' });
            setSelectedActs();
          },
          close() {
            $(this).dialog('destroy');
          },
        });
      },
    });
  });

  function setSelectedActs() {
    const actIds = getExistedActIds();
    $('#activity-form #pb_acts').val(actIds);
    $('#activity-form #pb_acts').trigger('chosen:updated');
  }

  $(document).on(clickEventType, '#activity-form [name=commit]', (e) => {
    e.preventDefault();
    let actName = '';
    let actId = '';
    const sourceActId = $('#activity-form').data('id');
    if (sourceActId != '') {
      actId = $('#activity-form #act_id').val();
      const duration = $('#activity-form #activity_duration').val();
      actName = getActNameFromPlanBuilderTable(actId);
      updateActivity(sourceActId, actId, actName, duration);
    } else {
      const existedActIds = getExistedActIds();
      const actIds = $('#activity-form #pb_acts').val() || [];
      actIds.forEach((id) => {
        actName = getActNameFromPlanBuilderTable(id);
        if (existedActIds.indexOf(parseInt(id)) < 0) {
          addActivityToTable(id, actName, 1);
        }
      });
      existedActIds.forEach((id) => {
        actId = id.toString();
        if (actIds.indexOf(actId) < 0) {
          removePlanBuilderActivity(actId);
        }
      });
    }
    $('#activity-form').dialog('destroy');
    updateExistingActsCount();
  });

  $(document).on('submit', '#activity-form, #precedors-form, #activities_form, #plan_builder', (e) => {
    e.preventDefault();
  });

  function getExistedActIds() {
    const result = [];
    const acts = $('#plan-builder-activities tr');
    $('#plan-builder-activities tr').each(function () {
      const id = parseInt($(this).attr('data-id'));
      result.push(id);
    });
    return result;
  }

  $(document).on(clickEventType, '#plan-builder-activities tr', function (e) {
    $('#destroy-plan-builder-activity').show();
    e.preventDefault();
    const projectId = $('#project_dropdown').val();
    const subprojectId = $('#subproject_dropdown').val();
    const actId = parseInt($(this).attr('data-id'));
    $('#activity-form').data('id', actId);
    const duration = $(this).find('.duration').html() || 1;
    $.ajax({
      url: `/projects/${projectId}/activity_form`,
      type: 'GET',
      data: { project_id: projectId, subproject_id: subprojectId },
      success() {
        $('#activity-form').dialog({
          modal: true,
          title: 'Edit activity',
          autoOpen: true,
          resize: false,
          width: 'auto',
          open() {
            $('#activity-form #activity_duration').val(duration);
            $('#activities_pb').hide();
            $('#duration_pb').show();
          },
          close() {
            $(this).dialog('destroy');
          },
        });
      },
    });
  });

  function removePrecedorsWithId(id) {
    $(`.precedor[data-id=${id}]`).each(function () {
      $(this).remove();
    });
  }

  function removePlanBuilderActivity(id) {
    $(`#plan-builder-activities tr[data-id=${id}]`).remove();
    removePrecedorsWithId(id);
  }

  $(document).on(clickEventType, '#destroy-plan-builder-activity', (e) => {
    e.preventDefault();
    const actId = $('#activity-form').data('id');
    removePlanBuilderActivity(actId);
    $('#activity-form').dialog('destroy');
    updateExistingActsCount();
  });

  function getActNameFromPlanBuilderTable(id) {
    return $(`#activity-form #pb_acts option[value=${id}]`).html();
  }

  function getPrecedorsFromPage(actId) {
    const precedorIds = [];
    $(`#plan-builder-activities tr[data-id=${actId}] .precedors .precedor`).each(function () {
      precedorIds.push({ id: $(this).data('id'), lag_lead_count: $(this).data('lagLeadCount') });
    });
    return precedorIds;
  }

  function setSelectedPrecedors(actId) {
    const precedorIds = getPrecedorsFromPage(actId);
    precedorIds.forEach((obj) => {
      const el = $(`select#precedors option[data-id=${obj.id}]`);
      el.attr('selected', true);
      el.data('lag_lead_count', obj.lag_lead_count);
    });
    $('#precedors-form #precedors').trigger('chosen:updated');
    $('#precedors-form #precedors').trigger('chosen:ready');
  }

  $(document).on(clickEventType, '#plan-builder-activities tr .fa-edit', function (e) {
    e.stopPropagation();
    const actId = $(this).parents('tr').attr('data-id');
    const projectId = $('#project_dropdown').val();
    const subprojectId = $('#subproject_dropdown').val();
    const actIds = getExistedActIds();
    $.ajax({
      url: `/projects/${projectId}/precedors_form`,
      type: 'POST',
      data: {
        act_id: actId,
        id: projectId,
        subproject_id: subprojectId,
        act_ids: actIds,
      },
      success() {
        $('#precedors-form #precedors').attr('tabindex', -1).chosen({ width: 'auto' });
        setSelectedPrecedors(actId);
        $('#precedors-form').data('id', actId).dialog({
          modal: true,
          title: 'Choose precedors',
          autoOpen: true,
          resize: false,
          width: '25vw',
          height: 430,
          close() {
            $(this).dialog('destroy');
          },
        });
      },
    });
  });

  $(document).on(clickEventType, '#precedors-form [type=submit]', (e) => {
    e.preventDefault();
    const actId = $('#precedors-form').data('id');
    const precedors = [];
    const precedorIds = $('#precedors-form #precedors').val() || [];
    precedorIds.forEach((id) => {
      const el = $(`#precedors-form #precedors option[value=${id}]`);
      const name = el.html();
      let lagLeadCount = el.data('lag_lead_count');
      if (lagLeadCount === undefined) {
        lagLeadCount = $(`select#precedors option[data-id=${id}]`).data('lag_lead_count');
      }
      precedors.push({ id, name, lag_lead_count: lagLeadCount });
    });
    setPrecedors(precedors, actId);
    $('#precedors-form').dialog('destroy');
  });

  $(document).on(clickEventType, '.destroy-plan-builder-precedor', function (e) {
    e.stopPropagation();
    $(this).parent().remove();
  });

  function setPrecedors(precedors, actId) {
    let precedorsHtml = '';
    precedors.forEach((el) => {
      const act = $(`#plan-builder-activities tr[data-id=${el.id}]`);
      if (act.length) {
        precedorsHtml += precedor(el.id, el);
      }
    });
    $(`#plan-builder-activities tr[data-id=${actId}] .precedors`).html(precedorsHtml);
  }

  function precedor(id, obj) {
    const textOfLink = lagLeadTextLink(obj.lag_lead_count);
    const name = obj.name.replace(/\(.*\)/, () => textOfLink);
    return `<div class='precedor' data-id=${id} data-lag-lead-count=${obj.lag_lead_count}>${name} <span class='destroy-plan-builder-precedor'>x</span></div>`;
  }

  function updateActivity(actId, id, name, duration) {
    const act = $(`#plan-builder-activities tr[data-id=${actId}]`);
    act.attr('data-id', id);
    act.find('.name').html(name);
    act.find('.duration').html(duration);
  }

  function addActivityToTable(id, name, duration) {
    const activity = `<tr data-id=${id}><td>${name}</td><td class=duration>${duration}</td><td><div class='precedors'></div><i class='fa fa-edit'></i></td></tr>`;
    $('#plan-builder-activities').append(activity);
  }

  function updateLocationOrder() {
    const locations = {};
    const locationIds = [];
    $('#sortable_location_selected li').each(function () {
      const id = $(this).data('order');
      locationIds.push(id);
      locations[id] = $(this).text();
    });
    redrawLocationOrder(locationIds, locations);
    $('#sortable_location_selected').html('');
  }

  function redrawLocationOrder(locationIds, locations, concurrentLocsCount) {
    const locationsOrder = [];
    if (concurrentLocsCount === undefined) { concurrentLocsCount = getConcurrentLocationsCount(); }
    const locationsHtml = locationIds.map((id) => getLocationFromTemplate(id, locations[id]));
    for (let i = 0; i < locationsHtml.length; i += concurrentLocsCount) {
      const groupedLocs = locationsHtml.slice(i, concurrentLocsCount + i).join('');
      const groupHtml = `<div class="separator"><span>></span></div><div class="location-order-group">${groupedLocs}</div>`;
      locationsOrder.push(groupHtml);
    }
    const locationsOrderHtml = locationsOrder.join('');
    $('#location-order').html(locationsOrderHtml);
  }

  function getLocationFromTemplate(id, name) {
    return `<div class="item-container-wrapper" data-order=${id} data-name=${name}>`
      + `<div class="item-container">${name}</div></div>`;
  }

  function validDurations() {
    return $('#plan-builder-activities tr').filter(function () {
      return $(this).find('.duration').html() <= 0;
    }).length === 0;
  }

  function planBuilderFormValid() {
    const result = true;
    const locationIds = getLocationsFromPage();
    const actIds = getExistedActIds();
    if (!validDurations()) {
      showFlashMessage('All durations must be set! All durations must be positive', 'danger');
      return false;
    }
    if (!locationIds.length > 0) {
      showFlashMessage('At least one location must be choosen', 'danger');
      return false;
    }
    if (!$('#subproject_dropdown').val()) {
      showFlashMessage('Subproject must be set', 'danger');
      return false;
    }
    if ($('#concurrent_locations').val() <= 0) {
      showFlashMessage('Concurrent locations must be positive', 'danger');
      return false;
    }
    if (!actIds.length > 0) {
      showFlashMessage('At least one activity must be set', 'danger');
      return false;
    }
    return result;
  }

  function getActsWithConstrainedLocations() {
    const result = {};
    if ($('.constrained_locations_column').is(':visible') === false) { return {}; }
    $('#plan-builder-activities tr').filter(function () {
      const concurrentLocationsValue = $(this).find('.constrained_locations_column .concurrent_locations_span').text();
      if ($(concurrentLocationsValue) != 'Unconstrained') {
        const cmaId = $(this).data('id');
        result[cmaId] = concurrentLocationsValue;
      }
    });
    return result;
  }

  $(document).on(clickEventType, '#create-plan', () => {
    $('.spinner').show();
    const projectId = $('#project_id').data('id');
    subprojectId = $('#subproject_dropdown').val();
    if (planBuilderFormValid()) {
      $.ajax({
        url: `/projects/${projectId}/apply_plan_from_builder`,
        type: 'POST',
        data: {
          subproject_id: subprojectId,
          location_ids: getLocationsFromPage(),
          act_ids: getExistedActIds(),
          concurrent_locations: getConcurrentLocations(),
          precedors: getActsWithPrecedors(),
          durations: getDurations(),
          start_date: getStartDate(),
          override_existing_activities: getPlanBuilderMode(),
          acts_with_constrained_locations: getActsWithConstrainedLocations(),
        },
        success() {
          $('.spinner').hide();
        },
        error() {
          $('.spinner').hide();
        },
      });
    } else {
      $('.spinner').hide();
    }
  });

  function getStartDate() {
    return $('#pb_start_date').val();
  }

  function getPlanBuilderMode() {
    return $('#plan_builder #override_activities').prop('checked');
  }

  function getDurations() {
    const durations = {};
    $('#plan-builder-activities tr .duration').each(function () {
      const duration = $(this).html();
      if (duration !== '') {
        const actId = $(this).parents('tr').data('id');
        durations[actId] = $(this).html();
      }
    });
    return durations;
  }

  function getConcurrentLocations() {
    return $('#concurrent_locations').val();
  }

  function getLocationsFromPage() {
    const locations = [];
    $('#location-order .item-container').each(function () {
      locations.push($(this).parent().data('order'));
    });
    return locations;
  }

  function getActsWithPrecedors() {
    const acts = getExistedActIds();
    const actsWithPrecedors = {};
    acts.forEach((act) => {
      const precedors = getPrecedors();
      actsWithPrecedors[act] = getPrecedors(act);
    });
    return actsWithPrecedors;
  }

  function getPrecedors(actId) {
    const precedorIds = [];
    $(`#plan-builder-activities tr[data-id=${actId}]`).find('.precedors .precedor').each(function () {
      let lagLeadCount = 0;
      if ($(this).data('lagLeadCount') != 'undefined') {
        lagLeadCount = $(this).data('lagLeadCount');
      }
      precedorIds.push({ id: $(this).data('id'), lag_lead_count: lagLeadCount });
    });
    return precedorIds;
  }

  $(document).on(clickEventType, '.offtrack_cal', function () {
    const calId = $(this).data('calId');
    const cmaId = $(this).data('cmaId');
    $.ajax({
      url: `/contr_act_locs/${calId}/edit.js?contr_main_act_id=${cmaId}`,
      type: 'GET',
      success() {
        $('.chosen-select').chosen({ width: 'auto' });
        $('#contr-act-loc-form #main_form [name=_method]').val('post');
        let formAction = $('#contr-act-loc-form #main_form').attr('action');
        formAction += `?contr_act_loc_id=${calId}`;
        $('#contr-act-loc-form #main_form').attr('action', formAction);
        const hiddenInput = $('<input/>', { type: 'hidden', name: 'summary_page', value: true });
        hiddenInput.appendTo($('#main_form, #delete_cal'));

        $('#contr-act-loc-form #start_date').datepicker({
          dateFormat: 'dd/mm/yy',
        });
        $('#contr-act-loc-form').dialog({
          modal: true,
          title: 'Edit activity',
          width: '35vw',
          height: 'auto',
          resize: false,
          close() {
            $(this).dialog('destroy');
          },
        });
      },
    });
  });

  $('#pb_start_date').datepicker({
    dateFormat: 'dd/mm/yy',
  });

  $(document).on(clickEventType, '#summary_apply_filter', () => {
    updatePlanSummary(true);
  });

  $(document).on(clickEventType, '#summary_report', () => {
    const projectId = $('#project_id').data('projectId');
    const subprojectId = $('#subproject_dropdown_summary').val();
    const locations = $('#summary_locations_dropdown').val();
    const activities = $('#summary_activities_dropdown').val();
    const contractors = $('#summary_contractors_dropdown').val();
    let params = `?subproject_id=${subprojectId}`;
    if (locations) {
      locations.forEach((location) => {
        params += `&location_ids[]=${location}`;
      });
    }
    if (activities) {
      activities.forEach((activity) => {
        params += `&activity_ids[]=${activity}`;
      });
    }
    if (contractors) {
      contractors.forEach((contractor) => {
        params += `&contractor_ids[]=${contractor}`;
      });
    }
    window.location.href = `/projects/${projectId}/summary.pdf?${params}`;
  });

  $(document).on('change', '#subproject_dropdown_summary', () => {
    updatePlanSummary();
  });

  $(document).on(clickEventType, '#plan_summary_comments_popup', function () {
    const calId = $(this).data('calId');
    showSpinner();
    const counter = $(this).next();
    counter.remove();
    $.ajax({
      url: `/contr_act_locs/${calId}/contr_act_comments.js`,
      type: 'GET',
      data: { summary_page: 'true' },
      dateType: 'html',
      success(data) {
        $('.spinner').hide();
        $('#summary_comments_popup').dialog({
          modal: true,
          title: 'Comments',
          width: '35vw',
          height: 'auto',
          resize: false,
          open() {
            $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
          },
          close() {
            $(this).dialog('destroy');
          },
        });
      },
      error() {
        $('.spinner').hide();
      },
    });
  });

  function updatePlanSummary(preserveFilter) {
    const subprojectId = $('#subproject_dropdown_summary').val();
    const projectId = $('#project_id').data('projectId');
    if (preserveFilter) {
      const locations = $('#summary_locations_dropdown').val();
      const activities = $('#summary_activities_dropdown').val();
      const contractors = $('#summary_contractors_dropdown').val();
      var data = {
        subproject_id: subprojectId,
        location_ids: locations,
        contractor_ids: contractors,
        activity_ids: activities,
      };
    } else {
      var data = { subproject_id: subprojectId };
    }
    showSpinner();
    $.ajax({
      url: `/projects/${projectId}/summary.js`,
      type: 'POST',
      dataType: 'html',
      data,
      success(data) {
        $('#subproject_summary_section').html(data);
        $('.chosen-select').chosen({ width: 'auto' });
        $('.spinner').hide();
      },
      error() {
        $('.spinner').hide();
      },
    });
  }

  function updateCmasPositions(cmas) {
    $('#plan-builder-activities tr').each(function () {
      const cmaId = $(this).data('id');
      const position = cmas.indexOf(cmaId);
      $(this).data('position', position);
    });
  }

  function redrawPlanBuilderActivities() {
    let activities = '';
    $('#plan-builder-activities tr').sort((a, b) => $(a).data('position') - $(b).data('position')).each(function () {
      activities += $(this)[0].outerHTML;
    });
    $('#plan-builder-activities').html(activities);
  }

  function updatePlanBuilderActivities(cmas) {
    updateCmasPositions(cmas);
    redrawPlanBuilderActivities();
    cmas.forEach((cmaId) => { setPrecedors([], cmaId); });
  }

  $(document).on(clickEventType, '#plan_builder #cma_order_popup_link', () => {
    $('#cmas_with_subcontracts_order_popup').dialog({
      modal: true,
      title: 'Activities order',
      autoOpen: true,
      resize: 'none',
      width: 'auto',
      open() {
        $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
        $('#sortable_cma').sortable();
        $('#sortable_cma').disableSelection();
      },
      close() {
        $(this).dialog('destroy');
      },
    });
  });

  $(document).on(clickEventType, '#cmas_with_subcontracts_order_popup #save_cma_order_button', () => {
    const cmas = [];
    $('#cma_order li').each(function () {
      cmas.push($(this).data('order'));
    });
    const projectId = $('#project_dropdown').val();
    const subprojectId = $('#subproject_dropdown').val();
    $.ajax({
      url: '/contr_main_acts/reorder',
      type: 'POST',
      beforeSend() {
        $('#cma_order_popup').dialog('destroy');
        $('.spinner').show();
      },
      data: {
        project_id: projectId,
        subproject_id: subprojectId,
        cma_ids: cmas,
      },
      success() {
        updatePlanBuilderActivities(cmas);
        $('#cmas_with_subcontracts_order_popup').dialog('destroy');
        $('.spinner').hide();
      },
      error() {
        $('.spinner').hide();
      },
    });
  });

  $(() => {
    const help = 'Checking this box will overwrite any existing activities you already have in your plan, \
    however comments will remain. If you uncheck this box, existing activities won\'t be overwritten, \
    but new items will link to the existing activities with precedences as per the plan builder setup';
    $('#override_activities_help').tooltip({ content: help });
  });

  $('.datepicker').datepicker({
    dateFormat: 'dd/mm/yy',
  });

  $(document).on(clickEventType, '#manage_working_hours', () => {
    const projectId = $('#project_id').data('id');
    $.ajax({
      url: `/projects/${projectId}/working_periods/default_working_hours`,
      type: 'GET',
      success() {
        $('#working_period_form').dialog({
          modal: true,
          title: 'Working hours',
          width: 'auto',
          resizable: false,
          close() {
            $(this).dialog('destroy');
          },
        });
      },
    });
  });

  $(document).on('change', '#working_period_form #period', function () {
    const projectId = $('#project_id').data('id');
    const workingPeriodId = $(this).val();
    if (workingPeriodId === '0') {
      showProjectDefaultWorkingHours(projectId);
    } else {
      showWorkingHoursForPeriod(projectId, workingPeriodId);
    }
  });

  function showProjectDefaultWorkingHours(projectId) {
    $.ajax({
      url: `/projects/${projectId}/working_periods/default_working_hours`,
      type: 'GET',
    });
  }

  function showWorkingHoursForPeriod(projectId, workingPeriodId) {
    $.ajax({
      url: `/projects/${projectId}/working_periods/form`,
      type: 'GET',
      data: { id: workingPeriodId },
      success() {
      },
    });
  }

  $(document).on('click', '#working_period_period_start_date, #working_period_period_end_date', function () {
    $('#working-period-datepicker-form').data('date-field', $(this).attr('id'));

    $('#working-period-datepicker').datepicker('setDate', $(this).val());
    $('#working-period-datepicker-form').dialog({
      modal: true,
      title: 'Please select a date',
      width: 'auto',
      resize: true,
      close() {
        $(this).dialog('destroy');
      },
    });
  });

  $('#working-period-datepicker').datepicker({
    dateFormat: 'dd/mm/yy',
  });

  $(document).on(clickEventType, '#submit-working-period-date', (e) => {
    const date = $('#working-period-datepicker').val();

    dateField = $('#working-period-datepicker-form').data('date-field');
    $(`#${dateField}`).val(date);
    $(`#${dateField}`).change();

    $('#working-period-datepicker-form').dialog('destroy');
  });

  $(document).on(clickEventType, '#build-sequence-button', () => {
    const activityColIndex = $('th:contains("Activity")').index() + 1;
    const precededByColIndex = $('th:contains("Preceded by")').index() + 1;

    let existingPrecedors = false;
    $(`tbody#plan-builder-activities tr td:nth-child(${precededByColIndex}) .precedors`).each(function () {
      if ($(this).html() !== '') {
        existingPrecedors = true;
      }
    });

    if (existingPrecedors && !confirm('Some precedences have already been created and will be overwritten. Continue?')) {
      return false;
    }

    $(`tbody#plan-builder-activities tr td:nth-child(${activityColIndex})`).each(function (index) {
      const precededByIndex = index + 1;
      const name = `${$(this).html()}()`;
      const id = $(this).parents('tr').data('id');
      const precedorHtml = precedor(id, { id, name, lag_lead_count: 0 });
      $(`tbody#plan-builder-activities tr td:nth-child(${precededByColIndex}):eq(${precededByIndex}) .precedors`).html(precedorHtml);
    });
  });

  $(document).on(clickEventType, '#plan-builder-save-template-popup', () => {
    $('#save_template_form').dialog({
      modal: true,
      title: 'Save template',
      width: 'auto',
      resizable: false,
      open() {
        const subprojectName = $('#subproject_dropdown option:selected').text();
        const d = new Date();
        const offset = parseInt($('#diff_with_server_time').val(), 10) || 0;
        d.setMilliseconds(d.getMilliseconds() + offset);
        const currentDate = [d.getDate(), d.getMonth() + 1, d.getFullYear()].join('/');
        const userFullName = $('#sidebar #user_full_name').text();
        $('#template_name').val(`${userFullName} - ${currentDate} - ${subprojectName}`);
      },
      close() {
        $(this).dialog('destroy');
      },
    });
  });

  $(document).on(clickEventType, '#plan-builder-save-template', () => {
    const projectId = $('#project_dropdown').val();
    const subprojectId = $('#subproject_dropdown').val();
    const name = $('#template_name').val();
    showSpinner();
    $.ajax({
      url: `/projects/${projectId}/plan_builder_templates`,
      type: 'POST',
      data: {
        subproject_id: subprojectId,
        name,
        acts: getExistedActIds(),
        concurrent_locations: getConcurrentLocations(),
        precedors: getActsWithPrecedors(),
        durations: getDurations(),
        start_date: getStartDate(),
        override_existing_activities: getPlanBuilderMode(),
        location_ids: getLocationsFromPage(),
      },
      success(data) {
        $('#save_template_form').dialog('destroy');
        if (data.status === 'ok') {
          showFlashMessage(data.message, 'success');
        } else {
          showFlashMessage(data.message);
        }
        $('.spinner').hide();
      },
      error() {
        $('.spinner').hide();
      },
    });
  });

  $(document).on(clickEventType, '#plan-builder-templates-popup', () => {
    const projectId = $('#project_dropdown').val();
    const subprojectId = $('#subproject_dropdown').val();
    showSpinner();
    $.ajax({
      url: `/projects/${projectId}/plan_builder_templates`,
      type: 'GET',
      dataType: 'html',
      success(data) {
        const popupDiv = '<div id="plan_builder_template_popup" style="display:none"></div>';
        $(document.body).append(popupDiv);
        $('#plan_builder_template_popup').html(data);
        $('#plan_builder_template_popup').dialog({
          modal: true,
          title: 'Select template',
          autoOpen: true,
          resizable: false,
          autoSize: true,
          open() {
            $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
            $('.chosen-select').attr('tabindex', -1).chosen({ width: 'auto' });
          },
          close() {
            $(this).dialog('close');
            $('#plan_builder_template_popup').remove();
          },
        });
        $('.spinner').hide();
      },
      error() {
        $('.spinner').hide();
      },
    });
  });

  $(document).on(clickEventType, '#plan-builder-apply-template', () => {
    const projectId = $('#project_dropdown').val();
    const subprojectId = $('#subproject_dropdown').val();
    const templateId = $('#templates').val();
    showSpinner();
    $.ajax({
      url: `/projects/${projectId}/plan_builder_templates/${templateId}`,
      type: 'GET',
      dataType: 'json',
      data: { subproject_id: subprojectId },
      success(data) {
        data.acts.forEach((act) => {
          updateActivity(act.id, act.id, act.name, act.duration);
          setPrecedors(act.precedors, act.id);
        });
        $('#concurrent_locations').val(data.concurrent_locations);
        $('#pb_start_date').val(data.start_date);
        updateExistingActsCount();
        if ($('#override_activities_block').is(':visible')) {
          let checked = false;
          if (data.override_existing_activities == 'true') {
            checked = true;
          }
          $('#override_activities_block #override_activities').prop('checked', checked);
        }
        redrawLocationOrder(data.location_ids, data.locations);
        $('.spinner').hide();
      },
      error() {
        $('.spinner').hide();
      },
    });
    $('#plan_builder_template_popup').remove();
    $('#plan_builder_template_popup').dialog('destroy');
  });

  $(document).on('change', '#concurrent_locations', () => {
    const locationIds = getLocationsFromPage();
    const locations = getLocationObjsFromPage();
    if ($('.constrained_locations_column').is(':visible')) {
      showConfirmationPopupForChangedConcurrentLocations();
    } else {
      redrawLocationOrder(locationIds, locations);
    }
  });

  function getMinConcurrentLocationFromAdvancedView() {
    const concurrentLocationValues = $('.concurrent_locations_span').map(function () {
      const value = parseInt($(this).text());
      if (value > 0) { return value; }
    }).toArray();
    if (concurrentLocationValues.length === 0) {
      return undefined;
    }
    return Math.min.apply(Math, concurrentLocationValues);
  }

  function getLocationObjsFromPage() {
    const locations = {};

    $('#location-order .item-container').each(function () {
      const id = $(this).parent().data('order');
      locations[id] = $(this).text();
    });
    return locations;
  }

  function templateForLocationOrderList(locObj) {
    return `<li class="ui-state-default" data-order=${locObj.id
    }><span class="ui-icon ui-icon-arrowthick-2-n-s"></span><span style="margin-left:0px;">${locObj.name}</span></li>`;
  }

  $(document).on(clickEventType, '#copy_all_locations', (e) => {
    e.preventDefault();
    moveLocationToAnotherList('#sortable_location_all', '#sortable_location_selected');
  });

  $(document).on(clickEventType, '#clear_all_locations', (e) => {
    e.preventDefault();
    moveLocationToAnotherList('#sortable_location_selected', '#sortable_location_all', applyAllLocationsFilters);
  });

  function moveLocationToAnotherList(sourceListSelector, targetListSelector, callback) {
    const visibleLocsSelector = `${sourceListSelector} li:visible`;
    const visibleLocs = $(visibleLocsSelector);
    visibleLocs.each(function () {
      $(this).removeClass('filtered_by_parent_location');
      $(this).remove();
      $(targetListSelector).append($(this));
    });
    splitLocsByConcurentLocsCount();
    if (callback) { callback(); }
  }

  function splitLocsByConcurentLocsCount() {
    const concurrentLocsCount = getConcurrentLocationsCount();
    const locs = $('#sortable_location_selected li');
    let locsWithSeparatorHtml = '';
    locs.each((i, el) => {
      locsWithSeparatorHtml += $(el)[0].outerHTML;
      if (((i + 1) % concurrentLocsCount) === 0) {
        locsWithSeparatorHtml += '<hr size="1">';
      }
    });
    $('#sortable_location_selected').html(locsWithSeparatorHtml);
  }

  function getConcurrentLocationsCount() {
    return parseInt($('#concurrent_locations').val());
  }

  $(document).on('keyup', '#filter_parent_location', () => {
    applyParentLocationFilter();
  });

  function applyLocationFilter(filter, locationsSelector, onEmptyFilterCallback, toggleLocationIfNeededCallback) {
    if (filter === '') {
      onEmptyFilterCallback();
      return;
    }
    $(locationsSelector).each(function () {
      toggleLocationIfNeededCallback(this);
    });
  }

  function applyParentLocationFilter() {
    $('#filter_location_order').val('');
    const filter = $('#filter_parent_location').val();
    const locationsSelector = '#sortable_location_all li';
    const onEmptyFilterCallback = function () {
      showLocationAll();
    };
    const toggleLocationIfNeededCallback = function (el) {
      const match = $(el).data('parentLocation').search(new RegExp(filter, 'i'));
      if (match >= 0) {
        $(el).addClass('filtered_by_parent_location');
        $(el).show();
      } else {
        $(el).removeClass('filtered_by_parent_location');
        $(el).hide();
      }
    };
    applyLocationFilter(filter, locationsSelector, onEmptyFilterCallback, toggleLocationIfNeededCallback);
  }

  function applyGeneralLocationFilter() {
    const filter = $('#filter_location_order').val();
    const locationsSelector = '#sortable_location_all li.filtered_by_parent_location';
    const onEmptyFilterCallback = function () {
      showLocationsWithClass('filtered_by_parent_location');
    };
    const toggleLocationIfNeededCallback = function (el) {
      const match = $(el).text().search(new RegExp(filter, 'i'));
      if (match >= 0) {
        $(el).show();
      } else {
        $(el).hide();
      }
    };
    applyLocationFilter(filter, locationsSelector, onEmptyFilterCallback, toggleLocationIfNeededCallback);
  }

  function applyAllLocationsFilters() {
    applyParentLocationFilter();
    applyGeneralLocationFilter();
  }

  $(document).on('keyup', '#filter_location_order', () => {
    applyGeneralLocationFilter();
  });

  $(document).on(clickEventType, '#clear_location_filter', () => {
    $('#filter_location_order').val('');
    showLocationsWithClass('filtered_by_parent_location');
  });

  $(document).on(clickEventType, '#clear_parent_location_filter', () => {
    $('#filter_parent_location').val('');
    $('#filter_location_order').val('');
    showLocationAll();
  });

  function showLocationsWithClass(locationClass) {
    const selector = `#sortable_location_all li.${locationClass}`;
    $(selector).each(function () {
      $(this).show();
    });
  }

  function showLocationAll() {
    $('#sortable_location_all li').each(function () {
      $(this).show();
      $(this).addClass('filtered_by_parent_location');
    });
  }

  $('#plan_builder').on('keyup keypress', (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      e.preventDefault();
      return false;
    }
  });

  function subprojectsOnTrackInfo(projectId) {
    $(`.dash-project[data-id=${projectId}].dash-owner-project .mini-spinner`).show();
    $.ajax({
      url: `/projects/${projectId}/subprojects_on_track`,
      type: 'GET',
      datatype: 'html',
      success(data) {
        $(`.dash-project[data-id=${projectId}] .subprojects_on_track`).html(data);
        $(`.dash-project[data-id=${projectId}].dash-owner-project .mini-spinner`).hide();
      },
      error() {
        $(`.dash-project[data-id=${projectId}].dash-owner-project .mini-spinner`).hide();
      },
    });
  }

  function activitiesOnTrackInfo(projectId) {
    $(`.dash-project[data-id=${projectId}].dash-subcontract-project .mini-spinner`).show();
    $.ajax({
      url: `/projects/${projectId}/activities_on_track`,
      type: 'GET',
      datatype: 'html',
      success(data) {
        $(`.dash-project[data-id=${projectId}] .activities_on_track`).html(data);
        $(`.dash-project[data-id=${projectId}].dash-subcontract-project .mini-spinner`).hide();
      },
      error() {
        $(`.dash-project[data-id=${projectId}].dash-subcontract-project .mini-spinner`).hide();
      },
    });
  }

  function asynchronousFetchingOfDashboardInfo() {
    $('.dash-project').each(function () {
      const projectId = $(this).data('id');
      if ($(this).find('.subprojects_on_track').length > 0) {
        subprojectsOnTrackInfo(projectId);
      }
      if ($(this).find('.activities_on_track').length > 0) {
        activitiesOnTrackInfo(projectId);
      }
    });
    loadUserActivitySummary();
  }
  asynchronousFetchingOfDashboardInfo();

  function loadUserActivitySummary() {
    if ($('#user_activity_summary').length) {
      $('#spinner_for_user_activity_summary .mini-spinner').show();
      $.ajax({
        url: '/user_activity_summary',
        type: 'GET',
        datatype: 'html',
        success(data) {
          $('#user_activity_summary').html(data);
          $('#spinner_for_user_activity_summary .mini-spinner').hide();
        },
        error() {
          $('#spinner_for_user_activity_summary .mini-spinner').hide();
        },
      });
    }
  }

  $(() => {
    if (window.location.pathname === '/') {
      $('.spinner').hide();
    }
  });

  $(document).on(clickEventType, '#toggle_subcontract_interface_section', () => {
    $('#subcontract_interface_filters').toggle();
  });

  $(document).on(clickEventType, '#apply_subcontract_interface_filters', () => {
    const projectId = $('#subcontract_interface_acts').data('projectId');
    const locationIds = $('#locations_filter').val() || [];
    const activityIds = $('#activities_filter').val() || [];
    const subprojectIds = $('#subprojects_filter').val() || [];
    $.ajax({
      url: `/projects/${projectId}/subcontr_acts.js`,
      type: 'GET',
      dataType: 'html',
      data: {
        location_ids: locationIds,
        activity_ids: activityIds,
        subproject_ids: subprojectIds,
      },
      success(data) {
        $('#subcontract_interface_acts').html(data);
        $('.chosen-select').chosen({ width: 'auto' });
      },
    });
  });

  $(document).on('change', '#plan_builder #subproject_dropdown', () => {
    updateExistingActsCount();
  });

  function togglePlanBuilderView() {
    if ($('#plan-builder-mode').text() === 'Simple view') {
      checkChangesInLocationConstrainedColumn();
      $('#plan-builder-mode').text('Advanced view');
    } else {
      $('#plan-builder-mode').text('Simple view');
    }
    $('.constrained_locations_column').toggle();
  }

  function checkChangesInLocationConstrainedColumn() {
    if (locationConstrainedColumnWasChangedFromDefault()) {
      showConfirmationPopupForChangedConcurrentLocations();
    }
  }

  function showConfirmationPopupForChangedConcurrentLocations() {
    const valueFromMainInput = $('#concurrent_locations').val();
    const text = `This will change concurrent locations to ${valueFromMainInput} for all activities. Are you sure?`;
    const successCallback = function () {
      const concurrentLocationsValue = $('#concurrent_locations').val();
      setConcurrentLocationsForActivities(concurrentLocationsValue);
    };
    const cancelCallback = function () {
      const oldValue = $('#concurrent_locations').data('value');
      $('#concurrent_locations').val(oldValue);
      $('#concurrent_locations').data('value', $('#concurrent_locations').val());
    };
    showConfirmationPopup(text, successCallback, cancelCallback);
  }

  function locationConstrainedColumnWasChangedFromDefault() {
    return $('.concurrent_locations_span').filter(function () {
      if ($(this).text() !== 'Unconstrained') { return $(this); }
    }).toArray().length > 0;
  }

  function showConfirmationPopup(text, successCallback, cancelCallback) {
    $('#constrained_locations_confirmation_dialog #dialog_text').text(text);
    $('#constrained_locations_confirmation_dialog').dialog({
      modal: true,
      title: 'Confirmation dialog',
      autoOpen: true,
      resizable: false,
      width: 'auto',
      buttons: [
        {
          text: 'Ok',
          click() {
            successCallback();
            $('#constrained_locations_confirmation_dialog').dialog('destroy');
          },
        },
        {
          text: 'Cancel',
          click() {
            cancelCallback();
            $('#constrained_locations_confirmation_dialog').dialog('destroy');
          },
        },
      ],
      close() {
        $(this).find('#dialog_text').text('');
        $(this).dialog('destroy');
      },
    });
  }

  $(document).on(clickEventType, '#plan-builder-mode', () => {
    togglePlanBuilderView();
  });

  $(() => {
    const content = 'Dictates how many locations can be worked on at once per activity. \
    \'Unconstrained\' means that an activity can be performed concurrently on all locations at once if needed.';
    $('#constrained_locations_help').tooltip({ content });
  });

  $(document).on(clickEventType, '.constrained_locations_column', (e) => {
    e.stopPropagation();
  });

  function openProjectRelatedResource(getLinkCallback, setLinksForPopupCallback, popupName) {
    if (!popupName) {
      popupName = '#company-projects';
    }
    const projectId = getProjectIdFromUrl();
    if (projectId) {
      const link = getLinkCallback(projectId);
      followLink(link);
    } else {
      setLinksForPopupCallback();
      showCompanyProjectsPopup(popupName);
    }
  }

  $(document).on(clickEventType, '#toggle_filters_section', () => {
    $('#plan_filters').toggle();
    $('#plan_filters_break_element').toggle();
    $('#plan_filters_separator').toggle();
    adjustOverlayAreaSizesForLoading('#cmas_blocking_area');
  });

  $(document).on('click', '.manage-tasks', function (e) {
    e.preventDefault();
    const url = $(this).attr('href');
    $(document.body).append('<div id="tasks_mgmt_popup" style="display:none"></div>');

    $.ajax({
      url,
      type: 'GET',
      dataType: 'script',
      success(data) {
        eval(data);
      },
    });
  });

  $(document).on(clickEventType, '.wda', function () {
    const id = $(this).data('id');
    const projectId = getProjectIdFromSubcontractorPlanPage();
    const subprojectId = $('#subproject_dropdown').val();
    const date = $(this).data('date');
    $.ajax({
      url: `/working_day_adjustments/${id}/edit`,
      dataType: 'html',
      data: { project_id: projectId, subproject_id: subprojectId },
      type: 'GET',
      success(data) {
        $('#edit_wda_popup').html(data);
        $('#edit_wda_popup #adjustment_date').val(date);
        $('#edit_wda_popup').dialog({
          modal: true,
          title: 'Edit working day adjustment',
          width: 'auto',
          resizable: false,
          autoOpen: true,
          open() {
            $(this).find('#adjustment_type_dropdown').trigger('change');
          },
          close() {
            $(this).dialog('destroy');
          },
        });
      },
    });
  });

  function createInputFromSpan(label) {
    const labelClass = label.attr('class');
    labelClass.replace('_span', '_input');
    const value = label.text() === 'Unconstrained' ? 1 : label.text();
    const textbox = label.next();
    textbox.val(value);
    textbox.show();
    textbox.focus();
    textbox.click();
  }

  $(document).on(clickEventType, '#plan_builder .concurrent_locations_span', function () {
    $(this).hide();
    createInputFromSpan($(this));
  });

  function updateConcurrentLocations(context) {
    const concurrentLocationsValue = $(context).val() === '' ? 'Unconstrained' : $(context).val();
    const concurrentLocationsSpan = $(context).prev();
    const unconstrainedButton = $(context).parent().find('.unconstrained-button');
    unconstrainedButton.hide();
    concurrentLocationsSpan.text(concurrentLocationsValue);
    $(context).hide();
    concurrentLocationsSpan.show();
    if (unconstrainedButton.data('clicked')) { unconstrainedButton.trigger('click'); }
    redrawLocationOrderForAdvancedView();
  }

  function redrawLocationOrderForAdvancedView() {
    const locationIds = getLocationsFromPage();
    const locations = getLocationObjsFromPage();
    const concurrentLocsCount = getMinConcurrentLocationFromAdvancedView();
    redrawLocationOrder(locationIds, locations, concurrentLocsCount);
  }

  $(document).on(clickEventType, '#plan_builder .concurrent_locations_span', function () {
    const label = $(this);
    const labelClass = label.attr('class');
    labelClass.replace('_span', '_input');
    const value = label.text() === 'Unconstrained' ? 1 : label.text();
    const textbox = $(this).next();
    textbox.val(value);
    $(this).hide();
    textbox.show();
    textbox.focus();
    textbox.click();
  });

  $(() => {
    unfocusOrEnterPress('.concurrent_locations_input', updateConcurrentLocations);
    const unconstrainedButtonHelp = 'Activity not constrained by location';
    $('.unconstrained-button').tooltip({ content: unconstrainedButtonHelp });
  });

  $(document).on(clickEventType, '.unconstrained-button', function () {
    const parentElement = $(this).parent();
    parentElement.find('.concurrent_locations_span').text('Unconstrained');
    $(this).hide();
    $(this).data('clicked', false);
  });

  function setConcurrentLocationsForActivities(concurrentLocationsValue) {
    $('.concurrent_locations_span').each(function () {
      $(this).text(concurrentLocationsValue);
    });
  }

  $(document).on('change', '#concurrent_locations', () => {
    if ($('.constrained_locations_column').is(':visible')) {
      showConfirmationPopupForChangedConcurrentLocations();
    }
  });

  $(document).on(clickEventType, '.concurrent_locations_span', function () {
    $(this).siblings('.unconstrained-button').css('display', 'inline');
  });

  $(document).on('mousedown', '.unconstrained-button', function () {
    $(this).data('clicked', true);
  });

  function calculateDiffWithServerTimeForTemplate() {
    if (($('#plan_builder').length > 0) && ($('#server_time').length > 0)) {
      const clientTime = new Date();
      const serverTime = new Date($('#server_time').val());
      const diffWithServerTime = serverTime - clientTime;
      const hiddenField = `<input id='diff_with_server_time' type='hidden' value=${diffWithServerTime}></input>`;
      $('#plan_builder').append(hiddenField);
    }
  }
  calculateDiffWithServerTimeForTemplate();

  $(document).on(clickEventType, '.infinity-button', function () {
    $(this).parents('td.constrained_locations_column').find('.concurrent_locations_span').text('∞');
  });

  $(document).on('change', '#concurrent_locations', () => {
    if ($('.constrained_locations_column').is(':visible') === false) { }
  });

  $(document).on(clickEventType, '#new-project-next-button', function () {
    $(this).hide();
    $('#new-project-details').hide();
    $('#new-project-save-button').show();
    $('#new-project-back-button').show();
    $('#new-project-permissions').show();
    updateSetupModeText();
  });

  $(document).on(clickEventType, '#new-project-back-button', function () {
    $(this).hide();
    $('#new-project-details').show();
    $('#new-project-next-button').show();
    $('#new-project-save-button').hide();
    $('#new-project-permissions').hide();
    updateSetupModeText();
  });

  $(document).on(clickEventType, '#select-all-permissions', function () {
    const selected = $(this).data('selected');
    $('#permission-rules-table, #supervisor-permission-rules-table').find('input:checkbox:visible').prop('checked', !selected);
    $(this).data('selected', !selected);
    const buttonText = selected ? 'Select All' : 'Unselect All';
    $(this).text(buttonText);
  });

  $(document).on(clickEventType, '.new_project_form #setup-mode', function () {
    let toggleElements = null;
    const setupMode = $(this);
    if ($('#new-project-back-button').is(':visible')) {
      toggleElements = $('#new-project-permissions .paired-element');
    } else {
      toggleElements = $('.new_project_form .advanced-setup-fields');
    }
    toggleElements.toggle();
    updateSetupModeText();
    if ($('#new-project-permissions').is(':visible')) {
      updateSelectAllButton();
    }
  });

  function updateSetupModeText() {
    const advancedSetupIsShown = $('.advanced-setup-fields:visible, .all-permissions:visible').length > 0;
    const text = advancedSetupIsShown ? 'Simple Setup' : 'Advanced Setup';
    $('#setup-mode').text(text);
  }

  function updateSelectAllButton() {
    const allCheckboxesCount = $('#new-project-permissions input:checkbox:visible').length;
    const checkedCheckboxesCount = $('#new-project-permissions input:checkbox:visible:checked').length;
    const allChecked = checkedCheckboxesCount === allCheckboxesCount;
    const text = allChecked ? 'Unselect All' : 'Select All';
    $('#select-all-permissions').text(text);
    $('#select-all-permissions').data('selected', allChecked);
  }
});
