import { clickEventType, showSpinner } from './helpers';

$(document).ready(function () {
  $(document).on(clickEventType, '#subproject_change_order', function () {
    $('#subproject_order_popup, #cmas_with_subcontracts_order_popup').dialog({
      modal: true, title: 'Activities order', autoOpen: true,
      resize: 'none', width: 'auto',
      open: function () {
        $(this).attr('id', 'subproject_order_popup');
        $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
        var projectId = $('#project_id').data('id');
        var subprojectId = $('#subproject_id').data('id');
        $.ajax({
          url: '/contr_main_acts/reorder',
          type: 'POST',
          dataType: 'html',
          data: {
            project_id: projectId,
            subproject_id: subprojectId
          },
          success: function (data) {
            $('#sortable_cma').html(data);
            $('#sortable_cma li').each(function () {
              $(this).children('a').remove();
              var text = $(this).text();
              $(this).html(text);
              $(this).prepend('<span class="ui-icon ui-icon-arrowthick-2-n-s"></span>');
              $(this).addClass('ui-state-default');
            });
            $('#sortable_cma').sortable();
            $('#sortable_cma').disableSelection();
          }
        });
      },
      close: function () {
        $(this).dialog('destroy');
      }
    });
  });

  $(document).on(clickEventType, '#subproject_order_popup #save_cma_order_button', function () {
    var cmaIds = $.map($('#cma_order li'), function (el) { return $(el).data('order'); });
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    $.ajax({
      url: '/contr_main_acts/reorder',
      type: 'POST',
      dataType: 'html',
      beforeSend: function () {
        $('#subproject_order_popup').dialog('destroy');
        showSpinner();
      },
      data: {
        project_id: projectId,
        subproject_id: subprojectId,
        cma_ids: cmaIds
      },
      success: function (data) {
        $('#contr_main_acts').html(data);
        $('#save_cma_order_button').attr('disabled', false);
      },
      error: function () {
        $('.spinner').hide();
      }
    });
  });

  $(document).on('submit', '#cma_order_form', function (e) {
    e.preventDefault();
  });
});
