import {
  clickEventType,
  showSpinner,
} from './helpers';

$(document).ready(() => {
  $('.chosen-select').chosen();

  const $companySelect = $('#q_employee_company_name_in');
  const createNewOption = (value, text, selected) => {
    return $('<option></option>').attr('value', value).attr('selected', selected && 'selected').text(text);
  };

  const SELECT_UPDATED_EVENT = 'chosen:updated';

  $('#q_completion_step_completion_requirement_id_in').chosen().change((event) => {
    showSpinner();
    let completionRequirementIds = $(event.target).val();
    if (!completionRequirementIds.length) {
      completionRequirementIds = $(event.target).children().toArray().map((o) => o.getAttribute('value'));
    }

    $.ajax({
      url: 'completion_steps',
      type: 'GET',
      dataType: 'json',
      data: { completion_requirement_id: completionRequirementIds },
      success(response) {
        const select = $('#q_completion_step_id_in');
        select.empty();
        $.each(response.completion_steps, (index, step) => {
          select.append(createNewOption(step.id, step.question));
        });
        select.trigger(SELECT_UPDATED_EVENT);
        $('.spinner').hide();
      },
    });
  });

  $('#q_subject_of_ActualEmployeeDay_type_project_id_in, #q_employee_company_name_in').chosen().change(() => {
    showSpinner();
    $.ajax({
      url: 'collections/completion_requirements',
      type: 'GET',
      dataType: 'json',
      data: {
        project_ids: $('#q_subject_of_ActualEmployeeDay_type_project_id_in').val(),
        company_names: $companySelect.val(),
      },
      success(response) {
        const select = $('#q_completion_step_completion_requirement_id_in');
        select.empty();

        $.each(response.completion_requirements, (index, item) => {
          select.append(createNewOption(item.value, item.text));
        });
        select.trigger(SELECT_UPDATED_EVENT);
        $('.spinner').hide();
      },
    });
  });

  $companySelect.chosen().change(() => {
    showSpinner();
    $.ajax({
      url: 'collections/projects',
      type: 'GET',
      dataType: 'json',
      data: {
        company_names: $companySelect.val(),
      },
      success(response) {
        const select = $('#q_subject_of_ActualEmployeeDay_type_project_id_in');
        const previousValues = select.val();

        select.empty();

        $.each(response.projects, (index, item) => {
          select.append(createNewOption(item.value, item.text, previousValues.includes(item.value.toString())));
        });

        select.trigger(SELECT_UPDATED_EVENT);
        $('.spinner').hide();
      },
    });
  });

  $('.search-submit').on(clickEventType, () => {
    showSpinner();
  });
});

$(document).on(clickEventType, '.form_entry', (event) => {
  const dataValues = $('#show_form_entries_button');
  const data = { url: `/${dataValues.data('classType')}/${dataValues.data('objectId')}/form_entries` };
  $.ajax({
    url: $(event.target).parent().data('link'),
    data,
    dataType: 'script',
    type: 'GET',
  });
});
