$(document).ready(() => {
  const $selectedEmplyeesCountSpan = $('#selected-employees-count');
  const $mergeEmployeesButton = $('#merge-employees');
  let duplicateIds = [];

  const updateSelectedEmployeesCountText = () => {
    $selectedEmplyeesCountSpan.text(duplicateIds.length);
  };

  const updateMergeButtonVisibility = () => {
    if (duplicateIds.length) {
      if ($mergeEmployeesButton.hasClass('hidden')) { $mergeEmployeesButton.removeClass('hidden'); }
    } else if (!$mergeEmployeesButton.hasClass('hidden')) {
      $mergeEmployeesButton.addClass('hidden');
    }
  };

  const updateElements = () => {
    updateSelectedEmployeesCountText();
    updateMergeButtonVisibility();
  };

  $('#duplicates-selection-section').on('click', 'input[name="duplicate-mark"]', (event) => {
    const duplicateId = event.target.getAttribute('data-id');

    if (event.target.checked) {
      duplicateIds.push(duplicateId);
    } else {
      const index = duplicateIds.indexOf(duplicateId);
      if (index > -1) {
        duplicateIds.splice(index, 1);
      }
    }
    updateElements();
  });

  $('#clear-duplicate-selections').on('click', () => {
    $('input[name="duplicate-mark"]').each((_, input) => {
      $(input).prop('checked', false);
    });

    duplicateIds = [];
    updateElements();
  });

  $mergeEmployeesButton.on('click', (event) => {
    const searchString = `?duplicate_ids[]=${duplicateIds.join('&duplicate_ids[]=')}`;
    window.location.href = `${event.target.getAttribute('data-link')}${searchString}`;
  });

  window.checkSelectedDuplicates = () => {
    duplicateIds.forEach((id) => {
      const checkbox = $(`input[name="duplicate-mark"][data-id="${id}"]`);
      if (checkbox.length) {
        checkbox.attr('checked', 'checked');
      }
    });
  };
});
