import {
  clickEventType,
  completionDateInput,
  lagLeadTextLink,
  massEditModeEnabled,
  showSpinner
} from './helpers';

$(document).ready(function () {
  $('#contr-act-loc-form').submit(function () {
    if ($('#details_section').is(':visible')) {
      $('#contr_act_loc_default_values [name=\'contr_act_loc_precedor_ids[]\']').remove();
    }
    $(this).dialog('destroy');
    showSpinner();
  });

  $(document).on(clickEventType, '.add_cal_button', function () {
    var contrMainActId = $(this).data('cmaId');
    showSpinner();
    $.ajax({
      url: '/contr_main_acts/' + contrMainActId + '/contr_act_locs/new.js',
      type: 'GET',
      success: function () {
        $('.spinner').hide();
        $('#details_section').show();
        $('#toggle_details').hide();
        $('#contr-act-loc-form #start_date').datepicker({
          dateFormat: 'dd/mm/yy'
        });
        $('.chosen-select').chosen({ width: 'auto' });
        var weekStart = $('#week_start_date').val();
        $('#main_form').append('<input type="hidden" name="week_start" value="' + weekStart + '" />');
        $('#contr-act-loc-form').dialog({
          modal: true, title: 'Edit activity', resize: false,
          width: '35vw', height: 'auto',
          close: function () {
            $(this).dialog('destroy');
          }
        });
      },
      error: function () {
        $('.spinner').hide();
      }
    });
  });

  $(document).on(clickEventType, '.location_td span', function () {
    var target = $(this).parent();
    if (massEditModeEnabled()) {
      target.toggleClass('selected_cal');
      if (target.hasClass('selected_cal')) {
        target.css('color', '#73C64C');
        target.css('font-weight', 'bold');
      } else {
        target.css('color', '');
        target.css('font-weight', '');
      }
      return;
    }
    var calId = target.parent().data('calId');
    var cmaId = target.parent().data('cmaId');
    var weekStart = $('#week_start_date').val();
    showSpinner();
    $.ajax({
      url: '/contr_act_locs/' + calId + '/edit.js?contr_main_act_id=' + cmaId,
      type: 'GET',
      data: { week_start: weekStart },
      success: function () {
        $('.spinner').hide();
        $('#contr-act-loc-form #main_form [name=_method]').val('post');
        var formAction = $('#contr-act-loc-form #main_form').attr('action');
        formAction += '?contr_act_loc_id=' + calId;
        $('#contr-act-loc-form #main_form').attr('action', formAction);

        weekStart = $('#week_start_date').val();
        $('#main_form').append('<input type="hidden" name="week_start" value="' + weekStart + '" />');
        $('#contr-act-loc-form #start_date').datepicker({
          dateFormat: 'dd/mm/yy'
        });
        $('#contr-act-loc-form').dialog({
          modal: true, title: 'Edit activity',
          width: '35vw', height: 'auto', resize: false,
          close: function () {
            $(this).dialog('destroy');
          }
        });
      },
      error: function () {
        $('.spinner').hide();
      }
    });
  });

  function makeLinkForLagLeadPopup(precedorName, precedorId, lagLeadCount) {
    var textOfLink = lagLeadTextLink(lagLeadCount);
    var precedorWithLink = precedorName.replace(/\(.*\)/, function () {
      return '<span class=lag_lead data-id=' + precedorId + ' data-lag_lead_count=' + lagLeadCount + '>' + textOfLink + '</span>';
    });
    return precedorWithLink;
  }

  $(document).on('change', '#contr-act-loc-form #contr_act_loc_precedor_ids, #precedors-form #precedors', function () {
    $(this).trigger('chosen:ready');
  });

  $(document).on('chosen:ready', '#contr_act_loc_precedor_ids', function () {
    clearSearchChoicesForDropdown();
    var selectedPrecedors = $('#contr_act_loc_precedor_ids').val();
    if (selectedPrecedors) {
      $('#contr_act_loc_precedor_ids option:selected').each(function (i, el) {
        var id = $(el).data('id');
        var lagLead = $(el).data('lag_lead_count');
        var preparedName = makeLinkForLagLeadPopup($(el).html(), id, lagLead);
        var index = i - 1;
        var searchChoiceItem = makeSearchChoiceItem(preparedName, index);
        $('#contr-act-loc-form .chosen-choices .search-field').before($.parseHTML(searchChoiceItem));
      });
    }
  });

  $(document).on('chosen:ready', '#precedors-form #precedors', function () {
    $('#precedors-form .search-choice').each(function () {
      $(this).remove();
    });
    $('select#precedors option:selected').each(function (i, el) {
      var id = $(el).data('id');
      var lagLeadCount = $(el).data('lag_lead_count');
      var preparedName = makeLinkForLagLeadPopup($(el).html(), id, lagLeadCount);
      var index = i - 1;
      var searchChoiceItem = makeSearchChoiceItem(preparedName, index);
      $('#precedors-form .chosen-choices .search-field').before($.parseHTML(searchChoiceItem));
    });
  });

  $(document).on(clickEventType, '#precedors-form .search-choice', function (e) {
    if ($(e.target).hasClass('lag_lead')) {
      openLagLeadPopup(e.target);
      $('#precedors-form').parents('.ui-dialog').hide();
    }
    if ($(e.target).hasClass('.search-choice-close')) {
      e.target.parents('.search-choice').remove();
    }
  });

  function makeSearchChoiceItem(name, indexInArray) {
    return '<li class=search-choice><span>' + name + '</span><a class=search-choice-close data-option-array-index=' + indexInArray + '></a></li>';
  }

  $(document).on(clickEventType, '#contr-act-loc-form .search-choice', function (e) {
    if ($(e.target).hasClass('lag_lead')) {
      openLagLeadPopup(e.target);
      $('#contr-act-loc-form').parents('.ui-dialog').hide();
    }
    if ($(e.target).hasClass('.search-choice-close')) {
      e.target.parents('.search-choice').remove();
    }
  });

  function openLagLeadPopup(precedor) {
    var precedorId = $(precedor).data('id');
    var lagLeadCount = $(precedor).data('lag_lead_count');
    $('#lag_lead_form #precedor_id').data('id', precedorId);
    if (lagLeadCount === undefined) {
      lagLeadCount = 0;
    }
    $('#lag_lead_form #lag_lead').val(Math.abs(lagLeadCount));
    if (parseInt(lagLeadCount, 10) < 0) {
      lagLeadType = '1';
    } else {
      lagLeadType = '0';
    }
    $('#lag_lead_form #type').val(lagLeadType);
    $('#lag_lead_form').dialog({
      modal: true, title: 'Edit lag/lead',
      width: 'auto', resizable: false, autoOpen: true,
      close: function () {
        $('#lag_lead_form form')[0].reset();
        $(this).dialog('destroy');
        $('#contr-act-loc-form, #precedors-form').parents('.ui-dialog').show();
      }
    });
  }

  function addLagLeadToCalForm() {
    var lagLeadCount = $('#lag_lead_form #lag_lead').val();
    var lagLeadType = $('#lag_lead_form #type').val();
    if (lagLeadType === '1') {
      lagLeadCount = -lagLeadCount;
    }
    var id = $('#lag_lead_form #precedor_id').data('id');
    var textLink = lagLeadTextLink(lagLeadCount);
    $('#contr-act-loc-form #contr_act_loc_precedor_ids option[data-id=' + id + ']').data('lag_lead_count', lagLeadCount);
    $('#contr-act-loc-form .search-choice .lag_lead[data-id=' + id + ']').html(textLink);
    $('#contr-act-loc-form .search-choice .lag_lead[data-id=' + id + ']').data('lag_lead_count', lagLeadCount);
    var calLagLead = "<input type='hidden' name='lag_lead[][" + id + "]' value=" + lagLeadCount + '></input>';
    $('#contr-act-loc-form #main_form').append($(calLagLead));
  }

  $(document).on(clickEventType, '#lag_lead_form #save_lag_lead', function (e) {
    e.preventDefault();
    if ($('#contr-act-loc-form').length) {
      planLagLeadPopupSave();
    }
    if ($('#precedors-form').length) {
      planBuilderLagLeadPopupSave();
    }
  });

  function planLagLeadPopupSave() {
    addLagLeadToCalForm();
    closeLagLeadForm();
    $('#contr-act-loc-form').parents('.ui-dialog').show();
  }

  function planBuilderLagLeadPopupSave() {
    addLagLeadToPlanBuilderPrecedorsForm();
    closeLagLeadForm();
    $('#precedors-form').parents('.ui-dialog').show();
  }

  function closeLagLeadForm() {
    $('#lag_lead_form form')[0].reset();
    $('#lag_lead_form').dialog('destroy');
  }

  function addLagLeadToPlanBuilderPrecedorsForm() {
    var id = $('#lag_lead_form #precedor_id').data('id');
    var lagLeadCount = $('#lag_lead_form #lag_lead').val();
    var lagLeadType = $('#lag_lead_form #type').val();
    if (lagLeadType === '1') {
      lagLeadCount = -lagLeadCount;
    }
    $('#precedors-form span[data-id=' + id + ']').data('lag_lead_count', lagLeadCount);
    $('select#precedors option[data-id=' + id + ']').data('lag_lead_count', lagLeadCount);
    var textOfLink = lagLeadTextLink(lagLeadCount);
    $('#precedors-form span[data-id=' + id + ']').html(textOfLink);
  }

  function unselectItemFromOriginalDropdown(selector, closeButton) {
    var itemId = $(closeButton).parents('.search-choice').find('.lag_lead').data('id');
    $(selector + ' option[data-id=' + itemId + ']').prop('selected', false);
    $(selector).trigger('chosen:updated');
    $(selector).trigger('chosen:ready');
  }

  function clearSearchChoicesForDropdown() {
    $('#contr-act-loc-form .search-choice').each(function () {
      $(this).remove();
    });
  }

  $(document).on(clickEventType, '#contr-act-loc-form .search-choice-close', function (e) {
    e.preventDefault();
    unselectItemFromOriginalDropdown('#contr-act-loc-form #contr_act_loc_precedor_ids', e.target);
  });

  $(document).on(clickEventType, '#precedors-form .search-choice-close', function (e) {
    e.preventDefault();
    unselectItemFromOriginalDropdown('#precedors-form #precedors', e.target);
  });

  $(document).on(clickEventType, '#contr-act-loc-form #toggle_details', function () {
    if (!$('#contr-act-loc-form .chosen-select').data('chosen')) {
      $('#contr-act-loc-form .chosen-select').chosen({ width: 'auto' });
    }
    $('#details_section').slideToggle('fast', function () {
      if ($('#details_section').is(':visible')) {
        var id = $('#contr_act_loc_id').val();
        $('#toggle_details').html('Hide details');
        $.ajax({
          url: '/contr_act_locs/' + id + '/precedors',
          type: 'GET',
          dataType: 'html',
          beforeSend: function () {
            $('#details_section .mini-spinner').show();
          },
          success: function (data) {
            $('#details_section #precedors').html(data);
            $('#contr_act_loc_precedor_ids').chosen({ width: 'auto' }).trigger('chosen:ready');
            $('#contr-act-loc-form #start_date').datepicker({
              dateFormat: 'dd/mm/yy'
            });
          },
          complete: function () {
            $('#details_section .mini-spinner').hide();
          }
        });
      } else {
        $('#toggle_details').html('Show details');
        $('#details_section #precedors').html('');
      }
    });
  });

  $(document).on(clickEventType, '#contr_act_loc_detail_status', function (e) {
    e.preventDefault();
    e.stopPropagation();
    var calId = $(this).data('calId');
    var formType = $(this).data('form-type');
    $.ajax({
      url: '/approvals/completion_form',
      type: 'GET',
      dataType: 'html',
      data: { 'cal_id': calId, 'form_type': formType },
      success: function (data) {
        $('#completion_form').html(data);
        $('#completion_form').dialog({
          modal: true, title: 'Complete activity form', resize: false,
          width: '35vw', height: 'auto',
          open: function () {
            $('#contr-act-loc-form').dialog('close');
          },
          close: function () {
            $(this).dialog('destroy');
            $('#contr-act-loc-form').dialog({
              title: 'Edit contract main activity',
              autoSize: true, resizable: false, width: '35vw',
              close: function () {
                $(this).dialog('destroy');
                completionDateInput();
              }
            });
          }
        });
      }
    });
  });
});
