import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/skins/lightgray/skin.min.css';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';

import { clickEventType, showSpinner } from './helpers';
import { showFlashMessage } from './flash';

$(document).ready(function () {
  function initializeTinyMCE() {
    tinymce.init({
      selector: 'textarea.tinymce',
      paste_data_images: true,
      plugins: [
        'advlist autolink lists link image charmap hr anchor pagebreak',
        'searchreplace visualblocks visualchars code fullscreen',
        'insertdatetime media table ',
        'paste textcolor colorpicker'
      ],
      toolbar1: 'insertfile undo redo | styleselect | bold italic |\
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | link image media',
      toolbar2: 'forecolor backcolor | fontsize fontsizeselect',
      media_live_embeds: true,
      file_browser_callback_types: 'file image media',
      file_picker_types: 'file image media',
      images_reuse_filename: true,
      skin: false,
      media_url_resolver: function (data, embedHtmlCallback) {
        if (data.url === undefined) { return; }
        if (data.url.match(/^data:video.*;base64,.*/)) {
          uploadMedia(data.url, embedHtmlCallback);
        } else {
          embedHtmlCallback({ html: '' });
        }
      },
      file_picker_callback: function (callback, value, meta) {
        showSpinner();
        var input = $('#training_attachment');
        input.trigger('click');
        input.on('change', function () {
          var file = this.files[0];
          if (file === undefined) { return; }
          var reader = new FileReader();
          reader.onload = function (e) {
            callback(e.target.result, {
              alt: ''
            });
          };
          reader.readAsDataURL(file);
        });
        $('.spinner').hide();
      },
      images_upload_handler: function (blobInfo, successCallback) {
        var formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        sendMedia(formData, successCallback);
      }
    });
  }

  function sendMedia(formData, successCallback) {
    $.ajax({
      url: '/trainings/upload_attachment',
      data: formData,
      type: 'POST',
      contentType: false,
      processData: false,
      cache: false,
      dataType: 'json',
      success: function (data) {
        successCallback(data.location);
        var hiddenField = '<input type="hidden" name="asset_ids[]" value="' + data.asset_id + '"></input>';
        $('.training_form').append(hiddenField);
      },
      error: function (data) {
        showFlashMessage(data.responseJSON.error_message);
      },
      complete: function () {
        $('#training_attachment').val('');
      }
    });
  }

  if ($('textarea.tinymce')) {
    initializeTinyMCE();
  }

  function uploadMedia(sourceData, embedHtmlCallback) {
    var parsedData = sourceData.split(';');
    var contentType = parsedData[0].split(':')[1];
    var media = parsedData[1].split(',')[1];
    var blob = b64toBlob(media, contentType);
    var formData = new FormData();
    formData.append('file', blob);
    showSpinner();
    var successCallback = function (mediaUrl) {
      var embedHtml = "<video width='320' height='240' controls> \
          <source src='" + mediaUrl + "' type='video/mp4'></video>";
      embedHtmlCallback({ html: embedHtml });
    };
    sendMedia(formData, successCallback);
  }

  function b64toBlob(b64Data, contentType, sliceSize) {
    var contentType = contentType || '';
    var sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  $(document).on(clickEventType, '.training_icon', function (e) {
    e.preventDefault();
    var tag = $(this).data('tag');
    var superUser = $(this).data('super_user');
    if (!tag) {
      showFlashMessage('Training is not found');
      return;
    }
    $.ajax({
      url: '/trainings/' + tag,
      type: 'GET',
      dataType: 'json',
      success: function (data) {
        if(data == null) return;

        $('.training_popup').html(data.content);
        if (superUser) {
          $('.training_popup').prepend(
            "<a href='" + '/trainings/' + tag + '/edit' + "' target='_blank'>Edit</a> \
             <a href='/trainings/new' target='_blank'>New</a>"
          );
        }
        $('.training_popup').prepend(
          "<label class='hints-label'>Don't show hints</label><input class='disable-hints' type='checkbox'/>"
        );
        $('.training_popup').dialog({
          modal: true, title: data.name,
          width: '50vw', autoOpen: true,
          resizable: false, close: function () {
            $(this).dialog('destroy');
          }
        });
        $('.ui-icon-closethick').attr('tabindex', -1).focus();
        $('.training_popup').css('height', '40vh');
      }
    });
  });

  function setTooltipForTrainingIcon() {
    var hint = $('.training_icon').attr('hint');
    $('.training_icon').tooltip({ content: hint });
  }
  setTooltipForTrainingIcon();

  $(document).ready(function() {
    $('.hints_icon').trigger(clickEventType);
  });

  $(document).on(clickEventType, 'input.disable-hints[type=checkbox]', function(){
    $.ajax({
      url: '/user_preferences',
      type: 'PUT',
      data: { user_preference: { data: { 'hints_disabled': this.checked }}},
    });
  });

  $(document).on(clickEventType, '.training_popup .training-link', function(){
    $('.training_icon').data('tag', $(this).val());
    $('.training_icon').trigger(clickEventType);
  });
});
