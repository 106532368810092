import { clickEventType } from './helpers';

$(document).ready(function () {
  $(document).on(clickEventType, '#removeTask', function () {
    $(this).parent().remove();
  });

  $(document).on(clickEventType, '#addNewTask', function (e) {
    e.preventDefault();
    var newTask = $('#jsTemplates #newTask').clone();
    $(this).prev().append(newTask);
    var addedTask = $(this).prev().children('#newTask');
    addedTask.attr('id', '');
    var input = addedTask[0].firstElementChild;
    input.setAttribute('class', 'task-input');
    input.addEventListener('keyup', function () {
      sortAndSuggest(input);
    });
  });

  addListeners();

  function addListeners() {
    var inputs = document.querySelectorAll('.task-input');
    var len = inputs.length;
    for (var i = 0; i < len; i++) {
      var elem = inputs[i];
      elem.addEventListener('keyup', function () {
        sortAndSuggest(elem);
      });
    }
  }

  var documentClick;

  $(document).on('touchstart', function () {
    documentClick = true;
  });

  $(document).on('touchmove', function () {
    documentClick = false;
  });

  $(document).on('click touchend', '.task-suggestion', function (e) {
    if (e.type === 'click') documentClick = true;

    if (documentClick) {
      $(this).parent().siblings('.task-input').val($(this).html());
      hideSuggestions();
    }
  });


  $('.task-input').focusout( function () {
    setTimeout(function () {
      hideSuggestions();
    }, 200);
  });

  function sortAndSuggest(elem) {
    var searchTxt = elem.value.toLowerCase();
    var suggestedTasks = sortTasks(task_suggestions, searchTxt);
    listSuggestions(suggestedTasks, elem);
  }

  function sortTasks(task_suggestions, searchTxt) {
    var filteredTasks = $.grep(task_suggestions, function (i, n) {
      var searchable = i.description.toLowerCase();
      return searchable.includes(searchTxt);
    });
    return filteredTasks;
  }

  function listSuggestions(suggestedTasks, elem) {
    hideSuggestions();
    var i = 0;
    $.each(suggestedTasks, function (key, value) {
      var task = $('#taskSuggestion').clone();
      task.text(value.description);
      task.attr('id', '');

      if (i < 10) {
        appendToSiblingElement('task-suggestions', elem, task);
      }

      i++;
    });
  }

  function appendToSiblingElement(targetClass, element, task) {
    var children = element.parentNode.childNodes;
    var len = children.length;

    for (var i = 0; i < len; i++) {
      if (children[i].className === targetClass) {
        children[i].append(task[0]);
        break;
      }
    }
  }

  function hideSuggestions() {
    $('.task-suggestions').empty();
  }
});
