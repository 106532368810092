import { clickEventType } from './helpers';

$(document).ready(function () {
  $(document).on(clickEventType, 'button#new_delay_reason', function () {
    $.ajax({
      url: '/delay_reasons/new',
      type: 'GET',
      success: function () {
        $('#delay_reason_form').dialog({
          modal: true, title: 'New delay reason',
          width: 'auto', resizable: false,
          close: function () {
            $(this).dialog('destroy');
          }
        });
      }
    });
  });

  $(document).on(clickEventType, '#delay_reasons tr', function () {
    var delayReasonId = $(this).data('id');
    $.ajax({
      url: '/delay_reasons/' + delayReasonId + '/edit',
      type: 'GET',
      success: function () {
        $('#delay_reason_form').dialog({
          modal: true, title: 'Edit delay reason',
          width: 'auto', resizable: false,
          close: function () {
            $(this).dialog('destroy');
          }
        });
      }
    });
  });
});
