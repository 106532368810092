import { clickEventType } from './helpers';

$(document).ready(() => {
  $('#new_material').on(clickEventType, () => {
    const projectId = $('table.material').data('projectId');
    $.ajax({
      url: `/projects/${projectId}/materials/new.js`,
      type: 'GET',
    });

    $(document).ajaxComplete(() => {
      $('#material_form').dialog({
        modal: true,
        title: 'New material',
        zIndex: 10000,
        autoOpen: true,
        width: 'auto',
        resizable: false,
        close() {
          $(this).remove();
        },
      });
    });
  });
});
