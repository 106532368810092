import { isFunction } from './helpers';

let map;
let newLat;
let newLong;
const markersArray = [];

function showApplyButton() {
  if ($('#set-location-apply').hasClass('hidden')) { $('#set-location-apply').removeClass('hidden'); }
}

function placeMarker(latLng) {
  while (markersArray.length) {
    markersArray.pop().setMap(null);
  }

  let nextLatLng;
  if (isFunction(latLng.lat)) {
    nextLatLng = latLng;
  } else {
    nextLatLng = new google.maps.LatLng(latLng.lat, latLng.long);
  }

  const marker = new google.maps.Marker({
    position: nextLatLng,
    map,
  });

  markersArray.push(marker);
  newLat = nextLatLng.lat().toFixed(5);
  newLong = nextLatLng.lng().toFixed(5);
  showApplyButton();
  return nextLatLng;
}

function placeMarkerAndPanTo(latLng) {
  const nextLatLng = placeMarker(latLng);
  map.panTo(nextLatLng);
}

function initAutocomplete() {
  const input = document.getElementById('location-search-input');
  const options = {
    fields: ['geometry'],
    strictBounds: false,
    types: [],
  };

  const autocomplete = new google.maps.places.Autocomplete(input, options);
  autocomplete.bindTo('bounds', map);

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();

    if (!place.geometry || !place.geometry.location) {
      return;
    }

    placeMarkerAndPanTo(place.geometry.location);
  });
}

window.initMap = function () {
  const mapHolder = $('#map');
  if (mapHolder.length > 0) {
    map = new google.maps.Map(mapHolder[0], {
      center: {
        lat: -34.397,
        lng: 150.644,
      },
      zoom: 8,
    });
    map.addListener('click', (e) => {
      placeMarker(e.latLng);
    });
  }
  initAutocomplete();
};

$(document).ready(() => {
  const latElement = $('#project_lat');
  const longElement = $('#project_long');
  const coordinatesValueElement = $('#coordinates-value');
  const applyButton = $('#set-location-apply');

  const mapDialog = $('#map-dialog');

  mapDialog.dialog({
    modal: true,
    title: 'Choose location',
    resize: false,
    width: 'auto',
    height: 'auto',
    autoOpen: false,
    close(e) {
      closeDialog(e);
    },
  });

  function closeDialog(e) {
    e.preventDefault();

    newLat = '';
    newLong = '';
    $('#location-search-input').val('');
    mapDialog.dialog('close');

    while (markersArray.length) {
      markersArray.pop().setMap(null);
    }
  }

  function setCurrentUserLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const long = position.coords.longitude;

      placeMarkerAndPanTo({ lat, long });
    });
  }

  applyButton.on('click', (e) => {
    e.preventDefault();

    if (newLat && newLong) {
      latElement.val(newLat);
      longElement.val(newLong);

      coordinatesValueElement.css('display', 'flex');
    }

    closeDialog(e);
  });

  $('#set-location-close').on('click', closeDialog);

  $('#set-location').on('click', (e) => {
    e.preventDefault();

    const lat = latElement.val();
    const long = longElement.val();

    if (lat && long) {
      placeMarkerAndPanTo({ lat, long });
    } else {
      setCurrentUserLocation();
    }
    mapDialog.dialog('open');
  });
});
