import {
  clickEventType,
  handleResourceNameInput,
  messageOnEmptyValue,
  unfocusOrEnterPress
} from './helpers';
import { showFlashMessage } from './flash';

$(document).ready(function () {
  $(document).on(clickEventType, '.new_location_type_name_input, .new_location_type_within_input', function () {
    var projectId = $('#project_id').data('id');
    $(this).autocomplete({
      source: '/projects/' + projectId + '/project_location_types.json',
      minLength: 0
    });
    var e = $.Event('keydown');
    e.which = 8;
    $(this).trigger(e);
  });

  handleResourceNameInput('#new_location_section .new_location_name_input', handleLocationNameInput);
  handleResourceNameInput('#new_location_type_section .new_location_type_name_input', handleLocationTypeNameInput);

  handleTabOnNameInput('#new_location_type_section .new_location_type_name_input', '.new_location_type_within_input');
  handleTabOnNameInput('#new_location_section .new_location_name_input', '.new_location_within_input');

  function handleTabOnNameInput(selector, relatedSelector) {
    $(document).on('keydown', selector, function (e) {
      var relatedElem = $(this).parent().siblings().children(relatedSelector);
      if (relatedElem.length === 0) {
        return true;
      }
      var code = (e.keyCode ? e.keyCode : e.which);
      if (code === 9) {
        $(document).off('focusout', selector);
        relatedElem.focus();
        relatedElem.click();
        return false;
      }
    });
  }

  $(document).on('focusout', '#new_location_type_section .new_location_type_within_input', function () {
    var within = $(this).val();
    var locationTypeElem = $(this).parent().siblings().children('.new_location_type_name_input');
    var locationTypeName = locationTypeElem.val();
    if (messageOnEmptyValue(locationTypeName, 'Location Type name can\'t be empty')) { return false; }
    if (within === '') {
      handleLocationTypeNameInput(locationTypeElem);
      return true;
    }
    var values = { };
    values.locationTypeName = locationTypeName;
    values.location_type_within = within;
    insertLocationType(locationTypeElem, values, successOnInsertLocationType);
  });

  function handleLocationTypeNameInput(context) {
    var locationTypeName = $(context).val();
    if (messageOnEmptyValue(locationTypeName, 'Location Type Name can\'t be empty')) { return false; }
    var values = { };
    values.locationTypeName = locationTypeName;
    insertLocationType(context, values, successOnInsertLocationType);
  }

  function insertLocationType(context, values, callback) {
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    $.ajax({
      url: '/projects/' + projectId + '/subprojects/' + subprojectId + '/manage_location_types',
      type: 'POST',
      data: values,
      success: function (data) {
        callback(data, context);
        return false;
      }
    });
  }

  function successOnInsertLocationType(data, context) {
    var res = data.status;
    if (res !== 'ok') {
      showFlashMessage(res);
      return false;
    }
    $(context).val('');
    $(context).parent().siblings().children('.new_location_type_name_input').val('');
    listLocationTypes(updateLocationsTable);
    showFlashMessage('Successfully created.', 'success');
  }

  function listLocationTypes(callback) {
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    $.ajax({
      url: '/projects/' + projectId + '/subprojects/' + subprojectId + '/location_types',
      type: 'GET',
      success: function (data) {
        $('#subproject_location_types').html(data);
        callback();
        return false;
      }
    });
  }

  function addLocationTypeDetails(id) {
    var projectId = $('#project_id').data('id');
    $.ajax({
      url: '/projects/' + projectId + '/location_types/' + id,
      type: 'GET',
      success: function (data) {
        var locationTypeLocationsElem = $('.location_type_locations[data-location-type-id="' + id + '"]');
        if (locationTypeLocationsElem.length) {
          locationTypeLocationsElem.replaceWith(data);
        } else {
          $('#location_type_details').append(data);
        }
      }
    });
    return false;
  }

  removeResource('#remove_location_type', removeLocationType, 'locationTypeId');
  removeResource('#remove_location', removeLocation, 'locationId');

  function removeResource(selector, callback, idAttributeName) {
    $(document).on(clickEventType, selector, function () {
      var id = $(this).parents('tr').data(idAttributeName);
      callback(id);
    });
  }

  function removeLocationType(id) {
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    checkAffectedSubprojectsAndPlants('location_type', id, true,
      function () {
        $.ajax({
          url: '/projects/' + projectId + '/location_types/' + id + '?subproject_id=' + subprojectId,
          type: 'DELETE',
          success: function (data) {
            var res = data.status;
            if ( res !== 'ok') {
              showFlashMessage(res);
              return false;
            }
            $('#subproject_location_types tr[data-location-type-id="' + id + '"]').remove();
            $('#location_type_details .location_type_locations[data-location-type-id="' + id + '"]').remove();

            listLocationTypes(updateLocationsTable);
            showFlashMessage('Location type has been deleted', 'success');
          }
        });
        return false;
      },
      function () {});
  }

  function removeLocation(id) {
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    checkAffectedSubprojectsAndPlants('location', id, true, function () {
      $.ajax({
        url: '/projects/' + projectId + '/subprojects/' + subprojectId + '/remove_location',
        type: 'POST',
        data: {location_id: id},
        success: function (data) {
          var res = data.status;
          if ( res !== 'ok') {
            showFlashMessage(res);
            return false;
          }
          $('#location_type_locations tr[data-location-id="' + id + '"]').remove();
          $('td[data-location-id="' + id + '"').each(function () {
            $(this).html('<span class="location_within_span" style="font-style: italic;">click to add</span>');
          });

          showFlashMessage('Location type has been deleted', 'success');
        }
      });
      return false;
    }, function () {});
  }

  function handleLocationNameInput(context) {
    var locationName = $(context).val();
    var locationTypeId = $(context).parents('tbody').data('locationTypeId');
    if (messageOnEmptyValue(locationName, 'Value can\'t be empty')) { return false; }
    var values = { };
    values.location_name = locationName;
    values.location_type_id = locationTypeId;
    insertLocation(context, values, successOnHandleLocationName);
  }

  function successOnHandleLocationName(data, context) {
    var res = data.status;
    var id = data.id;
    if (res !== 'ok') {
      showFlashMessage(res);
      return false;
    }
    addLocationDetails(id, context);
    $(context).val('');
    showFlashMessage('Successfully created.', 'success');
  }

  function addLocationDetails(id, context) {
    var projectId = $('#project_id').data('id');
    $.ajax({
      url: '/projects/' + projectId + '/locations/' + id,
      type: 'GET',
      success: function (data) {
        $(context).parents('tr').before(data);
      }
    });
    return false;
  }

  $(function () {
    var elem = '#subproject_location_types .location_type_name_input';
    unfocusOrEnterPress(elem, updateLocationTypeName);
  });

  function updateLocationTypeName(context) {
    var name = $(context).val();
    if (messageOnEmptyValue(name, 'Location Type name can\'t be empty')) { return false; }
    var id = $(context).parents('tr').data('locationTypeId');
    checkAffectedSubprojectsAndPlants('location_type', id, false, function () {
      var projectId = $('#project_id').data('id');
      var values = { };
      values['location_type[name]'] = name;
      $.ajax({
        url: '/projects/' + projectId + '/location_types/' + id + '/change',
        type: 'POST',
        data: values,
        success: function (data) {
          var res = data.status;
          if (res === 'ok') {
            $(context).prev().html(name);
            $(context).prev().show();
            $(context).remove();
            updateParentLocationTypeNames(id, name);
            showFlashMessage('Successfully changed.', 'success');
          } else {
            $(context).focus();
            showFlashMessage(res);
          }
        }
      });
      return false;
    }, function () {
      var elem = '#subproject_location_types .location_type_name_input';
      $(document).off('focusout', elem);
      $(elem).focus();
      unfocusOrEnterPress(elem, updateLocationTypeName);
    });
  }

  function updateParentLocationTypeNames(id, name) {
    $('span[data-location-type-id="' + id + '"').each(function () {
      $(this).text(name);
    });
  }

  function getLocationTypeIds() {
    return $('#subproject_location_types tr').map(function () { return $(this).data('locationTypeId'); });
  }

  $(function () {
    var elem = '#location_type_locations .location_name_input';
    unfocusOrEnterPress(elem, updateLocationName);
  });

  function updateLocationName(context) {
    var name = $(context).val();
    if (messageOnEmptyValue(name, 'Location name can\'t be empty')) { return false; }
    var id = $(context).parents('tr').data('locationId');
    checkAffectedSubprojectsAndPlants('location', id, false, function () {
      var projectId = $('#project_id').data('id');
      var values = { };
      values['location[name]'] = name;
      $.ajax({
        url: '/projects/' + projectId + '/locations/' + id + '/change',
        type: 'POST',
        data: values,
        success: function (data) {
          var res = data.status;
          if (res === 'ok') {
            $(context).prev().html(name);
            $(context).prev().show();
            $(context).remove();
            updateParentLocationNames(id, name);
            showFlashMessage('Successfully changed.', 'success');
          } else {
            $(context).focus();
            showFlashMessage(res);
          }
        }
      });
      return false;
    }, function () {
      var elem = '#location_type_locations .location_name_input';
      $(document).off('focusout', elem);
      $(elem).focus();
      unfocusOrEnterPress(elem, updateLocationName);
    });
  }

  function updateParentLocationNames(id, name) {
    $('td[data-location-id="' + id + '"').each(function () {
      $(this).text(name);
    });
  }

  $(document).on(clickEventType, '.new_location_within_input', function () {
    var projectId = $('#project_id').data('id');
    var ltId = $(this).parents('tbody').data('locationTypeId');
    $(this).autocomplete({
      source: '/projects/' + projectId + '/location_types/' + ltId + '/parent_locations.json',
      minLength: 0
    });
    var e = $.Event('keydown');
    e.which = 8;
    $(this).trigger(e);
  });

  $(document).on('focusout', '#new_location_section .new_location_within_input', function () {
    var within = $(this).val();
    var locationElem = $(this).parent().siblings().children('.new_location_name_input');
    var locationName = locationElem.val();
    var locationTypeId = $(this).parents('tbody').data('locationTypeId');
    if (messageOnEmptyValue(locationName, 'Location Name can\'t be empty')) { return false; }
    if (within === '') {
      handleLocationNameInput(locationElem);
      return true;
    }
    var values = { };
    values.location_name = locationName;
    values.location_within = within;
    values.location_type_id = locationTypeId;
    insertLocation(locationElem, values, successOnInsertLocation);
  });

  function insertLocation(context, values, callback) {
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    $.ajax({
      url: '/projects/' + projectId + '/subprojects/' + subprojectId + '/manage_locations',
      type: 'POST',
      data: values,
      success: function (data) {
        callback(data, context);
        return false;
      }
    });
  }

  function successOnInsertLocation(data, context) {
    var res = data.status;
    if (res !== 'ok') {
      showFlashMessage(res);
      return false;
    }
    $(context).val('');
    var withinElem = $(context).parent().siblings().children('.new_location_within_input');
    if ((withinElem.length) && (withinElem.val() !== '')) {
      withinElem.val('');
      updateLocationsTable();
    } else {
      var locationTypeId = $(context).parents('tbody').data('locationTypeId');
      addLocationTypeDetails(locationTypeId);
    }
    showFlashMessage('Successfully created.', 'success');
  }

  function updateLocationsTable() {
    var ids = getLocationTypeIds();
    ids.each(function (_, el) {
      addLocationTypeDetails(el);
    });
  }

  prepareWithinDropdown('#subproject_location_types .location_type_within_span', 'location_type_within_dropdown', populateLocationTypeWithin);
  prepareWithinDropdown('#location_type_locations .location_within_span', 'location_within_dropdown', populateLocationWithin);

  function prepareWithinDropdown(selector, dropdownClass, callback) {
    $(document).on(clickEventType, selector, function () {
      var projectId = $('#project_id').data('id');
      var context = this;
      callback(projectId, context, dropdownClass);
    });
  }

  function populateLocationTypeWithin(projectId, context, dropdownClass) {
    $.ajax({
      url: '/projects/' + projectId + '/project_location_types.json',
      type: 'GET',
      dataType: 'json',
      success: function (data) {
        showWithinDropdown(context, dropdownClass, data);
      }
    });
  }

  function populateLocationWithin(projectId, context, dropdownClass) {
    var ltId = $(context).parents('tbody').data('locationTypeId');
    $.ajax({
      url: '/projects/' + projectId + '/location_types/' + ltId + '/parent_locations.json',
      type: 'GET',
      dataType: 'json',
      success: function (data) {
        showWithinDropdown(context, dropdownClass, data);
      }
    });
  }

  function showWithinDropdown(context, dropdownClass, data) {
    $(context).hide();
    $(context).after('<select class="' + dropdownClass + '"> </select>');
    data.unshift('');
    $.each(data, function (_, value) {
      $('.' + dropdownClass).append($('<option></option>').val(value).html(value));
    });
    var val = $(context).html();
    $('.' + dropdownClass).val(val);
    $('.' + dropdownClass).focus();
  }

  $(document).on('change, focusout', '.location_type_within_dropdown', function () {
    updateLocationTypeWithin(this);
  });

  $(document).on('change, focusout', '.location_within_dropdown', function () {
    updateLocationWithin(this);
  });

  function updateLocationTypeWithin(context) {
    var within = $(context).val();
    var id = $(context).parents('tr').data('locationTypeId');
    checkAffectedSubprojectsAndPlants('location_type', id, false, function () {
      var projectId = $('#project_id').data('id');
      var values = { };
      values.within = within;
      $.ajax({
        url: '/projects/' + projectId + '/location_types/' + id + '/change',
        type: 'POST',
        data: values,
        success: function (data) {
          var res = data.status;
          if (res === 'ok') {
            $(context).prev().html(within);
            $(context).prev().show();
            $(context).remove();
            listLocationTypes(updateLocationsTable);
            showFlashMessage('Successfully changed.', 'success');
          } else {
            $(context).focus();
            showFlashMessage(res);
          }
        }
      });
      return false;
    }, function () {
      var elem = '#subproject_location_types .location_type_within_input';
      $(document).off('focusout', elem);
      $(elem).focus();
      unfocusOrEnterPress(elem, updateLocationTypeWithin);
    });
  }

  $(function () {
    var elem = '#location_type_locations .location_within_input';
    unfocusOrEnterPress(elem, updateLocationWithin);
  });

  function updateLocationWithin(context) {
    var name = $(context).val();
    var id = $(context).parents('tr').data('locationId');
    checkAffectedSubprojectsAndPlants('location', id, false, function () {
      var projectId = $('#project_id').data('id');
      var values = { };
      values.within = name;
      $.ajax({
        url: '/projects/' + projectId + '/locations/' + id + '/change',
        type: 'POST',
        data: values,
        success: function (data) {
          var res = data.status;
          if (res === 'ok') {
            $(context).prev().html(name);
            $(context).prev().show();
            $(context).remove();
            listLocationTypes(updateLocationsTable);
            showFlashMessage('Successfully changed.', 'success');
          } else {
            $(context).focus();
            showFlashMessage(res);
          }
        }
      });
      return false;
    }, function () {
      var elem = '#location_type_locations .location_within_input';
      $(document).off('focusout', elem);
      $(elem).focus();
      unfocusOrEnterPress(elem, updateLocationWithin);
    });
  }

  function checkAffectedSubprojectsAndPlants(resourceType, id, needConfirmation, successCallback, negativeCallback) {
    var projectId = $('#project_id').data('id');
    var subprojectId = $('#subproject_id').data('id');
    var values = {};
    values['' + resourceType + '_id'] = id;
    values.subprojectId = subprojectId;
    $.ajax({
      url: '/projects/' + projectId + '/check_affected_resources',
      type: 'POST',
      data: values,
      success: function (data) {
        if ((data === '') && needConfirmation) {
          data = '<p>Are you sure?</p>';
        }
        if (data !== '') {
          $(document.body).append('<div id="subproject_confirmation_popup" style="display:none"></div>');
          $('#subproject_confirmation_popup').dialog({
            modal: true, title: 'Confirmation dialog', autoOpen: true,
            resize: 'none', width: 'auto',
            buttons: [
              {
                text: 'Yes',
                click: function () {
                  successCallback();
                  $(this).dialog('close');
                }
              },
              {
                text: 'Cancel',
                click: function () {
                  negativeCallback();
                  $(this).dialog('close');
                }
              }
            ],
            open: function () {
              $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
              $(this).html(data);
            },
            close: function () {
              $(this).dialog('destroy');
              $('#subproject_confirmation_popup').remove();
            }
          });
        } else {
          successCallback();
        }
      }
    });
    return false;
  }
});
