$(document).ready(() => {
  const formEntriesFilter = '.form-entries-selection';
  const showFormEntriesButton = $('#show_form_entries_button');
  const id = showFormEntriesButton.data('objectId');
  const classType = showFormEntriesButton.data('classType');
  let data = {};
  const url = `/${classType}/${id}/form_entries`;

  $(document).on('change', formEntriesFilter, (e) => {
    e.preventDefault();
    const selectedOption = $('.form-entries-selection option:selected').text();

    if (selectedOption === 'All projects') {
      data = { company_id: showFormEntriesButton.data('companyId') };
    } else {
      data = { project_id: $(formEntriesFilter).val() };
    }

    $.ajax({
      url,
      type: 'GET',
      dataType: 'html',
      data,
      success(result) {
        $('#form_entries_page').html(result);
      },
    });
  });

  $(document).on('click', '#show_form_entries_button', (e) => {
    e.preventDefault();
    if (showFormEntriesButton.text() === 'Show form entries') {
      showFormEntriesButton.html('Hide form entries');
      data = { company_id: showFormEntriesButton.data('companyId') };

      $.ajax({
        url,
        type: 'GET',
        dataType: 'html',
        data,
        success(result) {
          $('#form_entries_page').html(result);
        },
      });
    } else {
      showFormEntriesButton.html('Show form entries');
      $('#form_entries_page').html('');
    }
  });
});
