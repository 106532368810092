import {
  UserPreferences,
  adjustPlanViewWidth,
  clickEventType,
  fullscreenModeEnabled,
  rerenderSidebarRequests,
} from './helpers';

import { plantPlanner } from './plans';

function dropMenu() {
  $('.login-logout').toggleClass('responsive');
  $('.header-menu').toggleClass('responsive');
  $('#mobile-menu .header-nav-links__dropdown input').prop('checked', false);
}

$(document).ready(() => {
  // LISTEN FOR SCREEN RESIZES AND AT THE END OF A RESIZE EVENT SET THE FLOATING DIV WIDTH
  const setFloatWidth = function () {
    if (typeof publicLogin !== 'undefined' && publicLogin) return;

    const mainWidth = $('.main-content').width();
    $('.floating').css({ width: mainWidth });
    const mainContentLeft = $('.main-content').position().left;
    $('.footer').css('left', mainContentLeft);
  };

  let timeout = false;
  // holder for timeout id

  const delay = 250; // Delay after event is "complete" to call back
  const closedSidebarWidth = '9px';
  const hideSidebarAnimateParams = { duration: 'slow', 
                                     complete: () => $('.sidebar-content-container').css('display', 'none') };
  const plannerResizeAnimateParams = { duration: 'slow',
                                       step: () => updatePlannerSizeIfPresent(),
                                       complete: () => updatePlannerSizeIfPresent() };

  window.addEventListener('resize', () => {
    adjustPlanViewWidth();
    clearTimeout(timeout);
    timeout = setTimeout(setFloatWidth, delay);
    if (($(document).width() > 768) && (!fullscreenModeEnabled())) {
      $('#sidebar').show();
    } else {
      $('#sidebar').hide();
    }
  });

  setFloatWidth();

  checkUserPreferencesForSidebars();

  // CLOSE SIDEBAR FUNCTION
  $(document).on(clickEventType, '#close-sidebar', () => {
    closeUserSidebarCssChanges();

    const url = window.location.href;
    const prefs = new UserPreferences();

    if (url.split('/')[5] === 'plan') {
      prefs.remember('userPlanSidebarIsOpen', false);
    } else {
      prefs.remember('userSidebarIsOpen', false);
    }

    setTimeout(() => {
      setFloatWidth();
    }, 400);
  });

  // OPEN SIDEBAR FUNCTION
  $(document).on(clickEventType, '#open-sidebar', () => {
    openUserSidebarCssChanges();

    const url = window.location.href;
    const prefs = new UserPreferences();

    if (url.split('/')[5] === 'plan') {
      prefs.remember('userPlanSidebarIsOpen', true);
    } else {
      prefs.remember('userSidebarIsOpen', true);
    }

    setTimeout(() => {
      setFloatWidth();
    }, 400);
  });

  // CLOSE REQUEST SIDEBAR FUNCTION
  $(document).on(clickEventType, '#close-requests-sidebar', () => {
    closeRequestsSidebarCssChanges();
    const prefs = new UserPreferences();
    prefs.remember('requestSidebarIsOpen', false);

    setTimeout(() => {
      setFloatWidth();
    }, 400);
  });

  // OPEN REQUEST SIDEBAR FUNCTION
  const openRequestSidebar = () => {
    openRequestsSidebarCssChanges();
    const prefs = new UserPreferences();
    prefs.remember('requestSidebarIsOpen', true);

    setTimeout(() => {
      setFloatWidth();
    }, 400);
  };

  $(document).on(clickEventType, '#open-requests-sidebar', () => {
    openRequestSidebar();
  });

  const urlParams = new URLSearchParams(window.location.search);
  const ppaId = urlParams.get('ppa_id');

  if (ppaId) {
    $.ajax({
      url: `/planned_plant_activities/${ppaId}/edit.js`,
      type: 'GET',
      success() {},
    });
  }

  // CLOSE MOBILE REQUEST SIDEBAR FUNCTION
  $(document).on('click', '.mobile-plan-view', () => {
    $('.planned-plant-activities-sidebar').css('width', '0px');
    $('.planned-plant-activities-sidebar').removeClass('request-sidebar__responsive');
    $('.planned-plant-activities-sidebar ul').css('display', 'none');
    $('.planned-plant-activities-sidebar h3').css('display', 'none');
    $('.planned-plant-activities-sidebar .request-filters').css('display', 'none');

    $('#close-requests-sidebar').css('display', 'none');
    $('#open-requests-sidebar').css('display', 'inline-block');

    $('#main-window').css('right', '0px', 'swing', () => updatePlannerSizeIfPresent());

    $('.mobile-plan-view').css('background', '#4F83CC');
    $('.mobile-plan-view').css('color', '#F0F0F5');
    $('.mobile-requests-view').css('background', '#F0F0F5');
    $('.mobile-requests-view').css('color', '#4F83CC');

    setTimeout(() => {
      setFloatWidth();
    }, 400);
  });

  // OPEN MOBILE REQUEST SIDEBAR FUNCTION
  $(document).on('click', '.mobile-requests-view', () => {
    $('.planned-plant-activities-sidebar').addClass('request-sidebar__responsive');
    $('.planned-plant-activities-sidebar ul').css('display', 'block');
    $('.planned-plant-activities-sidebar h3').css('display', 'block');
    $('.planned-plant-activities-sidebar .request-filters').css('display', 'block');

    $('#close-requests-sidebar').css('display', 'block');
    $('#main-window').css('right', '700px', 'swing', () => updatePlannerSizeIfPresent());
    $('#open-requests-sidebar').css('display', 'none');

    $('.mobile-plan-view').css('background', '#F0F0F5');
    $('.mobile-plan-view').css('color', '#4F83CC');
    $('.mobile-requests-view').css('background', '#4F83CC');
    $('.mobile-requests-view').css('color', '#F0F0F5');
    rerenderSidebarRequests();

    setTimeout(() => {
      setFloatWidth();
    }, 400);
  });

  // SIDEBAR DROPDOWN MENU FUNCTION
  $(document).on(clickEventType, '.dropdown-button', () => {
    $('.dropdown-menu').toggle('slow');
  });

  $(document).on(clickEventType, '.subcontracts_menu', () => {
    $('.subcontracts_items').toggle();
  });

  // MOBILE VERSION OF DROPDOWN MENU
  $(document).on(clickEventType, '.js-mobile-menu-icon', () => {
    dropMenu();
  });

  $(document).on(clickEventType, '.js-user-dropdown', () => {
    if ($('#mobile-menu .header-nav-links__dropdown input').prop('checked') === true) {
      $('#mobile-menu .header-nav-links__dropdown input').prop('checked', false);
    } else {
      $('#mobile-menu .header-nav-links__dropdown input').prop('checked', true);
    }
  });

  // Change the colour of employees when they're selected without a checkbox
  $(document).on(clickEventType, '.checkbox-div label', function () {
    if ($(this).siblings('#employee_days__id').checked) {
      $(this).siblings('#employee_days__id').prop('checked', false);
    } else {
      $(this).siblings('#employee_days__id').prop('checked', true);
    }
  });

  // AUtohide header when scrolling down
  let didScroll;
  let lastScrollTop = 0;
  const delta = 5;
  const navbarHeight = $('header').outerHeight();

  $(window).scroll(() => {
    didScroll = true;
  });

  setInterval(() => {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    const st = $(this).scrollTop();

    if (Math.abs(lastScrollTop - st) <= delta) { return; }

    if (st > lastScrollTop && st > navbarHeight) {
      $('header').addClass('nav-up');
      $('#sidebar').css('margin-top', '0px');
    } else if (st + $(window).height() < $(document).height()) {
      $('header').removeClass('nav-up');
      $('#sidebar').css('margin-top', '0px');
    }

    lastScrollTop = st;
  }

  function updatePlannerSizeIfPresent() {
    if (plantPlanner) {
      plantPlanner.updateSize();
    };
  }

  function closeUserSidebarCssChanges() {
    $('.sidebar-width').animate({ width: '20px' }, hideSidebarAnimateParams);
    $('#close-sidebar').css('display', 'none');
    $('#open-sidebar').css('display', 'inline-block');
    if ($('#main-window').css('left') != '20px') {
      $('#main-window').animate({ left: '20px' }, plannerResizeAnimateParams);
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 600);
  }

  function openUserSidebarCssChanges() {
    $('#close-requests-sidebar').click();

    $('.sidebar-width').animate({ width: '220px' }, 'slow');
    if (typeof publicLogin === 'undefined' || !publicLogin) {
      $('#main-window').animate({ left: '220px' }, plannerResizeAnimateParams);
    }
    $('.sidebar-content-container').css('display', 'block');
    $('.sidebar-content-container').css('overflow', 'hidden')
    $('#close-sidebar').css('display', 'block');
    $('#open-sidebar').css('display', 'none');

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1200);
  }

  function closeRequestsSidebarCssChanges() {
    $('.planned-plant-activities-sidebar').animate({ width: closedSidebarWidth }, 'slow');
    $('.planned-plant-activities-sidebar').removeClass('request-sidebar__responsive');
    $('.planned-plant-activities-sidebar ul').css('display', 'none');
    $('.planned-plant-activities-sidebar h3').css('display', 'none');
    $('.planned-plant-activities-sidebar .request-filters').css('display', 'none');

    $('#close-requests-sidebar').css('display', 'none');
    $('#open-requests-sidebar').css('display', 'inline-block');
    $('#planned-plant-activities-sidebar-column').css('display', 'none');
    $('#plant_planner.one-plant').animate({ width: '70%' }, plannerResizeAnimateParams);

    if ($('#main-window').data('planPage') === true) {
      if ($('#main-window').css('right') != closedSidebarWidth) {
        $('#main-window').animate({ right: closedSidebarWidth }, plannerResizeAnimateParams);
      }
    } else {
      if ($('#main-window').css('right') != '0px') {
        $('#main-window').animate({ right: '0px' }, plannerResizeAnimateParams);
      }
    }
  }

  function openRequestsSidebarCssChanges() {
    $('#close-sidebar').click();
    $('.planned-plant-activities-sidebar').animate({ width: '50%' }, 'slow');
    $('.planned-plant-activities-sidebar').addClass('request-sidebar__responsive');
    $('#planned-plant-activities-sidebar-column').css('display', 'block');
    if ($('#main-window').css('right') != '50%') {
      $('#main-window').animate({ right: '50%' }, plannerResizeAnimateParams);
    }
    $('#plant_planner.one-plant').animate({ width: '100%' }, 'slow');
    $('.planned-plant-activities-sidebar ul').css('display', 'block');
    $('.planned-plant-activities-sidebar h3').css('display', 'block');
    $('.planned-plant-activities-sidebar .request-filters').css('display', 'block');
    $('#close-requests-sidebar').css('display', 'block');
    $('#open-requests-sidebar').css('display', 'none');
    rerenderSidebarRequests();
  }

  function checkUserPreferencesForSidebars() {
    const prefs = new UserPreferences();
    const userSidebarIsOpen = prefs.fetch('userSidebarIsOpen');
    const userPlanSidebarIsOpen = prefs.fetch('userPlanSidebarIsOpen');
    const requestSidebarIsOpen = prefs.fetch('requestSidebarIsOpen');
    const url = window.location.href;

    if (url.split('/')[5] === 'plan') {
      if (userPlanSidebarIsOpen) {
        openUserSidebarCssChanges();
      } else {
        closeUserSidebarCssChanges();
      }
    } else if (userSidebarIsOpen) {
      openUserSidebarCssChanges();
    } else {
      closeUserSidebarCssChanges();
    }

    if (requestSidebarIsOpen && url.split('/')[5] === 'plan') {
      openRequestsSidebarCssChanges();
    } else {
      closeRequestsSidebarCssChanges();
    }
  }

  // USER ACTIVITY
  $(document).on(clickEventType, '#user_activity_link', () => {
    $('#user_activity_separator').toggle();
    $('#user_activity_section').toggle();
    $('#user_activity_summary').toggle();
    $('#spinner_for_user_activity_summary').toggle();
  });
});

export { dropMenu };
