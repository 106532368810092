import {
  clickEventType,
  completionDateInput,
  getProjectIdFromUrl
} from './helpers';

$(document).ready(function () {
  $(document).on(clickEventType, '#save_approvals', function (e) {
    e.preventDefault();
    if ($('.approvement-checkbox').is(':checked')) {
      var data = { 'cma': $(this).data('cma-id')};
      var approvers = $('.approvement-checkbox:checked').get().map(function (e) { return e.dataset.approver; });
      $('.approvement-checkbox:checked').each(function () {
        var approver = $(this).data('approver');
        var cma = $(this).data('cma-id');
        var steps = $('.' + approver + '-approval-form').find('.approval-form-details').find('.form')
          .get()
          .map(function (e) { return e.dataset.stepId;});
        if (steps.length === 0) { steps = ''; }
        data[approver] = steps;
        $.extend(data, { 'cma_id': cma });
      });
      $.ajax({
        url: '/approvals',
        type: 'POST',
        data: data,
        success: function (data) {
          $('#manage_approvals_popup').dialog('destroy');
        }
      });
    } else {
      flashError("At least one party must be able to mark this off as complete, otherwise the plan can't be updated", '#approvals-errors');
    }
  });

  $(document).on('change', '.approvement-checkbox', function (e) {
    if (this.checked) {
      var approver = $(this).data('approver');
      var cma = $(this).data('cma-id');
      $.ajax({
        url: '/approvals/approval_form',
        type: 'GET',
        data: { 'approver': approver, 'cma_id': cma },
        success: function (data) {
          $('.' + approver + '-approval-form').html(data);
        }
      });
    } else {
      $(this).parent().next().html('');
    }
  });

  $(document).on('change', '.choose-form .switch input', function (e) {
    var target = $(this);
    var approver = $('#save_steps').data('approver');
    var cma = $('#save_steps').data('cma-id');
    data = { 'approver': approver, 'cma_id': cma };
    if (target.is(':checked')) { data.form_flag = 'true'; }
    $.ajax({
      url: '/approvals/form_properties',
      type: 'GET',
      data: data,
      success: function (data) {
        $('.form, .no-form').toggle();
        $('.form-properties').html(data);
      }
    });
  });

  $(document).on(clickEventType, '#save_steps', function (e) {
    var approver = $(this).data('approver');
    var cma = $(this).data('cma-id');
    var details = $('#details').val();
    var data = { 'approver': approver, 'cma_id': cma, 'form_details': details };
    if ($('.switch input').is(':checked')) { if (!collectSteps(data)) { return false; } }
    $.ajax({
      url: '/approvals/approval_form',
      type: 'GET',
      data: data,
      success: function (data) {
        $('.' + approver + '-approval-form').html(data);
        $('#configure_form_popup').dialog('close');
      }
    });
  });

  $(document).on(clickEventType, '.remove-step', function (e) {
    e.preventDefault();
    $(this).parent().remove();
  });

  $(document).on(clickEventType, 'input.step-completion', function (e) {
    e.preventDefault();
    e.stopPropagation();
    var stepId = $(this).data('step-id');
    var calId = $(this).data('cal-id');
    $.ajax({
      url: '/approvals/' + stepId + '/approve',
      type: 'GET',
      data: { 'cal_id': calId },
      success: function (data) {
        $('#completion_form').html(data);
        completionDateInput();
      }
    });
  });


  $(document).on(clickEventType, '#complete-approval, #not-complete-approval, .cal-status #default-completion', function (e) {
    var projectId = getProjectIdFromUrl();
    var stepType = $(this).attr('id');
    if (stepType === 'default-completion') { e.preventDefault(); e.stopPropagation(); }
    var calId = $(this).data('cal-id');
    var cmaId = $(this).data('cma-id');
    var formType = $(this).data('form-type');
    data = { 'contr_main_act_id': cmaId, 'form_type': formType, 'project_id': projectId };
    if (stepType === 'complete-approval') {
      data.completion_date = $('#completion_date').val();
      if (!$('#completion_date').val()) {
        flashError('Choose completion date', '#completion-errors');
        return false;
      }
    }
    $.ajax({
      url: '/contr_act_locs/' + calId + '.js',
      type: 'PUT',
      data: data,
      success: function (data) {
        if (formType === 'planner') {
          processPlannerApproval(data, stepType);
        } else if (formType === 'subcontractor') {
          processSubcontractorApproval(data, calId, stepType);
        }
      }
    });
  });

  function processPlannerApproval(data, stepType) {
    if (stepType !== 'default-completion') { $('#completion_form').dialog('close'); }
    $('.cal-status').html(data);
  }

  function processSubcontractorApproval(data, calId, stepType) {
    $('li[data-id=' + calId + ']').replaceWith(data);
    if (stepType !== 'default-completion') { $('#completion_form').dialog('destroy'); }
  }

  $(document).on(clickEventType, '#subcontract-cal-complete', function (e) {
    e.preventDefault();
    e.stopPropagation();
    var calId = $(this).closest('li').data('id');
    var formType = $(this).data('form-type');
    $.ajax({
      url: '/approvals/completion_form',
      type: 'GET',
      dataType: 'html',
      data: { 'cal_id': calId, 'form_type': formType },
      success: function (data) {
        $('#completion_form').html(data);
        completionDateInput();
        $('#completion_form').dialog({
          modal: true, title: 'Complete activity form', resize: false,
          width: '35vw', height: 'auto',
          close: function () {
            $(this).dialog('destroy');
          }
        });
      }
    });
  });

  function collectSteps(data) {
    data.form_flag = true;

    if (!$('.new-steps li').length && !$('.existing-steps li').length) {
      flashError('Add at least one approval.', '#step-errors');
      return false;
    }
    data.new_steps = [];
    $('.new-steps li').each(function (e, el) {
      var orderNumber = $(el).find('#order_number').val();
      var requirementId = $(el).find('#completion_requirement_id').val();
      var question = $(el).find('#question').val();
      var kind = $(el).attr('class');
      data.new_steps.push({ 'kind': kind, 'completion_requirement_id': requirementId, 'order_number': orderNumber, 'question': question });
    });
    if ($('.existing-steps li').length) {
      data.existing_steps = [];
      $('.existing-steps li').each(function (e, el) {
        id = $(el).find('#id').val();
        data.existing_steps.push(id);
      });
    }

    return data;
  }

  function flashError(message, selector) {
    $(selector).html("<div class='alert-danger'>" + message + '</div>');
    setTimeout(function () {
      $(selector).html('');
    }, 3000);
  }
});
