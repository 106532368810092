import { showFlashMessage } from './flash';

$(document).ready(() => {
  function updateCompanyForSuper(data, successCallback) {
    $.ajax({
      url: '/users/update_for_super',
      type: 'PUT',
      data,
      success() {
        showFlashMessage('Company is updated!', 'success');
        successCallback();
      },
      error() {
        showFlashMessage('Something went wrong', 'danger');
      },
    });
  }

  $(document).on('click', '#edit_company_form #leave-company', () => {
    const successCallback = () => {
      $('#set-as-current-company').removeClass('hidden');
      $('#leave-company').addClass('hidden');
    };
    updateCompanyForSuper({}, successCallback);
  });

  $(document).on('click', '#edit_company_form #set-as-current-company', () => {
    const companyId = $('#company_id').val();
    const data = { company_id: companyId };
    const successCallback = () => {
      $('#set-as-current-company').addClass('hidden');
      $('#leave-company').removeClass('hidden');
    };
    updateCompanyForSuper(data, successCallback);
  });
});
