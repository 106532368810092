/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactRailsUJS from 'react_ujs';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';

import 'chosen-js';
import 'tinymce';
import 'blueimp-file-upload';
import datepickerFactory from 'jquery-datepicker';

import './gems.js.erb';
import '../src/actual_plant_activities';
import '../src/approvals';
import '../src/cma_subscription_invites';
import '../src/completion_marks';
import '../src/completion_requirements';
import '../src/contr_act_comments';
import '../src/contr_act_locs';
import '../src/contr_main_acts';
import '../src/planned_plant_activity_comments';
import '../src/planned_plant_activities_sidebar';
import '../src/report_subscriptions';
import '../src/delay_reasons';
import '../src/employees_planner_feature';
import '../src/employees/employees';
import '../src/employees/merge_duplicates';
import '../src/employees/helpers';
import '../src/employees/index';
import '../src/employees_planner/draw_employee_planner';
import '../src/employees_planner/employee_activity_periods';
import '../src/employees_planner/employee_planner';
import '../src/employees_planner/employee_planner_mobile';
import '../src/employees_planner/end_of_day_wizard';
import '../src/employees_planner/manage_employee_days';
import '../src/employees_planner/manage_employees';
import '../src/employees_planner/manage_tasks';
import '../src/employees_planner/shared';
import '../src/flash';
import '../src/form_entries';
import '../src/helpers';
import '../src/initial_setup';
import '../src/invites';
import '../src/kpis';
import '../src/location';
import '../src/locations';
import '../src/location_types';
import '../src/materials';
import '../src/mobile';
import '../src/module_accessors';
import '../src/notifications';
import '../src/payrun_management/index';
import '../src/payrun_management/pay_cycles';
import '../src/payrun_management/payrate_overtimes';
import '../src/payrun_management/payrates';
import '../src/permission_rules';
import '../src/plan_comments';
import '../src/planned-execution-graph';
import '../src/plans';
import '../src/plant-activity-chart';
import '../src/plant-activity-chart-pdf';
import '../src/plant_planner.js.erb';
import '../src/plants';
import '../src/plan_view_zoom';
import '../src/populate_country_code';
import '../src/proj_contr_acts';
import '../src/projects';
import '../src/project_weathers';
import '../src/quotes';
import '../src/request_for_new_company';
import '../src/request_for_sign_up';
import '../src/section-graph';
import '../src/sessions';
import '../src/static_pages';
import '../src/styled_index_tables';
import '../src/subcontracts';
import '../src/subproject_management_change_order';
import '../src/subproject_management';
import '../src/subproject_management_locations';
import '../src/tasks.js.erb';
import '../src/tables';
import '../src/trainings';
import '../src/user_activity_rules';
import '../src/users';
import '../src/visual_mode';
import '../src/working_day_adjustments';

import theme from '../styles/Theme';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('jquery');
require('jquery-ui-bundle');
require('@rails/ujs').start();

datepickerFactory($);
window.$ = jQuery;
$.rails.allowAction = () => true; // fix for nested_form_fields

// modified from
// https://github.com/reactjs/react-rails/blob/master/react_ujs/index.js#L83-L121
// and
// https://github.com/souporserious/react-rails-theme-provider/blob/main/app/javascript/packs/application.js

ReactRailsUJS.mountComponents = (searchSelector) => {
  const ujs = ReactRailsUJS;
  const nodes = ujs.findDOMNodes(searchSelector);

  nodes.forEach((node) => {
    const className = node.getAttribute(ujs.CLASS_NAME_ATTR);
    const constructor = ujs.getConstructor(className);
    const propsJson = node.getAttribute(ujs.PROPS_ATTR);
    const props = propsJson && JSON.parse(propsJson);
    const hydrate = node.getAttribute(ujs.RENDER_ATTR);
    const cacheId = node.getAttribute(ujs.CACHE_ID_ATTR);
    const turbolinksPermanent = node.hasAttribute(ujs.TURBOLINKS_PERMANENT_ATTR);

    if (!constructor) {
      const message = `Cannot find component: '${className}'`;
      if (console && console.log) {
        console.log(
          `%c[react-rails] %c${message} for element`,
          'font-weight: bold',
          '',
          node,
        );
      }
      throw new Error(
        `${message}. Make sure your component is available to render.`,
      );
    } else {
      let component = ujs.components[cacheId];
      if (component === undefined) {
        component = React.createElement(constructor, props);
        if (turbolinksPermanent) {
          ujs.components[cacheId] = component;
        }
      }

      if (hydrate && typeof ReactDOM.hydrate === 'function') {
        component = ReactDOM.hydrate(
          (
            <StyledEngineProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                <ThemeProvider theme={theme}>
                  {component}
                </ThemeProvider>
              </LocalizationProvider>
            </StyledEngineProvider>
          ),
          node,
        );
      } else {
        component = ReactDOM.render(
          (
            <StyledEngineProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                <ThemeProvider theme={theme}>
                  {component}
                </ThemeProvider>
              </LocalizationProvider>
            </StyledEngineProvider>
          ),
          node,
        );
      }
    }
  });
};

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);

ReactRailsUJS.useContext(componentRequireContext);
