import {
  hideSpinner,
  openConfirmationPopup,
  optionsForSelect,
  showSpinner
} from '../helpers';
import { showFlashMessage } from '../flash';

window.styleNewEmployeeForm = function () {
  if ($('#new-employee-form.multistep-form').length == 0) { return; }
  $('body').addClass('new-employee-page-styling');
  var currentStep = getCurrentStepFromProgressBar();
  var totalSteps = $('#total-steps').text();
  $('.progress-bar').progressbar({ value: 100 / totalSteps * currentStep });
}

export function getCurrentStepFromProgressBar() {
  var currentStepStr = $('.progress-bar').data('currentStep');
  return  parseInt(currentStepStr, 10);
}

export function loadStepForEmployeeCreation(step, showStepContentCallback, direction) {
  var employeeAttributes = $('#new-employee-form').data();
  $.ajax({
    url: '/employees/creation_step.json',
    type: 'POST',
    data: { step: step, employee_attributes: employeeAttributes, direction: direction },
    beforeSend: function () { showSpinner(); },
    complete: function () { hideSpinner(); },
    success: function (jsonData) {
      if (jsonData.error) {
        var status = jsonData.error || 'Something went wrong';
        showFlashMessage(status);
      } else {
        showStepContentCallback(jsonData);
      }
    },
    error: function () {
      showFlashMessage('Something went wrong');
    }
  });
}

function clearNewEmployeeForm() {
  var currentStep = getCurrentStepFromProgressBar();
  if (currentStep === 1) {
    $('.bottom-bar .prev-step').hide();
  } else {
    $('.bottom-bar .prev-step').show();
  }
  $('#employee_attribute').val('');
  $('#select-options').html('').data('multiselect', false);
  $('#unit').text('');
  $('.final-step-content, .final-step-bottom-bar, #additional-text-field').remove();
  $('.bottom-bar, .step-content').removeClass('hidden');
}

function showSelectOptions(jsonData) {
  var filledInfo = filledInfoForStep(jsonData.current_step);
  var selectedOptions = filledInfo === undefined ? jsonData.selected : filledInfo.options;
  var optionButtons = '';
  var multiselect = jsonData.multiselect;
  for (var optionName in jsonData.options) {
    var optionValue = jsonData.options[optionName];
    var selected = selectedOptions.includes(optionValue) ? 'selected' : '';
    optionButtons += selectOptionTemplete(optionName, optionValue, selected);
  }
  if (!multiselect && selectedOptions.includes(-1)) {
    showAdditionalTextField(filledInfo.text);
  }
  $('.control-element#select-options').data('multiselect', multiselect)
    .html(optionButtons).removeClass('hidden');
}

function showTextField(currentStep) {
  var filledInfo = filledInfoForStep(currentStep);
  if (filledInfo && filledInfo.text) { $('#employee_attribute').val(filledInfo.text); }
  $('.control-element.text-field').removeClass('hidden');
}

function showUnitSpan(unit) {
  $('#unit.control-element').removeClass('hidden').text(unit);
}

function showDatepicker(currentStep, defaultDate) {
  var filledInfo = filledInfoForStep(currentStep);
  $('.control-element.datepicker-element').data('defaultDate', true).datepicker({
    dateFormat: 'dd/mm/yy',
    defaultDate: defaultDate,
    onSelect: function () {
      $('.datepicker-element').data('defaultDate', false);
      updateNextStepButton();
    }
  });
  if (filledInfo && filledInfo.date) {
    $('.datepicker-element').data('defaulDate', false).val(filledInfo.date);
  }
  $('.datepicker-element').removeClass('hidden');
}

function showActiveFormElements(jsonData) {
  var selectOptions = jsonData.options;
  var mandatoryField = jsonData.mandatory_field;
  var unit = jsonData.unit;
  if (selectOptions !== undefined) { showSelectOptions(jsonData); }
  if (jsonData.text_field === true) { showTextField(jsonData.current_step); }
  if (unit !== undefined) { showUnitSpan(unit); }
  if (jsonData.datepicker === true) { showDatepicker(jsonData.current_step, jsonData.selected); }
  if (jsonData.overtimes === true) {
    showOvertimes();
    transformSkipToNextStepButton();
  }
}

function showOvertimes() {
  $('#overtimes').removeClass('hidden');
}

export function showCurrentStepCallback(jsonData) {
  var currentStep = jsonData.current_step;
  updateProgressBarValue(currentStep);
  clearNewEmployeeForm();
  var question = jsonData.question;
  $('.step-content #question-text').text(question);
  $('.step-content .control-element').addClass('hidden');
  showActiveFormElements(jsonData);
  $('.bottom-bar .next-step').data('mandatoryField', jsonData.mandatory_field);
  updateNextStepButton();
}

function selectOptionTemplete(optionName, optionValue, selected) {
  return '<button class="nav-button select-option ' + selected + '" data-option-value="' +
    optionValue + '">' + optionName + '</button>';
}

window.updateNextStepButton = function (mandatoryField) {
  var nextStepButton = $('.bottom-bar .next-step');
  var mandatoryField = nextStepButton.data('mandatoryField');
  if (mandatoryField) {
    applySettingsForNextButton(nextStepButton);
  } else {
    applySettingsForSkipButton(nextStepButton);
  }
}

function formIsFilled() {
  var selectedOptions = getSelectedOptionsInEmployeeCreation();
  var selectedOptionsPresent = (selectedOptions.length > 0) && !selectedOptions.includes(-1);
  var additionalTextFieldValue = $('#additional-text-field').val();
  var additionalTextFieldIsFilled = (additionalTextFieldValue !== undefined) && (additionalTextFieldValue.length > 0);
  var textFieldValue = $('#employee_attribute').val();
  var textFieldIsFilled = (textFieldValue !== undefined) && (textFieldValue.length > 0);
  var defaultDate = $('.datepicker-element').data('defaultDate');
  var datepickerValueIsChanged = $('.datepicker-element').is(':visible') && !defaultDate;
  var multiselect = $('#select-options').data('multiselect');
  var overtimes = $('#overtimes.hidden').length === 0;
  return selectedOptionsPresent || textFieldIsFilled || additionalTextFieldIsFilled || multiselect || datepickerValueIsChanged || overtimes;
}

function applySettingsForSkipButton(nextStepButton) {
  nextStepButton.addClass('skip');
  if (formIsFilled()) {
    nextStepButton.removeClass('skip')
      .addClass('base-green').text('Next');
  } else {
    nextStepButton.removeClass('base-green').text('Skip');
  }
  nextStepButton.attr('disabled', false);
}

function applySettingsForNextButton(nextStepButton) {
  nextStepButton.removeClass('skip').text('Next');
  if (formIsFilled()) {
    nextStepButton.addClass('base-green').attr('disabled', false);
  } else {
    nextStepButton.removeClass('base-green').attr('disabled', true);
  }
}

function getSelectedOptionsInEmployeeCreation() {
  return $('#new-employee-form #select-options .select-option.selected').map(function () {
    return $(this).data('optionValue');
  }).toArray();
}

export function saveCurrentStepFilledInfo(currentStep) {
  var data = {};
  if ($('.control-element.text-field').is(':visible')) {
    var attributeValue = $('#employee_attribute').val();
    data = { text: attributeValue };
  } else if ($('.control-element#select-options').is(':visible')) {
    var selectedOptions = getSelectedOptionsInEmployeeCreation();
    data = { options: selectedOptions };
    if ($('#additional-text-field').is(':visible')) { data.text = $('#additional-text-field').val(); }
  } else if ($('.datepicker-element').is(':visible')) {
    data = { date: $('.datepicker-element').val() };
  } else if ($('#overtimes').is(':visible')) {
    data = getOvertimesData();
  }
  $('#new-employee-form').data('step' + currentStep, data);
}

function getOvertimesData() {
  var data = {};
  $.each($('input[name="overtime_type"]'), function(index, radioButton) {
    if(radioButton.checked) {
      var customOvertimesData = getCustomOvertimesData();
      data = {
        overtime_type: radioButton.value,
        payrate_overtime_ids: $('#payrate_overtime_ids').val()
      };
      if (customOvertimesData) {
        data.overtimes = customOvertimesData;
      }
      return data;
    }
  });
  return data;
}

function getCustomOvertimesData() {
  var newCustomOvertimes = $('#custom-overtimes-section .new-custom-overtime');
  if (newCustomOvertimes.length === 0) {
    return;
  }
  return newCustomOvertimes.map(function(index, newOvertime) {
    return {
      multiplier: $(newOvertime).find('input[name="overtimes[][multiplier]"]').val(),
      wkdays: $(newOvertime).find('input[name="overtimes[][wkdays][]"]').map((index, overtime) => overtime.value).toArray(),
      overtime_hr_limit: {
        hour_limit: $(newOvertime).find('input[name="overtimes[][overtime_hr_limit][hour_limit]"]').val(),
        period: $(newOvertime).find('input[name="overtimes[][overtime_hr_limit][period]"]').val()
      }
    };
  }).toArray();
};

export function showFinalStepCallback(jsonData) {
  $('.step-content, .bottom-bar').addClass('hidden');
  var currentStep = jsonData.current_step;
  updateProgressBarValue(currentStep);
  var finalStepContainer = '<div class="final-step-content"><div>';
  $('.step-content').after(finalStepContainer);
  var finalStepContent = $('.final-step-content');
  var customRateUnit = jsonData.custom_rate_unit;
  finalStepContent.data('payDateOptions', jsonData.pay_date_option);
  finalStepContent.append(headerTemplate('Review'));
  finalStepContent.append(inputWithLabelTemplate('First name:', jsonData.first_name, 'first-name'));
  finalStepContent.append(inputWithLabelTemplate('Last name:', jsonData.last_name, 'last-name'));
  finalStepContent.append(inputWithLabelTemplate('ID:', jsonData.id, 'id'));
  finalStepContent.append(dropdownWithLabelTemplate('Paid:', jsonData.rate_unit.options, jsonData.rate_unit.selected, 'rate-unit'));
  finalStepContent.append(dropdownWithLabelTemplate('When:', jsonData.pay_date_option.options[0], jsonData.pay_date_option.selected, 'pay-date-option'));
  updatePaydateOptions(jsonData.pay_date_option.selected);
  finalStepContent.append(inputWithLabelTemplate('Rate:', jsonData.rate, 'rate'));
  finalStepContent.append(inputWithLabelTemplate('Starting:', jsonData.start_pay_date, 'start-pay-date'));
  finalStepContent.append(multiselectWithLabelTemplate('3rd parties:', jsonData.third_parties, 'third-parties'));
  if (customRateUnit) { $('#third-parties').append('<div id="custom-rate-unit" data-value="' + customRateUnit + '"><div>'); }
  $('#overtimes').appendTo('.final-step-content');
  showOvertimes();
  $('#start-pay-date').datepicker({ dateFormat: 'dd/mm/yy' });
  $('.bottom-bar').after(finalStepBottomBar());
}

export function updatePaydateOptions(selectedPaydateOption) {
  var currentRateUnitStr = $('#rate-unit').val();
  var currentRateUnit = parseInt(currentRateUnitStr, 10);
  var allPaydateOptions = $('.final-step-content').data('payDateOptions').options;
  var availableOptions = allPaydateOptions[currentRateUnit] || [];
  $('#pay-date-option').html(optionsForSelect(availableOptions, selectedPaydateOption));
}

function headerTemplate(headerText) {
  return '<span>' + headerText + '</span>';
}

function inputWithLabelTemplate(label, inputValue, id) {
  return "\
    <div class='flex flex-column controls-group'> \
      <span>" + label + "</span> \
      <input type='text' id='" + id + "' value=" + inputValue + "></input> \
    </div> \
  ";
}

function dropdownWithLabelTemplate(label, dataForOptions, selectedOption, id) {
  return "\
    <div class='flex flex-column controls-group'> \
      <span>" + label + '</span> \
      <select id=' + id + '>' + optionsForSelect(dataForOptions, selectedOption, true) + '</select> \
    </div> \
  ';
}

function multiselectWithLabelTemplate(label, thirdParties, id) {
  var buttons = '';
  for (var name in thirdParties.options) {
    var value = thirdParties.options[name];
    var selected = thirdParties.selected.includes(value) ? 'selected' : '';
    buttons += selectOptionTemplete(name, value, selected);
  }
  return $('<div id="' + id + '" class="flex flex-row multiselect-container" data-multiselect="true"></div>').append(buttons);
}

function finalStepBottomBar() {
  return "\
    <div class='flex flex-column final-step-bottom-bar'> \
      <div class='flex flex-row'> \
        <a class='nav-button cancel' href='\/employees'>Cancel</a> \
        <a class='nav-button base-green' id='create-employee'>Finish</a> \
      </div> \
      <a class='nav-button base-green' id='add-another-employee'>Finish and add another</a> \
    </div> \
  ";
}

function dataFromFinalStepForm() {
  var thirdParties = $('#third-parties .select-option.selected').map(function () {
    return $(this).data('optionValue');
  }).toArray();
  return  {
    employee: {
      name: $('#first-name').val(),
      surname: $('#last-name').val(),
      id_number: $('#id').val()
    },
    payrate: {
      rate_unit: $('#rate-unit').val(),
      rate: $('#rate').val(),
      pay_date: $('#pay-date-option').val(),
      start_date: $('#start-pay-date').val(),
      custom_rate_unit: $('#custom-rate-unit').data('value'),
      third_parties: thirdParties,
      overtimes: getOvertimesData()
    }
  };
}

export function createEmployeeWithPayrate(callback) {
  var data = dataFromFinalStepForm();
  $.ajax({
    url: '/employees/create_with_payrate.json',
    type: 'POST',
    data: data,
    success: function (jsonData) {
      if (jsonData.status === 'ok') {
        callback();
      } else {
        var status = jsonData.status || 'Something wait wrong';
        showFlashMessage(status);
      }
    },
    error: function (jsonData) {
      var status = jsonData.status || 'Something wait wrong';
      showFlashMessage(status);
    }
  });
}

function transformSkipToNextStepButton() {
   $('.bottom-bar .next-step').removeClass('skip')
    .addClass('base-green').text('Next step');
}

export function showPopupWithImportResults(jsonData) {
  var selector = '#confirmation-popup';
  var content = jsonData.status_of_import;
  if (jsonData.failed_employees_info !== null) {
    content += invalidEmployees(jsonData.failed_employees_info);
  }
  var closeCallback = function () { $(selector).dialog('destroy'); }
  var buttons = [{ text: 'Ok', click: closeCallback }];
  openConfirmationPopup(selector, content, null, closeCallback, buttons);
}

function invalidEmployees(failedEmployeesInfo) {
  var listItems = failedEmployeesInfo.map(function(info) { return '<li>' + info + '</li>'; });
  return '<ul>' + listItems + '</ul>';
}

export function showAdditionalTextField(filledBeforeValue) {
  var value = filledBeforeValue || '';
  var textField = '<input type="text" id="additional-text-field" value="' + value + '"></input>';
  $('#new-employee-form #select-options').after(textField);
}

function updateProgressBarValue(currentStep) {
  var totalSteps = $('.progress-bar #total-steps').text();
  var value = currentStep * 100 / totalSteps;
  $('.progress-bar').progressbar('value', value);
  $('#new-employee-form .progress-bar').data('currentStep', currentStep);
  $('#new-employee-form #current-step').text(currentStep);
}

function filledInfoForStep(stepNumber) {
  return $('#new-employee-form').data('step' + stepNumber);
}
