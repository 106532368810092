import { showSpinner } from './helpers';

$(document).ready(function(){
  function initializeStyledIndexTable(){
    $('.styled-index-table').each(function(){
      var table = $(this);
      table.data('page', 1);
      modifyHeadersOfStyledIndexTable(table);
      table.wrap('<div></div>');
      addSearchFieldsForColumnsInStyledIndexTable(table)
      addSearchButtonToStyledIndexTable(table);
      addMoreButtonToStyledIndexTable(table);
      var lastRow = table.find('tbody tr').last();
      if (lastRow.length == 0 || lastRow.data('nextPage') == false){
        $('.more-rows').addClass('hidden');
      }
    })
  }
  initializeStyledIndexTable();

  function modifyHeadersOfStyledIndexTable(table){
    var headers = table.find('th');
    headers.each(function(){
      var modifiedHeader =
        "<div class='sortable-column'> \
            <span>"+$(this).text()+"</span>\
            <a class='sort-by asc desc'></a> \
          </div>";
      $(this).html(modifiedHeader);
    })
  }

  function addMoreButtonToStyledIndexTable(table){
    var addMoreButton = '<button class="nav-button more-rows">+ More</button>';
    table.after(addMoreButton);
  }

  function addSearchButtonToStyledIndexTable(table){
    var searchButton = '<a class="nav-button search-in-rows">Search</a>';
    table.parent().prepend(searchButton);
  }

  function addSearchFieldsForColumnsInStyledIndexTable(table){
    var thead = table.find('thead');
    var searchFields = thead.find('th').get().map(function(th){
      var fieldNameValue = $(th).data('fieldName');
      if (fieldNameValue) {
        return '<td data-field-name="' + fieldNameValue + '"><input type="text" class="search-in-column"></input></td>';
      }

      return '<td></td>'
    }).join('');
    var searchFieldsRow = $('<tr class="search-fields hidden"></tr>').html(searchFields);
    thead.prepend(searchFieldsRow);
  }

  function intitializePaginationVariables(table) {
    var skipCount = 0;
    var takeCount = 20;
    var prevRowsCount = parseInt(table.data('prevRowsCount'), 10);
    if (!isNaN(prevRowsCount)) {
      skipCount = prevRowsCount;
    } else {
      prevRowsCount = takeCount;
    }
    return { skip: skipCount, take: takeCount, rowsCount: prevRowsCount };
  }

  function showModifiedIndexTable(table, data, successCallback) {
    var url = table.data('indexUrl');
    $.ajax({
      url: '/' + url,
      type: 'GET',
      dataType: 'html',
      data: data,
      beforeSend: function(){ showSpinner(); },
      complete: function(){ $('.spinner').hide(); },
      success: function(data){
        successCallback(data);
        if (table.find("tr").last().data('nextPage') == false){
          $('.more-rows').addClass('hidden');
        }
      }
    });
  }

  $(document).on('click', '.search-in-rows', function(){
    var table = $(this).siblings('.styled-index-table');
    table.find('.search-fields').toggle();
    var nextPage = table.find("tr").last().data('nextPage');
    if (nextPage) {
      if (table.find('.search-fields').is(":visible")) {
        $('.more-rows').addClass('hidden');
      } else {
        $('.more-rows').removeClass('hidden');
      }
    }
  })

  let searchTimer;
  $(document).on('keyup', '.search-in-column', function(){
    if (searchTimer) clearTimeout(searchTimer);
    let table = $(this).closest('.styled-index-table');
    let fieldSearched = $(this).closest('td').data('fieldName');
    let value = $(this).val();
    searchTimer = setTimeout(function(){
      let data = { field_searched: fieldSearched, value: value, format: 'js' };
      let successCallback = (data) => {
        table.find('tbody').html(data);
        if (table.data('callback')) {
          window[table.data('callback')]();
        }
      }
      showModifiedIndexTable(table, data, successCallback);
    }, 500);
  })

  $(document).on('click', '.styled-index-table th', function(){
    var table = $(this).closest('.styled-index-table');
    var sortBy = $(this).find('.sort-by');
    sortBy.closest('th').siblings().find('.sort-by').removeClass('asc desc').addClass('asc desc');
    if (sortBy.hasClass('asc desc')) {
      sortBy.removeClass('desc');
    } else {
      sortBy.toggleClass('asc desc');
    }
    var fieldSearched = $(this).data('fieldName');
    var order = sortBy.attr('class').split(' ').pop();
    var pageParams = intitializePaginationVariables(table);
    var allDisplayed = !$('.more-rows').is(':visible')
    var data = { field_searched: fieldSearched, order: order, take: pageParams.rowsCount, format: 'js', all_displayed: allDisplayed };
    var successCallback = function(data) {
      table.find('tbody').html(data);
      table.data('lastSearch', fieldSearched);
      table.data('lastSearchOrder', order);
    }
    showModifiedIndexTable(table, data, successCallback);
  })

  $(document).on('click', '.more-rows', function(){
    var table = $(this).siblings('.styled-index-table');
    var pageParams = intitializePaginationVariables(table);
    var fieldSearched = table.data('lastSearch');
    var order = table.data('lastSearchOrder');
    var data = { skip: pageParams.skip + 20, take: pageParams.take, format: 'js' };
    if(fieldSearched) {
      data['field_searched'] = fieldSearched;
    }
    if(order) {
      data['order'] = order;
    }
    var successCallback = function(data) {
      table.find('tbody').append(data);
      table.data('prevRowsCount', pageParams.skip + pageParams.take);
    }
    showModifiedIndexTable(table, data, successCallback);
  })

  $(document).on('click', '.search-in-column', function () {
    $(this).closest('td').siblings('td').find('.search-in-column').each(function () {
      $(this).val('');
    });
  });
})
