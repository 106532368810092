/* eslint-disable max-len */
import React from 'react';
import ReactDOM from 'react-dom';
import { addDays, format, isSameYear } from 'date-fns';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';

import {
  clickEventType,
  rerenderSidebarRequests,
  convertTime12to24,
  getDuration,
  confirmationDialog,
  splitTime,
  UserPreferences,
  setRequestTooltips,
  showSpinner,
  hideSpinner,
  rerenderActivitiesIndexPage,
} from './helpers';

import {
  createPlanner, renderPlanner, renderWeatherIcons, prepareMaxTime,
} from './plant_planner';
import { loadComments } from './plan_comments';
import {
  updateNavigation,
  getVisiblePlantIds,
  getCurrentlyVisibleColumnCount,
  rollLeft,
  rollRight,
  refreshEnabledPlants,
  calculateWorkingHours,
  setWorkingHoursMinMax,
  getWorkingAndEventHoursMinMax,
  calculateVisibleDaysForCalendar,
} from './plans_navigation';

import { generateCranePlanPdf } from './crane_plan_pdf';

import DatePicker from '../components/common/DatePicker';

export var plantPlanner;

const isPlannerPage = () => $('.plant_plan').length !== 0;
const isActivitiesIndexPage = () => $('.activities_index_page').length !== 0;

function setDate(dateVal, remember = true) {
  const date = new Date(dateVal);
  const dateFormat = isSameYear(date, new Date()) ? 'E d MMM' : 'E d MMM yyyy';
  $('#date').text(format(date, dateFormat));
  if (remember) {
    sessionStorage.setItem('selectedDate', format(date, 'yyyy-MM-dd'));
  }
  $('#datepicker').data('date', format(date, 'yyyy-MM-dd'));
}

$(document).ready(() => {
  if (!isPlannerPage() && !isActivitiesIndexPage()) { return; }
  refreshEnabledPlants();

  const plannerElement = document.getElementById('plant_planner');

  let selectedDate = sessionStorage.getItem('selectedDate');
  if (selectedDate === 'undefined' || selectedDate === null) {
    selectedDate = $('#date').data('date');
    if (!selectedDate) selectedDate = format(new Date(), 'yyyy-MM-dd');
    sessionStorage.setItem('selectedDate', selectedDate);
  }
  setDate(selectedDate, false);

  loadComments(selectedDate);

  if (plannerElement) {
    const initialDate = selectedDate;
    const duration = parseInt(localStorage.getItem('daysToShow')) || 1;
    const hourRange = calculateWorkingHours(initialDate, duration);
    setWorkingHoursMinMax(hourRange.earliestWorkingTime, hourRange.latestWorkingTime);
    const hourMinMax = getWorkingAndEventHoursMinMax([selectedDate]);

    const extraOptions = {
      slotMinTime: hourMinMax.min,
      scrollTime: hourMinMax.min,
      slotMaxTime: hourMinMax.max,
      initialDate: initialDate,
      duration: duration,
      view: localStorage.getItem('calenderView') || 'ResourceByDay',
    };

    const postRender = () => {
      renderWeatherIcons();
      ReactRailsUJS.mountComponents();
    };

    plantPlanner = createPlanner(plannerElement, extraOptions, postRender);

    renderPlanner(plantPlanner);
    refreshEnabledPlants();
    updateNavigation();
    renderWeatherIcons();
    ReactRailsUJS.mountComponents();
  }

  if ($(window).width() <= 768 && !sessionStorage.getItem('currentVisiblePlant')) {
    const firstId = getVisiblePlantIds().shift();
    const prefs = new UserPreferences();
    const plantSelection = prefs.fetch('plantSelection');
    const projectId = $('.plan_section').data('projectId');
    const preferedPlantIds = plantSelection[projectId];
    const userPreferedId = preferedPlantIds ? preferedPlantIds[0] : firstId;
    sessionStorage.setItem('currentVisiblePlant', userPreferedId);
  }

  let header = $('.fc-head');

  $(document).on('click', '#change_date', () => {
    const date = sessionStorage.getItem('selectedDate');
    const datePicker = document.querySelector('#date-picker');
    ReactDOM.unmountComponentAtNode(datePicker);
    ReactDOM.render(
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <DatePicker
          date={date}
          dateFormat="yyyy-MM-dd"
          applyDate={(value) => setupPlannerWithTimeAndDateConfigured(value)}
          open
        />
      </LocalizationProvider>,
      datePicker,
    );
  });

  $(document).on('click', '#plan_comments_count', function (e) {
    if (!publicLogin) {
      const dataId = $(this).data('id');

      $('.entity--request-comments').each(function (e) {
        const el = $(this);

        if (el.data('id') === dataId) el.toggle('slow');
      });

      e.stopPropagation();
    }
  });

  $(document).on('click', '#previous_day', () => {
    const day = new Date(sessionStorage.getItem('selectedDate'));
    const previousDay = addDays(day, -1);

    setupPlannerWithTimeAndDateConfigured(previousDay);
  });

  $(document).on('click', '#next_day', () => {
    const day = new Date(sessionStorage.getItem('selectedDate'));
    const nextDay = addDays(day, 1);

    setupPlannerWithTimeAndDateConfigured(nextDay);
  });

  $(document).on(clickEventType, '#submit-copy-previous-day', (event) => {
    const projectId = $('.plan_section').data('projectId');
    const date = $('#date').text();
    const sourceDate = $('#source_date').val();
    const plantIds = $('#plant_ids').val();

    $.ajax({
      url: `/projects/${projectId}/copy_previous_day`,
      type: 'POST',
      data: {
        date,
        source_date: sourceDate,
        plant_ids: plantIds,
      },
    });
  });

  $(document).on(clickEventType, '#new-plant-activity', () => {
    const projectId = $('.plan_section').data('projectId');
    $.ajax({
      url: '/planned_plant_activities/new.js',
      type: 'GET',
      data: { project_id: projectId },
    });
  });

  $(document).on(clickEventType, '#copy_previous_day', () => {
    $('#copy-previous-day-form').dialog({
      modal: true,
      title: 'Copy previous plan',
      autoOpen: true,
      resizable: false,
      close() {
        $(this).dialog('destroy');
      },
    });
  });

  $(document).on(clickEventType, '#submit_selected_plant', (e) => {
    e.preventDefault();
    const plantId = $('#planned_plant_activity_plant_id').val();
    $.ajax({
      url: '/planned_plant_activities/new.js',
      type: 'GET',
      data: { plant_id: plantId },
    });
  });

  function setActivityParams() {
    const date = $('#activity_date').val() || sessionStorage.getItem('selectedDate');
    const plantId = $('#planned_plant_activity_plant_id').val();
    const start = convertTime12to24($('#planned_plant_activity_start_time').val());
    const end = convertTime12to24($('#planned_plant_activity_end_time').val());
    const note = $('#planned_plant_activity_note').val();
    const refNo = $('#planned_plant_activity_ref_no').val();
    const quantity = $('#planned_plant_activity_quantity').val();
    const destinationId = $('#planned_plant_activity_destination_id').val();
    const sourceId = $('#planned_plant_activity_source_id').val();
    const materialId = $('#planned_plant_activity_material_id').val();
    const duration = $('#planned_plant_activity_duration').val();
    const customer = $('#planned_plant_activity_customer option:selected');
    const decodedCustomer = JSON.parse($('#planned_plant_activity_customer').val());
    const customerId = decodedCustomer?.customer_id;
    const customerType = decodedCustomer?.customer_type;

    return {
      plant_id: plantId,
      destination_id: destinationId,
      source_id: sourceId,
      material_id: materialId,
      start_time: start,
      end_time: end,
      ref_no: refNo,
      customer_id: customerId,
      customer_type: customerType,
      duration: splitTime(duration),
      date,
      note,
      quantity,
    };
  }

  function updatePlannerTimeAndDate() {
    const day = new Date(sessionStorage.getItem('selectedDate'));
    setupPlannerWithTimeAndDateConfigured(day);
  }

  $(document).on('click', '#submit-new-activity-button', (e) => {
    e.preventDefault();
    const projectId = $('.plan_section').data('projectId');
    const status = $('#planned_plant_activity_status').val();
    const activityParams = setActivityParams();
    activityParams.status = status;

    $.ajax({
      url: '/planned_plant_activities',
      type: 'POST',
      dataType: 'text',
      beforeSend: showSpinner(),
      data: {
        project_id: projectId,
        planned_plant_activity: activityParams,
      },
      success(response) {
        rerenderSidebarRequests();
        updatePlannerTimeAndDate();
        hideSpinner();
      },
    });
    $('#new-activity-form').dialog('close');
  });

  $(document).on('dialogclose', '#new-activity-form', (e) => {
    plantPlanner.unselect();
  });

  if (header.is('thead')) {
    var headerOffset = header.offset().top;
    $(document).on('scroll', () => {
      header = $('.fc-head');
      freezePlannerHeader();
      if ($('.navbar-top').hasClass('nav-up')) {
        header.removeClass('header-down');
        header.removeClass('navbar-header-offset');
      } else {
        header.addClass('header-down');
        header.addClass('navbar-header-offset');
      }
    });
  }

  let clientX;
  $(document).on('touchstart', '#plant_planner', (e) => {
    clientX = e.originalEvent.touches[0].pageX;
  });

  $(document).on('touchend', '#plant_planner', (e) => {
    const deltaX = e.originalEvent.changedTouches[0].clientX - clientX;
    if (Math.abs(deltaX) > 150) {
      changePlant(deltaX);
    }
  });

  $(document).on('click', '#submit-edit-activity-button', (e) => {
    e.preventDefault();
    const projectId = $('.plan_section').data('projectId');
    const activityId = $('#activity_id').val();

    const activityParams = setActivityParams();

    $.ajax({
      url: `/planned_plant_activities/${activityId}`,
      type: 'PUT',
      dataType: 'text',
      beforeSend: showSpinner(),
      data: {
        project_id: projectId,
        planned_plant_activity: activityParams,
      },
      success() {
        rerenderActivitiesIndexPage();
        rerenderSidebarRequests();
        updatePlannerTimeAndDate();
        hideSpinner();
      },
    });
    $('#edit-activity-form').dialog('close');
  });

  $(document).on('click', '#submit-request-delete-button', (e) => {
    e.preventDefault();
    const activityId = $('#activity_id').val();

    $.ajax({
      url: `/planned_plant_activities/${activityId}/request_destroy`,
      type: 'PUT',
      beforeSend: showSpinner(),
      dataType: 'text',
      success() {
        rerenderActivitiesIndexPage();
        rerenderSidebarRequests();
        updatePlannerTimeAndDate();
        hideSpinner();
      },
    });
    $('#edit-activity-form').dialog('close');
  });

  $(document).on('click', '#submit-request-restore-button', (e) => {
    e.preventDefault();
    const activityId = $('#activity_id').val();

    $.ajax({
      url: `/planned_plant_activities/${activityId}/request_restore`,
      type: 'PUT',
      beforeSend: showSpinner(),
      dataType: 'text',
      success() {
        rerenderActivitiesIndexPage();
        rerenderSidebarRequests();
        updatePlannerTimeAndDate();
        hideSpinner();
      },
    });
    $('#edit-activity-form').dialog('close');
  });

  $(document).on(clickEventType, '#submit-approve-delete-request-button', (e) => {
    e.preventDefault();
    const activityId = $('#activity_id').val();

    $.ajax({
      url: `/planned_plant_activities/${activityId}`,
      type: 'DELETE',
      dataType: 'text',
      beforeSend: showSpinner(),
      success() {
        rerenderActivitiesIndexPage();
        rerenderSidebarRequests();
        updatePlannerTimeAndDate();
        hideSpinner();
      },
    });
    $('#edit-activity-form').dialog('close');
  });

  $(document).on(clickEventType, '#submit-reject-delete-request-button', (e) => {
    e.preventDefault();
    const activityId = $('#activity_id').val();

    const activityParams = setActivityParams();
    activityParams.status = 'approved';

    $.ajax({
      url: `/planned_plant_activities/${activityId}`,
      type: 'PUT',
      dataType: 'text',
      beforeSend: showSpinner(),
      data: {
        planned_plant_activity: activityParams,
      },
      success() {
        rerenderActivitiesIndexPage();
        rerenderSidebarRequests();
        updatePlannerTimeAndDate();
        hideSpinner();
      },
    });
    $('#edit-activity-form').dialog('close');
  });

  $(document).on('click', '#submit-cancel-delete-request-button', (e) => {
    e.preventDefault();
    const activityId = $('#activity_id').val();
    const activityParams = setActivityParams();

    $.ajax({
      url: `/planned_plant_activities/${activityId}/request_changes`,
      type: 'PUT',
      dataType: 'text',
      beforeSend: showSpinner(),
      data: {
        planned_plant_activity: activityParams,
      },
      success() {
        rerenderActivitiesIndexPage();
        rerenderSidebarRequests();
        updatePlannerTimeAndDate();
        hideSpinner();
      },
    });
    $('#edit-activity-form').dialog('close');
  });

  $(document).on(clickEventType, '#submit-new-requested-activity-button', function (e) {
    e.preventDefault();

    const $buttonEl = $(this);
    const $loadingSpinner = $buttonEl.parent().find('.loading-spinner-btn');

    const form = $('#new-activity-form');
    const activityParams = setActivityParams();
    activityParams.status = 'requested';

    // show loading spinner - visual queue to user that something is happening
    if ($loadingSpinner.length !== 0) {
      $buttonEl.addClass('hidden');
      $loadingSpinner.removeClass('hidden');
    }

    $.ajax({
      url: '/planned_plant_activities',
      type: 'POST',
      dataType: 'text',
      beforeSend: showSpinner(),
      data: {
        planned_plant_activity: activityParams,
      },
      success() {
        rerenderActivitiesIndexPage();
        rerenderSidebarRequests();
        updatePlannerTimeAndDate();
        hideSpinner();
      },
    });
    form.dialog('close');
  });

  $(document).on(clickEventType, '#submit-request-activity-button', (e) => {
    e.preventDefault();

    const $buttonEl = $(this);
    const requestId = $('#activity_id').val();
    const activityParams = setActivityParams();
    const form = $('#edit-activity-form');
    const $loadingSpinner = $buttonEl.parent().find('.loading-spinner-btn');
    if ($loadingSpinner.length !== 0) {
      $buttonEl.addClass('hidden');
      $loadingSpinner.removeClass('hidden');
    }

    $.ajax({
      url: `/planned_plant_activities/${requestId}/request_changes`,
      type: 'PUT',
      dataType: 'text',
      beforeSend: showSpinner(),
      data: {
        planned_plant_activity: activityParams,
      },
      success() {
        rerenderActivitiesIndexPage();
        rerenderSidebarRequests();
        updatePlannerTimeAndDate();
        hideSpinner();
      },
    });
    form.dialog('close');
  });

  $(document).on(clickEventType, '#approve-and-create-activity-button', (e) => {
    e.preventDefault();
    const id = $('#activity_id').val();
    const activityParams = setActivityParams();
    activityParams.status = 'approved';

    $.ajax({
      url: `/planned_plant_activities/${id}/approve`,
      type: 'PUT',
      beforeSend: showSpinner(),
      data: {
        planned_plant_activity: activityParams,
      },
      success() {
        $('#edit-activity-form').dialog('destroy');
        rerenderActivitiesIndexPage();
        showFlashMessage('Planned activity approved successfully', 'success');
        rerenderSidebarRequests();
        updatePlannerTimeAndDate();
        hideSpinner();
      },
      error() {
        showFlashMessage('Sorry, something went wrong while attempting to approve the activity.', 'danger');
      },
    });
  });

  $(document).on(clickEventType, '#submit-restore-activity-button', (e) => {
    e.preventDefault();
    const id = $('#activity_id').val();

    $.ajax({
      url: `/planned_plant_activities/${id}/restore`,
      type: 'PUT',
      beforeSend: showSpinner(),
      success() {
        $('#edit-activity-form').dialog('destroy');
        rerenderActivitiesIndexPage();
        updatePlannerTimeAndDate();
        rerenderSidebarRequests();
        hideSpinner();
      },
    });
  });

  $(document).on('click', '.activity-entity', function () {
    const activityEntity = $(this.closest('.activity-entity'));
    const date = new Date(activityEntity.data('date').split('/').reverse());
    const dateFormat = isSameYear(date, sessionStorage.getItem('selectedDate')) ? 'E d MMM' : 'E d MMM yyyy';
    const newDate = format(date, dateFormat);

    if (!publicLogin || (publicLogin && activityEntity.data('own-request'))) {
      const activityEntityId = activityEntity.data('id');
      $.ajax({
        url: `/planned_plant_activities/${activityEntityId}/edit.js`,
        type: 'GET',
        beforeSend: showSpinner(),
        success() {
          $('#activity_date').val(newDate);
          hideSpinner();
        },
      });
    }
  });

  $(document).on('mouseenter', '.request-activity', function () {
    const activityId = $(this).data('activity-id');
    if (!activityId) return;
    const activityElement = $(`.fc-event[data-activity-id=${activityId}]`);
    activityElement.addClass('event-highlighted');
  });

  $(document).on('mouseleave', '.request-activity', function () {
    const activityId = $(this).data('activity-id');
    if (!activityId) return;
    const activityElement = $(`.fc-event[data-activity-id=${activityId}]`);
    activityElement.removeClass('event-highlighted');
  });

  $(document).on('change', '#planned_activity_plant_id', function () {
    const field = $(this);
    const select = field.closest('.activity-form').find('.location-input');
    const plantId = field.val();

    $.ajax({
      url: `/locations/${plantId}/available_locations`,
      type: 'GET',
      dataType: 'json',
      success(response) {
        select.empty().append("<option value=''>Where it will be loaded to?</option>");

        response.locations.forEach((location) => {
          const opt = document.createElement('option');
          opt.value = location.id;
          opt.innerHTML = location.name;

          select.append(opt);
        });
      },
    });
  });

  $(document).on('click', '#edit_event_button', function () {
    const activityId = $(this).closest($('a')).data('activity-id');
    const event = plantPlanner.getEventById(activityId);
    $.ajax({
      url: `/planned_plant_activities/${activityId}/edit.js`,
      type: 'GET',
      beforeSend: showSpinner(),
      success(response) {
        $('#planned_plant_activity_start_time').val(format(event.start, 'hh:mm a'));
        $('#planned_plant_activity_end_time').val(format(event.end, 'hh:mm a'));

        const duration = getDuration(event.start, event.end);
        $('#duration').val(duration);
        hideSpinner();
      },
    });
  });

  $(document).on('click', '#delete_event_button, #delete_activity', function (e) {
    e.preventDefault();
    const activityId = $(this).closest('a').data('activity-id') || $('#activity_id').val();
    const approveCallback = function () {
      $.ajax({
        url: `/planned_plant_activities/${activityId}`,
        type: 'DELETE',
        beforeSend: showSpinner(),
        dataType: 'text',
        success(response) {
          $('#edit-activity-form').dialog('close');
          rerenderActivitiesIndexPage();
          rerenderSidebarRequests();
          updatePlannerTimeAndDate();
          hideSpinner();
        },
      });
    };
    const denyCallback = function () {

    };
    confirmationDialog('Delete this item from the schedule?', approveCallback, denyCallback);
  });

  $(document).on('click', '#change_day_duration', () => {
    const projectId = $('.plan_section').data('project-id');

    $.ajax({
      url: `/projects/${projectId}/working_periods/default_working_hours`,
      type: 'GET',
      success() {
        $('#working_period_form').dialog({
          modal: true,
          title: 'Working hours',
          width: 'auto',
          resizable: false,
          close() {
            $(this).dialog('destroy');
            refreshEnabledPlants();
          },
        });
      },
    });

    // disabled in favour of using #working_period_form
    // startTime = $('#plants-info').data('day-start');
    // endTime = $('#plants-info').data('day-end');
    // debugger;
    // $('#working-day-duration-form #working-day-start').val(startTime);
    // $('#working-day-duration-form #working-day-end').val(endTime);
    // $('#working-day-duration-form').dialog({
    //   modal: true, title: 'Working day duration',
    //   width: 'auto', resizable: false,
    //   close: function() {
    //     $(this).dialog('close');
    //   }
    // })
  });

  // update plantPlanner based on values selected from working_periods/_working_hours
  $(document).on('ajax:success', '.edit_project, .edit_working_period', (event) => {
    const plannerElement = document.getElementById('plant_planner');
    if (plannerElement) {
      updatePlannerTimeAndDate();
    }
  });

  $(document).on('click', '#submit-working-day-duration', () => {
    const start = $('#working-day-duration-form #working-day-start').val();
    const end = $('#working-day-duration-form #working-day-end').val();
    $('#working-day-duration-form').dialog('close');
    plantPlanner.setOption('minTime', convertTime12to24(start));
    plantPlanner.setOption('maxTime', convertTime12to24(end));
    $('#plants-info').data('day-start', start);
    $('#plants-info').data('day-end', end);
  });

  $(document).on('click', '#check-all-plants', function () {
    const checked = $(this).prop('checked');

    $('div#plant-checkboxes input:checkbox').prop('checked', checked);
  });

  $(document).on(clickEventType, '#choose-cranes', () => {
    const projectId = $('.plan_section').data('projectId');
    $.ajax({
      url: `/projects/${projectId}/available_plants.js`,
      type: 'GET',
      success() {
        $('#enabled-plants-info').children().each((index, plant) => {
          const id = $(plant).data('plant-id');
          $(`input:checkbox#plant-id-checkbox-${id}`).attr('checked', 'checked');
        });
      },
    });
  });

  const rememberPlantIds = (plantIds) => {
    const projectId = $('.plan_section').data('projectId');
    const prefs = new UserPreferences();
    const plantSelection = prefs.fetch('plantSelection');
    plantSelection[projectId] = plantIds;
    prefs.remember('plantSelection', plantSelection);
  };

  $(document).on('click', '#submit-crane-selection', () => {
    const plantIds = [];
    $('#choose-cranes-form #plant-checkboxes :checkbox:checked').each(function () {
      plantIds.push($(this).val());
    });
    rememberPlantIds(plantIds);
 
    refreshEnabledPlants();
    $('#choose-cranes-form').dialog('close');
    updateNavigation();
    plantPlanner.setOption('duration', { days: getCurrentlyVisibleColumnCount() });
    updatePlannerTimeAndDate();
  });

  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  $(document).on(clickEventType, '#report', (e) => {
    generateCranePlanPdf();
  });

  function freezePlannerHeader() {
    const header = $('.fc-head');
    if (window.pageYOffset + 40 > headerOffset) {
      header.addClass('sticky');
    } else {
      header.removeClass('sticky');
    }
  }

  function changePlant(deltaX) {
    if (deltaX < 0) {
      rollRight();
    } else {
      rollLeft();
    }
  }

  setRequestTooltips();
});

export function setupPlannerWithTimeAndDateConfigured(dateVal, invalidate = false) {
  const projectId = $('.plan_section').data('project-id');
  const days = localStorage.getItem('daysToShow') || 1;
  let date = new Date(dateVal);

  const currentlyVisibleDays = calculateVisibleDaysForCalendar(plantPlanner);

  const newRelativeURL = `?date=${format(date, 'yyyy-MM-dd')}&days=${days}`;

  window.history.replaceState(null, "LinkNBuild Project Calendar", newRelativeURL);

  $.ajax({
    url: `/projects/${projectId}/project_plant_metadata`,
    data: { date: format(date, 'yyyy-MM-dd'), duration: days },
    type: 'GET',
    success(response) {
      $('#project-plant-metadata').html(response);
      const hourRange = calculateWorkingHours(format(date, 'yyyy-MM-dd'), days);
      setWorkingHoursMinMax(hourRange.earliestWorkingTime, hourRange.latestWorkingTime);  
    },
  });

  setDate(date);
  loadComments(date);

  if (!plantPlanner) {
    return;
  }
  
  if ((currentlyVisibleDays.length > 0) && (currentlyVisibleDays[0] == format(date, 'yyyy-MM-dd'))) {
    plantPlanner.refetchEvents();
  } else {
    plantPlanner.gotoDate(format(date, 'yyyy-MM-dd'));
  };
  plantPlanner.render();
  renderWeatherIcons();
}

export function regenerateColumns() {
  updateNavigation();
}
