import { getCountryCallingCode, AsYouType } from 'libphonenumber-js';

$(document).ready(function () {
  $(document).on('focusin', '#invite_mob_number, #mob_number, #user_mob_number', function (e) {
    var element = $(this);
    element.off('focus');
    if(element.val()) {
      return;
    }
    $.ajax({
      url: 'http://ip-api.com/json',
      success: function (data) {
        var countryCode = data.countryCode;
        var phoneCode = getCountryCallingCode(countryCode)
        element.val("+" + phoneCode);
        element.data('countryCode', countryCode)
      }
    });
  });

  $(document).on('change', '#invite_mob_number, #mob_number, #user_mob_number', function (e) {
    var element = $(this);
    var phoneNumber = element.val();
    var countryCode = element.data('countryCode');
    var formattedNumber = new AsYouType(countryCode).input(phoneNumber);
    element.val(formattedNumber);
  });
});
