import { clickEventType } from './helpers';

$(document).ready(function () {
  function saveQuote() {
    var quoteId = $('#quote_id').val();
    $.ajax({
      url: '/quotes/' + quoteId,
      type: 'PUT',
      data: $('#quote_form').serialize(),
      timeout: 3000
    });
    $('#title').text('Quote for ' + $('#project_name').val());
  }

  function saveQuoteItem() {
    var quoteId = $('#quote_id').val();
    $.ajax({
      url: '/quotes/' + quoteId,
      type: 'PUT',
      data: $('#quote_item_form').serialize(),
      success: function () {
        $('[id=quote_item_row]').each(function () {
          setTotalInRow($(this));
        });
      },
      timeout: 3000
    });
  }

  $(document).on(clickEventType, '#save_quote_button', function () {
    saveQuote();
    saveQuoteItem();
  });

  function updateMarginOnDefaultChange() {
    var val = $('#quote_margin').val();
    $('[id=quote_item_margin]').each(function () {
      $(this).val(val);
    });
    $('#quote_item_margin').trigger('change');
  }

  $(document).on(clickEventType, '#update_margin', function (e) {
    e.preventDefault();
    $('<div></div>').appendTo('body')
      .html('<div><h6>This will update the margin in all line items. Are you sure?</h6></div>')
      .dialog({
        modal: true, title: 'Info', zIndex: 10000, autoOpen: true,
        width: 'auto', resizable: false,
        buttons: {
          Yes: function () {
            updateMarginOnDefaultChange();
            saveQuote();
            saveQuoteItem();
            $(this).dialog('close');
          },
          No: function () {
            $(this).dialog('close');
          }
        },
        close: function () {
          $(this).remove();
        }
      });
  });

  $(document).on(clickEventType, '#removeQuoteItem', function () {
    $(this).parent().remove();
    var quoteItemDescr = $(this).parent().children('input[name="quote[item_types][][items][][description]"]').val();
    if (quoteItemDescr !== '') {
      saveQuoteItem();
    }
    setTotals();
  });

  $(document).on(clickEventType, '#addNewQuoteItem', function () {
    var newQuoteItem = $('#quoteItemTemplate #quote_item_row').clone();
    var defaultMargin = $('#quote_margin').val();
    newQuoteItem.children('#quote_item_margin').val(defaultMargin);
    var quoteItemTypeId = $(this).parent().attr('data-quote-item-type-name');
    newQuoteItem.children('[name="item_types[][name]"]').val(quoteItemTypeId);
    $(this).before(newQuoteItem);
  });

  $(document).on('change','#quote_item_row #description, #quote_item_row #item_unit, #quote_item_row #item_quantity, #quote_item_row #item_rate, #quote_item_row #quote_item_margin',function () {
    saveQuoteItem();
    setTotals();
  });

  $(document).on('change', '.left-table #project_name, .left-table #project_address, .left-table #client, .left-table #client_name, .left-table #client_email, .left-table #quote_margin, .left-table #quote_reference',function () {
    saveQuote();
    setTotals();
  });

  $('[id=quote_item_row]').each(function () {
    setTotalInRow($(this));
  });

  function setTotals() {
    $('[id=quote_item_row]').each(function () {
      setTotalInRow($(this));
    });
    setTotalCost();
    setMargin();
    setMarginPercentage();
    setTotalQuote();
  }

  function setMarginPercentage() {
    var totalCost = parseFloat($('#total_cost').text().replace('R', ''));
    var totalMargin = parseFloat($('#total_margin').text().replace('R', ''));
    var marginPercentage = totalMargin / totalCost * 100;
    if (totalCost && totalMargin) {
      $('#margin_percentage').text('(' + marginPercentage.toFixed(2) + '%)');
    }
  }

  function setMargin() {
    var totalMargin = 0.00;
    var defaultMargin = parseFloat($('#quote_margin').val());
    $('[id=quote_item_row]').each(function () {
      var quantity = $(this).children('#item_quantity').val();
      var rate = $(this).children('#item_rate').val();
      var marginEl = $(this).children('#quote_item_margin');
      var margin = marginEl.val();
      if ((quantity === '') || (rate === '')) {
        return true;
      }

      if (margin === '') {
        marginEl.val(defaultMargin);
        margin = defaultMargin;
      }
      totalMargin += parseFloat(quantity) * parseFloat(rate) * parseFloat(margin) / 100;
    });
    $('#total_margin').text('R' + totalMargin.toFixed(2));
  }

  function setTotalCost() {
    var totalCost = 0.00;
    $('[id=total_item_value]').each(function () {
      totalCost += parseFloat($(this).text());
    });
    $('#total_cost').text('R' + totalCost.toFixed(2));
  }

  function setTotalQuote() {
    var totalQuote = parseFloat($('#total_cost').text().replace('R', '')) + parseFloat($('#total_margin').text().replace('R', ''));
    $('#total_quote').text('R' + totalQuote.toFixed(2));
  }

  function setTotalInRow(row) {
    var quantity = row.children('#item_quantity').val();
    var rate = row.children('#item_rate').val();
    var total = row.children('#total_item_value');
    total.text((rate * quantity).toFixed(2));
  }
});
