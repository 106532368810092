$(document).ready(function () {
  $(document).on('click', '.module_accessor', function () {
    var userId = $(this).data('userId');
    var lnbModuleId = $(this).data('moduleId');
    $.ajax({
      url: '/module_accessors',
      data: {
        user_id: userId,
        lnb_module_id: lnbModuleId
      },
      type: 'PUT'
    });
  });
});
