import { showRequestsChanged } from './helpers';

$(document).ready(() => {
  function updateAnotherCheckbox(anotherCheckboxName, checkboxValue) {
    const anotherCheckbox = $(`.show-${anotherCheckboxName}-selector input[type=checkbox]`);
    if (!checkboxValue) return anotherCheckbox[0].checked;

    anotherCheckbox.prop('checked', false);
    return false;
  }

  if ($('#planned-plant-activities-sidebar-column').length) {
    $('.show-unscheduled-selector input[type=checkbox]').change(function () {
      const onlyUnscheduledChecked = this.checked;
      const archivedChecked = updateAnotherCheckbox('archived', onlyUnscheduledChecked);
      showRequestsChanged(onlyUnscheduledChecked, archivedChecked);
    });

    $('.show-archived-selector input[type=checkbox]').change(function () {
      const archivedChecked = this.checked;
      const onlyUnscheduledChecked = updateAnotherCheckbox('unscheduled', archivedChecked);
      showRequestsChanged(onlyUnscheduledChecked, archivedChecked);
    });
  }
});
