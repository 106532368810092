import { showSpinner, changeDateByDays, openAreYouSurePopup } from '../helpers';
import { showTaskDetails, updateEmployeesCounter } from './shared';
import { updateUrl } from './draw_employee_planner';
import { showFlashMessage } from '../flash';

$(document).ready(function () {
  $(document).on('click', '.colored_task_mobile', function () {
    var expandableArea = $(this).parent().find('.expandable_area');
    if (expandableArea.is(':visible')) {
      expandableArea.hide();
      expandableArea.find('.planned_employees').html('');
    } else {
      showTaskDetails(expandableArea);
    }
  });

  $(document).on('click', '.assigned_to_task_employee .convert_to_actual_task', function (e) {
    var assignedToTaskEmployee = $(this).closest('.assigned_to_task_employee');
    var task = assignedToTaskEmployee.closest('.task_mobile');
    var expandableArea = $(this).closest('.expandable_area');
    e.stopPropagation();
    var taskId = assignedToTaskEmployee.data('taskId');
    var taskClass = assignedToTaskEmployee.data('taskClass');
    if (assignedToTaskEmployee.data('taskClass') === 'Task') {
      $.ajax({
        url: 'planned_employee_days/destroy_task',
        type: 'DELETE',
        data: { task_id: taskId, task_class: taskClass },
        beforeSend: function () { showSpinner(); },
        complete: function () { $('.spinner').hide(); },
        success: function (data) {
          if (data.status === 'ok') {
            showTaskDetails(expandableArea);
            updateEmployeesCounter(task, expandableArea);
          }
        }
      });
    } else {
      var data = {
        employee_id: assignedToTaskEmployee.data('employeeId'),
        task_id: taskId,
        task_class: taskClass,
        project_id: task.data('projectId'),
        supervisor_id: task.data('supervisorId'),
        date: task.data('date')
      };
      $.ajax({
        url: 'planned_employee_days/create_actual_task_from_planned',
        type: 'POST',
        data: data,
        beforeSend: function () { showSpinner(); },
        complete: function () { $('.spinner').hide(); },
        success: function (data) {
          if (data.status === 'ok') {
            showTaskDetails(expandableArea);
          }
        }
      });
    }
  });

  $(document).on('click', '.task_mobile .assigned_to_task_employee', function (e) {
    e.stopPropagation();
    var task = $(this);
    var duration = task.find('.task_tracked_time_span').text();
    var dataAttributes = $(this).data();
    var data = {
      task_id: dataAttributes.taskId,
      task_class: dataAttributes.taskClass
    };
    if ($(this).next().hasClass('employee_details_container')) {
      $(this).next().remove();
    } else {
      $.ajax({
        url: '/planned_employee_days/task_comments',
        type: 'GET',
        data: data,
        beforeSend: function () { showSpinner(); },
        complete: function () { $('.spinner').hide(); },
        success: function (data) {
          var employeeDetailsContainerHtml =
            "<div class='hidden employee_details_container'> \
            <div class='comments_container'> \
              <b>Comments: </b> \
              <div class='comments_feed'></div> \
              <input type='text' id='comment_input_box' placeholder='Enter comment'> \
              <a class='nav-button send_comment'>Send</a> \
            </div> \
          </div>";
          var employeeDetailsContainer = $(employeeDetailsContainerHtml);
          employeeDetailsContainer.find('.comments_feed').html(data);
          if (task.find('.formatted_tracked_time').length) {
            addSectionWithTaskDuration(employeeDetailsContainer, duration);
          }
          task.after(employeeDetailsContainer);
          employeeDetailsContainer.show();
        }
      });
    }

    function addSectionWithTaskDuration(employeeDetailsContainer, duration) {
      var taskDurationInfoHtml =
        "<div class='task_duration_info'>  \
           <b>Duration: </b> \
           <input type='text' class='tracked_time_input' placeholder='0.0' value=" + duration + "> \
           <a class='nav-button save_task_duration hidden'>Save</a> \
        </div>";
      employeeDetailsContainer.prepend(taskDurationInfoHtml);
    }
  });

  $(document).on('click', '.task_duration_info .tracked_time_input', function () {
    $(this).siblings('.save_task_duration').show();
  });

  $(document).on('click', '.task_mobile .expandable_area, .task_mobile .comments_container', function (e) {
    e.stopPropagation();
  });

  $(document).on('click', '.comments_container .send_comment', function () {
    var employeeDetailsContainer = $(this).closest('.employee_details_container');
    var task = employeeDetailsContainer.siblings('.assigned_to_task_employee');
    var commentInputBox = employeeDetailsContainer.find('#comment_input_box');
    var message = commentInputBox.val();
    var dataAttributes = task.data();
    var data = {
      task_id: dataAttributes.taskId,
      task_class: dataAttributes.taskClass,
      message: message
    };
    $.ajax({
      url: 'planned_employee_days/leave_task_comment',
      type: 'POST',
      data: data,
      beforeSend: function () { showSpinner(); },
      complete: function () { $('.spinner').hide(); },
      success: function (data) {
        commentInputBox.val('');
        var commentsFeed = employeeDetailsContainer.find('.comments_feed');
        commentsFeed.html(data);
        var commentsCount = commentsFeed.find('div.panel').length;
        task.find('.comments_count').text(commentsCount);
      }
    });
  });

  $(document).on('click', '.expandable_area .delete_task', function (e) {
    e.stopPropagation();
    var task = $(this).closest('.task_mobile');
    var plannedTaskId = task.find('#planned_task_id').val();
    if (!plannedTaskId) { return; }
    var data = {
      task_id: plannedTaskId,
      task_class: 'PlannedTask'
    };
    var deleteTaskCallback = function() {
      $.ajax({
        url: 'planned_employee_days/destroy_task',
        type: 'DELETE',
        data: data,
        beforeSend: function(){ showSpinner(); },
        complete: function(){ $('.spinner').hide(); },
        success: function(data){
          if (data.status === 'ok') {
            task.remove();
          }
        }
      });
    }
    openAreYouSurePopup(deleteTaskCallback);
  });

  $(document).on('click', '.expandable_area .complete_task', function(e) {
    e.stopPropagation();
    var task = $(this).closest('.task_mobile');
    var expandableArea = $(this).closest('.expandable_area');
    var completeButton = $(this);
    var status = task.data('completedActualTasks');
    var data = {
      date: task.data('date'),
      competency_id: task.data('competencyId'),
      supervisor_id: task.data('supervisorId'),
      task_id: task.data('taskId'),
      task_class: task.data('taskClass'),
      completed: status
    };
    $.ajax({
      url: 'planned_employee_days/toggle_status_for_actual_tasks',
      type: 'PUT',
      data: data,
      beforeSend: function () { showSpinner(); },
      complete: function () { $('.spinner').hide(); },
      success: function (data) {
        if (data.status === 'ok') {
          showTaskDetails(expandableArea);
          completeButton.toggleClass('base-green');
          var actualTasksAreCompleted = completeButton.hasClass('base-green');
          task.data('completedActualTasks', actualTasksAreCompleted);
          var coloredTask = task.find('.colored_task_mobile');
          if (actualTasksAreCompleted) {
            coloredTask.removeClass('incompleted_task');
            coloredTask.addClass('completed_task');
          } else {
            coloredTask.removeClass('completed_task');
            coloredTask.addClass('incompleted_task');
          }
        }
      }
    });
  });

  $(document).on('click', '.prev_week_mobile, .next_week_mobile', function () {
    var weekButton = $(this);
    var date = weekButton.data('date');
    var mode = weekButton.data('mode');
    var dateDelta = 0;
    var week = '';
    if (weekButton.attr('class') === 'prev_week_mobile') {
      dateDelta = -7;
      week = 'previous';
    } else {
      dateDelta = 7;
      week = 'next';
    }

    var newDate = changeDateByDays(date, dateDelta);
    var formattedDate = $.datepicker.formatDate('dd/mm/yy', newDate);
    var daysCount = $('.week_day_mobile').length;
    $.ajax({
      url: mode === 'tasks' ? '/planned_employee_days/task_planner.js' : '/planned_employee_days.js',
      data: {date: formattedDate, mode: mode, week: week},
      success: function (data) {
        var url = 'date=' + formattedDate + '&mode=' + mode + '&days_count=' + daysCount;
        updateUrl(url);
      },
      error: function () {
        showFlashMessage('Something went wrong.');
      }
    });
  });
});
