import { format } from "date-fns";
import html2pdf from "html2pdf.js";

import { hideSpinner, showSpinner } from "./helpers";

import { plantPlanner } from "./plans";
import { getCurrentlyVisibleColumnCount } from "./plans_navigation";

export function generateCranePlanPdf() {
  const visibleColumnCount = getCurrentlyVisibleColumnCount();
  const extraWidthPerColumn = 64;
  const minimumWidth = 833;
  const maximumWidth = 1405;
  const unconstrainedPdfWidth = minimumWidth + extraWidthPerColumn * (visibleColumnCount - 1);
  const pdfWidth = Math.min(Math.max(minimumWidth, unconstrainedPdfWidth), maximumWidth);

  showSpinner();
  var element = document.getElementById('plant_planner');
  var date = sessionStorage.getItem('selectedDate');
  var projectName = $('.plan_section').data('projectName');
  var reportTitle = `${projectName} Plant for ${date}`;
  var options = {
    margin: [22, 10, 10, 10],
    filename: reportTitle,
    html2canvas: {
      ignoreElements: (element) => hideElementForPdf(element),
      width: pdfWidth,
    },
    jsPDF: {
      orientation: 'portrait',
      format: 'a3'
    }
  };
  var existingPlantPlannerHeight = plantPlanner.getOption('height');
  var existingPlantPlannerWidth = element.style.width;
  plantPlanner.setOption('height', 'auto');
  element.style.width = `${pdfWidth}px`;
  plantPlanner.updateSize();

  html2pdf().set(options).from(element).
    toPdf().get('pdf').then((pdf) => addHeaderToPdf(pdf, reportTitle)).
    save().then(() => {
      plantPlanner.setOption('height', existingPlantPlannerHeight);
      element.style.width = existingPlantPlannerWidth;
      plantPlanner.updateSize();
      hideSpinner();
    });
};

function addHeaderToPdf(pdf, title) {
  var totalPages = pdf.internal.getNumberOfPages();
  var generatedTimeText = `Generated at ${format(Date.now(), 'Y-MM-dd HH:mm:ss')}`;

  for (var currentPage = 1; currentPage <= totalPages; currentPage++) {
    pdf.setPage(currentPage);
    pdf.setFont('Manrope');
    pdf.setFontSize(24);
    pdf.text(title, 11, 14);
    pdf.setFontSize(14);
    pdf.text(generatedTimeText, 11, 20);  
  }
}

function hideElementForPdf(element) {
  return ['event-buttons', 'plus'].some((className) => element.classList.contains(className));
}
