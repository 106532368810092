import {sendAjax} from "../helpers";

$(document).ready(function() {
  if($('#payrate_pay_date option').length === 0) {
    $('.pay-date').hide();
  }
  $('#payrate_rate_unit').change(function() {
    var options = {
      data: {
        rate_unit: $('#payrate_rate_unit').val()
      },
      success: function () {
        if($('#payrate_pay_date option').length === 0) {
          $('.pay-date').hide();
        } else {
          $('.pay-date').show();
        }
      }
    }
    sendAjax('/payrates/pay_dates', 'GET', options);
  });
});
