import { followLink, openConfirmationPopup, openAreYouSurePopup } from './helpers';
import { stylePopup } from './employees_planner/shared';

$(document).ready(function () {
  $(document).on('click', '#add-supervisors-step #invite-supervisor', function () {
    var employeeName = $('#add-supervisor-form #name').val().trim();
    var emailOrPhoneNumber = $('#add-supervisor-form #email_or_phone_number').val().trim();
    var step = $('#add-supervisors-step').data('step');
    var data = {
      fullname: employeeName,
      email_or_phone_number: emailOrPhoneNumber,
      user_type: 'supervisor',
      id: step
    }
    var inviteCallback = function () { inviteEmployee(data); };
    validateFormAndInviteEmployee(inviteCallback);
  });

  $(document).on('click', '#add-employees-step #add-employee', function () {
    var employeeName = $('#add-employee-form #name').val().trim();
    var step = $('#add-employees-step').data('step');
    var data = {
      fullname: employeeName,
      user_type: 'employee',
      id: step
    }
    var inviteCallback = function () { inviteEmployee(data); };
    validateFormAndInviteEmployee(inviteCallback);
  });


  $(document).on('click', '#add-projects-step #add-project', function () {
    var name = $('#add-project-form #name').val();
    var addProjectCallback = function () {
      $.ajax({
        url: '/initial_setup/create_project',
        dataType: 'html',
        type: 'POST',
        data: { name: name },
        success: function (content) {
          $('.initial-setup-form input').val('');
          $('#added-projects').html(content);
        }
      });
    }
    validateFormAndInviteEmployee(addProjectCallback);
  });

  $(document).on('click', '.invited-employee .red-remove-button', function () {
    var employeeId = $(this).closest('.invited-employee').data('employeeId');
    var removeEmployee = function () {
      $.ajax({
        url: '/initial_setup/destroy_employee',
        type: 'DELETE',
        data: { employee_id: employeeId },
        dataType: 'json',
        success: function (jsonData) {
          $('.invited-employee[data-employee-id='+employeeId+']').remove();
        }
      });
    }
    openAreYouSurePopup(removeEmployee);
  });

  $(document).on('click', '#added-projects .red-remove-button', function () {
    var projectId = $(this).closest('.added-project').data('projectId');
    var removeProject = function () {
      $.ajax({
        url: '/projects/' + projectId,
        type: 'DELETE',
        success: function (jsonData) {
          if (jsonData.project_destroyed) {
            $('.added-project[data-project-id='+projectId+']').remove();
          }
        }
      });
    }
    openAreYouSurePopup(removeProject);
  });

  function inviteEmployee(data) {
    $.ajax({
      url: '/initial_setup/invite_employee',
      type: 'POST',
      data: data,
      success: function (content) {
        $('.initial-setup-form input').val('');
        $('.initial-setup #invited-employees').html(content);
      }
    })
  }

  $(document).on('click', '.initial-setup-step .progress-bar li', function (e) {
    e.stopPropagation();
    var step = $(this).data('step');
    var url = '/initial_setup/' + step;
    followLink(url);
  });

  function initialSetupFormValid() {
    return $('.initial-setup-form input').filter(function() {
      if ($(this).val().length === 0) { return true; }
    }).length == 0;
  }

  function initialSetupFormError() {
    var result = '';
    if ($('.initial-setup-form input#name').val() === '') {
      result += "Name can't be blank. ";
    }
    if ($('.initial-setup-form input#email_or_phone_number').val() === '') {
      result += "Email or mobile number can't be blank ";
    }
    return result;
  }

  function validateFormAndInviteEmployee(successCallback) {
    if (initialSetupFormValid()) {
      successCallback();
    } else {
      var message = initialSetupFormError();
      var selector = '#confirm-popup';
      var openCallback = function () { stylePopup(selector); }
      var buttons = [{ text: 'Ok', click: function () { $(this).dialog('destroy'); } }];
      openConfirmationPopup(selector, message, openCallback, null, buttons);
    }
  }
});
