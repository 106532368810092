import { clickEventType } from './helpers';

$(document).ready(function () {
  $(document).on(clickEventType, '#login_form .login_switch, #reset_form .login_switch, #invitation_section .login_switch', function () {
    var loginSwitch = $(this);
    if($('.login_switch .switch input').is(':checked')) {
      $('#login-switch-label').text('To Email');
      $('.login_email').hide();
      $('.login_phone').show();
    } else {
      $('#login-switch-label').text('To Phone');
      $('.login_email').show();
      $('.login_phone').hide();
    }
  });
});
