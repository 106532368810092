import { clickEventType, optionsForSelect } from './helpers';
import { getCountryCallingCode, AsYouType } from 'libphonenumber-js';

$(document).ready(function () {
  $(document).on(clickEventType, '#show_new_company_section', function () {
    $('#new_company_section').toggle();
  });

  $(document).on(clickEventType, '#new_company_section #create_new_company', function () {
    var companyName = $('#new_company_section #new_company_name').val().trim();
    var userId = $('#invite_form #user_id').val();
    $.ajax({
      url: '/companies/create_from_subcontract_company_name',
      type: 'POST',
      data: { company: { name: companyName }, user_id: userId },
      dataType: 'json',
      success: function (data) {
        $('#new_company_section').hide();
        var companyOptions = data.company_options;
        var selectedCompanyId = data.selected_company_id;
        var options = optionsForSelect(companyOptions, selectedCompanyId);
        $('#invite_form #company_id').html(options);
      }
    });
  });

  $(document).on(clickEventType, '#invite_form #send_invite', function (e) {
    e.preventDefault();
    var id = $('#invite_form #invite_id').val();
    var form = $('#invite_form form.edit_invite').clone();
    form.find('[name=_method]').remove();
    form.removeAttr('action');
    form.removeAttr('method');
    var data = form.serialize();
    $.ajax({
      url: '/invites/' + id + '/send_invite',
      type: 'POST',
      data: data
    });
  });

  function changeFormStep(direction) {
    var form = $('#new_user_form');
    var currentStep = form.data('step');
    var maxStep = form.data('maxStep');
    var changeStepValue = 1;
    if(direction === 'back') {
      maxStep = 1;
      changeStepValue = -1;
    }

    if(maxStep !== currentStep) {
      $('#step-'+ currentStep).hide();
      currentStep = currentStep + changeStepValue;
      form.data('step', currentStep);
      $('#step-'+ currentStep).show();
    }

    if(direction === 'next') {
      $('#signup-form-back-button').show();
      if(maxStep === currentStep) {
        $('#signup-form-next-button').hide();
        $('#signup-form-save-button').show();
      }
    } else if(direction === 'back') {
      $('#signup-form-next-button').show();
      $('#signup-form-save-button').hide();
    }
  }

  $(document).on(clickEventType, '#signup-form-next-button', function() {
    changeFormStep('next');
  });

  $(document).on(clickEventType, '#signup-form-back-button', function() {
    changeFormStep('back');
  });

  $("#invite_mob_number").focus(function(e) {
    var element = $(this);
    element.off('focus');
    if(element.val()) {
      return;
    }
    $.ajax({
      url: 'http://ip-api.com/json',
      success: function (data) {
        var countryCode = data.countryCode;
        var phoneCode = getCountryCallingCode(countryCode)
        element.val("+" + phoneCode);
        element.data('countryCode', countryCode)
      }
    });
  });

  $("#invite_mob_number").change(function() {
    var element = $(this);
    var phoneNumber = element.val();
    var countryCode = element.data('countryCode');
    var formattedNumber = new AsYouType(countryCode).input(phoneNumber);
    element.val(formattedNumber);
  });
});
