import { clickEventType, openPopup } from '../helpers';
import { stylePopup } from '../employees_planner/shared';

$(document).ready(function() {
  $(document).ready(function(){
    $("input[type=radio][name='overtime_type']").each(function(index, element) {
      if($(element).is(':checked')) {
        $(element).parent().next('.overtime-details-section').show();
      }
    });
  });

  $(document).on('change', '.overtime-type', function() {
    $('.overtime-details-section').hide();
    var overtimeDetails = $(this).next('.overtime-details-section')
    overtimeDetails.show();
    var overtimeIds = overtimeDetails.data('overtimeIds');
    $('#payrate_overtime_ids').val(overtimeIds);
  });

  $(document).on(clickEventType, '.add-custom-overtime', function() {
    $('#custom-overtimes-section').append('<div class="new-custom-overtime"></div>')
    openOvertimePopup('#payrate-popup', 'step1');
  });

  function customOvertimePopupContent(stepClass) {
    var popupTemplate = $('.custom-overtime-popup').html()
    var step = $(popupTemplate).filter(function () {
      if ($(this).hasClass(stepClass)) { return true; }
    });
    return step[0].outerHTML;
  }

  $(document).on(clickEventType, '.popup-step.step1 .continue', function() {
    var step = $(this).closest('.popup-step');
    saveMultipliedRate();
    var nextStep = step.hasClass('step2') ? 'step3' : 'step2';
    var popupSelector = '#payrate-popup';
    $(popupSelector).dialog('close');
    openOvertimePopup(popupSelector, 'step2');
  });

  $(document).on(clickEventType, '.popup-step.step2 .continue', function() {
    var step = $(this).closest('.popup-step');
    saveOvertimeDays();
    var popupSelector = '#payrate-popup';
    $(popupSelector).dialog('close');
    openOvertimePopup(popupSelector, 'step3');
  });

  $(document).on(clickEventType, '.overtime-save', function() {
    var step = $(this).closest('.popup-step');
    saveLimitForOvertime();
    var popupSelector = '#payrate-popup';
    $(popupSelector).dialog('close');
    showNewOvertime();
  });

  $(document).on(clickEventType, '.overtime-day', function() {
    $(this).toggleClass('overtime-day-selected');
    if($('.popup-step .overtime-day-selected').length > 0) {
      $('.popup-step .continue').removeClass('disabled');
    } else {
      $('.popup-step .continue').addClass('disabled');
    }
  });

  $(document).on(clickEventType, '.limit-hours', function() {
    if($(this).find('input').val() === 'yes') {
      $('#overtime-hr-section').show();
      $('.popup-step .overtime-save').addClass('disabled');
    } else {
      $('#overtime-hr-section').hide();
      $('.popup-step .overtime-save').removeClass('disabled');
    }
  });

  function openOvertimePopup(selector, step) {
    var options = {'width': '300px'};
    var openCallback = function () {
      stylePopup(selector);
    };
    var closeCallback = function () {
      $(selector).dialog('destroy');
    };
    openPopup(selector, customOvertimePopupContent(step), openCallback, closeCallback, options);
  }

  function saveMultipliedRate() {
    var lastNewCustom = $('#custom-overtimes-section').find('.new-custom-overtime:last');
    var popup = $('#payrate-popup');
    var multiplier = popup.find('#overtime_multiplier');
    lastNewCustom.append('<input type="hidden" name="overtimes[][multiplier]" value="'+ multiplier.val() +'">');
  }

  function saveOvertimeDays() {
    var lastNewCustom = $('#custom-overtimes-section').find('.new-custom-overtime:last');
    var popup = $('#payrate-popup');
    var days = popup.find('.overtime-day');
    days.each(function(index, element) {
      if($(element).hasClass('overtime-day-selected')) {
        lastNewCustom.append('<input type="hidden" name="overtimes[][wkdays][]" value="'+ index +'">');
      }
    });
  }

  function saveLimitForOvertime() {
    var lastNewCustom = $('#custom-overtimes-section').find('.new-custom-overtime:last');
    var overtimeHrLimitSection = $('#overtime-hr-section');
    if(overtimeHrLimitSection.is(':visible')) {
      var hourLimit = overtimeHrLimitSection.find('#overtime_hour_limit')
      lastNewCustom.append('<input type="hidden" name="overtimes[][overtime_hr_limit][hour_limit]" value="'+ hourLimit.val() +'">');
      var periods = overtimeHrLimitSection.find('.overtime_hr_limit_period');
      periods.each(function(index, element) {
        if($(element).is(':checked')) {
          lastNewCustom.append('<input type="hidden" name="overtimes[][overtime_hr_limit][period]" value="'+ $(element).val() +'">');
        }
      });
    }
  }

  function showNewOvertime() {
    var customOvertimeSection = $('.overtime-details-section .custom-overtimes-section');
    var newCustomOvetime = $('#custom-overtimes-section').find('.new-custom-overtime').last();
    var multiplier = newCustomOvetime.find('input[name="overtimes[][multiplier]"]').val();
    var days = [];
    newCustomOvetime.find('input[name="overtimes[][wkdays][]"]').each(function(index, element) {
      days.push(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][$(element).val()]);
    });
    var limit = 'all days';
    var overtimeHrLimit = newCustomOvetime.find('input[name^="overtimes[][overtime_hr_limit][hour_limit]"]');
    if(overtimeHrLimit.length) {
      limit = 'hours worked more than ' + overtimeHrLimit.val();
    }
    customOvertimeSection.append('<li>' + multiplier+ 'x normal pay for '+ days.join(', ') +' for '+ limit +
    '<i class="fa-actual-times remove-custom-overtime"></i></li>');
  }

  $(document).on(clickEventType, '.remove-custom-overtime', function() {
    var li = $(this).closest('li');
    var liIndex = li.index();
    li.remove();
    $('#custom-overtimes-section').find('.new-custom-overtime')[liIndex].remove();
  });

  $(document).on('keyup', 'input#overtime_multiplier, input#overtime_hour_limit', function() {
    var button = $('.popup-step .nav-button');
    if($(this).val() === '') {
      button.addClass('disabled');
    } else {
      button.removeClass('disabled')
    }
  });
});
