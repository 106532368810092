import { clickEventType, updateCounter } from './helpers';
import { showFlashMessage } from './flash';

$(document).ready(() => {
  $(document).on('change', '#notification_project', () => {
    const projectId = $('#notification_project').val();
    if (projectId !== undefined && projectId !== '') {
      $.ajax({
        url: '/notification_scopes/subprojects',
        type: 'get',
        dataType: 'html',
        data: { project_id: projectId },
        success(data) {
          $('#subprojects_configuration').html(data);
        },
      });
    } else { $('#subprojects_configuration').empty(); }
  });

  $(document).on('change', '#notification_completion_requirement', (event) => {
    $.ajax({
      url: '/notification_scopes/completion_steps',
      type: 'get',
      dataType: 'html',
      data: { completion_requirement_id: $(event.target).val() },
      success(data) {
        $('#completion_step_configuration').html(data);
      },
    });
  });

  $(document).on('change', '#notification_completion_step', (event) => {
    $.ajax({
      url: '/notification_scopes/completion_step_actions',
      type: 'get',
      dataType: 'html',
      data: { completion_step_id: $(event.target).val() },
      success(data) {
        $('#completion_step_action_configuration').html(data);
      },
    });
  });

  $(document).on('change', '#notification_project_with_plants', () => {
    const projectId = $('#notification_project_with_plants').val();
    if (projectId !== undefined && projectId !== '') {
      $.ajax({
        url: '/notification_scopes/plants',
        type: 'get',
        dataType: 'html',
        data: { project_id: projectId },
        success(data) {
          $('#plant_configuration').html(data);
        },
      });
    } else {
      $('#plant_configuration').empty();
    }
  });

  function timeToMinutes(time) {
    let minutes = parseInt(time.min);
    let hours = parseInt(time.hour);

    minutes = isNaN(minutes) ? 0 : minutes;
    hours = isNaN(hours) ? 0 : hours;

    return hours * 60 + minutes;
  }

  function minutesToTime(minutes) {
    return { hour: Math.floor(minutes / 60), min: minutes % 60 };
  }

  $(document).on('change', '#notification_subproject', () => {
    const projectId = $('#notification_project').val();
    const subprojectId = $('#notification_subproject').val();
    const params = { project_id: projectId, subproject_id: subprojectId };

    if (subprojectId !== undefined && subprojectId !== '') {
      getActivities(params, (data) => {
        showActivities(data);
        $('div.activities').html("<p style='text-align:center'>All activities in selected subproject</p>");
        $('#manage_notifications_popup .load-activities, #subscribe').toggle();
      });
    } else { $('#activities_configuration').empty(); }
  });

  $(document).on(clickEventType, '#apply_filter', () => {
    var params = collectParams((projectId, subprojectId, locationsIds, contractorsIds, activitiesIds, notificationType, allowedIdleTime) => {
      params = {
        project_id: projectId,
        subproject_id: subprojectId,
        activities_ids: activitiesIds,
        locations_ids: locationsIds,
        contractors_ids: contractorsIds,
      };

      return params;
    });

    getActivities(params, (data) => {
      showActivities(data);
      $('#manage_notifications_popup .load-activities, #subscribe').toggle();
    });
  });

  $(document).on(clickEventType, '#subscribe', function () {
    const params = collectParams((
      projectId,
      subprojectId,
      locationsIds,
      contractorsIds,
      activitiesIds,
      notificationType,
      allowedIdleTime,
      completionStepActionId,
      eventType,
      plantId,
      delayReasonsIds,
    ) => {
      const calIds = $('li.contr_act_comment:visible').get().map((e) => e.id);
      let data = {};

      if (completionStepActionId) {
        data = { completion_step_action_id: completionStepActionId };
      } else if (eventType || allowedIdleTime || delayReasonsIds) {
        if (eventType) {
          data.event_type = eventType;
        }
        if (plantId) {
          data.plant_id = plantId;
        } else {
          data.project_id = projectId;
        }
      } else if (!projectId) {
        data = {};
      } else if (projectId && !subprojectId) {
        data = { project_id: projectId };
      } else if (subprojectId && filtersEmpty()) {
        data = { subproject_id: subprojectId };
      } else {
        data = { cal_ids: calIds };
        if (locationsIds !== null) $.extend(data, { locations_ids: locationsIds });
        if (contractorsIds !== null) $.extend(data, { contractors_ids: contractorsIds });
        if (activitiesIds !== null) $.extend(data, { activities_ids: activitiesIds });
      }

      if (delayReasonsIds !== null) $.extend(data, { delay_reasons_ids: delayReasonsIds });

      $.extend(data, { notification_type: notificationType });
      $.extend(data, { subscription_rules: { allowed_idle_time: allowedIdleTime } });

      return data;
    });

    if (!filtersEmpty() && !!params.projectId && !!params.subprojectId && (params.data.cal_ids.length === 0)) {
      showFlashMessage('There are no activities to subscribe', 'danger', '#notifications-errors');
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('user_id');
    if (userId) {
      $.extend(params, { user_id: userId });
    }

    const scopeId = $(this).data('scope-id');
    if (scopeId) {
      const id = $(`.notification-scope[data-id=${scopeId}]`).closest('tr.notification-subscription').data('id');
      $.extend(params, { notification_scope_id: scopeId });
      $.ajax({
        url: `/notification_subscriptions/${id}.js`,
        type: 'PUT',
        data: params,
      });
    } else {
      $.ajax({
        url: '/notification_subscriptions.js',
        type: 'POST',
        data: params,
      });
    }
  });

  $(document).on(clickEventType, '.mark_as_read, #notifications-list a.not_seen', (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const notificationBlock = e.currentTarget.parentNode;
    const data = {
      notification_id: notificationBlock.getAttribute('data-id'),
      event: e.currentTarget.getAttribute('data-event'),
    };
    if (searchParams.get('page')) {
      data.page = searchParams.get('page');
    }
    $.ajax({
      url: '/notifications/read',
      data,
      type: 'get',
      dataType: 'html',
      success(data) {
        $('#notifications-list').html(data);
        updateCounter();
      },
    });
  });

  $(document).on(clickEventType, '.read-all--js', (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const data = { user_id: $(this).data('userId') };
    if (searchParams.get('page')) {
      data.page = searchParams.get('page');
    }
    $.ajax({
      url: '/notifications/read_all',
      data,
      type: 'get',
      dataType: 'html',
      success(data) {
        $('#notifications-list').html(data);
        updateCounter();
      },
    });
  });

  $(document).on('change', '.notification-delivery-method', function () {
    const notificationSubscriptionId = $(this).closest('.notification-subscription').data('id');
    const deliveryMethodId = $(this).val();

    $.ajax({
      url: `/notification_subscriptions/${notificationSubscriptionId}`,
      type: 'PUT',
      data: { delivery_method_id: deliveryMethodId },
    });
  });

  function filtersEmpty() {
    if ($('#plan_locations_dropdown').val() === null
      && $('#plan_contractors_dropdown').val() === null && $('#plan_activities_dropdown').val() === null) { return true; }
    return false;
  }

  function showActivities(data) {
    $('#activities_configuration').html(data);
    $('#notification_plan_filters').show();
    $('#plan_contractors_dropdown, #plan_locations_dropdown, #plan_activities_dropdown').chosen();
  }

  function getActivities(params, callback) {
    $('#manage_notifications_popup .load-activities, #subscribe').toggle();
    $.ajax({
      url: '/notification_scopes/activities',
      type: 'get',
      dataType: 'html',
      data: params,
      success: callback,
    });
  }

  function collectParams(callback) {
    const projectId = $('#notification_project').val() || $('#notification_project_with_plants').val();
    const subprojectId = $('#notification_subproject').val();
    const locationsIds = $('#plan_locations_dropdown').val();
    const contractorsIds = $('#plan_contractors_dropdown').val();
    const activitiesIds = $('#plan_activities_dropdown').val();
    const notificationType = $('#manage_notifications_popup').data('notification-type') || $('#notification_type').val();
    const allowedIdleTime = $('#allowed_idle_time').val();
    const completionStepActionId = $('#notification_completion_step_action').val();
    const eventType = $('#notification_event_type').val();
    const plantId = $('#notification_plant').val();
    const delayReasonsIds = $('#delay_reasons_ids').val();

    return callback(
      projectId,
      subprojectId,
      locationsIds,
      contractorsIds,
      activitiesIds,
      notificationType,
      allowedIdleTime,
      completionStepActionId,
      eventType,
      plantId,
      delayReasonsIds,
    );
  }
});
