import { plantPlanner, regenerateColumns } from './plans';
import { UserPreferences } from './helpers';
import { addDays, format, eachDayOfInterval } from 'date-fns';

const navigationSelector = '.plants-navigation';
let numberOfDays = 1;
let firstVisibleColumnIndex = 0;
let lastWeatherFetch = {};

const getFirstVisibleColumnIndex = () => firstVisibleColumnIndex < totalColumnCount() ? firstVisibleColumnIndex : 0;

let workingHoursMinMax = { min: undefined, max: undefined};
let eventHoursMinMax = { min: undefined, max: undefined};

export const setWorkingHoursMinMax = (min, max) => { workingHoursMinMax = { min, max }; };
export const setEventHoursMinMax = (daysWithMinMaxes) => { eventHoursMinMax = daysWithMinMaxes; };

export const alignHourAndMinuteStringToPreviousHour = (hourAndMinuteString) => {
  if (!hourAndMinuteString) return undefined;
  const hourAndMinute = hourAndMinuteString.split(':');
  const hour = hourAndMinute[0];

  return [hour, "00"].join(":");
}

export const alignHourAndMinuteStringToNextHour = (hourAndMinuteString) => {
  if (!hourAndMinuteString) return undefined;
  const hourAndMinute = hourAndMinuteString.split(':');
  const hour = hourAndMinute[0];
  const minute = hourAndMinute[1];

  if (minute !== "00") {
    const addOneToHourAlignedToTwoDigits = (parseInt(hour) + 1).toString().padStart(2, '0');
    return [addOneToHourAlignedToTwoDigits, "00"].join(":");
  }

  return [hour, "00"].join(":");
}

export const getWorkingAndEventHoursMinMax = (days) => {
  const relevantDaysEventHoursMinMaxes = days.map((day) => eventHoursMinMax[day]);
  const relevantDaysEventHoursMinMaxesWithValues = relevantDaysEventHoursMinMaxes.filter((day) => day !== undefined);

  const relevantDaysEventHoursMin = relevantDaysEventHoursMinMaxesWithValues.map((day) => day.min);
  const relevantDaysEventHoursMax = relevantDaysEventHoursMinMaxesWithValues.map((day) => day.max);

  const min = [...relevantDaysEventHoursMin, workingHoursMinMax.min].filter(item => item).sort().slice(0)[0];
  const max = [...relevantDaysEventHoursMax, workingHoursMinMax.max].filter(item => item).sort().slice(-1)[0];

  const hourlyAlignedMin = alignHourAndMinuteStringToPreviousHour(min);
  const hourlyAlignedMax = alignHourAndMinuteStringToNextHour(max);

  return { min: hourlyAlignedMin, max: hourlyAlignedMax };
};

export const setNumberOfDays = (days) => { numberOfDays = days; };
export const triggerColumnRegeneration = () => regenerateColumns();
export const getNumberOfDays = () => numberOfDays;

export const getMaxVisibleColumnCount = () => ($(window).width() <= 768 ? 1 : (totalColumnCount() || 256));

export function getCurrentlyVisibleColumnCount() {
  return Math.min(getMaxVisibleColumnCount(), totalColumnCount() - getFirstVisibleColumnIndex());
};

const enabledPlantColumns = () => $('#content-plan-form > #enabled-plants-info > div');

const getEnabledPlantResources = () => enabledPlantColumns().map((i, div) => ({id: parseInt(div.dataset.plantId), title: div.dataset.plantName })).toArray();

export const getVisiblePlantResources = () => {
  const plantIds = getVisiblePlantIds();
  const gVPR = getEnabledPlantResources().filter((resource) => plantIds.includes(resource.id));
  let alternatingColumn = false;
  let columnCount = 1;

  gVPR.forEach((resource) => {
    resource.orderingOverride = String(columnCount).padStart(8, '0');
    columnCount = columnCount + 1;

    resource.alternatingColumn = alternatingColumn;
    alternatingColumn = !alternatingColumn;
  });

  return gVPR;
}

export const getEnabledPlantIds = () => getEnabledPlantResources().map((resource) => resource.id);

export const getCalendarView = () => {
  const days = localStorage.getItem('daysToShow') || 1;
  const view = localStorage.getItem('calenderView') || 'ResourceByDay';

  return { daysVisible: parseInt(days, 10), view: view };
}

const totalColumnCount = () => enabledPlantColumns().length * numberOfDays;

const numberOfPlants = () => enabledPlantColumns().length;

export function visibleColumnIndexToPlantIndex(index) {
  return (index + getFirstVisibleColumnIndex()) % numberOfPlants();
}

const visibleColumnIndexToPlantId = (index) => enabledPlantColumns().eq(visibleColumnIndexToPlantIndex(index)).attr('data-plant-id');

const isNavigationVisible = () => !$(navigationSelector).hasClass('hidden');

const needNavigation = () => { 
  return totalColumnCount() > getMaxVisibleColumnCount();
};

const canMoveRight = () => (getFirstVisibleColumnIndex() + getMaxVisibleColumnCount()) < totalColumnCount();

const canMoveLeft = () => getFirstVisibleColumnIndex() !== 0;

function setResources() { plantPlanner.refetchResources(); }

function setButtonVisibility(selector, canMove) {
  const button = $(selector);

  if (canMove) {
    if (button.hasClass('hidden')) { button.removeClass('hidden'); }
  } else if (!button.hasClass('hidden')) {
    button.addClass('hidden');
  }
}

function setButtonsVisibility() {
  setButtonVisibility('#plants-navigation-right', canMoveRight());
  setButtonVisibility('#plants-navigation-left', canMoveLeft());
}

export function rollRight() {
  if (!canMoveRight() && !canMoveLeft()) {
    return;
  }

  if (canMoveRight()) {
    firstVisibleColumnIndex = getFirstVisibleColumnIndex() + 1;
  } else {
    firstVisibleColumnIndex = 0;
  }

  setButtonsVisibility();
  setResources();
}

export function rollLeft() {
  if (!canMoveRight() && !canMoveLeft()) {
    return;
  }

  if (canMoveLeft()) {
    firstVisibleColumnIndex = getFirstVisibleColumnIndex() - 1;
  } else {
    firstVisibleColumnIndex = Math.max(totalColumnCount() - getMaxVisibleColumnCount(), 0);
  }

  setButtonsVisibility();
  setResources();
};

function moveRight() {
  if (!canMoveRight()) {
    return;
  }

  rollRight();
}

function moveLeft() {
  if (!canMoveLeft()) {
    return;
  }

  rollLeft();
}

function initCallbacks() {
  $('#plants-navigation-right').on('click', () => {
    moveRight();
  });

  $('#plants-navigation-left').on('click', () => {
    moveLeft();
  });
}

function updateNavigationVisibility() {
  if (needNavigation()) {
    if (!isNavigationVisible()) { $(navigationSelector).removeClass('hidden'); }
    setButtonsVisibility();
  } else if (isNavigationVisible()) { $(navigationSelector).addClass('hidden'); }
}

export function initNavigation() {
  if ($('.plants-navigation').length === 0) {
    $('.fc-col-header').append(
      '<div class="plants-navigation hidden">'
      + '<div><div class="navigation-item plants-navigation-button" id="plants-navigation-left">&#60;</div></div>'
      + '<div><div class="navigation-item plants-navigation-button" id="plants-navigation-right">&#62;</div></div>'
      + '</div>',
    );
    initCallbacks();
  }
  updateNavigationVisibility();
}

export function updateNavigation() {
  firstVisibleColumnIndex = 0;

  updateNavigationVisibility();
}

export function getVisiblePlantIds() {
  const visiblePlantIds =  [];
  for (let columnId = 0; columnId < getMaxVisibleColumnCount(); columnId++) {
    const visiblePlantId = visibleColumnIndexToPlantId(columnId);
    if (visiblePlantId) {
      visiblePlantIds.push(parseInt(visiblePlantId));
    }
  }

  return [...new Set(visiblePlantIds)];
}

function addPlantDataDivToContainer(plantsInfo, index, id, plantName, dayOffset = 0, plantInfoJson = '{}') {
  plantsInfo.append(`<div class="hidden" data-column="${index}" data-plant-id="${id
  }" data-plant-name="${plantName}" data-day-offset="${dayOffset}"></div>`);
}

export function refreshEnabledPlants(){
  const prefs = new UserPreferences();
  const plantSelection = prefs.fetch('plantSelection');
  const projectId = $('.plan_section').data('projectId');

  const previouslyEnabledPlantIds = getEnabledPlantIds();

  if (plantSelection[projectId]) {
    const plantIds = plantSelection[projectId];
    const plantsInfo = $('#enabled-plants-info');

    plantsInfo.empty();
    plantIds.forEach((id, i) => {
      let plantName;
      const plantsInfoDataNodes = $('#plants-info').children();

      for (let index = 0; index < plantsInfoDataNodes.length; index++) {
        if (plantsInfoDataNodes[index].dataset.plantId === id) {
          plantName = plantsInfoDataNodes[index].dataset.plantName;
        }
      }
      addPlantDataDivToContainer(plantsInfo, i, id, plantName);
    });
  }

  if (plantPlanner) {  
    plantPlanner.refetchResources();
  };
}

export function getPlantInfo(plantId) {
  const plant = $('#plants-info > div[data-plant-id="' + plantId + '"]')[0];
  const project = $('#projects-info > div[data-project-id="' + plant.dataset.projectId + '"]')[0];
  
  const parsedPlantJSON = JSON.parse(plant.dataset.plantInfoJson);
  const parsedProjectJSON = JSON.parse(project.dataset.projectInfoJson);

  return {...parsedProjectJSON, ...parsedPlantJSON};
}

export function calculateWorkingHours(duration, days) {
  let earliestWorkingTime = "24:00";
  let latestWorkingTime = "00:00";
  getEnabledPlantResources().forEach((resource) => {
    const plantInfo = getPlantInfo(resource.id);
    let durationAsDate = new Date(duration);
    for (let dayIterator = 0; dayIterator < days; dayIterator++){
      const dateAsString = format(durationAsDate, 'yyyy-MM-dd');
      let workingTimes = plantInfo.working_times[dateAsString];
      if (!workingTimes) {
        workingTimes = { start_time: "00:00", end_time: "24:00" };
      }
      if (workingTimes.start_time < earliestWorkingTime) {
        earliestWorkingTime = workingTimes.start_time;
      }
      if (workingTimes.end_time > latestWorkingTime) {
        latestWorkingTime = workingTimes.end_time;
      }
      durationAsDate = addDays(durationAsDate, 1);
    }
  });

  return { earliestWorkingTime, latestWorkingTime };
}

export function getEvents(fetchInfo, successCallback, failureCallback, postSuccessCallback) {
  var thisProjectId = $('.plan_section').data('projectId');

  $.ajax({
    url: '/projects/' + thisProjectId + '/plant_calendar_entries.json',
    type: 'GET',
    data: {
      start: fetchInfo.startStr,
      end: fetchInfo.endStr,
      private: true,
      plant_ids: getEnabledPlantIds()
    },
    dataType: 'json',
    success(response) {
     lastWeatherFetch = response.projects;
     successCallback(response);
     postSuccessCallback();
    },
    error(err) {
      failureCallback(err);
    },
  });
}

export function getWeatherForProjectId(projectId) {
  if (!lastWeatherFetch) return null;

  const projectInfo = lastWeatherFetch[`${projectId}`];
  const weatherInfo = projectInfo?.weather;
  return weatherInfo;
}

function createLocalTimeFromUTCTime(utcTime, offset = 0) {
  return new Date(new Date(utcTime.toISOString().slice(0, 19)).getTime() + offset);
}

export function calculateVisibleDaysForCalendar(calendar) {
  if (!calendar) return [];

  const startDateTime = calendar.view.currentStart;
  const endDateTime = calendar.view.currentEnd;

  var startDateTimeRepresentedInLocalTime = createLocalTimeFromUTCTime(startDateTime);
  var endDateTimeRepresentedInLocalTime = createLocalTimeFromUTCTime(endDateTime, -1);

  var intervals = eachDayOfInterval({ start: startDateTimeRepresentedInLocalTime, end: endDateTimeRepresentedInLocalTime });

  const intervalsAsDateString = intervals.map((interval) => format(interval, 'yyyy-MM-dd'));

  return intervalsAsDateString;
}