import { clickEventType } from './helpers';

$(document).ready(function () {
  $('#new_location').on(clickEventType, function () {
    var projectId = $('table.location').data('projectId');
    $.ajax({
      url: '/projects/' + projectId + '/locations/new.js',
      type: 'GET',
      success: function () {
        $('#location_form').dialog({
          modal: true, title: 'New location', zIndex: 10000, autoOpen: true,
          width: 'auto', resizable: false,
          close: function () {
            $(this).dialog('destroy');
          }
        });
      }
    });
  });


  $('.location_item').on(clickEventType, function () {
    var locationId = $(this).data('id');
    var projectId = $('table.location').data('projectId');
    $.ajax({
      url: '/projects/' + projectId + '/locations/' + locationId + '/edit.js',
      type: 'GET',
      success: function () {
        $('#location_form').dialog({
          modal: true, title: 'Edit location', zIndex: 10000, autoOpen: true,
          width: 'auto', resizable: false,
          close: function () {
            $(this).dialog('destroy');
          }
        });
      }
    });
  });
});
