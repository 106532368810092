import { followLink, openAreYouSurePopup } from '../helpers';
import {
  getCurrentStepFromProgressBar,
  loadStepForEmployeeCreation,
  showCurrentStepCallback,
  saveCurrentStepFilledInfo,
  showFinalStepCallback,
  updatePaydateOptions,
  createEmployeeWithPayrate,
  showPopupWithImportResults,
  showAdditionalTextField,
} from './helpers';
import { showFlashMessage } from '../flash';

$(document).ready(() => {
  $(() => { styleNewEmployeeForm(); });

  $(document).on('click', '#new-employee-form.multistep-form .next-step', function () {
    if ($(this).hasClass('skip')) { return; }
    const currentStep = getCurrentStepFromProgressBar();
    const lastStep = parseInt($('#total-steps').text(), 10);
    const nextStep = currentStep + 1;
    const callback = nextStep === lastStep ? showFinalStepCallback : showCurrentStepCallback;
    saveCurrentStepFilledInfo(currentStep);
    loadStepForEmployeeCreation(nextStep, callback, 'forward');
  });

  $(document).on('click', '#new-employee-form.multistep-form .prev-step', () => {
    const currentStep = getCurrentStepFromProgressBar();
    if (currentStep === 1) { return; }
    const prevStep = currentStep - 1;
    saveCurrentStepFilledInfo(currentStep);
    loadStepForEmployeeCreation(prevStep, showCurrentStepCallback, 'back');
  });

  $(document).on('keyup', '#new-employee-form #employee_attribute, #new-employee-form #additional-text-field', updateNextStepButton);

  $(document).on('click', '#new-employee-form .select-option', function () {
    $('#new-employee-form #additional-text-field').remove();
    const multiselect = $(this).closest('.multiselect-container').data('multiselect');
    if (multiselect) {
      $(this).toggleClass('selected');
    } else {
      $('.select-option.selected').removeClass('selected');
      $(this).addClass('selected');
      $('.next-step').data('mandatoryField', true);
    }
    if ($(this).data('optionValue') === -1) { showAdditionalTextField(); }
    updateNextStepButton();
  });

  $(document).on('click', '#new_employee_form #toggle-advanced-section', () => {
    $('#new_employee_form #advanced-section').toggle();
  });

  $(document).on('click', '#new_employee_form #bulk-upload-template', () => {
    followLink('/employees/bulk_upload_template');
  });

  $(document).on('click', '#new_employee_form #upload-employees', () => {
    $('#new_employee_form #file').fileupload({
      url: '/employees/bulk_upload.json',
      add(e, data) {
        data.submit()
          .success((jsonData) => {
            showPopupWithImportResults(jsonData);
          })
          .error(() => {
            showFlashMessage('Something went wrong');
          });
      },
    });
    $('#new_employee_form #file').click();
  });

  $(document).on('click', '#new-employee-form #create-employee', () => {
    const callback = function () {
      followLink('/employees');
    };
    createEmployeeWithPayrate(callback);
  });

  $(document).on('click', '#new-employee-form #add-another-employee', () => {
    const callback = function () {
      $('#new-employee-form').removeData();
      loadStepForEmployeeCreation(1, showCurrentStepCallback);
    };
    createEmployeeWithPayrate(callback);
  });

  $(document).on('change', '.final-step-content #rate-unit', () => {
    updatePaydateOptions();
  });

  $(document).on('click', '.next-step.skip', () => {
    const currentStep = getCurrentStepFromProgressBar();
    const lastStep = parseInt($('#total-steps').text(), 10);
    saveCurrentStepFilledInfo(currentStep);
    loadStepForEmployeeCreation(lastStep, showFinalStepCallback, 'forward');
  });

  $(document).on('click', '.bottom-bar .nav-button.cancel', () => {
    const successCallback = function () { followLink('/employees'); };
    openAreYouSurePopup(successCallback);
  });

  $(document).on('click', '#new_employee_form #employee-fields-setup-mode', function () {
    $('.advanced-employee-fields').toggle();
    const advancedModeIsShown = $('.advanced-employee-fields').is(':visible');
    const text = advancedModeIsShown ? 'Simple setup' : 'Advanced setup';
    $(this).text(text);
  });

  $(document).on('change', '#employee_photo', (event) => {
    const submitPhotoButton = $('.submit--photo-form');
    if (event.target.files.length === 0) {
      submitPhotoButton.hide();
    } else {
      submitPhotoButton.show();
    }
  });

  window.onChangeCompanyId = (companyName) => {
    $.ajax({
      url: '/collections/company_competencies.json',
      type: 'GET',
      data: { company_name: companyName },
      success(response) {
        const select = $('#employee_competency_ids');
        select.attr('disabled', false).trigger('chosen:updated');
        select.empty();
        response.company_competencies.forEach((competency) => {
          select.append($('<option></option>').attr('value', competency.value).text(competency.text));
        });
        select.trigger('chosen:updated');
      },
    });
  };
});
