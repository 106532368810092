import {
  clickEventType,
  getProjectIdFromSubcontractorPlanPage,
  showSpinner,
  fullscreenModeEnabled,
  updateWorkingDayAdjustment,
  changeDateByDays,
  adjustPlanViewWidth,
  toggleSubcontractorColumn,
  toggleZoomButtons,
  setDefaultScaleForPlanView,
  mergeRowsForSubcontractorPlan
} from './helpers';
import { showFlashMessage } from './flash';

$(document).ready(function () {
  $('#week_start_date').datepicker({
    firstDay: 1,
    dateFormat: 'dd/mm/yy',
    beforeShowDay: function (date) {
      return [date.getDay() === 1];
    }
  });

  function getDateObjectFromString(dateStr) {
    var date = dateStr.split('/');
    return new Date(date[2], date[1] - 1, date[0]);
  }

  function getMinDateForWeekEndInput() {
    var minDateStr = $('#week_start_date').val();
    var minDate = getDateObjectFromString(minDateStr);
    minDate.setDate(minDate.getDate() + 6);
    return minDate;
  }


  $(function () {
    if ($('#plan_view').length) {
      var minDate = getMinDateForWeekEndInput();
      $('#week_end_date').datepicker({
        firstDay: 1,
        dateFormat: 'dd/mm/yy',
        beforeShowDay: function (date) {
          return [date.getDay() === 0];
        },
        minDate: minDate
      });
    }
  });

  $(document).on('change', '#week_start_date', function () {
    var minDate = getMinDateForWeekEndInput();
    $('#week_end_date').datepicker('option', 'minDate', minDate);
  });

  $(document).on(clickEventType, '#left-arrow', function () {
    $('#plan_cal_dropdown').val('').trigger('chosen:updated');
    changeWeekRangeByDays(-7);
  });

  function changeWeekRangeByDays(daysShift) {
    var startDate = getWeekStartDate();
    var endDate = getWeekEndDate();
    var newStartDate = changeDateByDays(startDate, daysShift);
    var newOldDate = changeDateByDays(endDate, daysShift);
    var formattedNewStartDate = $.datepicker.formatDate('dd/mm/yy', newStartDate);
    var formattedNewEndDate = $.datepicker.formatDate('dd/mm/yy', newOldDate);
    $('#week_start_date').val(formattedNewStartDate).trigger('change');
    $('#week_end_date').val(formattedNewEndDate);
    drawCMATable(7);
  }

  $(document).on(clickEventType, '#right-arrow', function () {
    $('#plan_cal_dropdown').val('').trigger('chosen:updated');
    changeWeekRangeByDays(7);
  });

  function getWeekStartDate() {
    return $('#week_start_date').val();
  }

  function getWeekEndDate() {
    return $('#week_end_date').val();
  }

  $(function () { if ($('#plan_view').length === 1) { drawCMATable(7); } });
  $(document).on(clickEventType, '#apply_plan_main_parameters', function () {
    var daysCount = getDaysCount();
    drawCMATable(daysCount);
  });

  function getDaysCount() {
    var weekStartStr = $('#week_start_date').val();
    var weekEndStr = $('#week_end_date').val();
    var weekStartDate = getDateObjectFromString(weekStartStr);
    var weekEndDate = getDateObjectFromString(weekEndStr);
    return daysBetween(weekStartDate, weekEndDate);
  }

  function daysBetween(date1, date2) {
    var diffInMilliSeconds = date2 - date1;
    return Math.round(diffInMilliSeconds / 1000 / 3600 / 24) + 1;
  }

  $(document).on('submit', '#working-adjustment-popup[data-plan-view-page=true] #adjustment_form', function (e) {
    e.preventDefault();
    var route = '/working_day_adjustments';
    var callback = function () { drawCMATable(7); };
    updateWorkingDayAdjustment($(this), 'POST', route, callback);
  });

  $(document).on('submit', '#edit_wda_popup[data-plan-view-page=true] #adjustment_form', function (e) {
    e.preventDefault();
    var id = $(this).find('#id').val();
    var route = '/working_day_adjustments/' + id;
    var callback = function () { drawCMATable(7); };
    updateWorkingDayAdjustment($(this), 'PUT', route, callback);
  });

  function drawCMATable(daysToDisplay) {
    if ($('#plan_view').length !== 1) { return; }
    var projectId = getProjectIdFromSubcontractorPlanPage();
    var subprojectId = $('#subproject_dropdown').val();
    var weekStart = getWeekStartDate();
    var locationIds = $('#plan_locations_dropdown').val();
    var contractorIds = $('#plan_contractors_dropdown').val();
    var activityIds = $('#plan_activities_dropdown').val();
    var calId = $('#plan_cal_dropdown').val();
    var hideOrdering = !!locationIds || !!contractorIds || !!activityIds;
    var currentDaysCount = $('.contr_main_act_day').length;
    var daysCount = daysToDisplay || currentDaysCount || 7;
    var calsSelected = collectSelectedContrActLocs();
    if (subprojectId) {
      showSpinner('#cmas_blocking_area');
      $.ajax({
        url: '/contr_main_acts.js',
        data: {
          'subproject_id': subprojectId,
          'week_start': weekStart,
          'project_id': projectId,
          'hide_ordering': hideOrdering,
          'location_ids': locationIds,
          'contractor_ids': contractorIds,
          'activity_ids': activityIds,
          'contr_act_loc_id': calId,
          'days_count': daysCount
        },
        dataType: 'html',
        success: function (data) {
          $('#contr_main_acts').html(data);
          $('#contr_main_acts table').tableHeadFixer({ 'left': 3, 'head': true });
          $('.scrollable-table').css('overflow-y', 'scroll');
          mergeRowsForSubcontractorPlan();
          if ($(window).width() < 768) {
            adjustPlanViewWidth();
          }
          setDefaultScaleForPlanView();
          listJobs();
          $('.chosen-select').chosen({ width: 'auto' });
          $('.chosen-cal').chosen({
            allow_single_deselect: true,
            width: 'auto'
          });
          if (calId) {
            $.ajax({
              url: '/contr_act_locs/' + calId,
              type: 'GET',
              dataType: 'json',
              success: function (data) {
                $('#week_start_date').val(data.week_start);
              }
            });
          }
          if (calsSelected.length) {
            markContrActLocsAsSelected(calsSelected);
            $('#new-plan-contr-act, #cma_order_popup_link, .add_cal_button').toggle();
            $('#left-arrow, #add_previous_week, #add_next_week, #remove_next_week, #remove_previous_week, #right-arrow').toggle();
            $('#plan_filters').toggle();
          }
          if (fullscreenModeEnabled()) {
            $('.scrollable-table').css('max-height', '100%');
            $('#plan_view_navigation_section, #plan_filters').hide();
          }
          $('.spinner').hide();
        },
        error: function () {
          $('.spinner').hide();
        }
      });
    } else {
      var emptyCmas = '<div> <p> There are no matching activities. Choose filter. </p> </div>';
      $('#contr_main_acts').html(emptyCmas);
      $('.spinner').hide();
    }
  }

  $(document).on(clickEventType, '#plan_apply_filter', function () {
    $('#plan_cal_dropdown').val('').trigger('chosen:updated');
    drawCMATable();
  });

  $(document).on(clickEventType, '#plan_cal_filter', function () {
    $('#plan_locations_dropdown').val([]).trigger('chosen:updated');
    $('#plan_contractors_dropdown').val([]).trigger('chosen:updated');
    $('#plan_activities_dropdown').val([]).trigger('chosen:updated');
    drawCMATable(7);
  });

  $(document).on('ajax:complete', '#main_form, #delete_cal, #new_contr_main_act, #contr_main_act_form, #delete_cma', function () {
    drawCMATable();
  });

  $(document).on('dialogclose', '#contr-act-loc-form', function () {
    if ($("[name='contr_act_loc_detail[status]']").data('reload-flag')) {
      drawCMATable();
    }
  });

  $(document).on(clickEventType, '#subcontract_projects', function () {
    showSpinner();
    $.ajax({
      url: '/projects.js',
      context: document.body,
      dataType: 'html',
      data: {'subcontracts': 'true'},
      success: function (data) {
        $('.spinner').hide();
        $(document.body).append('<div id="project_popups" style="display:none"></div>');
        $('#project_popups').html(data);
        $('#project_popups').dialog({
          modal: true, title: 'Select project',
          autoOpen: true, resizable: false, autoSize: true,
          open: function () {
            $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
          },
          close: function () {
            $(this).dialog('close');
          }
        });
      },
      error: function () {
        $('.spinner').hide();
      }
    });
  });

  $(document).on(clickEventType, '#contr_main_acts #cma_order_popup_link', function () {
    $('#cma_order_popup').dialog({
      modal: true, title: 'Activities order', autoOpen: true,
      resize: 'none', width: 'auto',
      open: function () {
        $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
        $( '#sortable_cma' ).sortable();
        $( '#sortable_cma' ).disableSelection();
      },
      close: function () {
        $(this).dialog('destroy');
      }
    });
  });

  $(document).on(clickEventType, '#cma_order_popup #save_cma_order_button', function () {
    var cmas = [];
    $('#cma_order li').each(function () {
      cmas.push($(this).data('order'));
    });
    var projectId = getProjectIdFromSubcontractorPlanPage();
    var subprojectId = $('#subproject_dropdown').val();
    $.ajax({
      url: '/contr_main_acts/reorder',
      type: 'POST',
      beforeSend: function () {
        $('#cma_order_popup').dialog('destroy');
        showSpinner();
      },
      data: {
        project_id: projectId,
        subproject_id: subprojectId,
        cma_ids: cmas
      },
      success: function () {
        drawCMATable();
      },
      error: function () {
        $('.spinner').hide();
      }
    });
  });

  $(document).on('submit', '#cma_order_form', function (e) {
    e.preventDefault();
  });

  function listJobs() {
    if (('#calculation_jobs').length) {
      var subprojectId = $('#subproject_dropdown').val();
      $.ajax({
        type: 'GET',
        dataType: 'html',
        url: '/calculation_jobs.js',
        data: { subproject_id: subprojectId },
        success: function (data) {
          $('#calculation_jobs').html(data);
          processJob(subprojectId);
        }
      });
    }
  }

  function processJob(subprojectId) {
    var jobEl = $('#calculation_jobs #job_message');
    if (jobEl.length) {
      var jobId = jobEl.data('jobId');
      fetchJob(jobId, drawCMATable, subprojectId);
    }
  }

  function fetchJob(jobId, successCallback, subprojectId) {
    function fetchJobStatus() {
      if (subprojectId !== $('#subproject_dropdown').val()) {
        return;
      }
      var fetchTimeout = 3000;
      $.ajax({
        type: 'GET',
        url: '/calculation_jobs/fetch/' + jobId,
        dataType: 'text',
        statusCode: {
          200: function () {
            if ($('#contr_main_acts').length) {
              successCallback();
            }
          },
          202: function() { setTimeout(fetchJobStatus, fetchTimeout); },
          500: function() { console.log('Job failed'); }
        }
      });
    };
    fetchJobStatus();
  }

  $(document).on(clickEventType, '#add_next_week', function () {
    var daysCount = $('.contr_main_act_day').length + 7;
    drawCMATable(daysCount);
  });

  $(document).on(clickEventType, '#remove_next_week', function () {
    var daysCount = $('.contr_main_act_day').length - 7;
    drawCMATable(daysCount);
  });

  $(document).on(clickEventType, '#add_previous_week', function () {
    var startDate = getWeekStartDate();
    var date = changeDateByDays(startDate, -7);
    var formattedDate = $.datepicker.formatDate('dd/mm/yy', date);
    var daysCount = $('.contr_main_act_day').length + 7;
    $('#week_start_date').val(formattedDate);
    drawCMATable(daysCount);
  });

  $(document).on(clickEventType, '#remove_previous_week', function () {
    var daysCount = $('.contr_main_act_day').length;
    if (daysCount > 7) {
      var startDate = getWeekStartDate();
      var date = changeDateByDays(startDate, 7);
      var formattedDate = $.datepicker.formatDate('dd/mm/yy', date);
      $('#week_start_date').val(formattedDate);
    } else {
      daysCount -= 7;
    }
    drawCMATable(daysCount);
  });

  $(document).on(clickEventType, '#mass-edit-link', function () {
    toggleMassEditMode();
  });

  $(document).on(clickEventType, '#mass-edit-exit', function () {
    toggleMassEditMode();
  });

  $(document).on(clickEventType, '#mass-edit-complete', function () {
    if ($('.selected_cal').length <= 0 ) {
      showFlashMessage('Choose activities first.', 'danger');
      return;
    }
    var cals = collectSelectedContrActLocs();
    var subprojectId = $('#subproject_dropdown').val();
    showSpinner();
    $.ajax({
      type: 'POST',
      url: '/contr_act_locs/batch_update.js',
      data: { contr_act_loc_ids: cals, type: 'complete', subproject_id: subprojectId },
      dataType: 'json',
      success: function (data) {
        var res = data.status;
        if (res === 'ok') {
          showFlashMessage('Activities have been completed.', 'success');
          markSelectedContrActLocsCompleted();
        } else {
          showFlashMessage(res);
        }
        $('.spinner').hide();
      },
      error: function () {
        unselectContrActLocs();
        $('.spinner').hide();
      }
    });
  });

  $(document).on(clickEventType, '#mass-edit-delete', function () {
    if ($('.selected_cal').length <= 0 ) {
      noActivitiesSelectedFlash();
      return;
    }
    var cals = collectSelectedContrActLocs();
    var subprojectId = $('#subproject_dropdown').val();
    showSpinner();
    $.ajax({
      type: 'POST',
      url: '/contr_act_locs/batch_delete.js',
      data: { contr_act_loc_ids: cals, subproject_id: subprojectId },
      dataType: 'json',
      success: function (data) {
        var res = data.status;
        if (res === 'ok') {
          showFlashMessage('Activities have been deleted.', 'success');
          toggleMassEditMode();
          drawCMATable();
        } else {
          showFlashMessage(res);
        }
        $('.spinner').hide();
      },
      error: function () {
        unselectContrActLocs();
        $('.spinner').hide();
      }
    });
  });

  $(document).on(clickEventType, '#mass-edit-incomplete', function () {
    if ($('.selected_cal').length <= 0 ) {
      noActivitiesSelectedFlash();
      return;
    }
    var cals = collectSelectedContrActLocs();
    var subprojectId = $('#subproject_dropdown').val();
    showSpinner();
    $.ajax({
      type: 'POST',
      url: '/contr_act_locs/batch_update.js',
      data: { contr_act_loc_ids: cals, type: 'incomplete', subproject_id: subprojectId },
      dataType: 'json',
      success: function (data) {
        var res = data.status;
        if (res === 'ok') {
          showFlashMessage('Activities have been incompleted.', 'success');
          drawCMATable();
          markContrActLocsAsSelected(cals);
        } else {
          showFlashMessage(res);
        }
        $('.spinner').hide();
      },
      error: function () {
        unselectContrActLocs();
        $('.spinner').hide();
      }
    });
  });

  $(document).on(clickEventType, '#mass-edit-comment', function () {
    if ($('.selected_cal').length <= 0 ) {
      noActivitiesSelectedFlash();
      return;
    }
    $('#mass_comment_form').dialog({
      modal: true, title: 'Mass comments',
      width: 'auto', resizable: false, autoOpen: true,
      close: function () {
        $('#mass_comments').html('');
        $('#mass-comment-errors').html('');
        $(this).dialog('destroy');
      }
    });
  });

  $(document).on('keypress', '#mass_comment', function (e) {
    if (e.which === 13) {
      var message = $('#mass_comment').val();
      var cals = collectSelectedContrActLocs();
      var subprojectId = $('#subproject_dropdown').val();
      showSpinner();
      $.ajax({
        type: 'POST',
        url: '/contr_act_locs/batch_update.js',
        data: { contr_act_loc_ids: cals, type: 'comment', message: message, subproject_id: subprojectId },
        dataType: 'json',
        success: function (data) {
          var res = data.status;
          if (res === 'ok') {
            var commentHtml = buildComment(data);
            $('#mass_comment').val('');
            $('#mass_comments').append(commentHtml).fadeIn();
          } else {
            var messageHtml = "<div class='alert-danger'>" + res + '</div>';
            $('#mass-comment-errors').html(messageHtml);
            setTimeout(function () {
              $('#mass-comment-errors').html('');
            }, 3000);
          }
          $('.spinner').hide();
        },
        error: function () {
          unselectContrActLocs();
          $('.spinner').hide();
        }
      });
    }
  });

  function toggleMassEditMode() {
    $('#plan_advanced_link, #plan_advanced, #mass-edit-flash, #mass-edit-buttons, #plan_filters_section_with_separator').toggle();
    $('#new-plan-contr-act, #cma_order_popup_link, .add_cal_button').toggle();
    $('#left-arrow, #add_previous_week, #add_next_week, #remove_next_week, #remove_previous_week, #right-arrow, #plan_builder_link, #mass-edit-link').toggle();
    toggleFilters();
    toggleZoomButtons();
    setDefaultScaleForPlanView();
    unselectContrActLocs();
  }

  function unselectContrActLocs() {
    $('.selected_cal').each(function () {
      $(this).toggleClass('selected_cal');
      $(this).css('color', '');
      $(this).css('font-weight', '');
    });
  }

  function collectSelectedContrActLocs() {
    var res = [];
    $('.selected_cal').each(function () {
      res.push($(this).parent().data('calId'));
    });
    return res;
  }

  function markSelectedContrActLocsCompleted() {
    $('.selected_cal').each(function () {
      $(this).css('background', '#73C64C');
    });
  }


  function markContrActLocsAsSelected(cals) {
    cals.forEach(function (el) {
      var calEl = $('[data-cal-id=' + el + '] .location_td');
      calEl.addClass('selected_cal');
      calEl.css('color', '#73C64C');
      calEl.css('font-weight', 'bold');
    });
  }

  function noActivitiesSelectedFlash() {
    showFlashMessage('Choose activities first.', 'danger');
  }

  function buildComment(data) {
    return '<div><hr/><strong>' + data.full_name + '</strong> <small>' + data.created_at + '</small> <p>' + data.message + '</p> </div>';
  }

  $(document).on(clickEventType, '#fullscreen_planview', function () {
    $('#main-window').removeClass('pushed');
    $('.main-content').css('padding', '0px 0px');
    $('.scrollable-table').css('max-height', '100%');
    $('#sidebar').hide();
    toggleFullscreen();
  });

  $(document).on(clickEventType, '#fullscreen-exit', function () {
    $('#main-window').addClass('pushed');
    $('.main-content').css('padding', '50px 20px');
    $('.scrollable-table').css('max-height', '80vh');
    if ($(document).width() > 769) {
      $('#sidebar').show();
    }
    toggleFullscreen();
  });

  function toggleFullscreen() {
    toggleFilters();
    $('#search-cal-filter, #user-issues-button').toggle();
    $('h1, .navbar-top').toggle();
    $('#plan_advanced_link, #plan_advanced, #mass-edit-link, #plan_view_pdf_report, #plan_builder_link, #new-plan-contr-act, #plan_filters_section_with_separator').toggle();
    $('#fullscreen_planview, #fullscreen-mode-flash').toggle();
    setDefaultScaleForPlanView();
    toggleZoomButtons();
  }

  function toggleFilters() {
    $('#plan_view_navigation_section').toggle();
    if (!$('#plan_view_navigation_section').is(':visible')) {
      $('#plan_filters').hide();
      $('#plan_filters_break_element').hide();
      $('#plan_filters_separator').show();
    }
  }

  $(document).on(clickEventType, '#mass-edit-duration', function () {
    if ($('.selected_cal').length <= 0 ) {
      noActivitiesSelectedFlash();
      return;
    }
    $('#mass_duration_form').dialog({
      modal: true, title: 'Change multiple durations',
      width: 'auto', resizable: false, autoOpen: true,
      close: function () {
        $('#mass_duration').val(1);
        $(this).dialog('destroy');
      }
    });
  });

  $(document).on(clickEventType, '#mass-duration-save', function (e) {
    e.preventDefault();
    var duration = $('#mass_duration').val();
    var cals = collectSelectedContrActLocs();
    var subprojectId = $('#subproject_dropdown').val();
    showSpinner();
    $('#mass_duration').val(1);
    $('#mass_duration_form').dialog('destroy');
    $.ajax({
      type: 'POST',
      url: '/contr_act_locs/batch_update.js',
      data: { contr_act_loc_ids: cals, type: 'duration', duration: duration, subproject_id: subprojectId },
      dataType: 'json',
      success: function (data) {
        var res = data.status;
        if (res === 'ok') {
          showFlashMessage('Duration for selected activities has been changed.', 'success');
          markContrActLocsAsSelected(cals);
          listJobs();
        } else {
          showFlashMessage(res);
        }
        $('.spinner').hide();
      },
      error: function () {
        unselectContrActLocs();
        $('.spinner').hide();
      }
    });
  });

  $(document).on(clickEventType, '#mass-edit-contractor', function () {
    if ($('.selected_cal').length <= 0 ) {
      noActivitiesSelectedFlash();
      return;
    }
    var cals = collectSelectedContrActLocs();
    $('#mass_contractor_form').dialog({
      modal: true, title: 'Change multiple contractors',
      width: 'auto', resizable: false, autoOpen: true,
      open: function () {
        $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
        getSubcontractors(cals);
      },
      close: function () {
        $('#mass_subcontractors_section').html('');
        $(this).dialog('destroy');
      }
    });
  });

  function getSubcontractors(cals) {
    var subprojectId = $('#subproject_dropdown').val();
    $.ajax({
      type: 'POST',
      url: '/contr_act_locs/subcontractors',
      data: {contr_act_loc_ids: cals, subproject_id: subprojectId},
      dataType: 'html',
      success: function (data) {
        $('#mass_subcontractors_section').html(data);
        $('#mass_contractor_form').dialog('option', 'position', { my: 'center', at: 'center', of: '.main-content' });
      }
    });
  }

  $(document).on(clickEventType, '#close_mass_contractors', function (e) {
    e.preventDefault();
    closeMassContractorForm();
  });

  $(document).on('change', '#contr_main_act_id', function (e) {
    e.preventDefault();
    var cmaId = $(this).val();
    var cals = collectSelectedContrActLocs();
    var subprojectId = $('#subproject_dropdown').val();
    showSpinner();
    closeMassContractorForm();
    $.ajax({
      type: 'POST',
      url: '/contr_act_locs/batch_update.js',
      data: {contr_act_loc_ids: cals, type: 'contractor', cma_id: cmaId, subproject_id: subprojectId},
      dataType: 'json',
      success: function (data) {
        var res = data.status;
        if (res === 'ok') {
          showFlashMessage('Contractor has been changed for selected activities.', 'success');
          markContrActLocsAsSelected(cals);
          listJobs();
        } else {
          showFlashMessage(res);
        }
        $('.spinner').hide();
      },
      error: function () {
        unselectContrActLocs();
        $('.spinner').hide();
      }
    });
  });

  function closeMassContractorForm() {
    $('#mass_subcontractors_section').html('');
    $('#mass_contractor_form').dialog('destroy');
  }

  $(document).on(clickEventType, '#hide-subcontractor, #show-subcontractor', function () {
    toggleSubcontractorColumn();
  });
});
