import {
  clickEventType,
  getProjectIdFromUrl,
  updateWorkingDayAdjustment
} from './helpers';

$(document).ready(function () {
  $('#wda_date').datepicker({
    dateFormat: 'dd/mm/yy'
  });
  $(document).on(clickEventType, '#new_wda', function () {
    var date = $('#wda_date').val();
    $('#adjustment_date').val(date);
    $('#working-adjustment-popup').attr('data-new-record', true).dialog({
      modal: true, title: 'Working day adjustment', autoOpen: true,
      resize: 'none',
      open: function () {
        $(this).parents('.ui-dialog').attr('tabindex', -1)[0].focus();
        $('.chosen-select').chosen({ width: 'auto' });
        $(this).find(':submit').removeAttr('disabled');
      },
      close: function () {
        $('#activities').hide();
        $(this).find('#adjustment_form')[0].reset();
        $(this).dialog('destroy');
      }
    });
  });

  $(document).on(clickEventType, '#working_day_adjustments tr', function () {
    var projectId = getProjectIdFromUrl();
    var id = $(this).data('id');
    var date = $(this).data('date');
    $.ajax({
      url: '/working_day_adjustments/' + id + '/edit',
      dataType: 'html',
      data: { project_id: projectId },
      type: 'GET',
      success: function (data) {
        $('#edit_wda_popup').html(data);
        $('#edit_wda_popup #adjustment_date').val(date);
        $('#edit_wda_popup').dialog({
          modal: true, title: 'Edit working day adjustment',
          width: 'auto', resizable: false, autoOpen: true,
          open: function () {
            $(this).find('#adjustment_type_dropdown').trigger('change');
            $('.chosen-select').chosen({ width: 'auto' });
          },
          close: function () {
            $(this).dialog('destroy');
          }
        });
      }
    });
  });

  function copyWdaDateToHiddenField() {
    var date = $('#wda_date').val();
    $('#adjustment_date').val(date);
  }

  $(document).on('submit', '#working-adjustment-popup[data-wdas-page=true] #adjustment_form', function (e) {
    e.preventDefault();
    var route = '/working_day_adjustments';
    copyWdaDateToHiddenField();
    updateWorkingDayAdjustment($(this), 'POST', route, callbackAfterUpatingWdaOnIndexPage);
  });

  $(document).on('submit', '#edit_wda_popup[data-wdas-page=true] #adjustment_form', function (e) {
    e.preventDefault();
    var id = $(this).find('#id').val();
    var route = '/working_day_adjustments/' + id;
    copyWdaDateToHiddenField();
    updateWorkingDayAdjustment($(this), 'PUT', route, callbackAfterUpatingWdaOnIndexPage);
  });

  function callbackAfterUpatingWdaOnIndexPage(data) {
    $('#working_day_adjustments').html(data);
    var date = $.datepicker.formatDate('dd/mm/yy', new Date());
    $('#wda_date').val(date);
    $('#adjustment_form')[0].reset();
  }
});
