$(document).on('change', '.next_step_completion_requirement_id', function (event) {
  $.ajax({
    url: '/completion_steps',
    type: 'GET',
    dataType: 'json',
    data: { 'completion_requirement_id': $(event.target).val() },
    success: function (response) {
      var select = $(event.target).siblings(".next_step_completion_step_id");
      select.empty();
      $.each(response['completion_steps'], function(index, step) {
        select.append($("<option></option>").attr("value", step['id']).text(step['question']));
      });
    }
  });
});

$(document).on('focusout', '.step_answer_option', function (event) {
  var stepForm = $(event.target).parent().parent();
  var nextStepAnswer = stepForm.find(".next_step_answer");
  var answerOptions = stepForm.find(".step_answer_option");
  var selectedValue = nextStepAnswer.val();
  nextStepAnswer.empty();
  $.each(answerOptions, function(index, option) {
    var answer = $(option).val();
    nextStepAnswer.append($("<option></option>").attr("value", answer).text(answer));
  });
  nextStepAnswer.val(selectedValue);
});

$(document).on('change', '.step_type', function (event) {
  var answer_options = $(event.target).siblings('.step_answer_options');
  if (['InputResponse', 'NoFormResponse', 'TimeInputResponse'].includes(event.target.value)) {
    answer_options.addClass('hidden');
  } else {
    answer_options.removeClass('hidden');
  }
});

$(document).on('change', '.action_type_select', function (event) {
  var notification_options = $(event.target).siblings('.notification_options');
  if (event.target.value === 'send_notification') {
    notification_options.removeClass('hidden');
  } else {
    notification_options.addClass('hidden');
  }
});

$(document).on('change', '.notification_option_type_select', function (event) {
  var notification_options = $(event.target).siblings('.notification_option_value');
  if (event.target.value === 'always') {
    notification_options.addClass('hidden');
  } else {
    notification_options.removeClass('hidden');
  }
});

$(document).on('change', '#show-archived-requirements', function (event) {
  $.ajax({
    url: '/completion_requirements.js',
    type: 'GET',
    data: { 'show_archived': event.target.checked },
  });
});
