/* eslint-disable max-len */
import { showSpinner, hideSpinner } from './helpers';

$(document).ready(() => {
  $(document).on('click', '#new-planned-plant-activity-comment-button', function (e) {
    e.preventDefault();
    const $parent = $(this).parent().parent();
    const plannedPlantActivityId = $parent.find('#comment-planned-plant-activity-id').text() || $('.activity-entity').data('activity-id');
    const message = $parent.find('.planned-plant-activity-new-comment').val();

    if (message === '') return;

    $.ajax({
      url: '/planned_plant_activity_comments',
      type: 'POST',
      dataType: 'text',
      data: { planned_plant_activity_id: plannedPlantActivityId, message },
      success() {
        showSpinner();
        $.ajax({
          url: `/planned_plant_activities/${plannedPlantActivityId}/additional_info`,
          type: 'GET',
          success(response) {
            $('#additional_info_section').html(response);
            hideSpinner();
          },
        });
      },
    });
  });
});
