/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
/* eslint-disable no-loops/no-loops */
/* eslint-disable no-mixed-operators */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-undef */
/* eslint-disable sonarjs/no-duplicate-string */

function plannedExecutionGraph(options) {
  const { containerId } = options;
  const { data } = options;
  const { notesPresent } = options;

  /// /////////////////////////////////////////////////////////
  /// / Initial Setup /////////////////////////////////////////
  /// /////////////////////////////////////////////////////////
  // Style
  const colorScale = d3.scaleOrdinal()
    .domain(['achieved_loads', 'late_and_early_loads', 'missed_loads', 'excess_loads', 'is_planned_downtime'])
    .range(['#73C64C', '#73C64C', '#E15555', '#29434E', '#01579B']);

  // Dimension
  const container = d3.select(`#${containerId}`).classed('planned-execution-graph', true);
  const initialContainerWidth = container.node().clientWidth;
  const initialContainerHeight = container.node().clientHeight;
  const margin = {
    top: 30, right: 180, bottom: 5, left: 60,
  };

  const width = initialContainerWidth - margin.left - margin.right;
  const height = initialContainerHeight - margin.top - margin.bottom;

  // Format
  const parseTime = d3.timeParse('%Y-%m-%dT%H:%M:%S');
  const formatTime = d3.timeFormat('%-H:%M %p');

  // Container
  const svg = container.append('svg').attr('viewBox', `0 0 ${initialContainerWidth} ${initialContainerHeight}`)
    .attr('preserveAspectRatio', 'xMidYMin meet');

  const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

  /// /////////////////////////////////////////////////////////
  /// / Layout ////////////////////////////////////////////////
  /// /////////////////////////////////////////////////////////
  const taskTimes = data.graph.loads.map((d) => d.start_time);
  const taskDurations = data.graph.loads.map((d) => d.duration);
  const totalDuration = d3.sum(taskDurations);
  const taskHeightScale = d3.scaleOrdinal().domain(taskTimes)
    .range(taskDurations.map((d) => d / totalDuration * height));

  const accumulatedTaskDurations = taskDurations.reduce((accumulated, duration, index) => {
    accumulated.push(accumulated[index] + duration);
    return accumulated;
  }, [0]);
  accumulatedTaskDurations.pop();
  const taskYPositionScale = d3.scaleOrdinal().domain(taskTimes)
    .range(accumulatedTaskDurations.map((d) => d / totalDuration * height));

  const loadYPositionScale = d3.local();
  const loadHeightScale = d3.local();

  /// /////////////////////////////////////////////////////////
  /// / Chart /////////////////////////////////////////////////
  /// /////////////////////////////////////////////////////////
  // Heading
  svg.append('text').attr('class', 'graph-heading').attr('text-anchor', 'middle').attr('dy', '0.35em')
    .attr('transform', `translate(${margin.left + width / 2},${margin.top / 2})`)
    .text(data.graph.graph_heading);

  // Task
  const task = g.selectAll('.task').data(data.graph.loads, (d) => d.start_time).enter().append('g')
    .attr('transform', (d) => `translate(0,${taskYPositionScale(d.start_time)})`);

  // Task line
  task.append('line').attr('class', 'task-line').attr('x1', -margin.left).attr('x2', width + margin.right)
    .attr('stroke', '#1C1C1C');

  // Task time
  task.append('text').attr('class', 'task-time').attr('text-anchor', 'end').attr('x', -6)
    .attr('dy', '1em')
    .text((d) => formatTime(parseTime(d.start_time)));

  // Task description
  const taskDescription = task.append('g').attr('transform', `translate(${width}, 0)`)
    .attr('class', 'task-description-g').append('text')
    .attr('class', 'task-description')
    .attr('text-anchor', 'start')
    .filter((d) => !d.is_filler); // Filler tasks have no labels

  const textIndent = notesPresent ? -280 : 6;

  taskDescription.append('tspan').attr('x', textIndent).attr('dy', '1em')
    .text((d) => (d.is_planned_downtime ? d.material_name : `${d.material_name} to ${d.location_name}`));

  taskDescription.append('tspan').attr('x', (d) => (d.duration > 1 ? textIndent : null))
    .attr('dy', (d) => (d.duration > 1 ? '1em' : null))
    .text((d) => (d.is_planned_downtime ? '' : `${d.duration > 1 ? '' : ' '}(${d3.format('.5~g')(d.total_loads)}/${d3.format('.5~g')(d.planned_loads)})`));

  if (notesPresent) {
    task.append('text').attr('x', 510).attr('dy', '1em').attr('y', 40)
      .attr('font-size', 16)
      .attr('width', '420px')
      .text((d) => d.note)
      .call(wrap);
  }

  // Load
  const load = task.append('g').attr('class', 'load-g');
  let nothingToDraw = true;
  const loadRectWidth = notesPresent ? 499 : width;
  // IsPlannedDowntime Rect
  load.filter((d) => {
    if (d.is_planned_downtime) {
      nothingToDraw = false;
      return true;
    }
    return false;
  }).append('rect').attr('class', 'load-rect').attr('x', 0)
    .attr('y', 0)
    .attr('width', loadRectWidth)
    .attr('height', (d) => taskHeightScale(d.start_time))
    .attr('fill', colorScale('is_planned_downtime'));

  // Other rects
  load.filter((d) => {
    if (!d.is_planned_downtime && !d.is_filler) {
      nothingToDraw = false;
      return true;
    }
    return false;
  }).each(function (d) {
    const taskHeight = taskHeightScale(d.start_time);
    const loadTypes = ['achieved_loads', 'late_and_early_loads'];
    if (d.excess_loads > 0) {
      loadTypes.push('excess_loads');
    } else {
      loadTypes.push('missed_loads');
    }
    const loads = loadTypes.map((loadType) => d[loadType]);
    const totalLoads = d3.sum(loads);
    const currentLoadHeightScale = d3.scaleOrdinal().domain(loadTypes).range(loads.map((d) => {
      if (totalLoads === 0) return taskHeight;

      return d / totalLoads * taskHeight;
    }));
    const accumulatedLoads = loads.reduce((accumulated, accLoad, index) => {
      accumulated.push(accumulated[index] + accLoad);
      return accumulated;
    }, [0]);
    accumulatedLoads.pop();
    const currentLoadYPositionScale = d3.scaleOrdinal().domain(loadTypes).range(accumulatedLoads.map((d) => {
      if (totalLoads === 0) return 0;

      return d / totalLoads * taskHeight;
    }));
    loadHeightScale.set(this, currentLoadHeightScale);
    loadYPositionScale.set(this, currentLoadYPositionScale);
  }).selectAll('.load-rect').data(function () {
    return loadYPositionScale.get(this).domain();
  })
    .enter()
    .append('rect')
    .attr('class', 'load-rect')
    .attr('x', 0)
    .attr('y', function (d) {
      return loadYPositionScale.get(this)(d);
    })
    .attr('width', loadRectWidth)
    .attr('height', function (d) {
      return loadHeightScale.get(this)(d);
    })
    .attr('fill', (d) => colorScale(d));

  if (nothingToDraw) {
    $(`#${containerId}`).css({ color: 'black', 'text-align': 'center', 'font-size': '28px' });
    container.text('No Plan');
  }

  /// /////////////////////////////////////////////////////////
  /// / Resize ////////////////////////////////////////////////
  /// /////////////////////////////////////////////////////////
  function resize() {
    const containerWidth = container.node().clientWidth;
    const containerHeight = container.node().clientHeight;

    if (containerWidth !== 0 && containerHeight !== 0) {
      const newcontainerWidth = containerWidth - margin.left - margin.right;
      const newcontainerHeight = containerHeight - margin.top - margin.bottom;

      svg.attr('viewBox', `0 0 ${containerWidth} ${containerHeight}`);

      // Layout
      taskHeightScale.range(taskDurations.map((d) => d / totalDuration * newcontainerHeight));
      taskYPositionScale.range(accumulatedTaskDurations.map((d) => d / totalDuration * newcontainerHeight));

      // Chart
      // Heading
      svg.select('.graph-heading')
        .attr('transform', `translate(${margin.left + newcontainerWidth / 2},${margin.top / 2})`);

      // Task
      task.attr('transform', (d) => `translate(0,${taskYPositionScale(d.start_time)})`);

      // Task line
      task.select('.task-line').attr('x1', -margin.left).attr('x2', newcontainerWidth + margin.right);

      // Task description
      task.select('.task-description-g').attr('transform', `translate(${newcontainerWidth}, 0)`);

      // IsPlannedDowntime Rect
      load.filter((d) => d.is_planned_downtime).select('.load-rect')
        .attr('width', loadRectWidth).attr('height', (d) => taskHeightScale(d.start_time));

      // Other rects
      load.filter((d) => !d.is_planned_downtime && !d.is_filler).each((d) => {
        const taskHeight = taskHeightScale(d.start_time);
        const loadTypes = ['achieved_loads', 'late_and_early_loads'];
        if (d.excess_loads > 0) {
          loadTypes.push('excess_loads');
        } else {
          loadTypes.push('missed_loads');
        }
        const loads = loadTypes.map((loadType) => d[loadType]);
        const totalLoads = d3.sum(loads);
        const currentLoadHeightScale = d3.scaleOrdinal().domain(loadTypes).range(loads.map((d) => {
          if (totalLoads === 0) return taskHeight;

          return d / totalLoads * taskHeight;
        }));
        const accumulatedLoads = loads.reduce((accumulated, accLoad, index) => {
          accumulated.push(accumulated[index] + accLoad);
          return accumulated;
        }, [0]);
        accumulatedLoads.pop();
        const currentLoadYPositionScale = d3.scaleOrdinal().domain(loadTypes).range(accumulatedLoads.map((d) => {
          if (totalLoads === 0) return 0;

          return d / totalLoads * taskHeight;
        }));
        loadHeightScale.set(this, currentLoadHeightScale);
        loadYPositionScale.set(this, currentLoadYPositionScale);
      }).selectAll('.load-rect').attr('y', (d) => loadYPositionScale.get(this)(d))
        .attr('width', newcontainerWidth)
        .attr('height', (d) => loadHeightScale.get(this)(d));
    }
  }

  d3.select(window).on('resize', throttle);
  throttle(resize, 1000);

  /// /////////////////////////////////////////////////////////
  /// / Utilities /////////////////////////////////////////////
  /// /////////////////////////////////////////////////////////
  function throttle(func, wait, funcOptions) {
    let context; let args; let
      result;
    let timeout = null;
    let previous = 0;
    if (!funcOptions) funcOptions = {};
    const later = function later() {
      previous = funcOptions.leading === false ? 0 : Date.now();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };
    return function () {
      const now = Date.now();
      if (!previous && funcOptions.leading === false) previous = now;
      const remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        previous = now;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && funcOptions.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };
  }

  function wrap(text) {
    text.each(function () {
      const d3Text = d3.select(this);
      const words = d3Text.text().split(/\s+/).reverse();
      const lineHeight = 40;
      const textWidth = parseFloat(d3Text.attr('width'));
      const y = parseFloat(d3Text.attr('y'));
      const x = d3Text.attr('x');
      const anchor = d3Text.attr('text-anchor');

      let tspan = d3Text.text(null).append('tspan').attr('x', x).attr('y', y)
        .attr('text-anchor', anchor);
      let lineNumber = 0;
      let line = [];
      let word = words.pop();

      while (word) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > textWidth) {
          lineNumber += 1;
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = d3Text.append('tspan').attr('x', x).attr('y', y + lineNumber * lineHeight).attr('anchor', anchor)
            .text(word);
        }
        word = words.pop();
      }
    });
  }
}

export default plannedExecutionGraph;

window.plannedExecutionGraph = plannedExecutionGraph;
