import { clickEventType, parseTime, convertTime12to24, initializeZTimeP } from '../helpers';
import {
  timeToFloat,
  updateActivityPeriodCallback,
  updateTaskTrackedTimeCallback,
  removeTaskTrackedTimeCallback,
  validStringForTime,
  validStringForFloat,
  updateColorOfTaskClockIcon
} from './shared';

$(document).ready(function () {
  $(document).on('click', '#employee_activity_periods .start_time_span, #employee_activity_periods .finish_time_span, #employee_activity_periods .fa-stopwatch', function (e) {
    e.stopPropagation();
    if ($(this).hasClass('fa-stopwatch')) {
      var workingTimeSpan = $(this).parent();
    } else {
      var workingTimeSpan = $(this);
    }
    var popup = workingTimeSpan.closest('.ui-dialog');
    popup.hide();
    var callback = function () {
      var options = parseTime(workingTimeSpan.text());
      options.duration = false;
      options.clickEvents = {
        onSave: function(h, m, ampm) {
          popup.show();
          saveWorkingHours(workingTimeSpan, h, m, ampm);
        },
        onClose: function () { popup.show(); }
      };
      initializeZTimeP(options);
      $('.ztimep-open').trigger(clickEventType);
    };
    if (workingTimeSpan.data('needDefaultTime') === true) {
      var workingTimeType = workingTimeSpan.data('workingTimeType');
      return setDefaultForWorkingTime(workingTimeType, workingTimeSpan, callback);
    } else {
      callback();
    }
  });

  function setDefaultForWorkingTime(workingTimeType, workingTimeSpan, callback) {
    var dataAttributes = $('#tasks_popup').data();
    var data = {
      supervisor_id: dataAttributes.supervisorId,
      working_time_type: workingTimeType
    };
    $.ajax({
      url: '/planned_employee_days/default_working_time_for_employee_day',
      type: 'GET',
      data: data,
      success: function (data) {
        workingTimeSpan.data('needDefaultTime', false);
        if (data.default_time) {
          workingTimeSpan.text(data.default_time);
        }
        if (callback) { callback(); }
      }
    });
  }

  $(document).on('click', '.employee_details_container .save_task_duration', function () {
    var button = $(this);
    var assignedToTaskEmployee = button.closest('.employee_details_container').prev();
    var task = button.closest('.task_mobile');
    var taskDurationInput = button.closest('.task_duration_info').find('.tracked_time_input');
    var time = taskDurationInput.val();
    if (!validStringForTime(time)) {
      taskDurationInput.addClass('base-red-border');
      return;
    }
    var duration = timeToFloat(time);
    var activityPeriodParams = { activity_type: 'working_time', duration: duration };
    var projectId = task.data('projectId');
    var supervisorId = task.data('supervisorId');
    var taskId = assignedToTaskEmployee.data('taskId');
    var date = task.data('date');
    $.ajax({
      url: '/employee_activity_periods',
      type: 'PUT',
      data: {
        date: date,
        project_id: projectId,
        supervisor_id: supervisorId,
        task_id: taskId,
        employee_activity_period: activityPeriodParams
      },
      success: function (data) {
        if (data.status === 'ok') {
          taskDurationInput.removeClass('base-red-border');
          assignedToTaskEmployee.find('.task_tracked_time_span').text(time);
          assignedToTaskEmployee.find('.colored_employee').removeClass('base-red');
          assignedToTaskEmployee.find('.colored_employee').addClass('base-green');
          assignedToTaskEmployee.find('.fa-stopwatch').hide();
          assignedToTaskEmployee.find('.formatted_tracked_time').show();
          updateColorOfTaskClockIcon(task);
          button.hide();
        }
      }
    });
  });

  $(document).on('click', '#toggle_activity_periods', function () {
    $('#employee_activity_periods').show();
    $(this).hide();
  });

  function saveWorkingHours(spanForTime, h, m, ampm) {
    var time12h = h + ':' + m + ' ' + ampm;
    var time24h = convertTime12to24(time12h);
    spanForTime.text(time24h);
    updateActivityPeriodCallback(spanForTime);
  }

  function parseTimeDuration(duration) {
    var result = {};
    var splittedDuration = duration.split('.');
    result.hour = splittedDuration[0];
    var minutes = convertFloatToMinutes(duration).toString()
    if (minutes !== '') { minutes = minutes.padStart(2, '0'); }
    result.min = minutes;
    return result;
  }

  function convertFloatToMinutes(durationStr) {
    if (!validStringForFloat(durationStr)) { return ''; }
    var duration = parseFloat(durationStr);
    var remainder = duration % 1;
    var numberOfMinutes = remainder * 60;
    return Math.round(numberOfMinutes / 5) * 5;
  }

  $(document).on('click', '#tasks_popup .task_tracked_time_span, #tasks_popup .container_with_task_tracked_time .fa-stopwatch', function () {
    selectTaskDuration($(this), '#tasks_popup');
  });

  $(document).on('click', '#employees_popup .task_tracked_time_span, #employees_popup .container_with_task_tracked_time .fa-stopwatch', function () {
    selectTaskDuration($(this), '#employees_popup');
  });

  function selectTaskDuration(target, popupSelector) {
    var timeSpan = target.closest('.container_with_task_tracked_time').find('.task_tracked_time_span');
    var popup = timeSpan.closest('.ui-dialog');
    popup.hide();
    var saveCallback = function (h, m) {
      if (!h && !m) {
        timeSpan = target.closest('.container_with_task_tracked_time').find('.formatted_tracked_time').hide();
        removeTaskTrackedTimeCallback(timeSpan, $(popupSelector));
      }
      else {
        var time = h + ':' + m;
        timeSpan.text(time);
        timeSpan.closest('.container_with_task_tracked_time').find('.fa-stopwatch').hide();
        updateTaskTrackedTimeCallback(timeSpan, $(popupSelector));
      }
    }
    initializeZtimePForTaskDuration(timeSpan.text(), saveCallback, popup);
  }

  $(document).on('click', '.task_tracked_time_input, .tracked_time_input', function () {
    var taskDurationInput = $(this);
    var popup = taskDurationInput.closest('.ui-dialog');
    popup.hide();
    var saveCallback = function (h, m) {
      var time = h + ':' + m;
      taskDurationInput.val(time);
    }
    initializeZtimePForTaskDuration(taskDurationInput.val(), saveCallback, popup);
  });

  function initializeZtimePForTaskDuration(taskDuration, saveCallback, popup) {
    var options = {};
    var parsedTimeDuration = parseTime(taskDuration);
    options.hour = parsedTimeDuration.hours || '';
    options.min = parsedTimeDuration.minutes || '';
    options.duration = true;
    options.clickEvents = {
      onSave: function(h, m, ampm) {
        popup.show();
        saveCallback(h, m, ampm);
      },
      onClose: function () { popup.show(); }
    };
    initializeZTimeP(options);
    $('.ztimep-open').trigger(clickEventType);
  }
});
