import { showSpinner, hideSpinner } from './helpers';

$(document).ready(function () {
  $('#contr_act_comment').on('keypress', function (e) {
    if (e.which === 13) {
      var calId = $('.contr_act_comments_section').data('calId');
      var url = '/contr_act_locs/' + calId + '/contr_act_comments.js';
      var message = $('#contr_act_comment').val();
      showSpinner();
      $.ajax({
        url: url,
        type: 'POST',
        data: { 'message': message },
        success: function () {
          $('#contr_act_comment').val('');
          hideSpinner();
        }
      });
    }
  });
});
