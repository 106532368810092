import { clickEventType } from './helpers';

$(document).ready(function () {
  $(document).on(clickEventType, '#pb-visual-mode', function () {
    if ($(this).text() === 'Visual mode') {
      $(this).text('Exit visual mode');
      mermaidAPI = mermaid.mermaidAPI;
      mermaidAPI.initialize({
        startOnLoad: false,
        theme: 'neutral'
      });

      var insertSvg = function (svgCode, bindFunctions) {
        $('#visual-mode-pic').html(svgCode);
      };
      var graph = buildGraph();
      var graphDefinition = 'graph TB\n' + graph;
      mermaidAPI.render('id1', graphDefinition, insertSvg);
      var width = $('#id1').css('max-width');
      $('#id1').css('max-width', 0);
      $('#id1').css('min-width', width);
    } else {
      $('#visual-mode-pic').html('');
      $(this).text('Visual mode');
    }
    $('#plan_builder_activities').toggle();
    $('#visual-mode-pic').toggle();

    $('#plan-builder-templates-popup').toggle();
    $('#plan-builder-mode').toggle();
    $('#create-plan').toggle();
    $('#plan-builder-save-template-popup').toggle();
    $('#pb-control-elements').toggle();
  });

  function buildGraph() {
    var res = [];
    var uniqueValues = [];
    var tree = getActsTree();
    var values = buildHierarchy(tree);
    values.forEach(function (el, index) {
      var rootName = 'id' + el.value.id;
      var part = processRootNode(el, rootName, res);
      res.concat(part);
    });
    $.each(res, function (i, el) {
      if ($.inArray(el, uniqueValues) === -1) uniqueValues.push(el);
    });
    return uniqueValues.join('\n');
  }

  function processRootNode(el, rootName, res) {
    var root = rootName + '["' + el.value.name + '(' + el.value.duration + ')"]';
    res.push(root);
    if (el.children) {
      el.children.forEach(function (c, i) {
        var childElName = 'id' + c.value.id;
        var lagLead = c.value.lag_lead_count;
        var arrowText = '';
        if (lagLead === 0) {
          arrowText = '-->';
        } else {
          var lagLeadType = lagLead > 0 ? 'Lag' : 'Lead';
          arrowText = '--' + lagLeadType + ':' + Math.abs(lagLead) + '-->';
        }
        var child = rootName + arrowText + childElName + '["' + c.value.name + '(' + c.value.duration + ')"]';
        res.push(child);
        processRootNode(c, childElName, res);
      });
    }
    return res;
  }

  function getActsTree() {
    var result = [];
    $('#plan-builder-activities tr').each(function () {
      var id = parseInt($(this).attr('data-id'), 10);
      var name = getActName(id);
      var duration = getActDuration(id);
      if ($(this).find('.precedors .precedor').length === 0) {
        result.push({ id: id, name: name, duration: duration, Parent: '' });
      } else {
        $(this).find('.precedors .precedor').each(function () {
          var lagLeadCount = 0;
          if ($(this).data('lagLeadCount') !== undefined) {
            lagLeadCount = $(this).data('lagLeadCount');
          }
          var parentId = $(this).data('id');
          result.push({ id: id, name: name, duration: duration, lag_lead_count: lagLeadCount, Parent: parentId });
        });
      }
    });
    return result;
  }

  function getActDuration(actId) {
    return $('#plan-builder-activities tr[data-id=' + actId + ']').find('.duration').text();
  }

  function getActName(actId) {
    return $('#plan-builder-activities tr[data-id=' + actId + ']').find('.name').text();
  }

  function buildHierarchy(arry) {
    var roots = []; var children = {};
    for (var i = 0, len = arry.length; i < len; ++i) {
      var item = arry[i];
      var p = item.Parent;
      var target = !p ? roots : (children[p] || (children[p] = []));
      target.push({ value: item });
    }
    var findChildren = function (parent) {
      if (children[parent.value.id]) {
        parent.children = children[parent.value.id];
        for (var i = 0, len = parent.children.length; i < len; ++i) {
          findChildren(parent.children[i]);
        }
      }
    };
    for (var i = 0, len = roots.length; i < len; ++i) {
      findChildren(roots[i]);
    }
    return roots;
  }
});
