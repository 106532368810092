import {
  clickEventType,
  setDefaultScaleForPlanView,
  setScaleForPlanView
} from './helpers';

$(document).ready(function () {
  $(document).on(clickEventType, '#zoom_out', function () {
    changeScale('zoom_out');
  });

  $(document).on(clickEventType, '#zoom_in', function () {
    changeScale('zoom_in');
  });

  function getTransformValue(element, property) {
    var values = element[0].style.webkitTransform.split(')');
    for (var key in values) {
      var val = values[key];
      var prop = val.split('(');
      if (prop[0].trim() === property) { return prop[1]; }
    }
    return false;
  }

  function changeScale(operation) {
    var initial = getScale($('.admin-table'));
    var scale = 1.0;
    if (initial) { scale = initial; }
    var limit = 1.0;
    var step = 0.1;
    if (operation === 'zoom_out') {
      limit = 0.3;
      scale = scale - step;
      if (scale < limit) { scale = limit; }
    } else {
      scale = scale + step;
      if (scale > limit) { scale = limit; }
    }
    var scrolled = $('.scrollable-table').scrollTop();
    $('.admin-table thead tr > *').css('top', scrolled / scale);
    setScaleForPlanView(scale);
  }

  window.addEventListener('resize', function () {
    if ($('#plan_view').length !== 1) { return; }
    if ($(window).width() < 768) {
      var scale = getScale($('.admin-table'));
      setScaleForPlanView(scale);
    } else {
      setDefaultScaleForPlanView();
    }
  }, false);

  function getScale(element) {
    return parseFloat(getTransformValue(element, 'scale'));
  }
});
