import { openPopup } from '../helpers';
import {
  checkHovering,
  showTaskDetails,
  markExtraEmployees,
  stylePopup,
  getDateFromEmployeeDay,
  getProjectId,
  getSupervisorId,
  openHover,
  showManagePlannedEmployeesPopup
} from './shared';

$(document).ready(function () {
  $(document).on('click', '#tasks_planner_view .employee_hover', function () {
    var projectId = getProjectId($(this));
    var supervisorId = getSupervisorId($(this));
    var competencyId = getCompetencyId($(this));
    var taskId = getTaskId($(this));
    var taskClass = getTaskClass($(this));
    var date = getDateFromEmployeeDay($(this));
    var data = {
      project_id: projectId,
      supervisor_id: supervisorId,
      competency_id: competencyId,
      task_id: taskId,
      task_class: taskClass,
      date: date
    };
    $.ajax({
      url: '/planned_employee_days/employees',
      type: 'GET',
      data: data,
      success: function (data) {
        var selector = '#employees_popup';
        var openCallback = function () {
          var requiredLabour = parseInt($(selector).find('#required_labour').val(), 10);
          var employees = $(selector).find('.assigned_to_task_employee');
          markExtraEmployees(employees, requiredLabour);
          $(selector).data('projectId', projectId);
          $(selector).data('supervisorId', supervisorId);
          $(selector).data('date', date);
          $(selector).data('competencyId', competencyId);
          $(selector).data('taskId', taskId);
          $(selector).data('taskClass', taskClass);
          stylePopup(selector);
        };
        var closeCallback = function () {
          $(selector).dialog('destroy');
          drawPlanner(false);
        };
        openPopup(selector, data, openCallback, closeCallback);
      }
    });
    return false;
  });

  function getTaskId(employeesCounter) {
    return employeesCounter.closest('.allocated').find('.task_template').first().data('taskId');
  }

  function getTaskClass(employeesCounter){
    return employeesCounter.closest('.allocated').find('.task_template').first().data('taskClass');
  }

  function getCompetencyId(employeesCounter) {
    return employeesCounter.closest('.allocated').find('.task_template').first().data('competencyId');
  }

  $(document).on('mouseover', '#tasks_planner_view .employee_hover', function (event) {
    if (checkHovering(event, this)) {
      var context = this;
      var projectId = getProjectId($(this));
      var supervisorId = getSupervisorId($(this));
      var competencyId = getCompetencyId($(this));
      var taskId = getTaskId($(this));
      var taskClass = getTaskClass($(this));
      var date = getDateFromEmployeeDay($(this));
      var data = {
        project_id: projectId,
        supervisor_id: supervisorId,
        competency_id: competencyId,
        task_id: taskId,
        task_class: taskClass,
        date: date
      };
      var callback = function () {
        hideElementsForEmployeesHoverPopup();
        var requiredLabour = parseInt($('#hover_popup #required_labour').val(), 10);
        var employees = $('#hover_popup .assigned_to_task_employee');
        markExtraEmployees(employees, requiredLabour);
      };
      $.ajax({
        url: '/planned_employee_days/employees',
        type: 'GET',
        data: data,
        success: function (data) {
          openHover(data, context, callback);
        }
      });
    }
  });

  function hideElementsForEmployeesHoverPopup() {
    $('#hover_popup #manage_employees').hide();
  }

  $(document).on('click', '#employees_popup #manage_employees', function () {
    var data = getDataForManagePlannedEmployeesPopup($('#employees_popup').data())
    $('#employees_popup').dialog('destroy');
    showManagePlannedEmployeesPopup(data);
  });

  function getDataForManagePlannedEmployeesPopup(dataAttributes) {
    return {
      date: dataAttributes['date'],
      competency_id: dataAttributes['competencyId'],
      project_id: dataAttributes['projectId'],
      supervisor_id: dataAttributes['supervisorId'],
      task_id: dataAttributes['taskId'],
      task_class: dataAttributes['taskClass']
    };
  }

  $(document).on('click', '#manage_planned_employees_popup #toggle-other-employees', function () {
    $('#manage_planned_employees_popup #other_employees').toggle();
  });

  function getAssignedToPlannedTaskEmployees() {
    var notPersistedEmployeeIds = $('#manage_planned_employees_popup #not_persisted_employees .employee').filter(function () {
      return $(this).hasClass('planned') || $(this).hasClass('actual');
    }).map(function () {
      return $(this).data('id');
    }).toArray();
    var persistedEmployeeIds = $('#manage_planned_employees_popup #persisted_employees .employee').filter(function () {
      return !$(this).data('actual') && $(this).hasClass('actual');
    }).map(function () {
      return $(this).data('id');
    }).toArray();
    return notPersistedEmployeeIds.concat(persistedEmployeeIds);
  }

  function getDataForSavingPlannedEmployees() {
    var employeeIds = getAssignedToPlannedTaskEmployees();
    var requiredEmployees = $('#required_employees').val();
    var data = {};
    if ($('#manage_planned_employees_popup').is(':visible')) {
      data = dataForSavingPlannedEmployeesFromPopup($('#manage_planned_employees_popup'));
    } else {
      data = dataForSavingPlannedEmployeesFromPopup($('#employees_popup'));
    }
    data.employee_ids = employeeIds;
    data.employee_ids_for_unassign = employeeIdsForUnassign();
    data.required_employees = requiredEmployees;
    return data;
  }

  function employeeIdsForUnassign() {
    return $('#manage_planned_employees_popup #persisted_employees .employee').filter(function () {
      var wasActual = $(this).data('actual');
      var wasPlanned = $(this).data('planned');
      var isActual = $(this).hasClass('actual');
      var isPlanned = $(this).hasClass('planned');
      return employeeIsForUnassign(wasActual, wasPlanned, isActual, isPlanned);
    }).map(function () {
      return $(this).data('id');
    }).toArray();
  }

  function employeeIsForUnassign(wasActual, wasPlanned, isActual, isPlanned) {
    var previousState = [wasActual, wasPlanned].filter(function (el) { return el; });
    var currentState = [isActual, isPlanned].filter(function (el) { return el; });
    return currentState.length < previousState.length;
  }

  function dataForSavingPlannedEmployeesFromPopup(popup) {
    var plannedFrom = popup.data('plannedFrom') || popup.data('date');
    var plannedTo = popup.data('plannedTo') || popup.data('date');
    var taskId = popup.data('taskId');
    var taskIds = popup.data('taskIds') || [taskId];
    var taskClass = popup.data('taskClass');
    return {
      task_ids: taskIds,
      task_class: taskClass,
      date: popup.data('date'),
      planned_from: plannedFrom,
      planned_to: plannedTo,
      project_id: popup.data('projectId'),
      supervisor_id: popup.data('supervisorId'),
      competency_id: popup.data('competencyId')
    };
  }

  $(document).on('click', '#manage_planned_employees_popup #save_employees', function () {
    var data = getDataForSavingPlannedEmployees();
    $.ajax({
      url: 'planned_employee_days/manage_planned_employees',
      type: 'PUT',
      data: data,
      success: function (data) {
        if (data.status === 'ok') {
          $('#manage_planned_employees_popup').dialog('destroy');
          if ($('#planner-mobile').length) {
            var date = $('#manage_planned_employees_popup').data('date');
            var taskId = $('#manage_planned_employees_popup').data('taskId');
            var competencyId = $('#manage_planned_employees_popup').data('competencyId');
            var projectId = $('#manage_planned_employees_popup').data('projectId');
            var supervisorId = $('#manage_planned_employees_popup').data('supervisorId');
            var task = findTaskMobile(date, projectId, supervisorId, competencyId, taskId);
            var expandableArea = task.find('.expandable_area');
            showTaskDetails(expandableArea);
          } else {
            $('#employees_popup').remove();
            drawPlanner(false);
          }
          $('body').css({ overflow: 'inherit' });
        }
      }
    });
    return false;
  });

  function findTaskMobile(date, projectId, supervisorId, competencyId, taskId) {
    return $('.task_mobile').filter(function () {
      var task = $(this);
      var competencyIdMatched = (task.data('competencyId') === competencyId) && (competencyId > 0);
      var taskIdMatched = (task.data('taskId') === taskId) && (taskId > 0);
      return (task.data('projectId') === projectId) &&
             (task.data('supervisorId') === supervisorId) &&
             (task.data('date') === date) &&
             (competencyIdMatched || taskIdMatched);
    });
  }

  $(document).on('click', '#manage_planned_employees_popup .employee', function () {
    var dateStr = $('#manage_planned_employees_popup').data('date');
    var date = $.datepicker.parseDate('dd/mm/yy', dateStr);
    var onlyPlannedEmployees = $('#manage_planned_employees_popup').data('onlyPlannedEmployees');
    var classForDay = date <= new Date() ? 'actual' : 'planned';
    if (onlyPlannedEmployees) { classForDay = 'planned'; }
    if (!$(this).hasClass(classForDay)) {
      $(this).addClass(classForDay);
      $(this).removeClass('for_destroy');
      return;
    }
    if ($(this).hasClass('actual') && $(this).hasClass('planned')) {
      $(this).removeClass('actual');
    } else {
      $(this).removeClass(classForDay);
    }
    $(this).addClass('for_destroy');
  });

  $(document).on('click', '.task_mobile #manage_employees', function (e) {
    e.stopPropagation();
    var task = $(this).closest('.task_mobile');
    var data = getDataForManagePlannedEmployeesPopup(task.data())
    showManagePlannedEmployeesPopup(data);
  });

  $(document).on('keyup', '#search_for_employee', function () {
    var regexp = new RegExp($(this).val(), 'i');
    filterUIElementsByMatchedText(regexp, '.employee');
  });

  function filterUIElementsByMatchedText(regexp, selectorOfElementsForFiltering) {
    var callback = function (el, regexp) {
      var elementText = $(el).text();
      if (regexp.test(elementText)) {
        $(el).show();
      } else {
        $(el).hide();
      }
    };
    filterUIElements(regexp, selectorOfElementsForFiltering, callback);
  }

  function filterUIElements(regexp, selector, callback) {
    $(selector).each(function () {
      callback(this, regexp);
    });
  }
});
