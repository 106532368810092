import { clickEventType, openAllocatedPlantsPopup } from './helpers';

$(document).ready(() => {
  $(document).on(clickEventType, '#new-location', () => {
    const projectId = $('#project_id').data('projectId');

    $.ajax({
      url: `/projects/${projectId}/locations/new.js`,
      type: 'GET',
      success() {
        $('#location-form').dialog({
          modal: true,
          width: 'auto',
          resizable: false,
          title: 'New location',
          autoOpen: true,
          close() { $(this).dialog('destroy'); },
        });
      },
    });
  });

  $(document).on('change', '#location-form #location_location_type_id', () => {
    const projectId = $('#project_id').data('projectId');
    const locationTypeId = $('#location-form #location_location_type_id').val();
    const locationId = $('#location-form').data('id');

    $.ajax({
      url: `/projects/${projectId}/parent_locations`,
      type: 'GET',
      data: {
        location_type_id: locationTypeId,
        id: locationId,
      },
    });
  });

  const selectAllPlantsButton = '.select-all-plants';

  $('.search-choice-close').on('change', () => {
    document.querySelector(selectAllPlantsButton).textContent = 'Select All';
  });

  $(document).on('click', selectAllPlantsButton, () => {
    const plantIds = $('#plant_ids');
    plantIds.trigger('chosen:open');
    const selectOptions = document.querySelectorAll('.chosen-results>li');
    const hasUnSelect = Array.from(selectOptions).some(({ classList }) => classList.contains('active-result'));
    if (hasUnSelect) {
      $('#plant_ids option').prop('selected', true);
      document.querySelector(selectAllPlantsButton).textContent = 'Clear';
    } else {
      $('#plant_ids option').prop('selected', false);
      document.querySelector(selectAllPlantsButton).textContent = 'Select All';
    }
    plantIds.trigger('chosen:updated');
    plantIds.trigger('chosen:close');
  });

  $(document).on('click', '#update-allocated-plants', () => {
    let plantIds = $('#plant_ids').val();
    const id = $('#allocated-plants-popup #location_id').val();
    const projectId = $('#allocated-plants-popup #project_id').val();
    const url = `/projects/${projectId}/locations/${id}`;

    if (plantIds.length === 0) {
      plantIds = [''];
    }

    $.ajax({
      url,
      type: 'PUT',
      data: { location: { allocated_plant_ids: plantIds } },
      success() {
        $('#allocated-plants-popup').dialog('destroy');
      },
    });
  });
});
