import { parseTime } from './helpers';

function plantActivityChart(options) {
  const chartId = options.chartId;
  const data    = options.data.graph;

  var colorScale = d3.scaleOrdinal().domain(["planned", "unplanned", "delay"]).range(["#73C64C", "#73C64C", "#E15555"]);

  // Setup
  const margin = { top: 8, right: 8, bottom: 8, left: 8 };
  const height = 700;

  const timeColumnWidth        = 32;
  const timeColumnPaddingWidth = 8;
  const actsColumnTickWidth    = 24;
  const actsColumnWidth        = 48;

  const width =
    timeColumnWidth +
    timeColumnPaddingWidth +
    actsColumnTickWidth +
    actsColumnWidth;

  const svgWidth  = width  + margin.left + margin.right;
  const svgHeight = height + margin.top  + margin.bottom;

  // Process data
  const parseTime  = d3.timeParse("%H:%M");
  const formatTime = d3.timeFormat("%H:%M");

  data.shift_start = parseTime(data.shift_start);
  data.shift_end   = parseTime(data.shift_end);

  data.acts.forEach(d => {
    d.start = parseTime(d.start);
    d.end   = parseTime(d.end);
  });

  data.lifts = data.lifts.map(parseTime);

  // Scales and axes
  const y = d3
    .scaleTime()
    .domain([data.shift_start, data.shift_end])
    .range([0, height]);

  const yAxis = d3
    .axisLeft(y)
    .tickFormat(formatTime)
    .tickPadding(timeColumnPaddingWidth)
    .tickSizeOuter(0)
    .tickSizeInner(actsColumnTickWidth + actsColumnWidth);

  // Containers
  const container = d3
    .select(`#${chartId}`)
    .classed("plant-activity-chart", true);

  const svg = container
    .append("svg")
    .attr("width", svgWidth)
    .attr("height", svgHeight);

  const g = svg
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  const tooltip = container.append("div").attr("class", "chart-tooltip");

  // Time column
  const gTime = g
    .append("g")
    .attr("class", "axis")
    .attr(
      "transform",
      `translate(${timeColumnWidth +
        timeColumnPaddingWidth +
        actsColumnTickWidth +
        actsColumnWidth},0)`
    )
    .call(yAxis);

  // Acts column
  const gActs = g
    .append("g")
    .attr("class", "acts")
    .attr(
      "transform",
      `translate(${timeColumnWidth +
        timeColumnPaddingWidth +
        actsColumnTickWidth},0)`
    );

  // Track lines
  gActs
    .append("g")
    .selectAll("line")
    .data([0, actsColumnWidth])
    .join("line")
    .attr("class", "acts-track-line")
    .attr("x1", d => d)
    .attr("x2", d => d)
    .attr("y2", height);

  // Rects
  gActs
    .append("g")
    .selectAll("rect")
    .data(data.acts)
    .join("rect")
    .attr("y", d => y(d.start))
    .attr("width", actsColumnWidth)
    .attr("height", d => y(d.end) - y(d.start))
    .attr("fill", function (d) {

      switch (d.type) {
        case "Planned":
          return colorScale("planned")
          break;
        case "Unplanned":
          return colorScale("unplanned")
          break;
        case "Delay":
          return colorScale("delay")
          break;
        default:
          break;
      }
    })
    .attr("class", d => `act-rect ${d.type.toLowerCase()}`)
    .on("mouseenter", showTooltip)
    .on("mouseleave", hideTooltip)
    .on("mousemove", moveTooltip);

  // Crosses
  gActs
    .append("g")
    .selectAll("text")
    .data(data.lifts)
    .join("text")
    .style("pointer-events", "none")
    .attr("x", actsColumnWidth / 2)
    .attr("y", d => y(d))
    .attr("dy", "0.32em")
    .attr("text-anchor", "middle")
    .attr("font-size", 10)
    .text("×");

  // Tooltip
  function showTooltip(d) {
    d3.select(this).classed("active", true);
    tooltip.style("opacity", 1).text(d.text);
  }

  function hideTooltip() {
    d3.select(this).classed("active", false);
    tooltip.style("opacity", 0).text("");
  }

  function moveTooltip() {
    const pos = d3.clientPoint(container.node(), d3.event);
    tooltip.style("transform", `translate(${pos[0] + 8}px,${pos[1] + 8}px)`);
  }
}
