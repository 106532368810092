import { followLink } from './helpers';
import { stylePopup } from './employees_planner/shared';

$(document).ready(function(){
  $(function () {
    $('.next').addClass('disable');
    if ($('.digital').val() != null) {
      $('.digital').val(commafy($('.digital').val()));
    }
  });

  $(document).on('keyup', '.input, .optional-input', (event) => disableNextButton(event.target));
  $(document).on('click', '.input, .optional-input', (event) => disableNextButton(event.target));

  $(document).on('change', '#multiselect_input', changeSkipAndNextButtons);

  $(document).on('keyup', '.digital', function() {
    $(this).val(commafy($(this).val()));
  });

  $(document).on('click', '.radio-buttons', showInputs);

  $(document).on('click', '#email-form button[type=submit]', function(e) {
    e.preventDefault();
    var email = $('#user_email').val();
    checkUserPresence('#email-form', email);
  });

  $(document).on('click', '#phone-form button[type=submit]', function(e) {
    e.preventDefault();
    var mobNumber = $('#user_mob_number').val();
    var country = $('input[name="user[country]"]').val();
    checkUserPresence('#phone-form', mobNumber, country);
  });

  function showInputs() {
    if ($('#referral').is(':checked')) {
      $('.referee-data').removeClass('hidden');
    } else {
      $('.referee-data').addClass('hidden');
    }
    if ($('#other').is(':checked')) {
      $('.other-data').removeClass('hidden');
      $('.other-data #user_referral_source').attr('disabled', false)
    } else {
      $('.other-data').addClass('hidden');
      $('.other-data #user_referral_source').attr('disabled', true)
    }
  }

  function disableNextButton(input) {
    if(Array.from(document.getElementsByClassName($(input).attr('class'))).some(e => e.value.length !=0)) {
      $('.next').removeClass('disable');
      $('.skip, .info').hide();
      $('.next-skip').show();
    }
    else {
      $('.next').addClass('disable');
      $('.next-skip').hide();
      $('.skip, .info').show();
    }
    if($('.password').val() != null) {
      if($('.password').val().length >= 6) {
        $('.next').removeClass('disable');
      } else {
        $('.next').addClass('disable');
      }
    }
  }

  function changeSkipAndNextButtons() {
    if($('#multiselect_input').val() === '') {
      $('.next-skip').hide();
      $('.skip').show();
    }
    else {
      $('.skip').hide();
      $('.next-skip').show();
    }
  }

  function checkUserPresence(formId, emailOrPhoneNumber, country='') {
    var requestData = country ? { mob_number: emailOrPhoneNumber, country: country } : emailOrPhoneNumber;
    $.ajax({
      url: '/users/check_user_presence',
      type: 'POST',
      data: { 'email_or_phone_number': requestData },
      success: function (data) {
        if (data['exist']) {
          showConfirmationPopup("This " + emailOrPhoneNumber + " is already signed up to LinkNBuild. Would you like to Log in?");
        } else {
          $(formId).submit();
        }
      }
    });
  }

  function commafy(num) {
    num = num.replace(/,/g, '');
    return Number(num).toLocaleString('en');
  }

  function showConfirmationPopup(text) {
    $('#confirmation_dialog #dialog_text').text(text);
    $('#confirmation_dialog').dialog({
      modal: true,
      title: 'Confirmation dialog',
      autoOpen: true,
      resizable: false,
      width: 'auto',
      position: { my: "center", at: "center", of: "#user_email" },
      buttons: [
        {
          text: 'Yes',
          click: function () {
            var link = '/login';
            followLink(link);
            $('#confirmation_dialog').dialog('destroy');
          }
        },
        {
          text: 'No',
          click: function () {
            var link = '/';
            followLink(link);
            $('#confirmation_dialog').dialog('destroy');
          }
        }
      ],
      closeOnEscape: false,
      open: function(event, ui) {
          stylePopup($(this));
          $(".ui-dialog-titlebar-close", ui.dialog | ui).hide();
      },
      create: function(event, ui) {
        $('#body').css({ overflow: 'hidden' });
      },
      beforeClose: function(event, ui) {
        $('#body').css({ overflow: 'inherit' });
      }
    });
  }
});
