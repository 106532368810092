
import React from 'react';
import ReactDOM from 'react-dom';
import { format, parse, getTime, isAfter, eachDayOfInterval } from 'date-fns';
import { Calendar } from '@fullcalendar/core';
import interaction from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { showSpinner, hideSpinner } from './helpers';

import Tooltip from '../components/shared/tooltips/Tooltip';

import {
  isUserPlanner,
  getDuration,
  isUserStakeholder,
  allowedToSelectOnCalendar,
  canEditPlanItem
} from './helpers';

import {
  getVisiblePlantIds,
  initNavigation,
  setNumberOfDays,
  getNumberOfDays,
  triggerColumnRegeneration,
  getCurrentlyVisibleColumnCount,
  getVisiblePlantResources,
  getEnabledPlantIds,
  getCalendarView,
  getPlantInfo,
  setEventHoursMinMax,
  getWorkingAndEventHoursMinMax,
  getEvents,
  getWeatherForProjectId,
  calculateVisibleDaysForCalendar,
} from './plans_navigation';

import { setupPlannerWithTimeAndDateConfigured } from './plans';

let dailySummaryMetadata = {};
function updateDailySummaryMetadata(dailySummaries) {
  dailySummaryMetadata = dailySummaries;
}

function getDailySummaryMetadata() {
  return dailySummaryMetadata;
}

function getWeather(unixTimeStamp, projectId) {
  const weather = getWeatherForProjectId(projectId);

  if (!weather) {
    return null;
  }

  var brokenWeather = weather.forecast?.map(o => o?.error).includes(true);

  if (brokenWeather) {
    var brokenForecast = weather.forecast.find(function(hourForecast) {
      return hourForecast.time
    });

    brokenForecast['iconPath'] = weather.images[ brokenForecast?.icon ];
    return brokenForecast;
  }

  var lastWeather = Math.max(...weather.forecast?.map(o => o?.time));

  if (lastWeather < unixTimeStamp) {
    return null;
  }

  var currentForecast = weather.forecast.find(function(hourForecast) {
    return hourForecast.time <= unixTimeStamp
  });

  if (!currentForecast) {
    return null;
  }

  currentForecast['iconPath'] = weather.images[ currentForecast.icon ];

  return currentForecast;
}

function stripTimeZone(timeStr) {
  return timeStr.slice(0, -1);
}

function trueWeatherTimeCalculator(plantId, slotInfo) {
  const plantInfo = getPlantInfo(plantId);
  const plantProjectId = plantInfo.project_id;

  const plantTimeOffsetInMS = plantInfo.offset_from_utc * 1000;
  const calendarStartUTCTime = slotInfo.view.currentStart.getTime();
  const slotTimeOffset = slotInfo.date.getTime();

  return (plantTimeOffsetInMS + slotTimeOffset + calendarStartUTCTime) / 1000;
}

function weatherRenderer(utcSpanTime, plantId) {
  const projectId = getPlantInfo(plantId).project_id;

  const myWeather = getWeather(utcSpanTime, projectId);
  if (!myWeather) return ['', ''];

  var weatherError = typeof myWeather.error !== 'undefined';
  var weatherTooltipText = myWeather.summary + " with " + myWeather.windSpeed + "km/h" + " from " + myWeather.windDirection;
  var temperature = myWeather.temperature;
  if (temperature) {
    weatherTooltipText += " and temperature " + temperature + "°C";
  }
  var precipIntensity = myWeather.precipIntensity;
  if (precipIntensity) {
    weatherTooltipText += ", rainfall intensity " + precipIntensity + " mm/hr";
  }

  if (weatherError) {
    weatherTooltipText = 'Error: weather forecast not available';
  }

  var img = `<div class='weather-image' data-title='${weatherTooltipText}' data-icon='${myWeather.iconPath}'></div>`;
  if (!myWeather.warning && !weatherError) return [img, ''];

  var textStatusClass = 'fc-warning-text';
  var breachText      = '';

  if (weatherError && myWeather.error) {
    var errorText = '<div class="fc-wind ' + textStatusClass + '">' +
    '</div>'
    return [img + errorText, ''];
  }

  for (const breach of myWeather.breaches) {
    breachText = breachText + '<div class="fc-wind ' + textStatusClass + '">' +
    breach +
    '</div>'
  }

  return [img + breachText, 'fc-warning-bg'];
};

export const prepareMaxTime = (maxTime) => {
  if (maxTime == '00:00') {
    return '24:01';
  }

  const endsWithZeros = (time) => {
    return time.substr(time.length - 2, 2) == '00';
  }

  if (endsWithZeros(maxTime)) {
    return `${maxTime.substr(0,maxTime.length - 2)}01`;
  }

  return maxTime;
}

let expandedHeaderState = localStorage && localStorage.getItem('calendarInfoOpen') ? JSON.parse(localStorage.getItem('calendarInfoOpen')) : false;

function syncExpandedHeaderState(element) {
  const iconElement = element.querySelector('.fc-timegrid-axis .widget-header-icon')

  if (!iconElement) return;

  if (expandedHeaderState) {
    if (iconElement.classList.contains('plus')) {
      iconElement.classList.remove('plus');
      $('.js-plan-header-information').show("slow");
    } else {
      if (getDailySummaryMetadata()["plants"]) $('.js-plan-header-information').css('display', 'block');
    }
    if (!iconElement.classList.contains('minus')) iconElement.classList.add('minus');
  } else {
    if (iconElement.classList.contains('minus')) {
      iconElement.classList.remove('minus');
      $('.js-plan-header-information').hide("slow");
    } else {
       $('.js-plan-header-information').css('display', 'none');     
    }
    if (!iconElement.classList.contains('plus')) iconElement.classList.add('plus');
  }
}

function setExpandedHeaderState(state) {
  expandedHeaderState = state;
  localStorage && localStorage.setItem('calendarInfoOpen', JSON.stringify(state));
  syncExpandedHeaderState(document);
}

function toggleExpandedHeader() {
  expandedHeaderState = !expandedHeaderState;
  setExpandedHeaderState(expandedHeaderState);
}

export function createPlanner(calendarElement, extraOptions = {}, postRenderCallback) {
  extraOptions.initialView = extraOptions.view === 'TimelineByResource' ? 'resourceTimeline' : (extraOptions.duration === 7 ? 'resourceTimeGridWeek' : 'resourceTimeGridDay');

  delete extraOptions.view;
  
  if (extraOptions.duration) {
    extraOptions.duration = { days: extraOptions.duration };
  }

  const calendar = new Calendar(calendarElement, {
    plugins:     [ resourceTimeGridPlugin, resourceTimelinePlugin, interaction ],

    datesAboveResources: true,
    eventResourceEditable: true,
    schedulerLicenseKey: '0343511028-fcs-1739345174',
    timeZone:          "RemoteTimezone",
    height:            '100%',
    headerToolbar:     false,
    allDaySlot:        false,
    slotDuration:      "00:15:00",
    slotLabelInterval: "01:00:00",
    slotMinTime:       "06:00:00",
    slotMaxTime:       "18:00:01",
    displayEventTime:  false,
    selectable:        true,
    selectMirror:      true,
    unselectAuto:      false,
    editable:          false,
    lazyFetching:      false,
    slotLabelFormat: {
      hour:           'numeric',
      minute:         '2-digit',
      omitZeroMinute: false,
      meridiem:       'short'
    },
    resourceOrder: 'orderingOverride',
    resources: function(fetchInfo, successCallback, failureCallback) {
      successCallback(getVisiblePlantResources());
    },
    events: function(fetchInfo, successCallback, failureCallback) {
      const reRender = () => { 
        calendar.render();
        if (postRenderCallback) postRenderCallback()
      };
      getEvents(fetchInfo, successCallback, failureCallback, reRender);
    },
    longPressDelay: 500,
    loading: function( isLoading ) {
      if(isLoading) {
        showSpinner();
      } else {
        hideSpinner();
      }
    },

    viewDidMount: function(view) {
      var timeGridAxis = view.el.querySelector('.fc-timegrid-axis');

      if (timeGridAxis) {
        timeGridAxis.innerHTML = '<div class="widget-header-icon"></div>' + view.el.querySelector('.fc-timegrid-axis').innerHTML;
        syncExpandedHeaderState(view.el);
        $(view.el.querySelector('.fc-timegrid-axis .widget-header-icon')).unbind().click(function() {
          toggleExpandedHeader();
        });
      };
    },

    eventClick: function(info) {
      info.jsEvent.preventDefault();
      const plannedPlantActivityId = info.event.id;
      $.ajax({
        url: `/planned_plant_activities/${plannedPlantActivityId}/edit.js`,
        beforeSend: showSpinner(),
        type: 'GET',
        success(response) {
          hideSpinner();
        },
      });
    },

    eventClassNames: function(info) {
      const props = info.event.extendedProps;

      var activityTypeClass   = "";
      var activityMarkerClass = "";

      switch(props.activityStatus) {
        case 'requested':
          activityTypeClass = 'plant_planner__fc-event--requested_activity';
          break;
        case 'approved':
          activityTypeClass = 'plant_planner__fc-event--planned-plant-activity';
          break;
        case 'delete_requested':
          activityTypeClass = 'plant_planner__fc-event--requested-activity-deleted';
          break;
        case 'actual_plant_activity':
          activityTypeClass = 'plant_planner__fc-event--actual-plant-activity';
          break;
        default:
      }

      if (props.activityType === 'actual_plant_activity' || props.activityType === 'planned_plant_activity') {
        if      (info.isMirror)                     activityMarkerClass = 'gray';
        else if (props.isInFuture)                  activityMarkerClass = 'blue';
        else if (props.completed >= props.quantity) activityMarkerClass = 'green';
        else                                        activityMarkerClass = 'red';

        if      (props.isActual)                    activityMarkerClass = 'yellow';
      }

      var eventClasses = ['planner-event'];
      if (activityTypeClass) eventClasses.push(activityTypeClass);
      if (activityMarkerClass) eventClasses.push(activityMarkerClass);
      return eventClasses;
    },

    eventContent: function(info) {
      const element        = $(info.el);
      const activityId     = info.event.id;
      const durationInMins = (info.event.end - info.event.start) / 1000 / 60;
      const props          = info.event.extendedProps;

      const materialText   = props.material || '';
      const personName     = props.creator;
      const personInitials = props.creatorInitials;
      const companyName    = props.company;
      let quantityText     = '';

      if (props.completed !== undefined && props.quantity !== null && props.status !== 'requested' && props.status !== 'delete_requested') {
        quantityText = props.completed + '/' + props.quantity;
      }
      else if ((props.quantity !== null && props.activityType === 'delete_requested') || (props.quantity !== null && props.activityType === 'requested')) {
        quantityText = " × " + props.quantity;
      }

      const locationHTML       = (type) => (props.destination != undefined) ? `<div class="title-text ${type}">${props.source} to ${props.destination}</div>` : '';
      const activityNoteHTML   = `<div class="title-text long">${info.event.title}</div>`;
      const customerText       = personName && companyName && `${personName} (${companyName})`
      const customerHTML       = customerText ? `<div class="title-text long"> ${customerText}'</div>` : '';
      const abbrevCustomerHTML = customerText ? `<div class="title-text small"><acronym class="qtip" title="${customerText}"> ${personInitials} </acronym></div>` : '';
      const fcContent          = element.children('div.fc-content');

      var col1Text = '';
      var col2Text = '';

      let headerContent = '<div class="event-header">' +
        '<div class="header-content">' +
          '<div class="crane-icon"><img src="/assets/icon-crane-white-9daa2ba7b07c7e21dbdc92fd0a4a0569eea5f51d6768d1ecee46f38875511128.svg" /></div>' +
          '<div class="header-material">' + materialText + '</div>' +
          '<div class="crane-icon"><img src="/assets/icon-quantity-white-ce02880d28b43a93a6b4e4c1f6c8963973e1e09bdd5ab1ca042049d619c8a73f.svg" /></div>' +
          '<div class="header-quantity">' + quantityText + '</div>';

      if (durationInMins <= 15) {
          col2Text = locationHTML('small');
      } else if (durationInMins <= 30) {
          col1Text = activityNoteHTML;
          col2Text = '<div>' +
            locationHTML('small') +
            '<br />' +
            abbrevCustomerHTML +
            '</div>';
      } else if (durationInMins <= 45) {
          col1Text = locationHTML('long') + '<br />' +
            activityNoteHTML;
          col2Text = `<div class='identify'><br /><br />${abbrevCustomerHTML}</div>`;
      } else {
          col1Text = locationHTML('long') + '<br />' +
            activityNoteHTML + '<br />' +
            customerHTML;
      }

      const title = `<div class="fc-title">${col1Text}</div>`;

      headerContent += `${col2Text}</div>`;

      return { html: `${headerContent}</div>${title}` };
    },

    slotLabelClassNames: function(slotInfo) {
      return ['plant-day-slot'];
    },

    slotLaneClassNames: function(slotInfo) {
      let trueWeatherTime;
      let firstVisiblePlantId;
      const visiblePlantIdList = getVisiblePlantIds();

      if ((slotInfo.view.type === 'resourceTimeGridDay') && (visiblePlantIdList.length > 0))  {
        firstVisiblePlantId = visiblePlantIdList[0];

        trueWeatherTime = trueWeatherTimeCalculator(firstVisiblePlantId, slotInfo);
      } else {
        return null;
      }

      var statusBg = '';

      const callbackResult = trueWeatherTime ? (weatherRenderer(trueWeatherTime, firstVisiblePlantId) || ['', '']) : ['', ''];

      statusBg = callbackResult[1] || '';

      if (statusBg.length > 0) return ['fc-warning-bg'];
      return null;
    },

    slotLabelContent: function(slotInfo) {
      const slotTime = slotInfo.date;
      let extraStyle = '';
      let trueWeatherTime;
      let plantInfo;
      let plantProjectId;
      let firstVisiblePlantId;
      const visiblePlantIdList = getVisiblePlantIds();

      if ((slotInfo.view.type === 'resourceTimeGridDay') && (visiblePlantIdList.length > 0)) {
        extraStyle = ' style="position: absolute;" ';

        firstVisiblePlantId = visiblePlantIdList[0];

        trueWeatherTime = trueWeatherTimeCalculator(firstVisiblePlantId, slotInfo);
      } else {
        return slotInfo.text;
      }
 
      var weather = '';
      var isLabeled = true;
      var axisHtml = '';
      var thisHtml = '';

      const callbackResult = trueWeatherTime ? (weatherRenderer(trueWeatherTime, firstVisiblePlantId) || ['', '']) : ['', ''];

      if (isLabeled) {
        weather = callbackResult[0] || '';
      }

      thisHtml = slotInfo.text;
      if (slotInfo.view.type !== "resourceTimeGridWeek") {
        thisHtml = thisHtml + '<div ' + extraStyle + ' class="fc-weather">' + weather + '</div>';
      };

      return {html: thisHtml};
    },

    resourceLabelContent: function(resource) {
      const currentDate = resource.date;
      const plantId = resource.resource.id;
      const plantName = '<h3>' + $('#plants-info > div[data-plant-id="' + plantId + '"]').data('plant-name') + '</h3>';
      let dailySummary = '';
      let plantHeaderData;
      let currentShortDate;

      if (currentDate) {
        currentShortDate    = format(currentDate, 'yyyy-MM-dd');
        plantHeaderData     = getPlantHeaderData(plantId, currentShortDate);
        const operator      = plantHeaderData.operator || $('#plants-info > div[data-plant-id="' + plantId + '"]').data('operator');
        const lastActivity  = plantHeaderData.lastActivity || $('#plants-info > div[data-plant-id="' + plantId + '"]').data('last-activity');
        const lastUpdated   = plantHeaderData.lastUpdated || 'None';
        dailySummary        =
          '<div class="last-updated">' +
            '<div class="plant-header-icon">' +
              '<div data-react-class="shared/tooltips/PlanHeaderTooltip" data-react-props="{&quot;type&quot;:&quot;lastUpdated&quot;}" data-react-cache-id="shared/tooltips/PlanHeaderTooltip-0"></div>' +
            '</div>' +
            '<div class="plant-header__left-text">'+ lastUpdated + '</div>' +
          '</div>' +
          '<div class="crane-operator">' +
            '<div class="plant-header-icon">' +
              '<div data-react-class="shared/tooltips/PlanHeaderTooltip" data-react-props="{&quot;type&quot;:&quot;operator&quot;}" data-react-cache-id="shared/tooltips/PlanHeaderTooltip-0"></div>' +
            '</div>' +
            '<div class="plant-header__left-text">'+ operator + '</div>' +
          '</div>' +
          '<div class="last-activity">' +
            '<div class="plant-header-icon">' +
              '<div data-react-class="shared/tooltips/PlanHeaderTooltip" data-react-props="{&quot;type&quot;:&quot;lastActivity&quot;}" data-react-cache-id="shared/tooltips/PlanHeaderTooltip-0"></div>' +
            '</div>' +
            '<div class="plant-header__left-text">'+ lastActivity + '</div>' +
          '</div>';
      }

      const instantPpaButton =
        '<div class="plant-header__left-text instant-container">' +
          '<a class="button" href="/planned_plant_activities/new?status=instant&plant_id=' + plantId + '" data-remote="true">Instant booking</a>' +
        '</div>';
      

      const daysToShow = localStorage.getItem('daysToShow') || 1;
      const dateStringToShow = '';
      const displayState = expandedHeaderState && plantHeaderData?.present ? 'block' : 'none';
      const header =
        '<div class="plant-column-header" data-plant-id=' + plantId + ' data-date="' + currentShortDate + '">' +
          dateStringToShow +
          plantName +
          '<div class="crane-header__plan-information js-plan-header-information" style="display: ' + displayState +'">' +
            dailySummary + 
            (isUserPlanner() ? instantPpaButton : '') +
          '</div>' +
        '</div>';

      return {html: header};
    },

    selectAllow: function(selectionInfo) {
      return allowedToSelectOnCalendar();
    },

    select: function(selectionInfo) {
      const plantId = selectionInfo.resource.id;
      const selectedColumnDate = selectionInfo.startStr.split('T')[0];
      const startDateTimeAsStr = stripTimeZone(selectionInfo.start.toISOString());
      const endDateTimeAsStr = stripTimeZone(selectionInfo.end.toISOString());
      const startDateTime = new Date(startDateTimeAsStr + "Z");
      const endDateTime = new Date(endDateTimeAsStr + "Z");
      const plantInfo = getPlantInfo(plantId);
      const plantTimeOffsetInMS = plantInfo.offset_from_utc * 1000;
      const offsetedStartDateTime = new Date(startDateTime.getTime() + plantTimeOffsetInMS);
      const timeIsInPast = isAfter(new Date(), offsetedStartDateTime);

      if (timeIsInPast) {
        $('#new-activity-form').dialog({
          modal:  true,
          title:  'You cannot plan an activity in the past',
          resize: false,
          close: function(){
            $(this).dialog('destroy')
            $('#new-activity-form').empty();
          }
        })
        this.unselect();
        return;
      }

      if (isUserStakeholder()) {
        this.unselect();

        const projectId = $('.plan_section').data('projectId');

        window.format = format;
        const duration = getDuration(startDateTime, endDateTime);

        $.ajax({
          url: '/planned_plant_activities/new.js',
          type: 'GET',
          data: { project_id: projectId, plant_id: plantId, start_time: startDateTimeAsStr, end_time: endDateTimeAsStr, date: selectedColumnDate },
          beforeSend: showSpinner(),
          success: function (response) {
            hideSpinner();
            $('#planned_plant_activity_duration').val(duration);
            $('#planned_activity_plant_id').val(plantId);
          }
        });

        return;
      }

      $.ajax({
        url: '/planned_plant_activities/new.js',
        type: 'GET',
        data: { plant_id: plantId, start_time: startDateTimeAsStr, end_time: endDateTimeAsStr, date: selectedColumnDate },
        beforeSend: showSpinner(),
        success: function (response) {
          hideSpinner();
          const duration = getDuration(startDateTime, endDateTime);
          $('#duration').val(duration);
          ReactRailsUJS.handleMount()
        }
      });
    },

    eventResize: function(eventInfo) {
      const activityId = eventInfo.event.id;
      const date = eventInfo.event.startStr.split('T')[0];
      const endTime = stripTimeZone(eventInfo.event.end.toISOString());
      
      $.ajax({
        url: '/planned_plant_activities/' + activityId,
        type: 'PUT',
        data: { planned_plant_activity: { date: date, end_time: endTime } },
        success(response) {
          setupPlannerWithTimeAndDateConfigured(date, true);
        },
      });
    },

    eventDrop: function(eventInfo) {
      const activity = eventInfo.event;
      const activityId = activity.id;
      const startTime  = stripTimeZone(activity.start.toISOString());
      const endTime    = stripTimeZone(activity.end.toISOString());
      const plantId    = eventInfo.newResource?.id || activity.extendedProps.plantId;
      const isRequester = activity.extendedProps.isRequester;
      const date = sessionStorage.getItem('selectedDate');
      var url = '';

      if (isRequester) {
        url = '/planned_plant_activities/' + activityId + '/request_changes';
      } else {
        url = '/planned_plant_activities/' + activityId;
      };

      $.ajax({
        url: url,
        type: 'PUT',
        data: { planned_plant_activity: { plant_id: plantId, start_time: startTime, end_time: endTime } },
        success(response) {
          setupPlannerWithTimeAndDateConfigured(date, true);
        },
      });
    },

    eventSourceSuccess: function(response) {
      var eventHoursMinMaxByDay = {};

      const pushToEventHoursMinMaxByDay = (date, min, max) => {
        if (!eventHoursMinMaxByDay[date]) {
          eventHoursMinMaxByDay[date] = { min: [min], max: [max] };
        } else {
          eventHoursMinMaxByDay[date].min.push(min);
          eventHoursMinMaxByDay[date].max.push(max);
        }
      };

      response.events.forEach(function(event) {
        const start = new Date(event.start.slice(0, 19));
        const end = new Date(event.end.slice(0, 19));
        
        const startDate = format(start, 'yyyy-MM-dd');
        const endDate = format(end, 'yyyy-MM-dd');

        const intervals = eachDayOfInterval({ start: start, end: end });

        intervals.forEach(function(interval) {
          const intervalDate = format(interval, 'yyyy-MM-dd');
          let minTime = '00:00';
          let maxTime = '24:00';

          if (intervalDate === startDate) {
            minTime = format(start, 'HH:mm');
          }

          if (intervalDate === endDate) {
            maxTime = format(end, 'HH:mm');
          }

          pushToEventHoursMinMaxByDay(intervalDate, minTime, maxTime);
        });
      });

      var eventHourDailyMinMax = {};

      Object.keys(eventHoursMinMaxByDay).forEach(function(date) {
        const min = eventHoursMinMaxByDay[date].min.sort().slice(0)[0];
        const max = eventHoursMinMaxByDay[date].max.sort().slice(-1)[0];

        eventHourDailyMinMax[date] = { min: min, max: max };
      });

      setEventHoursMinMax(eventHourDailyMinMax);

      const hourMinMax = getWorkingAndEventHoursMinMax(calculateVisibleDaysForCalendar(calendar));
      const hasMinTimeChanged = calendar.getOption('slotMinTime') !== hourMinMax.min;
      calendar.setOption('slotMinTime', hourMinMax.min);
      calendar.setOption('scrollTime', hourMinMax.min);
      calendar.setOption('slotMaxTime', hourMinMax.max);

      if (hasMinTimeChanged) calendar.scrollToTime(hourMinMax.min);
        
      updateDailySummaryMetadata(response.daily_summaries)
      updatePlannerColumnHeaders();
      return response.events;
    },

    ...extraOptions
  });

  return calendar
}

$(document).on("mouseenter", '.fc-event', function () {
  var activityId = $(this).data('activity-id');

  if (!activityId) return;

  var activityElement = $('.request-activity[data-activity-id=' + activityId + ']');
  activityElement.addClass('request-highlighted');
});

$(document).on("mouseleave", '.fc-event', function () {
  var activityId = $(this).data('activity-id');

  if (!activityId) return;

  var activityElement = $('.request-activity[data-activity-id=' + activityId + ']');
  activityElement.removeClass('request-highlighted');
});

function getPlantHeaderData(plantId, date) {
  const currentSummary = getDailySummaryMetadata()["plants"];
  const plantSummaryForAllDates = currentSummary ? currentSummary[plantId] : {};
  const plantSummaryData = plantSummaryForAllDates ? plantSummaryForAllDates[date] : {};

  const lastUpdated  = plantSummaryData?.lastUpdated;
  const lastActivity = plantSummaryData?.lastActivity;
  const operator     = plantSummaryData?.operator;
  const present      = !!plantSummaryData;

  return { lastUpdated, lastActivity, operator, present };
}

function updatePlannerColumnHeader(plantColumn) {
  const plantId = plantColumn.getAttribute('data-plant-id');
  const plantDate = plantColumn.getAttribute('data-date');
  const plantSummaryData = getPlantHeaderData(plantId, plantDate);
  const { lastUpdated, lastActivity, operator } = plantSummaryData;

  const header = $(plantColumn);

  if (header.find('.last-updated .plant-header__left-text')) header.find('.last-updated .plant-header__left-text').html(lastUpdated || 'None');
  if (header.find('.last-activity .plant-header__left-text')) header.find('.last-activity .plant-header__left-text').html(lastActivity || 'None');
  if (header.find('.crane-operator .plant-header__left-text')) header.find('.crane-operator .plant-header__left-text').html(operator || 'None');
}

function updatePlannerColumnHeaders() {
  const currentCalendarDate = $('#date').data('date');
  const currentSummary = getDailySummaryMetadata()["plants"];
  $('.plant-column-header').each(function (index, plantColumn) {
    updatePlannerColumnHeader(plantColumn);
  });

  syncExpandedHeaderState(document);

  initNavigation();
}

export function renderPlanner(calendar) {
  calendar.render();
}

function calendarHeight() {
  var height = $(document).height();
  return height - (height - $(window).height() < 30 ? 162 : 200);
}

export function renderWeatherIcons() {
  $('.weather-image').each((index, element) => {
    var title = $(element).data('title');
    var icon = $(element).data('icon');
    ReactDOM.render(<Tooltip title={title} html={`<img className='fc-weather-icon' src=${icon}/>`}></Tooltip>, element);
  });
}
