import { clickEventType } from './helpers';

$(document).ready(function () {
  $(document).on(clickEventType, '#box', function () {
    if ($(this).find('#submit_task').length === 0) {
      var activityId = $(this).data('activityId');
      var employeeId = $(this).data('employeeId');
      $.ajax({
        url: '/actual_plant_activities.js',
        type: 'POST',
        data: { actual_plant_activity: { employee_id: employeeId, activity_id: activityId } },
        success: function () {
        }
      });
    }
  });
});
