import { openPopup } from '../helpers';
import { stylePopup, getDateFromEmployeeDay, getProjectId, getEmployeeId, getSupervisorId } from './shared';
import { showFlashMessage } from '../flash';

$(document).on('click', '#add-to-project-button', function () {
  var startWeekDay = $('.week-day').first().data('date');
  var employeeIds = getSelectedEmployees();
  showNewPlannedEdPopup(startWeekDay, employeeIds);
});

$(document).on('click', '#employee_planner_view .add_to_project_button', function () {
  var startWeekDay = $('.week-day').first().data('date');
  var employeeId = $(this).data('employeeId');
  showNewPlannedEdPopup(startWeekDay, [employeeId]);
});

$(document).on('click', '.employee_day_mobile .add_to_project', function () {
  var startWeekDay = $(this).closest('.employee_day_mobile').find('.week_day_mobile').data('date');
  var employeeId = $('#current_employee_id').val();
  showNewPlannedEdPopup(startWeekDay, [employeeId]);
});

function showNewPlannedEdPopup(startWeekDay, employeeIds) {
  var popupSelector = '#add-to-project-popup';
  var openPopupCallback = function () {
    $(popupSelector).data('employeeIds', employeeIds);
  };
  showPlannedEdPopup('/planned_employee_days/add_to_project', popupSelector, { date: startWeekDay }, openPopupCallback);
}

function showPlannedEdPopup(url, popupSelector, data, callback) {
  $.ajax({
    url: url,
    dataType: 'html',
    data: data,
    success: function (data) {
      var openCallback = function () {
        stylePopup(popupSelector);
        $(popupSelector).find('.datepicker').datepicker({ dateFormat: 'dd/mm/yy' });
        if (callback) { callback(); }
      };
      var closeCallback = function () {
        $(popupSelector).dialog('destroy');
      };
      openPopup(popupSelector, data, openCallback, closeCallback);
    },
    error: function () {
      $(popupSelector).remove();
      showFlashMessage('Something went wrong.');
    }
  });
}

$(document).on('click', '.inner-grid-cell .element_name', function(){
  var tasksCounter = $(this).closest('.inner-grid-cell').find('.task_hover .tasks-counter');
  if (tasksCounter.length === 0) { return; }
  var date = getDateFromEmployeeDay(tasksCounter);
  var supervisorId = getSupervisorId(tasksCounter);
  var projectId = getProjectId(tasksCounter);
  var employeeId = getEmployeeId(tasksCounter);
  var ajaxParams = {
    date: date,
    supervisor_id: supervisorId,
    project_id: projectId,
    employee_id: employeeId
  };
  showPlannedEdPopup('/planned_employee_days/edit_planned_ed', '#planned_ed_popup', ajaxParams);
});

$(document).on('click', '#planned_ed_popup #save_planned_ed', function () {
  var plannedEdId = $('#planned_ed_popup #planned_ed_id').val();
  var projectId = $('#planned_ed_popup #project_id').val();
  var supervisorId = $('#planned_ed_popup #supervisor_id').val();
  var startsAt = $('#planned_ed_popup #from').val();
  var finishesAt = $('#planned_ed_popup #to').val();
  var data = {
    planned_ed_id: plannedEdId,
    project_id: projectId,
    supervisor_id: supervisorId,
    starts_at: startsAt,
    finishes_at: finishesAt
  };
  $.ajax({
    url: '/planned_employee_days/update_planned_ed',
    type: 'PUT',
    data: data,
    success: function () {
      $('#planned_ed_popup').dialog('destroy');
      $('body').css({ overflow: 'inherit' });
      $('#planned_ed_popup').remove();
      drawPlanner(false);
    }
  });
});

$(document).on('click', '#add-to-project-popup #save_planned_ed', addEmployeesToProject);

function addEmployeesToProject () {
  var employeeIds = $('#add-to-project-popup').data('employeeIds');
  var projectId = $('#add-to-project-popup #project_id').val();
  var supervisorId = $('#add-to-project-popup #supervisor_id').val();
  var fromDate = $('#add-to-project-popup #from').val();
  var toDate = $('#add-to-project-popup #to').val();
  var data = {
    project_id: projectId,
    supervisor_id: supervisorId,
    employee_ids: employeeIds,
    from: fromDate,
    to: toDate
  };
  $.ajax({
    url: '/planned_employee_days/assign_employees.js',
    data: data,
    dataType: 'json',
    method: 'POST',
    success: function() {
      $('body').css({ overflow: 'inherit' });
      $('#add-to-project-popup').dialog('destroy');
      $('#add-to-project-popup').remove();
      drawPlanner(false);
    },
    error: function (data) {
      var message = data.status || 'Something went wrong';
      showFlashMessage(message);
    }
  });
}

function getSelectedEmployees() {
  return $('.selected-employee').map(function (_, el) { return $(el).data('employeeId'); }).toArray();
}

$(document).on('click', '.actual_ed .fa-tick', function (e) {
  e.stopPropagation();
  var context = this;
  var callback = function (data) { successCallbackForTogglingEmployeeDayInMobile(context, 'actual_ed', 'planned_ed', data); };
  changeEmployeeDay(context, callback);
});

function changeEmployeeDay(context, successCallback) {
  var dataAttributes = $(context).closest('.employee_mobile').data();
  var data = {
    employee_id: dataAttributes.employeeId,
    project_id: dataAttributes.projectId,
    supervisor_id: dataAttributes.supervisorId,
    date: dataAttributes.date,
    actual_ed_id: $(context).data('employeeDayId'),
    state: $(context).parent().data('dayStatus')
  };
  $.ajax({
    url: '/planned_employee_days/manage_actual_employee_day',
    type: 'POST',
    dataType: 'json',
    data: data,
    success: successCallback
  });
}

function successCallbackForTogglingEmployeeDayInMobile(context, fromClass, toClass, data) {
  var employeeMobile = $(context).parents('.employee_mobile');
  if (employeeMobile.length === 0) { return; }
  var watch = employeeMobile.find('.fa-stopwatch');
  var taskCount = employeeMobile.find('.employees_counter');
  var from = fromClass === 'actual_ed' ? 'actual_status' : 'planned_status';
  var to = toClass === 'planned_ed' ? 'planned_status' : 'actual_status';
  employeeMobile.children('.employees_for_day_mobile').removeClass(from).addClass(to);
  $(context).closest('.' + fromClass).data('dayStatus', data.content).removeClass(fromClass).addClass(toClass);
  $(context).data('employeeDayId', data.aed_id);

  if (toClass === 'planned_ed') {
    watch.hide();
    taskCount.hide();
  } else {
    watch.show();
    taskCount.show();
  }
}

$(document).on('click', '.planned_ed .fa-tick', function (e) {
  e.stopPropagation();
  var context = this;
  var callback = function (data) { successCallbackForTogglingEmployeeDayInMobile(context, 'planned_ed', 'actual_ed', data); };
  changeEmployeeDay(context, callback);
});

$(document).on('click', '.actual-ed-manage', function () {
  var context = this;
  var date = getDateFromEmployeeDay($(this));
  var projectId = getProjectId($(this));
  var employeeId = getEmployeeId($(this));
  var supervisorId = getSupervisorId($(this));
  var actualEdId = $(this).data('actualEdId');
  var state = '';
  var classes = $(context).attr('class');
  switch (classes.split(' ')[0]) {
  case 'fa-actual-check':
    state = 'planned-actual';
    break;
  case 'fa-actual-times':
    state = 'planned';
    break;
  case 'fa-actual-exclamation':
    state = 'actual';
    break;
  }
  var data = {
    employee_id: employeeId,
    project_id: projectId,
    supervisor_id: supervisorId,
    date: date,
    actual_ed_id: actualEdId,
    state: state
  };
  $.ajax({
    url: '/planned_employee_days/manage_actual_employee_day',
    type: 'POST',
    dataType: 'json',
    data: data,
    success: function (data) {
      var aedClass = '';
      switch (data.content) {
      case 'planned-actual':
        aedClass = 'fa-actual-check';
        break;
      case 'planned':
        aedClass = 'fa-actual-times';
        break;
      case 'actual':
        aedClass = 'fa-actual-exclamation';
        break;
      }
      if (data.aed_id !== undefined) {
        $(context).data('actualEdId', data.aed_id);
      }
      $(context).removeClass(classes).addClass(aedClass + ' ' + classes.split(' ')[1]);
      if (data.content === 'planned-actual') {
        $($(context).parents()[2].lastElementChild.children).show();
        $($(context).parents()[2].children[1]).find('.actual-tasks-counter').show();
      } else if (data.content === 'planned') {
        $($(context).parents()[2].lastElementChild.children).hide();
        $($(context).parents()[2].children[1]).find('.actual-tasks-counter').hide();
      }
    },
    error: function () {
      showFlashMessage('Something went wrong.');
    }
  });
});

$(document).on('click', '#planned_ed_popup #delete_ped', function () {
  var data = {
    planned_ed_id: $('#planned_ed_popup #planned_ed_id').val()
  };
  $.ajax({
    url: '/planned_employee_days/destroy_planned_ed',
    type: 'delete',
    data: data,
    success: function (data) {
      $('body').css('overflow', 'inherit');
      $('#planned_ed_popup').dialog('destroy');
      if (data.status === 'ok') {
        drawPlanner(false);
      } else {
        showFlashMessage(data.status);
      }
    }
  });
});
