import { clickEventType } from './helpers';

$(document).ready(function () {
  $(document).on(clickEventType, '#new-location-type', function () {
    var projectId = $('#project_id').data('projectId');
    $.ajax({
      url: '/projects/' + projectId + '/location_types/new.js',
      type: 'GET'
    });
  });

  $(document).on(clickEventType, '#location-types tr', function () {
    var projectId = $('#project_id').data('projectId');
    var locationTypeId = $(this).data('locationTypeId');
    $.ajax({
      url: '/projects/' + projectId + '/location_types/' + locationTypeId + '/edit.js',
      type: 'GET'
    });
  });
});
