import { clickEventType, showSpinner, openPopup, sendAjax } from '../helpers';
import { stylePopup } from '../employees_planner/shared';
import { showFlashMessage } from '../flash';

$(document).ready(function () {
  $('#payrun-datepicker #start_date').datepicker({
    dateFormat: 'dd/mm/yy'
  });

  $('#payrun-datepicker #end_date').datepicker({
    dateFormat: 'dd/mm/yy'
  });

  $(document).on('change', '#payrun-datepicker #start_date, #payrun-datepicker #end_date', function() {
    var el = $(this).parent().prev();
    loadPayruns(el);
  });

  $(document).on(clickEventType, '#completed-payrun-button', function () {
    var el = $(this);
    loadPayruns(el);
  });

  function loadPayruns(element) {
    var showAll = element.data('showAll');
    var datapicker = $('#payrun-datepicker');
    var options = {
      dataType: 'html',
      beforeSend: function () { showSpinner(); },
      complete: function () { $('.spinner').hide(); },
      data: {
        'show_all': !showAll,
        'start_date': datapicker.find('input#start_date').val(),
        'end_date': datapicker.find('input#end_date').val()
      },
      success: function (data) {
        $('#payruns').replaceWith(data);
        element.data('showAll', !showAll)
        datapicker.toggle();
        $('#completed-payrun-button .tick').toggle();
      }
    }
    sendAjax('/pay_cycle', 'GET', options);
  }

  $(document).on(clickEventType, '.pay-now-button', function () {
    $('#pay-now-section').toggle();
  });

  $(document).on(clickEventType, '.pay-button', function () {
    var employeeHidden = $(this).parent();
    var data = {
      'employee_payment': {
        'amount_paid': employeeHidden.children('#amount_paid').val(),
        'date_paid': employeeHidden.children('.datepicker').val(),
        'paid_by_id': employeeHidden.children('#paid_by_id').val(),
        'paid_to_id': employeeHidden.children('#paid_to_id').val(),
        'purpose': 'pay_cycle'
      },
      'start_date': employeeHidden.children('#start_date').val(),
      'end_date': employeeHidden.children('#end_date').val(),
      'target_date': employeeHidden.children('#target_date').val(),
      'column_require_bibc': employeeHidden.children('#column_require_bibc').val(),
      'column_require_uif': employeeHidden.children('#column_require_uif').val(),
      'forwarding_url': employeeHidden.children('#forwarding_url').val()
    };
    var options = {
      dataType: 'html',
      data: data,
      beforeSend: function () { showSpinner(); },
      complete: function () { $('.spinner').hide(); },
      success: function (data) {
        $('.main-content').html(data);
        $('#pay-now-section').hide();
        $('.datepicker').datepicker({
          dateFormat: 'dd/mm/yy'
        });
        showFlashMessage('Payment added', 'success');
      },
      error: function () {
        showFlashMessage('Amount to pay must be greater than 0');
      }
    }
    sendAjax('/employee_payments.js', 'POST', options);
  });

  $(document).on('mouseover', '.payrun-payment', function (event) {
    var element = $(this);
    var tooltipContent = '';
    if(element.hasClass("ok")) {
      tooltipContent = 'Paid exact amount due for period';
    } else if(element.hasClass("missing")) {
      tooltipContent = 'No payment made yet';
    } else if(element.hasClass("more")) {
      tooltipContent = 'Paid more than due for peroid';
    } else if(element.hasClass("less")) {
      tooltipContent = 'Paid less than due for peroid';
    }
    element.tooltip({content: tooltipContent});
  });

  $(document).on(clickEventType, '.project-day', function(event) {
    var dayId = $(this).data('dayId');
    var forwardingUrl = $(this).data('forwardingUrl');
    var popupSelector = '#edit_popup';
    var url = '/actual_employee_days/' + dayId + '/edit_popup';
    var options = {
      dataType: 'html',
      data: { forwarding_url: forwardingUrl },
      beforeSend: function () { showSpinner(); },
      complete: function () { $('.spinner').hide(); },
      success: function (data) {
        var openCallback = function () {
          stylePopup(popupSelector);
        };
        var closeCallback = function () {
          $(popupSelector).dialog('destroy');
        };
        openPopup(popupSelector, data, openCallback, closeCallback);
      },
      error: function () {
        $(popupSelector).remove();
        showFlashMessage('Something went wrong.');
      }
    };
    sendAjax(url, 'GET', options);
  });

  $(document).on(clickEventType, '.add-employee-day-item', function(event) {
    var employeeId = $(this).data('employeeId');
    var date = $(this).data('date');
    var forwardingUrl = $(this).data('forwardingUrl');
    var popupSelector = '#edit_popup';
    var options = {
      dataType: 'html',
      data: {
        'date': date,
        'employee_id': employeeId,
        'forwarding_url': forwardingUrl
      },
      beforeSend: function () { showSpinner(); },
      complete: function () { $('.spinner').hide(); },
      success: function (data) {
        var openCallback = function () {
          stylePopup(popupSelector);
        };
        var closeCallback = function () {
          $(popupSelector).dialog('destroy');
        };
        openPopup(popupSelector, data, openCallback, closeCallback);
      },
      error: function () {
        $(popupSelector).remove();
        showFlashMessage('Something went wrong.');
      }
    };
    sendAjax('/pay_cycle/new_item', 'GET', options);
  });

  function showActivityNewForm(element, url, data) {
    var popupSelector = '#edit_popup';
    data['date'] = element.closest('#add-day-category').data('date');
    data['employee_id'] = element.closest('#add-day-category').data('employeeId');
    $(popupSelector).dialog('destroy');
    var options = {
      data: data,
      beforeSend: function () { showSpinner(); },
      complete: function () { $('.spinner').hide(); },
      success: function (data) {
        var openCallback = function () {
          stylePopup(popupSelector);
        };
        var closeCallback = function () {
          $(popupSelector).dialog('destroy');
        };
        openPopup(popupSelector, data, openCallback, closeCallback);
      },
      error: function () {
        $(popupSelector).remove();
        showFlashMessage('Something went wrong.');
      }
    };
    sendAjax(url, 'GET', options)
  }

  $(document).on(clickEventType, '.new-employee-activity', function(event) {
    var element = $(this);
    var data = {
      'status': element.closest('.category-item').data('status'),
      'individual': element.closest('.category-item').data('individual')
    };
    showActivityNewForm(element, '/actual_employee_days/new.js', data);
  });

  $(document).on(clickEventType, '.new-employee-payment', function(event) {
    showActivityNewForm($(this), '/employee_payments/new.js', {});
  });

  $(document).on(clickEventType, '.new-custom-pay-adjustment', function(event) {
    var element = $(this);
    var data = {
      'adjustment_type': element.closest('.category-item').data('adjustmentType'),
      'due_to_id': element.closest('#add-day-category').data('employeeId')
    };
    showActivityNewForm(element, '/custom_pay_adjustments/new.js', data);
  });
});
